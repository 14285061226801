import firebase from "firebase/app";
import "firebase/auth";

import { useEffect } from "react";

export default () => {
  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
      callback: () => {},
    });
  }, []);

  return <div id='recaptcha-container' />;
};
