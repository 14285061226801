import "assets/scss/custom/pages/registerSpeedDate.scss";
import { Box } from "@mui/system";
import useNavigator from "hooks/useNavigator";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import i18next from "i18next";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import { CircularProgress } from "@mui/material";
import moment from "moment";

export default () => {
	let { id } = useParams();
	const currentUser = useSelector(getUserProfile) || {};
	const navigate = useNavigator();
	const { showFittedMessagePopup } = usePopups();
	const [loading, setLoading] = useState(false);
	const [speedData, setSpeedDate] = useState({});

	const navigateToHomePage = () => {
		return navigate.navigateToHomePage();
	};

	const handleConfirmBtn = () => {
		setLoading(true);
		speedDatingService.confirmUserSpeedDating(id).then((data) => {
			if (data?.data?.success) {
				showFittedMessagePopup("אישור ההרשמה בוצע בהצלחה!");
				navigateToHomePage();
			} else {
				showFittedMessagePopup("ההרשמה נכשלה");
			}
			setLoading(false);
		});
	};

	const handleCancelBtn = () => {
		setLoading(true);
		speedDatingService
			.cancelUserSpeedDating(id)
			.then((data) => {
				if (data?.data?.success) {
					showFittedMessagePopup("הרישום בוטל בהצלחה");
					navigateToHomePage();
				} else {
					showFittedMessagePopup("ההרשמה נכשלה");
				}
				setLoading(false);
			})
			.catch((e) => console.log(e));
	};

	useEffect(() => {
		if (!id) return navigateToHomePage();
		speedDatingService.getSpeedDateInfo(id).then((data) => {
			if (!data) return navigateToHomePage();
			setSpeedDate(data);
		});
	}, []);

	return (
		<Box className="registerSpeedDateWrapper">
			<Box className="verificationSpeedDateWrapper">
				<h1>אישור השתתפות לספיד דייט - Jsmart</h1>

				{!speedData?.key ? (
					<CircularProgress size={40} className="text-secondary" />
				) : (
					<>
						<Box className="wrapDescAndRegBtn">
							<Box className="wrapRegDesc">
								<h2>{speedData?.title}</h2>
								<h3>{speedData?.description}</h3>
								{speedData?.eventDate && (
									<h4>
										{moment(speedData?.eventDate).format("בתאריך - DD/MM/YYYY בשעה - HH:mm:ss")}
									</h4>
								)}
							</Box>
						</Box>
						{!!loading ? (
							<CircularProgress size={40} className="text-secondary" />
						) : (
							<>
								<Button
									color="none"
									type="button"
									className="regSpeedBtn"
									onClick={handleConfirmBtn}>
									<span>{i18next.t(`speedDate.confirmParticipation`)}</span>
								</Button>
								<Button
									color="none"
									type="button"
									className="regSpeedBtn"
									onClick={handleCancelBtn}>
									<span>{i18next.t(`speedDate.cancelParticipation`)}</span>
								</Button>
							</>
						)}
					</>
				)}
			</Box>
		</Box>
	);
};
