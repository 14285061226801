import { createSelector } from "reselect";
import moment from "moment";
import _ from "lodash";

import ProfileFields from "consts/profileFields";
import Gender from "consts/gender";
import PendingStatus from "consts/pendingStatus";

export const getUserProfile = (state) => {
	return state.userProfile?.profile || {};
};

export const getUserProfileData = (state) => state.userProfile;

export const getFacebookPictureUrl = (state) => state.userProfile?.facebookPictureUrl;

export const getProfileAge = createSelector(getUserProfile, (profile) => {
	const res = moment().diff(profile.dob * 1000, "years");
	return res;
});

export const getProfileGender = createSelector(
	getUserProfile,
	(profile) => profile[ProfileFields.Gender] || Gender.Male
);

export const getProfileStance = createSelector(
	getUserProfile,
	(profile) => profile[ProfileFields.Stance]
);

export const getPictures = createSelector(getUserProfile, (profile) => profile.pictures);

export const geIsPicturesPrivate = createSelector(
	getUserProfile,
	(profile) => profile.isPicturesPrivate
);

export const getPicturesArray = createSelector(
	getUserProfile,
	(profile) =>
		(profile.pictures &&
			Object.keys(profile.pictures)
				.sort()
				.map((key) => ({ key, name: key, ...profile.pictures[key] }))) ||
		[]
);

export const getMainPicture = createSelector(getUserProfile, (profile) => profile.mainPictureName);
export const getHasContact = createSelector(getUserProfile, (profile) => profile.hasContacts);
export const getNotRejectedPictures = createSelector(getPictures, (pictures) => {
	return Object.keys(pictures || {}).filter(
		(key) => pictures[key].status !== PendingStatus.Rejected
	);
});

export const getIsProfileInit = createSelector(getUserProfile, (userProfile) => {
	return !_.isEmpty(userProfile);
});
