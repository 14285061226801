import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getImageKeyToDelete } from "redux/popups/selectors";
import useProfileImages from "hooks/useProfileImages";

export default () => {
	const { closePopup } = usePopups();
	const imageKey = useSelector(getImageKeyToDelete);
	const { deleteProfileImage } = useProfileImages();

	const _onDeleteImage = () => {
		deleteProfileImage(imageKey);
		closePopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>{i18next.t("Edit Pictures.Alert.Delete Image")}</ModalHeader>
			<ModalBody>{i18next.t("Edit Pictures.Alert.Are you sure to delete")}</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={_onDeleteImage}
					className="btn-rounded littleBtn">
					{i18next.t("Edit Pictures.Alert.Yes, Delete")}
				</Button>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Edit Pictures.Alert.No, don't Delete")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
