import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import Children from "consts/children";
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
	return (
			<DropDown
				field={ProfileFields.HasChildren}
				fieldOptions={Object.values(Children)}
				onChange={onChange}
				initSelected={profile.hasChildren}
				title={i18next.t("Edit Profile.Children")}
				translatePrefix="Children"
			/>
	);
};
