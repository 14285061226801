import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import { useEffect, useState } from "react";

import { fetchChatMessages, sendChatMessage } from "redux/chatMessages/actions";
import TemplateMessageButton from "components/TemplateMessageButton";
import { getChat, getIsNewChat } from "redux/chatReceived/selectors";
import { getCanSendMessage, getIsUserSubscribed } from "redux/chatMessages/selectors";
import usePopups from "hooks/usePopups";
import templateMsgService from "services/templateMsg.service";
import _ from "lodash";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";

export default ({ user, afterMessageSentHandler, toggle, closePopup }) => {
	const chatID = useSelector(getChat(user.uid));
	const isNewChat = useSelector(getIsNewChat(user.uid));
	const dispatch = useDispatch();
	const canSendMessage = useSelector(getCanSendMessage);
	const isUserSubscribed = useSelector(getIsUserSubscribed);
	const { showSubscriberLimitMessagesPopup, showNonSubscriberLimitMessagesPopup } = usePopups();
	const [templates, setTemplates] = useState([]);

	const handleOnMessageClick = (message, classImg = null) => {
		if (canSendMessage) {
			dispatch(sendChatMessage(chatID, message, user.uid, isNewChat, false, true, classImg));
			afterMessageSentHandler();
		} else {
			toggle && toggle();
			closePopup && closePopup();
			isUserSubscribed && showSubscriberLimitMessagesPopup();
			!isUserSubscribed && showNonSubscriberLimitMessagesPopup();
		}
	};

	useEffect(() => {
		dispatch(fetchChatMessages(user.uid, chatID));
	}, []);

	useEffect(() => {
		templateMsgService
			.fetchTemplateMsg()
			.then((data) => {
				if (!!data && !_.isEmpty(data)) {
					const newTemplates = Object.values(data)
						.map((item) => {
							let newTemp = item;
							newTemp.messageMale = item.messageMale.replace("[name]", user?.name);
							newTemp.messageFemale = item.messageFemale.replace("[name]", user?.name);
							return newTemp;
						})
						.filter((item) => {
							if (
								!item.active ||
								(item.type == "Birthday" && !getIfUserBirtdayInThisWeek(user?.dob))
							) {
								return false;
							}
							return true;
						});

					const sortedTemplates = _.sortBy(newTemplates, [
						(item) => {
							switch (item.type) {
								case "Birthday":
									return 1;
								case "Holiday":
									return 2;
								default:
									return 3;
							}
						},
						"priority",
					]);

					setTemplates(Object.values(sortedTemplates));
				}
			})
			.catch((e) => console.log(e));
	}, []);

	return (
		<div className="wrapEndeavorButtons">
			{(user?.gender == 0 || user?.gender == 1) &&
				!!templates &&
				!!templates?.length &&
				templates.map((temp) => (
					<TemplateMessageButton
						classImg={temp?.image ? `${temp.image}` : ""}
						message={user.gender == 0 ? temp.messageMale : temp.messageFemale}
						onClick={handleOnMessageClick}
					/>
				))}
		</div>
	);
};
