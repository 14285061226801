import produce from "immer";
import { isNil } from "lodash";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.PUBLIC_PROFILE_FETCH_PROFILE:
			if (isNil(state[action.payload])) {
				return {
					...state,
					...{
						[action.payload]: {
							status: ApiRequestStatus.PENDING,
						},
					},
				};
			}
			return state;
		case types.PUBLIC_PROFILE_FETCH_PROFILE_SUCCESSFULLY:
			const updateState = {
				...state,
				...{
					[action.userUID]: {
						...action.user,
						status: ApiRequestStatus.SUCCESSFULLY,
					},
				},
			};
			return updateState;
		case types.PUBLIC_PROFILE_FETCH_PROFILE_FAILED:
			return {
				...state,
				...{
					[action.payload]: {
						status: ApiRequestStatus.FAILED,
					},
				},
			};
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
