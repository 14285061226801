import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import UploadImageInput from "components/account/EditImages/UploadImageInput";
import imgUserEmpty from "assets/images/profilesImages/empty.png";
import { getFacebookPictureUrl } from "redux/profile/selectors";

export default ({ onChange, onValidityChange }) => {
	const [file, setFile] = useState();
	const refUploadInput = useRef();
	const facebookPictureUrl = useSelector(getFacebookPictureUrl);

	const handleSaveNewImage = (file) => {
		setFile(file);
		onValidityChange(true);
		onChange(file);
	};

	const onImageClick = () => refUploadInput.current.click();

	return (
		<div className="wrapOneImage empty">
			<span>Add Image</span>
			<UploadImageInput
				refInput={refUploadInput}
				handleSaveNewImage={handleSaveNewImage}
				urlForAutoOpening={facebookPictureUrl}
			/>
			<img src={file ? file : imgUserEmpty} onClick={onImageClick} />
		</div>
	);
};
