import i18next from "i18next";

import EnumBtn from "components/EnumBtn";
import SystemLanguage from "consts/systemLanguage";
import currentLanguage from "helpers/currentLanguage";
import usePopups from "hooks/usePopups";
import LocalStorageService from "services/localStorage";

export default () => {
  const { showLanguageSwitchPopup } = usePopups();

  const changeLanguageAction = (language) => {
    if (language === LocalStorageService.get("lang")) return;
    showLanguageSwitchPopup();
    LocalStorageService.set("langPopup", language);
  };

  return (
    <EnumBtn
      fieldOptions={Object.values(SystemLanguage)}
      onChange={changeLanguageAction}
      initSelected={currentLanguage()}
      title={i18next.t("Language.Header")}
      translatePrefix='Language.Items'
      isLang={true}
    />
  );
};
