import i18next from 'i18next';
import React from 'react';
import { AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';

import { Reasons } from 'consts/reasonsReport';

export default ({ sectionIndex , value , onChange }) => {
    
    return (
        <AccordionItem>
            <AccordionHeader targetId={sectionIndex}>
                {i18next.t(Reasons[sectionIndex])}
            </AccordionHeader>
            <AccordionBody accordionId={sectionIndex}>
                <div className="wrapradio">                 
                    <input value={value} onChange={onChange} type="text" placeholder={i18next.t('Detail')}></input>
                </div>
            </AccordionBody>
        </AccordionItem>
    );
}