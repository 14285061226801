import i18next from "i18next"
import { Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Switch from "components/Switch";
import Subtitle from "components/Subtitle";
import Communications from 'consts/communications';
import { getUserCommunications } from "redux/general/selectors";
import { updateCommunicationSettings } from "redux/general/actions";

export default () => {
    const dispatch = useDispatch();
    const communicationSettings = useSelector(getUserCommunications);

    const onChange = (key, newVal) => {
        dispatch(updateCommunicationSettings(key, newVal));
    }

    return  <Form>           
                <FormGroup>
                    {   
                        <>
                           <Subtitle className>
                                {i18next.t('Additional Settings.Communication Settings')}
                                </Subtitle>
                            {Communications.map(key => (
                                <Switch
                                    key={key}
                                    title={i18next.t(`Communications.${key}`)}
                                    value={communicationSettings[key]}
                                    onChange={e => onChange(key, e.target.checked)}
                                />
                            ))}
                        </>
                    }
                </FormGroup>
            </Form>
}


