import produce from "immer";
import { clone } from "lodash";

import ApiRequestStatus from "consts/apiRequestStatus";
import * as authTypes from "redux/auth/actionTypes";
import * as types from "./actionTypes";

const initialState = {
	data: {},
};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHAT_MESSAGES_FETCH_SUCCESSFULLY:
			state.data = {
				[action.chatID]: {
					messages: action.messages,
					status: ApiRequestStatus.SUCCESSFULLY,
					loadMoreStatus: ApiRequestStatus.INIT,
				},
			};
			break;
		case types.CHAT_MESSAGES_FETCH_FAILED:
			state.data = {
				[action.chatID]: {
					status: ApiRequestStatus.FAILED,
				},
			};
			break;
		case types.CHAT_MESSAGES_UPDATED:
			const messageIndex = state.data[action.chatID]?.messages.findIndex(
				({ id }) => id === action.message.id
			);
			if (messageIndex === -1) {
				return state;
			}
			const updatedMessages = clone(state.data[action.chatID]?.messages);
			updatedMessages[messageIndex] = action.message;

			state.data = {
				[action.chatID]: {
					messages: updatedMessages,
				},
			};
			break;
		case types.CHAT_MESSAGES_ADDED:
			state.data[action.chatID]?.messages.push(action.message);
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
