import i18next from "i18next";
import { useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";

export default () => {
	const currentUser = useSelector(getUserProfile) || {};
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const { currentUserProfile } = useSelector(getPopups);
	const collageUser = currentUserProfile || activeUserProfile;

	const { closePopup, showSubscriptionPopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("Non Subscriber To Subscriber Message Modal.Header")}</ModalHeader>
			<ModalBody>
				{i18next.t(`Non Subscriber To Subscriber Message Modal.Content_${currentUser.gender}`, {
					name: currentUser.name,
					collageName: collageUser.name,
				})}
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={showSubscriptionPopup}
					className="btn-rounded littleBtn">
					{i18next.t(
						`Non Subscriber To Subscriber Message Modal.Subscribe Now_${currentUser.gender}`
					)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
