import { Box } from "@mui/system";
import BlogCategoryPage from "./BlogCategoryPage";

export default function BlogPage() {
	return (
		<Box className="blogPageWrapper" width={"100%"}>
			<BlogCategoryPage isBlogPage={true} />
		</Box>
	);
}
