import i18next from "i18next";
import { Link } from "react-router-dom";

import usePopups from "hooks/usePopups";

export default () => {
	const { showFreezeAccountPopup } = usePopups();

	return (
		<Link to="#" onClick={showFreezeAccountPopup} className="freezeAccount">
			{i18next.t("Account Settings.Freeze account")}
		</Link>
	);
};
