import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import CommonRoute from "./CommonRoute";
import MainLayout from "layouts/mainLayout";
import LoginLayout from "layouts/loginLayout";
import EmptyLayout from "layouts/emptyLayout";
import CleanLayout from "layouts/cleanLayout";
import MessagesReceivedPage from "pages/MessagesReceivedPage";
import MessagesSentPage from "pages/MessagesSentPage";
import MessagesFilterPage from "pages/MessagesFilterPage";
import LikedMePage from "pages/LikedMePage";
import LikesPage from "pages/LikesPage";
import ViewedMePage from "pages/ViewedMePage";
import ViewsPage from "pages/ViewsPage";
import SearchPage from "pages/SearchPage";
import TermsPage from "pages/TermsPage";
import ContactPage from "pages/ContactPage";
import LoginEnterPhonePage from "pages/LoginEnterPhonePage";
import LoginPhoneVerificationPage from "pages/LoginPhoneVerificationPage";
import LoginQuestionsPage from "pages/LoginQuestionsPage";
import LogoutPage from "pages/LogoutPage";
import ProfileBlockedPage from "pages/ProfileBlockedPage";
import AccountPage from "pages/AccountPage";
import HomePage from "pages/HomePage";
import MaintenancePage from "pages/MaintenancePage";
import ForceAppDownload from "pages/ForceAppDownload";
import Unsubscribe from "components/Unsubscribe";
import ImpersonateUser from "components/tester/ImpersonateUser";
import UserPage from "pages/UserPage";
import ReplyContectUs from "pages/ReplyContectUs";
import BlogCategoryPage from "pages/BlogCategoryPage";
import BlogPage from "pages/BlogPage";
import BlogArticle from "components/blog/BlogArticle";
import SpeedDatingPage from "pages/SpeedDatingPage";
import RegisterSpeedDatingPage from "pages/RegisterSpeedDatingPage";
import SpeedDateRegistrationVerificationPage from "pages/SpeedDateRegistrationVerificationPage";

export default ({ children }) => {
	return (
		<Router>
			<Switch>
				<Route component={MaintenancePage} path="/maintenance" />
				<Route component={ForceAppDownload} path="/forcedownload" />
				<CommonRoute component={HomePage} exact layout={CleanLayout} path="/" />
				<Redirect exact from="/login" to="/login/phone" />
				<CommonRoute
					component={LoginEnterPhonePage}
					exact
					layout={LoginLayout}
					path="/login/phone"
				/>
				<CommonRoute component={ReplyContectUs} layout={CleanLayout} path="/replyContectUs/:key" />
				<CommonRoute component={ImpersonateUser} layout={CleanLayout} path="/token/:id" />
				<CommonRoute
					component={SpeedDatingPage}
					layout={CleanLayout}
					path="/speedDating/:urlName"
				/>
				<CommonRoute
					component={RegisterSpeedDatingPage}
					layout={CleanLayout}
					path="/registerSpeedDating/:urlName"
				/>
				<CommonRoute
					component={SpeedDateRegistrationVerificationPage}
					layout={CleanLayout}
					path="/verificationSpeedDating/:id"
				/>
				<CommonRoute component={UserPage} exact layout={LoginLayout} path="/user/:id" />

				<CommonRoute
					component={LoginPhoneVerificationPage}
					exact
					layout={LoginLayout}
					path="/login/phoneverify"
				/>
				<CommonRoute
					component={LoginQuestionsPage}
					exact
					layout={LoginLayout}
					path="/login/questions"
				/>
				<CommonRoute component={LogoutPage} exact layout={EmptyLayout} path="/logout" />
				<CommonRoute component={TermsPage} exact layout={EmptyLayout} path="/terms" />
				<CommonRoute component={ContactPage} exact layout={EmptyLayout} path="/contact" />
				<CommonRoute auth component={SearchPage} exact layout={MainLayout} path="/search" />
				<CommonRoute auth component={LikedMePage} exact layout={MainLayout} path="/likedme" />
				<CommonRoute auth component={LikesPage} exact layout={MainLayout} path="/likes" />
				<CommonRoute auth component={ViewedMePage} exact layout={MainLayout} path="/viewedme" />
				<CommonRoute auth component={ViewsPage} exact layout={MainLayout} path="/views" />
				<CommonRoute
					auth
					component={MessagesReceivedPage}
					exact
					layout={MainLayout}
					path="/messages"
				/>
				<CommonRoute
					auth
					component={MessagesReceivedPage}
					exact
					layout={MainLayout}
					path="/messages/received"
				/>
				<CommonRoute
					auth
					component={MessagesSentPage}
					exact
					layout={MainLayout}
					path="/messages/sent"
				/>
				<CommonRoute
					auth
					component={MessagesFilterPage}
					exact
					layout={MainLayout}
					path="/messages/filtered"
				/>
				<CommonRoute auth component={AccountPage} layout={MainLayout} path="/account" />
				<CommonRoute
					auth
					component={ProfileBlockedPage}
					exact
					layout={EmptyLayout}
					path="/ProfileBlocked"
				/>
				<CommonRoute component={Unsubscribe} exact layout={EmptyLayout} path="/unsubscribe" />
				<CommonRoute
					component={BlogCategoryPage}
					exact
					layout={MainLayout}
					path="/blog/category/:urlName"
				/>

				<CommonRoute component={BlogArticle} layout={MainLayout} path="/blog/:urlName" />
				<CommonRoute component={BlogPage} exact layout={MainLayout} path="/blog" />
				<Redirect from="*" to="/" />
			</Switch>
			{children}
		</Router>
	);
};
