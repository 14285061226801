import _ from "lodash";
import { createSelector } from "reselect";

import { getUserProfile } from "../profile/selectors";
import ProfileFields from "consts/profileFields";
import LoginSteps from "consts/loginSteps";
import Stance from "consts/stance";
import children from "consts/children";
import currentLanguage from "helpers/currentLanguage";
import { getIsPlatformWeb } from "redux/init/selectors";
import LocalStorageService from "services/localStorage";

export const getAuth = (state) => {
	return state.auth;
};

export const getLoginPhone = createSelector(getAuth, (auth) => auth.loginPhone);

export const getLoginWithPhoneStatus = createSelector(getAuth, (auth) => auth.loginWithPhoneStatus);

export const getLoginWithPasswordStatus = createSelector(
	getAuth,
	(auth) => auth.loginWithPasswordStatus
);

export const getLoggedInUserUID = createSelector(getAuth, (auth) => auth.user?.uid);

export const getResendCodeStatus = createSelector(getAuth, (auth) => auth.resendCodeStatus);

export const getFacebookImageUrl = createSelector(
	getAuth,
	(auth) => auth.facebookData?.data?.picture.data.url
);

export const getLoginWithFacebookErrorMessage = createSelector(
	getAuth,
	(auth) => auth.loginWithFacebookErrorMessage
);

export const getFacebookAccessToken = createSelector(getAuth, (auth) => auth.facebookAccessToken);

export const getLoggedInUser = createSelector(getAuth, (auth) => {
	return auth.user;
});

export const isUserLoggedIn = createSelector(getAuth, (auth) => {
	return !!(auth.user && auth.user.uid);
});

export const getIsNewUser = createSelector(getAuth, (auth) => auth.isNewUser);

export const getLoginSteps = createSelector(
	getUserProfile,
	getIsPlatformWeb,
	(profile, isPlatformWeb) => {
		if (_.isEmpty(profile)) {
			return [];
		}
		const conditions = getConditions(profile, isPlatformWeb);
		const result = Object.keys(conditions).filter((key) => conditions[key]);
		return result;
	}
);

export const getLoginWithPhoneErrorMessage = createSelector(
	getAuth,
	(auth) => auth.loginWithPhoneErrorMessage
);

export const getLoginWithGoogleStatus = createSelector(
	getAuth,
	(auth) => auth.loginWithGoogleStatus
);

const openEmailStep = () => {
	const storedLastDateMailPopupOpen = LocalStorageService.get("mpd"); //mpd = mail popup date
	const now = new Date().getTime();
	const THREE_DAYS_TIME = 259200000;
	// const DAY_TIME = 86400000;
	// const WEEK_TIME = 604800000;

	if (!storedLastDateMailPopupOpen) return true;

	//If 3 days has passed
	if (now - storedLastDateMailPopupOpen > THREE_DAYS_TIME) return true;
	return false;
};

const getConditions = (userProfile, isPlatformWeb) => {
	return {
		// [LoginSteps.Social]:
		// 	!userProfile[ProfileFields.EmailSkiped] &&
		// 	isPlatformWeb &&
		// 	_.isNil(userProfile[ProfileFields.Email]),
		[LoginSteps.Social]: false,
		[LoginSteps.Name]: _.isNil(userProfile[ProfileFields.Name]),
		[LoginSteps.Gender]: _.isNil(userProfile[ProfileFields.Gender]),
		[LoginSteps.Age]: _.isNil(userProfile[ProfileFields.Dob]),
		[LoginSteps.MartialStatus]: _.isNil(userProfile[ProfileFields.MaritalStatus]),
		[LoginSteps.Children]: _.isNil(userProfile[ProfileFields.HasChildren]),
		[LoginSteps.ChildrenCount]:
			_.isNil(userProfile[ProfileFields.ChildrenCount]) &&
			userProfile[ProfileFields.HasChildren] !== children.None,
		[LoginSteps.Stance]: _.isNil(userProfile[ProfileFields.Stance]),
		[LoginSteps.KeepShabbat]:
			_.isNil(userProfile[ProfileFields.Stance]) ||
			(userProfile[ProfileFields.Stance] === Stance.Mesorati &&
				_.isNil(userProfile[ProfileFields.KeepShabbat])),
		[LoginSteps.KosherFood]:
			_.isNil(userProfile[ProfileFields.Stance]) ||
			((userProfile[ProfileFields.Stance] === Stance.Mesorati ||
				userProfile[ProfileFields.Stance] === Stance.Datlash) &&
				_.isNil(userProfile[ProfileFields.EatKosherFood])),
		[LoginSteps.Height]: _.isNil(userProfile[ProfileFields.Height]),
		[LoginSteps.AboutMe]: _.isNil(userProfile[ProfileFields.AboutMe]),
		[LoginSteps.LivingCity]: _.isNil(userProfile[ProfileFields.Cites[currentLanguage()]]),
		[LoginSteps.UploadImage]:
			_.isNil(userProfile[ProfileFields.Pictures]) && !userProfile[ProfileFields.ImageSkiped],
		//[LoginSteps.Email]: !userProfile[ProfileFields.Email] && !!openEmailStep(userProfile),
		[LoginSteps.Email]:
			(!userProfile[ProfileFields.Email] ||
				(!!userProfile[ProfileFields.Email] && !userProfile[ProfileFields.IsVerifiedEmail])) &&
			!!openEmailStep(userProfile),
	};
};

export const getIsAuthChecked = createSelector(getAuth, (auth) => auth.isAuthChecked);
