import { useEffect } from "react";
import localStorage from "services/localStorage";
import AnonymousCallService from "services/anonymousCall";
export default () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const affId = urlParams.get("f");
		if (!!affId) {
			localStorage.set("affId", affId, 30);
			AnonymousCallService.clickEvent(affId);
		}
	}, []);

	return <></>;
};
