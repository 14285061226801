export default {
	"home evening": 1,
	"read and write": 2,
	cooking: 3,
	"artist and draw": 4,
	"theatre and shows": 5,
	"listen music": 6,
	lectures: 7,
	shopping: 8,
	"personal grouth spiritual": 9,
	"enrichment cources": 10,
	"concerts and opera": 11,
	"be with friends": 12,
	trips: 13,
	"parties and dance": 14,
	internet: 15,
	movies: 16,
	"music sing": 17,
	"sport fitness": 18,
	"studies and knowledge enrichment": 19,
	TV: 20,
	"ploitics and current event": 21,
};
