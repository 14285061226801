import { mergeMap, map, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import UserGeneralService from "services/userGeneral";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchUserGeneral = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(() => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      return UserGeneralService.fetchGeneral(loggedInUserUID);
    }),
    map((payload) => {
      return {
        type: types.USER_GENERAL_FETCH_SUCCESSFULLY,
        payload,
      };
    }),
    catchError((error) => {
      return of({
        type: types.USER_GENERAL_FETCH_FAILED,
        payload: error?.message,
      });
    }),
    repeat()
  );
