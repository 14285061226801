import Masonry from "react-masonry-css";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import SearchCard from "components/search/card/SearchCard";
import AnimatedBox from "components/AnimatedBox";
import { getSearchFreeTextFilter, getStatus } from "redux/search/selectors";
import { search } from "redux/search/actions";
import ApiRequestStatus from "consts/apiRequestStatus";
// import { getLikedMeStatus } from "redux/likedMe/selectors";
import { ReactComponent as HeartImage } from "assets/images/heart_2.svg";
import { getIsUserHasChatButFrozen } from "redux/chatReceived/selectors";
import { getEffortButtonTitle } from "redux/system/selectors";
import templateMsgService from "services/templateMsg.service";
import blocksService from "services/blocks";
import { useState, useEffect } from "react";
import _ from "lodash";
import { addBlockUser } from "redux/blocks/actions";

export default ({ users = [], ...rest }) => {
	const dispatch = useDispatch();
	const [templates, setTemplates] = useState([]);
	const [displayUsers, setDisplayUsers] = useState(users);

	const searchStatus = useSelector(getStatus);
	// const likedMeStatus = useSelector(getLikedMeStatus);
	const effortBtn = useSelector(getEffortButtonTitle);
	const history = useHistory();
	const currentPath = history.location.pathname;

	const isEmptyPage = displayUsers.length === 0;

	const isEmptySearch = () =>
		isEmptyPage && currentPath === "/search" && searchStatus === ApiRequestStatus.SUCCESSFULLY;

	// const isEmptyLikedMe = () =>
	// 	isEmptyPage && currentPath === "/likedme" && likedMeStatus === ApiRequestStatus.SUCCESSFULLY;

	useEffect(async () => {
		if (!users?.length) return setDisplayUsers([]);

		(await Promise.all(users.map((user) => blocksService.checkIfBlockedUser(user?.uid))))
			.filter((user) => !!user)
			.map((item) => {
				dispatch(addBlockUser(item));
			});

		setDisplayUsers(users);
	}, [users]);

	useEffect(() => {
		templateMsgService
			.fetchTemplateMsg()
			.then((data) => {
				if (!!data && !_.isEmpty(data)) {
					const newTemplates = Object.values(data).filter((item) => {
						if (!item.active) return false;
						return true;
					});
					setTemplates(newTemplates);
				}
			})
			.catch((e) => console.log(e));
	}, []);

	return (
		<>
			{displayUsers.length > 0 ? (
				<Masonry
					breakpointCols={{
						default: 4,
						1300: 3,
						1000: 2,
					}}
					className="wrapAllResults my-masonry-grid"
					columnClassName="my-masonry-grid_column">
					{displayUsers.map((user) => (
						<AnimatedBoxContainer
							key={user.uid}
							effortBtn={effortBtn}
							templates={templates}
							user={user}
							{...rest}
						/>
					))}
				</Masonry>
			) : isEmptySearch() ? (
				<EmptySearch />
			) : (
				<>
					{
						// isEmptyLikedMe() && <></>
						// <EmptyLikedMe />
					}
				</>
			)}
		</>
	);
};

const AnimatedBoxContainer = ({ user, effortBtn, templates, ...rest }) => {
	// const isUserHasChatButFrozen = useSelector(getIsUserHasChatButFrozen(user.uid));

	return (
		<AnimatedBox>
			<SearchCard
				key={user.uid}
				user={user}
				templates={templates}
				effortBtn={effortBtn}
				{...rest}
			/>
		</AnimatedBox>
	);
};

const EmptySearch = () => {
	const freeText = useSelector(getSearchFreeTextFilter);
	const dispatch = useDispatch();

	const onEmptySearch = () => {
		dispatch(search(""));
	};

	return (
		<div className="emptySearch">
			<div>
				{i18next.t("Search.No Results", { search: freeText })} {", "}
				<Link onClick={onEmptySearch}>{i18next.t("Search.Try Another Search")}</Link>
			</div>
		</div>
	);
};

const EmptyLikedMe = () => {
	return (
		<div className="emptyLikedMe">
			<div>
				<HeartImage />
				<div>{i18next.t("LikedMe.No Liked me")}</div>
			</div>
		</div>
	);
};
