import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

export const getPublicProfiles = state => state.publicProfiles;

const createDeepEqualSelector = userUid =>
  createSelectorCreator(defaultMemoize, (object, other) => {
    const isEq = isEqual(object[userUid], other[userUid]);
    return isEq;
  });
export const getUserPublicProfile = userUid =>
  createDeepEqualSelector(userUid)(getPublicProfiles, users => {
    return users[userUid];
  });
