import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	profile: undefined,
	loadProfileApiStatus: ApiRequestStatus.INIT,
	importImageStatus: ApiRequestStatus.INIT,
	updateEditImageStatus: ApiRequestStatus.INIT,
	fillProfilePhase: 0,
	preFillProfileActionCounter: 0,
	lastTimeRequestFillProfilePhase: null,
	alreadyHadRegistrationProcess: false,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.PROFILE_FETCH_PROFILE_SUCCESSFULLY:
			state.profile = action.profile;
			state.loadProfileApiStatus = ApiRequestStatus.SUCCESSFULLY;
			break;
		case types.PROFILE_FETCH_PROFILE_FAILED:
			state.profile = action.profile;
			state.loadProfileApiStatus = ApiRequestStatus.FAILED;
			break;
		case types.PROFILE_GET_FACEBOOK_PROFILE_DATA_SUCCESSFULLY:
			state.facebookPictureUrl = action.picture.data.url;
			break;
		case types.PROFILE_UPDATE_IMAGE:
			//added 'z' for sorting
			const temporaryPictureObj = {
				name: "z-picture",
				isNewImageInProcess: true,
			};
			state.profile.pictures
				? (state.profile.pictures["z-picture"] = temporaryPictureObj)
				: (state.profile.pictures = {
						temporaryPictureObj,
				  });
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
