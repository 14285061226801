import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { getUserProfile } from "redux/profile/selectors";
import { search, setSearchType, getFiltersIsEmpty } from "redux/search/actions";
import { getSearchFreeTextFilter, getUpdateRedPoint } from "redux/search/selectors";
import FilterButtons from "../FilterButtons";
import ElasticSortOptions from "consts/elasticSortOptions";
import isMobile from "helpers/isMobile";
import { usePrevious } from "hooks/usePrevious";

export default ({
	scrollToTop,
	typeSearch,
	navRef,
	className = "",
	page = "",
	showSearchBar = true,
}) => {
	const [freeText, setFreeText] = useState("");
	const prevFreeText = usePrevious(freeText);
	const [searchClass, setSearchClass] = useState("");
	const [sortedBy, setSortedBy] = useState(null);
	const [showX, setShowX] = useState("");
	const [isHover, setIsHover] = useState(false);
	const dispatch = useDispatch();
	const loggedInUserProfile = useSelector(getUserProfile);
	const filteredFreeText = useSelector(getSearchFreeTextFilter);
	const filtersIsEmpty = getFiltersIsEmpty(loggedInUserProfile);
	const location = useLocation();
	const __ = useSelector(getUpdateRedPoint); //In order to render this component after closing searchBar and update red point
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	useEffect(() => {
		dispatch(setSearchType(typeSearch));
		if (location.state?.searchTag) return;
		onSearch();
	}, []);

	useEffect(() => {
		setFreeText(filteredFreeText);
	}, [filteredFreeText]);

	useEffect(() => {
		showXButton();
	}, [isHover, searchClass, freeText]);

	const showXButton = () =>
		freeText && (isHover || searchClass === "focused") ? setShowX("show") : setShowX("");

	const onSearch = (sortOption, isClearButton) => {
		dispatch(search(isClearButton ? "" : freeText, sortOption));
		setSortedBy(sortOption);
		scrollToTop();
	};

	const onClear = () => {
		setFreeText("");
		onSearch(sortedBy, true);
	};

	const showSearcFilterhBar = () => {
		if (isMobile()) {
			document.querySelector(".sideBarSearchFilter").classList.add("showSearchFilterSlide");
			document.querySelector(".dimmer").classList.add("showDimmer");
			document.querySelector(".toBlur").classList.add("blurMe");
			document.querySelector(".header").classList.add("blurMe");
			document.querySelector("body").classList.add("modal-open");
			//document.querySelector("#root").classList.add("fixedBackground");
		}
	};

	const onkeyUpHandler = (e) => {
		e.key === "Enter" && onSearch(sortedBy);
		e.key === "Backspace" && prevFreeText[prevFreeText.length - 1] === " " && onSearch(sortedBy);
	};

	return (
		<div className={`topBar ${className}`} ref={navRef}>
			<div className="wrapTopPartBar">
				{showSearchBar && (
					<div className={`searchBar ${searchClass}`}>
						<div onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
							<Input
								onFocus={() => setSearchClass("focused")}
								onBlur={() => {
									setSearchClass("blurred");
								}}
								name="search"
								placeholder={
									isUserLoggedIn
										? !isMobile()
											? i18next.t(`Search.Type free text_${loggedInUserProfile.gender}`)
											: i18next.t(`Search.Type free textM_${loggedInUserProfile.gender}`)
										: ""
								}
								type="search"
								value={freeText}
								onChange={(e) => setFreeText(e.target.value)}
								onKeyUp={onkeyUpHandler}
							/>
							<Button
								color="none"
								type="button"
								className={`nav-btn searchX ${showX}`}
								onClick={onClear}>
								<i className="ri-close-line"></i>
							</Button>
						</div>
						<Button outline color="primary" onClick={() => onSearch()} />
					</div>
				)}
				{page !== "matches" && (
					<Button
						outline
						color="primary"
						className="tagsBtn newTag showSearcFilterhBar "
						onClick={showSearcFilterhBar}>
						{isMobile() && !filtersIsEmpty && <span className="dot"></span>}
					</Button>
				)}
			</div>

			<FilterButtons className="searchFilter">
				<Button
					color="light"
					{...(sortedBy === ElasticSortOptions.SortByCreateTimeDESC
						? {
								active: true,
								color: "primary",
						  }
						: {
								onClick: () => onSearch(ElasticSortOptions.SortByCreateTimeDESC),
						  })}>
					{i18next.t("Search.Buttons.New")}
				</Button>
				<Button
					color="light"
					{...(sortedBy === ElasticSortOptions.SortByLastLoginTimeDESC
						? {
								active: true,
								color: "primary",
						  }
						: {
								onClick: () => onSearch(ElasticSortOptions.SortByLastLoginTimeDESC),
						  })}>
					{i18next.t("Search.Buttons.Recently")}
				</Button>
				<Button
					color="light"
					{...(!sortedBy
						? {
								active: true,
								color: "primary",
						  }
						: {
								onClick: () => onSearch(),
						  })}>
					{i18next.t("Search.Buttons.All")}
				</Button>
			</FilterButtons>
		</div>
	);
};
