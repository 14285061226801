import i18next from "i18next";
import { Button } from "reactstrap";
import logoB from "assets/images/icons/logoGrad.svg";
import hands from "assets/images/bgHome.webp";
import imageTwo from "assets/images/homePage.webp";
import theMost from "assets/images/theMost.svg";
import TermsLink from "components/login/TermsLink";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import { useEffect } from "react";
import useNavigator from "hooks/useNavigator";
const blogWebsiteUrl = process.env.REACT_APP_BLOG_WEBSITE_URL;

export default ({ onSubmit, onMobileSubmit, hasError, errMsg, showLoader, className = "" }) => {
	const navigator = useNavigator();
	useEffect(() => {
		const html = document.querySelector("html");
		html.style.fontSize = "62.5%";
		return () => {
			html.style.fontSize = "";
		};
	}, []);

	const onLoginClick = (e) => {
		e.preventDefault();
		navigator.navigateToLoginEnterPhonePage();
	};

	return (
		<div className={`loginPagesWrapper homeConnect ${className}`}>
			<div className="headerHome">
				<a href={`${blogWebsiteUrl}/about`}>{i18next.t("HP.Tabs.About")}</a>
				<a href={`${blogWebsiteUrl}/מגזין`}>{i18next.t("HP.Tabs.Newsletter")}</a>
				{/* <a href="#">{i18next.t("HP.Tabs.Stories")}</a> */}
				<a href="#" onClick={onLoginClick}>
					{i18next.t("HP.Tabs.Login")}
				</a>
			</div>
			<div className="siteLogo">
				<img src={logoB} />
			</div>
			<div className="homeContent">
				<div className="wrapContentLP">
					<div className="textForDesktop">
						{/* <h2>{i18next.t("HP.Dating Site")}</h2> */}
						<h1>
							{i18next.t("HP.Love Stories")}
							<h1 className="specialColor">{i18next.t("HP.Here")}</h1>
						</h1>
						<h3>{i18next.t("HP.Enter your phone here")} </h3>
						<h6>{i18next.t("HP.Old or new member")}</h6>
						<PhoneQuestion
							className="phoneLP"
							onSubmit={onSubmit}
							hasError={hasError}
							errMsg={errMsg}
							showLoader={showLoader}
						/>
					</div>
				</div>
				<div className="wrapstats">
					<div className="imageTwo">
						<img src={imageTwo} />
					</div>
					<div className="pinkyWhite">
						<div className="pinky stats">
							<span>50,000+</span>
							<h6>{i18next.t("HP.Download App")}</h6>
						</div>
						<div className="whitey stats">
							<img src={theMost} />
						</div>
					</div>
				</div>
			</div>

			<div className="title">
				<h3>{i18next.t("HP.Congratulations!")}</h3>
			</div>
			<div className="wrapImgBtn">
				<div className="wrapGradient">
					<div className="imgBtn">
						<div className="circleImage">
							<img src={hands} />
						</div>
						<Button onClick={onMobileSubmit}>{i18next.t("Login.Connect")}</Button>
					</div>
					<TermsLink />
				</div>
			</div>
		</div>
	);
};
