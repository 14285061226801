export default {
  StartSubscription: "StartSubscription",
  ChangeSubscriptionRenewableSettings: "ChangeSubscriptionRenewableSettings",
  FinishPurchaseSubscriptionSuccesfully:
    "FinishPurchaseSubscriptionSuccesfully",
  FinishPurchaseSubscriptionFail: "FinishPurchaseSubscriptionFail",
  GetLimitOfMaxUsersForSendMessageIn24Hours:
    "GetLimitOfMaxUsersForSendMessageIn24Hours",
  RenewFreeDailySubscriptionWhileAppRunning:
    "RenewFreeDailySubscriptionWhileAppRunning",
  ValidateAndRegisterInAppPurchase: "ValidateAndRegisterInAppPurchase",
  EndIapSubscription: "EndIapSubscription",
};
