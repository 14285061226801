import React from "react";

import usePopups from "hooks/usePopups";
import GalleryTypes from "consts/galleryTypes";

export default ({ pictures, title }) => {
  const { showGalleryPopup } = usePopups();

  return (
    <div className="imagesGallery">
      <h4 className="text-left text-primary font-size-16 ">{title}</h4>
      <div className="imageGalleryMedium">
        {pictures.map((picture, index) => (
          <img
            src={picture.src}
            className="avatar-lg jsmartProfileImg"
            onClick={() => showGalleryPopup(GalleryTypes.Public, index)}
            alt="gallery image"
          />
        ))}
      </div>
    </div>
  );
};
