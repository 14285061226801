import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";

const initialState = {
	data: {},
};

export default produce((state, action) => {
	switch (action.type) {
		case types.LET_PICTURES_ME_FETCH_SUCCESSFULLY:
			state.data = action.payload;
			break;
		case types.LET_PICTURES_ME_ADDED:
			state.data = { ...state.data, ...action.payload };
			break;
		case types.LET_PICTURES_ME_REMOVED:
			delete state.data[action.payload];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
