import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import DropDown from "components/DropDown";
import ChildrenCount from "consts/childrenCount";

export default ({ onChange, profile }) => {
	const _onChange = (key, value) => onChange(key, value);

	return (
		<DropDown
			field={ProfileFields.ChildrenCount}
			fieldOptions={Object.values(ChildrenCount)}
			onChange={_onChange}
			initSelected={profile.ChildrenCount}
			title={i18next.t("Edit Profile.Children Count")}
			translatePrefix="Children Count"
		/>
	);
};
