import { catchError, map, mergeMap, repeat, switchMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import PresenceService from "services/presence";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";

export const fetchOnlineUsers = (action$) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    switchMap(async () => {
      try {
        const payload = await PresenceService.fetchOnlineUsers();
        return {
          type: types.PRESENCE_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.PRESENCE_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.PRESENCE_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchOnlineUsersAdded = (action$) =>
  action$.pipe(
    ofType(types.PRESENCE_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      return PresenceService.listenOnlineUsersChildAdded().pipe(
        map((userUid) => ({
          type: types.PRESENCE_CHILD_ADDED,
          payload: userUid,
        }))
      );
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.PRESENCE_CHILD_ADDED_FAILED,
        payload: error?.message,
      });
    }),
    repeat()
  );

export const fetchOnlineUsersRemoved = (action$) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(() => {
      return PresenceService.listenOnlineUsersChildRemoved().pipe(
        map((userUid) => ({
          type: types.PRESENCE_CHILD_REMOVED,
          payload: userUid,
        }))
      );
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.PRESENCE_CHILD_REMOVED_FAILED,
        payload: error?.message,
      });
    }),
    repeat()
  );
