import ScreensSizes from "consts/screensSizes";

export default () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    const isMobileAgent = toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
    });

    const isMobileBySize = window.innerWidth <= ScreensSizes.MobileWidth;

    return isMobileAgent || isMobileBySize;
}