import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	data: undefined,
	apiStatus: ApiRequestStatus.INIT,
	lastFetchTime: null,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.SKIPPED_FETCH_SUCCESSFULLY:
			state.data = action.payload;
			state.apiStatus = ApiRequestStatus.SUCCESSFULLY;
			break;
		case types.SKIPPED_ADDED:
			state.data = { ...state.data, ...action.payload };
			break;
		case types.SKIPPED_REMOVED:
			delete state.data[action.payload];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
