import i18next from "i18next";

import TextArea from "components/TextArea";
import ProfileFields from 'consts/profileFields';
import PendingStatus from 'consts/pendingStatus';
import Warnings from "../Warnings";

export default ({ onChange, profile , pending }) => {
   
    return  <div className="wrapInputValidation">
                <TextArea
                    field={ProfileFields.AboutSpouse} 
                    onChange={onChange} 
                    initValue={profile.aboutSpouse}
                    title={i18next.t('Edit Profile.About Spouse')}
                />
                <Warnings
                    warning={pending?.status === PendingStatus.Wait && i18next.t('Edit Profile.Pending')}
                    error={pending?.status === PendingStatus.Rejected && i18next.t('Edit Profile.Rejected')}
                />
            </div>       
};