import { useState } from "react";
import i18next from "i18next";

import FilterButtons from "../FilterButtons";
import TopBarSwithButton from "./TopBarSwithButton";

export default ({ tabsEnum }) => {
	const [tabs] = useState(Object.keys(tabsEnum).map((key) => ({ key: key, value: tabsEnum[key] })));
	return (
		<FilterButtons className="likedMeTabs">
			{tabs.map(({ key, value }) => {
				return <TopBarSwithButton label={i18next.t(key)} path={value} />;
			})}
		</FilterButtons>
	);
};
