export const LET_PICTURES_ME_FETCH_SUCCESSFULLY = "letPictures.LET_PICTURES_ME_FETCH_SUCCESSFULLY";
export const LET_PICTURES_ME_FETCH_FAILED = "letPictures.LET_PICTURES_ME_FETCH_FAILED";

export const LET_PICTURES_ME_REMOVED = "letPictures.LET_PICTURES_ME_REMOVED";
export const LET_PICTURES_ME_REMOVED_FAILED = "letPictures.LET_PICTURES_ME_REMOVED_FAILED";
export const LET_PICTURES_ME_ADDED = "letPictures.LET_PICTURES_ME_ADDED";
export const LET_PICTURES_ME_ADDED_FAILED = "letPictures.LET_PICTURES_ME_ADDED_FAILED";
export const LET_PICTURES_ME_ADD_SUCCESSFULLY = "letPictures.LET_PICTURES_ME_ADD_SUCCESSFULLY";
export const LET_PICTURES_ME_ADD_FAILED = "letPictures.LET_PICTURES_ME_ADD_FAILED";
export const LET_PICTURES_ME_REMOVE_SUCCESSFULLY = "letPictures.LET_PICTURES_ME_REMOVE_SUCCESSFULLY";
export const LET_PICTURES_ME_REMOVE_FAILED = "letPictures.LET_PICTURES_ME_REMOVE_FAILED";
