export const SKIPPED_FETCH_SUCCESSFULLY = 'skipped.SKIPPED_FETCH_SUCCESSFULLY';
export const SKIPPED_FETCH_FAILED = 'skipped.SKIPPED_FETCH_FAILED';
export const SKIPPED_ADDED = 'skipped.SKIPPED_ADDED';
export const SKIPPED_ADDED_ERROR = 'skipped.SKIPPED_ADDED_ERROR';
export const SKIPPED_REMOVED = 'skipped.SKIPPED_REMOVED';
export const SKIPPED_REMOVED_ERROR = 'skipped.SKIPPED_REMOVED_ERROR';
export const SKIPPED_ADD = 'skipped.SKIPPED_ADD';
export const SKIPPED_ADD_SUCCESSFULLY = 'skipped.SKIPPED_ADD_SUCCESSFULLY';
export const SKIPPED_ADD_FAILED = 'skipped.SKIPPED_ADD_FAILED';
export const SKIPPED_REMOVE = 'skipped.SKIPPED_REMOVE';
export const SKIPPED_REMOVE_SUCCESSFULLY = 'skipped.SKIPPED_REMOVE_SUCCESSFULLY';
export const SKIPPED_REMOVE_FAILED = 'skipped.SKIPPED_REMOVE_FAILED';
