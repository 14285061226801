import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { getIsManualyPromotedUser, getPromotionsList } from "redux/promotions/selectors";
import { openPromotion } from "redux/promotions/actions";
import { activeUserId } from "redux/chat/actions";
import { updatePromotionsRead } from "redux/chatMeta/actions";
import PromotionStatus from "consts/promotionStatus";
import ApiRequestStatus from "consts/apiRequestStatus";
import { TransformImg } from "helpers/cloudinary";
import { Face, Fill } from "consts/cloudinaryOptions";
import { isUserOnline } from "redux/presence/selectors";
import ScreensSizes from "consts/screensSizes";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import { getChat } from "redux/chatReceived/selectors";
import useNavigator from "hooks/useNavigator";
import privatePictureRound from "assets/images/emptyImages/privateRound.png";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";

const Item = ({ item, onPress }) => {
	const chatID = useSelector(getChat(item.uid));
	const isLoaded = item && item.status === ApiRequestStatus.SUCCESSFULLY;
	const uri = isLoaded ? item.pictures?.[item.mainPictureName]?.url || "" : "";
	const path = TransformImg(uri, null, null, Fill.Thumb, Face.Face, "r_max");
	const isManualyPromotedUser = useSelector(getIsManualyPromotedUser(item.uid));
	const isUnView = item.unView || item.promotionStatus === PromotionStatus.Promoted;
	const isOnline = useSelector(isUserOnline(item.uid));
	const userStatus = isOnline ? "online" : "offline";
	const active_userId = useSelector((state) => state.chat.active_userId);
	const isMobile = window.innerWidth <= ScreensSizes.MobileWidth;
	const isActiveUser = !isMobile && active_userId === item.uid;
	const defaultImage = item.gender ? placeholderWomanRound : placeholderManRound;

	return (
		<div
			className={isActiveUser ? " current" : ""}
			onClick={() => onPress(item.uid, isUnView, isManualyPromotedUser, item, chatID)}>
			<Link to="#" className="user-status-box">
				<div className={"avatar-xs mx-auto d-block chat-user-img " + userStatus}>
					<img
						src={uri ? path : item.isPicturesPrivate ? privatePictureRound : defaultImage}
						alt="user-img"
						className="img-fluid rounded-circle avatar-xs"
					/>
					<span className="user-status"></span>
				</div>

				<h5 className="font-size-13 text-truncate mt-3 mb-1">{item.name}</h5>
			</Link>
		</div>
	);
};

export default () => {
	const dispatch = useDispatch();
	const promotions = useSelector(getPromotionsList);
	const navigator = useNavigator();

	const { showUserProfileSideBar, showUserCardPopup } = usePopups();

	const handleOnPress = (chatUid, unView, isManualyPromotedUser, user, chatID) => {
		dispatch(openPromotion(chatUid, unView));
		if (isManualyPromotedUser) {
			dispatch(activeUserId(chatUid, chatID));
			navigator.navigateToMassagesReceived();
			!isMobile() && showUserProfileSideBar(user);
		} else {
			!isMobile() ? showUserCardPopup(user, true) : showUserProfileSideBar(user);
		}
	};

	const shouldAddButtons = () => {
		return !isMobile() && promotions.length > 4;
	};

	useEffect(() => {
		dispatch(updatePromotionsRead());
	}, []);

	return (
		<div
			className={`px-4 pb-4 recommendedUsers ${shouldAddButtons() ? "" : "dot_remove"}`}
			dir="ltr">
			<AliceCarousel
				responsive={{
					0: { items: 4 },
					1024: { items: 4 },
				}}
				disableDotsControls={shouldAddButtons() ? true : false}
				mouseTracking>
				{promotions.map((promotion) => (
					<Item key={promotion.uid} item={promotion} onPress={handleOnPress} />
				))}
			</AliceCarousel>
			{promotions.length === 0 && <p className="message">{i18next.t("No Promotions")}</p>}
		</div>
	);
};
