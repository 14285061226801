import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

import Content from "views/Content";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import TopBar from "views/TopBar";
import LikesCards from "components/search/LikesCards";
import BottomStrip from "components/BottomStrip";
import isMobile from "helpers/isMobile";
import LikesTopBar from "components/likedMe/LikesTopBar";

export default () => {
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const ref = useRef();

	const scrollToTop = () => {
		ref.current?.scrollIntoView();
	};

	return isUserLoggedIn ? (
		<>
			<TopBar>
				<LikesTopBar />
			</TopBar>
			<div className="likeMePage" ref={ref}>
				<Content>
					<LikesCards scrollToTop={scrollToTop} />
					{isMobile() && <BottomStrip />}
				</Content>
			</div>
		</>
	) : (
		<Loader />
	);
};
