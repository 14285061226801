import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup } from "reactstrap";

import SendTemplateMessageButton from "./SendTemplateMessageButton";
import LikeButton from "./LikeButton";
import MessageButton from "./MessageButton";
import isMobile from "helpers/isMobile";
import RedirectToChatButton from "./RedirectToChatButton";
import { getChat } from "redux/chatReceived/selectors";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import localStorage from "services/localStorage";
import { useState } from "react";
import chatReceived from "services/chatReceived";
import { addChatReceivedUser } from "redux/chatReceived/actions";

export default ({ user, effortBtn = "", templates = [] }) => {
	const dispatch = useDispatch();

	const checkIfMsgUser = () => {
		if (!user.uid) return null;
		chatReceived.checkIfMsgUser(user.uid).then((res) => {
			if (!res) return null;

			dispatch(addChatReceivedUser(res));
			return true;
		});
	};

	const chatID = useSelector(getChat(user.uid)) || checkIfMsgUser();
	const [displayLikeBtnWhenSendEffort, setDisplayLikeBtnWhenSendEffort] = useState(false);

	const setIsUserInStore = () => {
		if (!!getIfUserBirtdayInThisWeek(user?.dob)) {
			const storageList = localStorage.get("Birthday");
			if (!storageList) return false;
			const pharseList = JSON.parse(storageList);
			return !!pharseList.includes(user?.uid);
		} else if (!!effortBtn) {
			const storageList = localStorage.get(effortBtn);
			if (!storageList) return false;
			const pharseList = JSON.parse(storageList);
			return !!pharseList.includes(user?.uid);
		}
		return false;
	};

	return (
		<ButtonGroup>
			{!isMobile() ? (
				<>
					<SendTemplateMessageButton user={user} effortBtn={effortBtn} templates={templates} />
					<MessageButton chatID={chatID} user={user} />
					<LikeButton user={user} />
				</>
			) : (
				<>
					{(getIfUserBirtdayInThisWeek(user?.dob) || !!effortBtn) &&
					!setIsUserInStore() &&
					!displayLikeBtnWhenSendEffort ? (
						<SendTemplateMessageButton
							user={user}
							effortBtn={effortBtn}
							templates={templates}
							sentEffort={setDisplayLikeBtnWhenSendEffort}
						/>
					) : (
						<>{!chatID ? <LikeButton user={user} /> : <RedirectToChatButton user={user} />}</>
					)}
				</>
			)}
		</ButtonGroup>
	);
};
