import firebase from "firebase/app";
import "firebase/database";

class TemplateMsgService {
	BASE_REF = "message_templates";

	async fetchTemplateMsg() {
		return (
			await firebase.database().ref(this.BASE_REF).orderByChild("priority").once("value")
		).val();
	}
}

export default new TemplateMsgService();
