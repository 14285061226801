import { useState, useEffect } from "react";
import MultirangeThumb from "./MultirangeThumb";

export default ({ min, max, minVal, maxVal, maxThumbState }) => {
  const [maxThumbClasses, setMaxThumbClasses] = useState();
  const [minPercents, setMinPercents] = useState();
  const [maxPercents, setMaxPercents] = useState();

  useEffect(() => {
    setMaxThumbClasses(Object.keys(maxThumbState).join(" "));
  }, [maxThumbState]);

  useEffect(() => {
    setMinPercents(((minVal - min) / (max - min)) * 100);
  }, [minVal]);

  useEffect(() => {
    setMaxPercents(100 - ((maxVal - min) / (max - min)) * 100);
  }, [maxVal]);

  return (
    <div className="slider locationSlider">
      <div className="track"></div>
      <div
        className="range"
        style={{ left: minPercents + "%", right: maxPercents + "%" }}
      ></div>
      <span className="totalValue max singleRange">{max}</span>
      <MultirangeThumb
        className={`thumb right ${maxThumbClasses}`}
        value={maxVal}
        style={{ right: maxPercents + "%" }}
      />
    </div>
  );
};
