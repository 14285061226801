import i18next from "i18next";

import Stance from "consts/stance";
import SearchMultiEnumButton from "./SearchMultiEnumButton";
import SearchFilterKeys from "consts/searchFilterKeys";
import ProfileFields from "consts/profileFields";
import KeepShabbat from "consts/keepShabbat";

export default ({ onChange, profile, filter }) => {
	const spouseGender = 1 - profile.gender;

	const getPermitedStance = () => {
		const allStances = Object.values(Stance);
		switch (profile[ProfileFields.Stance]) {
			case Stance.Chiloni:
				return [];
			case Stance.Mesorati:
			case Stance.Datlash:
				if (profile[ProfileFields.KeepShabbat] === KeepShabbat.No) {
					return [];
				} else return allStances;
			case Stance.DatiLeumiTorani:
			case Stance.ModernOrthodox:
			case Stance.Orthodox:
			case Stance.Breslev:
			case Stance.Chabad:
				return allStances.filter(
					(stance) => stance !== Stance.Chiloni && stance !== Stance.Datlash
				);
			default:
				return allStances;
		}
	};

	const permitedStance = getPermitedStance();

	return (
		<>
			{permitedStance.length > 0 ? (
				<SearchMultiEnumButton
					className="showTinyTitle"
					onChange={onChange}
					options={permitedStance}
					subtitle={i18next.t("Search.Stance")}
					translatePrefix={`Stance_${spouseGender}`}
					SearchFilterKey={SearchFilterKeys.Stance}
					initSelected={filter}
				/>
			) : null}
		</>
	);
};
