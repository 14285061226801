import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import LookingFor from "consts/lookingFor";
import MultiSelect from "components/MultiSelect";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, values) => onChange(key, values.map(item => Number(item)));

    return <MultiSelect
                field={ProfileFields.LookingFor} 
                fieldOptions={Object.values(LookingFor)} 
                onChange={_onChange} 
                initSelected={profile.lookingFor}
                title={i18next.t('Edit Profile.LookingFor')}
                translatePrefix="LookingFor"
            />
};
