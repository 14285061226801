import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import usePopups from "hooks/usePopups";
import useIsEqualToRemoteData from "hooks/useIsEqualToRemoteData";
import { updateProfile } from "redux/profile/actions";
import { getUserProfile } from "redux/profile/selectors";
import Button from "components/Button";
import RouteLeavingGuard from "./CustomLeavingPrompt/RouteLeavingGuard";

export default ({ data, clearDataAfterSave, errors, onSaveMore }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const loggedInUserProfile = useSelector(getUserProfile);
	const { showUpdatesSavedSuccessfullyPopup } = usePopups();
	const isEqualToRemoteData = useIsEqualToRemoteData(data);
	const [tmp, setTmp] = useState("");

	const saveData = () =>
		Object.entries(data).map(([key, value]) =>
			dispatch(
				updateProfile(
					key,
					typeof value === "string" ? value.trim() : value,
					loggedInUserProfile[key]
				)
			)
		);

	const _onSave = () => {
		saveData();
		onSaveMore && onSaveMore();
		showUpdatesSavedSuccessfullyPopup();
		clearDataAfterSave();
	};

	/*Ayelet */
	useEffect(() => {
		const button = document.querySelector(".mainAccountBtn");
		if (button.classList.contains("disabled")) {
			setTmp("disabled");
		} else {
			setTmp("");
		}
	});

	return (
		<div className={`saveDataBtn ${tmp}`}>
			<Button
				onClick={_onSave}
				text={i18next.t("Edit Profile.Save")}
				disabled={!isEmpty(errors) || isEqualToRemoteData}
				className="mainAccountBtn"
			/>
			<RouteLeavingGuard
				when={!isEqualToRemoteData}
				navigate={(path) => {
					history.push(path);
				}}
				shouldBlockNavigation={() => {
					if (!isEqualToRemoteData) return true;
					return false;
				}}
			/>
		</div>
	);
};
