
export default urlImg => {
    return new Promise((resolve, reject) =>{
        var img = new Image();
        img.crossOrigin = 'Anonymous';    
        img.src = urlImg;    
        img.onload = () => {
          const canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');
          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;
          ctx.drawImage(img, 0, 0);
          const uri = canvas.toDataURL('image/png');
          resolve(uri);
        };
    })
}