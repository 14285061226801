export default ({ itemKey, selected, children, onClick }) => {
	return (
		<button
			className="btn"
			key={`${itemKey}_${selected}`}
			onClick={() => onClick(itemKey)}
			{...{
				...{ active: selected ? "true" : "false" },
			}}>
			{children}
		</button>
	);
};
