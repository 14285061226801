import { useState } from "react";
import i18next from "i18next";
import validate from 'validate.js';

import TextInput from "components/TextInput";
import ProfileFields from 'consts/profileFields';
import FillProfileDetailsValidations from "consts/fillProfileDetailsValidations";
import Warnings from "../Warnings";
import PendingStatus from 'consts/pendingStatus';

export default ({ onChange, profile, pending, setErrors }) => {
    const [ errorMessage, setErrorMessage ] = useState();

    const _onChange = (key, value) => {
        const emailValidationErrors = validate.single(value, FillProfileDetailsValidations.name);
        setErrorMessage(emailValidationErrors ? emailValidationErrors[0] : null);
        setErrors(ProfileFields.Name, emailValidationErrors ? emailValidationErrors[0] : null);
        onChange(key, value);
    }
   
    return  <div className="wrapInputValidation">
                <TextInput
                    field={ProfileFields.Name} 
                    onChange={_onChange} 
                    initValue={profile.name}
                    title={i18next.t('Account Settings.Name')}
                />
                <Warnings
                    warning={pending?.status === PendingStatus.Wait && i18next.t('Edit Profile.Pending')}
                    error={
                        (!!errorMessage && i18next.t(`Validations.${errorMessage}`)) ||
                        (pending?.status === PendingStatus.Rejected && i18next.t('Edit Profile.Rejected'))
                    }
                    errorClass="name"
                />
            </div>
};