import { createSelector } from "reselect";
import _ from "lodash";

export const getPopups = (state) => state.popups;

export const getCurrentPopupType = createSelector(getPopups, (popups) => {
	return popups.popupType;
});

export const getPopupUnderPopupType = createSelector(
	getPopups,
	(popups) => popups.popupUnderPopupType
);

export const getSecondPopupUnderPopupType = createSelector(
	getPopups,
	(popups) => popups.secondPopupUnderPopupType
);

export const getImageKeyToDelete = createSelector(getPopups, (popups) => popups.imageKey);

export const getImageToCrop = createSelector(getPopups, (popups) => popups.image);

export const getCropImageType = createSelector(getPopups, (popups) => popups.cropImageType);

export const getCropImageName = createSelector(getPopups, (popups) => popups.cropImageName);

export const isSale = createSelector(getPopups, (popups) => popups.isSale);
