import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import EducationalInstitution from "consts/educationalInstitution";
import MultiSelect from "components/MultiSelect";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, values) => onChange(key, values.map(item => Number(item)));

    return <MultiSelect
                field={ProfileFields.EducationalInstitution} 
                fieldOptions={Object.values(EducationalInstitution)} 
                onChange={_onChange} 
                initSelected={profile.educationalInstitution}
                title={i18next.t('Edit Profile.Educational Institution')}
                translatePrefix="Educational Institution"
            />
};
