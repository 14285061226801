import i18next from "i18next";
import { useSelector } from "react-redux";
import { useState } from "react";

import { getUserProfile } from "redux/profile/selectors";
import { TagsPool } from "./ProfileInputs/Tags";
import DetailsAreaContainer from "./DetailsAreaContainer";

export default () => {
      const [ data, setData ] = useState({});
      const loggedInUserProfile = useSelector(getUserProfile);
    
      const onChange = (key, value) => {
            data[key] = value;
            setData({...data});
      }

      return <DetailsAreaContainer addSaveDataButton={true} clearDataAfterSave={()=>setData({})} className="interestArea" data={data} title={`${i18next.t('Pages.Edit Profile')} - ${i18next.t('Pages.Interest')}`}>
                  <TagsPool onChange={onChange} profile={loggedInUserProfile}/>
             </DetailsAreaContainer>
}

