export default {
  HighSchool: 1,
  ProfessionalCertification: 2,
  BA: 4,
  MA: 6,
  Doctorate: 8,
  Torah: 10,
  Prepatory: 11,
  Midrashah: 12,
  YeshivatHesder: 14,
};
