import i18next from "i18next";
import { useState } from "react";

import TextArea from "components/TextArea";
import ProfileFields from "consts/profileFields";
import PendingStatus from "consts/pendingStatus";
import Warnings from "../Warnings";
import RequiredPointContainer from "../RequiredPointContainer";

export default ({ onChange, profile, pending }) => {
	const [isEmpty, setIsEmpty] = useState(false);

	return (
		<div className="wrapInputValidation">
			<RequiredPointContainer>
				<TextArea
					field={ProfileFields.AboutMe}
					onChange={onChange}
					initValue={profile.aboutMe}
					title={i18next.t("Edit Profile.About Me")}
					setIsEmpty={setIsEmpty}
					required
				/>
			</RequiredPointContainer>
			<Warnings
				warning={pending?.status === PendingStatus.Wait && i18next.t("Edit Profile.Pending")}
				error={
					(isEmpty && i18next.t("Edit Profile.Required Field")) ||
					(pending?.status === PendingStatus.Rejected && i18next.t("Edit Profile.Rejected"))
				}
				errorClass={isEmpty ? "name" : ""}
			/>
		</div>
	);
};
