export default {
  NationalService: 1,
  FullArmyService: 3,
  Combat: 19,
  Hesder: 20,
  Atudah: 21,
  SpecialUnit: 22,
  None: 23,
  Other: 24,
};
