import ProfileFields from "consts/profileFields";

export const ProfilePendingFields = [
	ProfileFields.AboutMe,
	ProfileFields.AboutSpouse,
	ProfileFields.OneLiner,
	ProfileFields.MaritalStatus,
];

export const GeneralPendingFields = [
	ProfileFields.Dob,
	ProfileFields.Email,
	ProfileFields.Gender,
	ProfileFields.Name,
];
