export default {
  NotRelevant: 9,
  Ashkenazi: 1,
  Sephardi: 2,
  Mixed: 4,
  Yemenite: 5,
  Russian: 6,
  French: 7,
  Ethiopian: 8,
  American: 10,
  British: 11,
  Georgian: 12,
  Bukhari: 13,
};
