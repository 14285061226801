import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
export default ({ path, label }) => {
	const relativePath = `/${path}`;
	const history = useHistory();
	const currentPath = history.location.pathname;
	const [isCurrentPath, setIsCurrentPath] = useState(currentPath === relativePath);

	const handleClick = () => {
		history.push(relativePath);
	};

	useEffect(() => {
		setIsCurrentPath(currentPath === relativePath);
	}, [currentPath]);
	return (
		<Button
			{...(!isCurrentPath
				? {
						onClick: handleClick,
				  }
				: {})}
			active={isCurrentPath}
			color={isCurrentPath ? "primary" : "light"}>
			{label}
		</Button>
	);
};
