import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class ConnectingService {
	checkConnections() {
		return new Observable((subscriber) => {
			var connectedRef = firebase.database().ref(".info/connected");
			connectedRef.on("value", function (snapshot) {
				var isConnected = snapshot.val();
				if (isConnected) {
					subscriber.next(true);
				} else {
					// The client is disconnected
					subscriber.next(false);
				}
			});
		});
	}
}

export default new ConnectingService();
