import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class MassletterService {
	BASE_REF = "users_massletter";

	fetchMassLetter(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
}

export default new MassletterService();
