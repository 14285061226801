export default {
  Hebrew: 1,
  English: 2,
  French: 3,
  Yiddish: 4,
  Amharit: 5,
  Russian: 6,
  Spanish: 8,
  Italian: 9,
  Other: 7,
};
