import "firebase/database";
import httpsCallable from "./httpsCallable";

class EmailService {
	async sendVerificationEmailCode(email) {
		const result = await httpsCallable.call("sendVerificationEmailCode", {
			email,
		});
		return result;
	}
	async verifyEmailCode(code) {
		const result = await httpsCallable.call("verifyEmailCode", {
			code,
		});
		return result;
	}
}

export default new EmailService();
