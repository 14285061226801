import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Lock from "assets/images/icons/locked.svg";
import Block from "assets/images/icons/block.svg";
import { getUserProfile } from "redux/profile/selectors";
import { getUserGeneralStatus } from "redux/general/selectors";
import UserStatus from "consts/userStatus";
import { unFreeze } from "redux/general/actions";

export default () => {
	const dispatch = useDispatch();
	const profile = useSelector(getUserProfile);
	const status = useSelector(getUserGeneralStatus);
	const history = useHistory();
	const [isUnfreezeClicked, setIsUnfreezeClicked] = useState(false);

	const _onLogout = () => {
		history.push("/logout");
	};

	const _unFreeze = () => {
		dispatch(unFreeze());
		setIsUnfreezeClicked(true);
	};

	useEffect(() => {
		const _unBlock = history.block((location) => {
			return location.pathname === "/logout";
		});
	}, [history]);

	useEffect(() => {
		if (status == UserStatus.Approved || status == UserStatus.PendingApproval) {
			window.location = "/";
		}
	}, [status]);

	return (
		<div className="freeze">
			<div className="wrapButtons">
				{status === UserStatus.Blocked || status === UserStatus.AutoBlocked ? (
					<div className="lockIcon blockedIcon">
						<img src={Block} />
					</div>
				) : (
					<div className="lockIcon">
						<img src={Lock} />
					</div>
				)}

				<div className="helloUser">
					<h1> {i18next.t("Blocks.Title", { name: profile.name || "" })}</h1>
					<h3> {i18next.t(`Blocks.${status}`)}</h3>
				</div>
			</div>
			<div className="wrapButtons">
				{status === UserStatus.Freezed && (
					<Button
						onClick={_unFreeze}
						className="freezeBtn"
						outline
						color="primary"
						style={{ display: "" }}
						disabled={isUnfreezeClicked}>
						{isUnfreezeClicked === false
							? i18next.t("Blocks.unFreeze")
							: i18next.t("Blocks.unFreezing")}
					</Button>
				)}

				<Button onClick={_onLogout} className="logoutBtn" outline color="primary">
					{i18next.t("Blocks.Logout")}
				</Button>
			</div>
		</div>
	);
};
