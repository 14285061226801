import i18next from "i18next";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";

export default ({ user }) => {
	const { gender, name } = useSelector(getUserProfile);
	const { closePopup } = usePopups();

	return (
		<>
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<h4>{i18next.t(`Template Messages.Title_${gender}`, { name: user.name })}</h4>
			<h5>{i18next.t(`Template Messages.Sub Title_${gender}`, { name })}</h5>
		</>
	);
};
