import produce from "immer";

import * as types from "./actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import apiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	users: {},
	status: apiRequestStatus.INIT,
	loadChatsNumber: 30,
	hasMoreChats: false,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHAT_SENT_FETCH_SUCCESSFULLY:
			return { ...state, ...action.payload, status: apiRequestStatus.SUCCESSFULLY };

		case types.CHAT_SENT_ADDED_SUCCESSFULLY:
		case types.CHAT_SENT_UPDATED_SUCCESSFULLY:
		case types.CHAT_SENT_ADD_TEMPORARY_CHAT_SENT:
			return { ...state, users: { ...state.users, ...action.payload } };
		case types.CHAT_SENT_REMOVED_SUCCESSFULLY:
			delete state.users[action.payload];
			break;
		case blocksTypes.BLOCKS_BLOCK_USER:
			delete state.users[action.user];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
		case types.UPDATE_MORE_NUMBER:
			return {
				...state,
				hasMoreChats: !!action.payload?.hasMoreChats,
				loadChatsNumber: action.payload?.loadChatsNumber,
			};
		case types.RESET_CHAT_SENT_USERS_SUCCESSFULLY:
			return {
				...action.payload,
			};
	}
}, initialState);
