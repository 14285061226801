import * as types from "./actionTypes";

export const activeUserId = (userId, chatID, isFilteredChat = false) => {
	return {
		type: types.ACTIVE_USER_ID,
		payload: { userId, chatID, isFilteredChat },
	};
};

export const cancelActiveUser = () => ({
	type: types.CANCEL_ACTIVE_USER,
});
