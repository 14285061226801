import { createSelector } from "reselect";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";
import _ from "lodash";

export const getViewedMe = (state) => state.viewedMe;

export const getPitId = createSelector(getViewedMe, (viewedMe) => viewedMe.pitId);

export const getSearchAfter = createSelector(getViewedMe, (viewed) => viewed.searchAfter);

export const getUnViewedViewedMe = createSelector(getViewedMe, (viewedMe) => {
	const result = Object.keys(viewedMe.unViewedViewedMe).filter(
		(key) => viewedMe.unViewedViewedMe[key].deleted !== true
	);
	return result;
});

export const getViewedMeUsers = createSelector(
	getViewedMe,
	getBlocks,
	getUnViewedViewedMe,
	(viewedMe, blocks, newViewMe = []) => {
		const viewedMeList = Object.keys(viewedMe.users)
			.map((uid) => viewedMe.users[uid])
			.filter((user) => !blocks[user.uid]);

		const viewedMeSorted = _.sortBy(viewedMeList, (item) => {
			if (newViewMe.includes(item.uid)) {
				return -1;
			} else {
				return item.sort[0];
			}
		});
		return viewedMeSorted;
	}
);

export const getIsLoadingMore = createSelector(
	getViewedMe,
	(viewedMe) => viewedMe.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getViewedMe, (viewedMe) => viewedMe.hasMoreResults);

export const getViewedMeStatus = createSelector(getViewedMe, (viewedMe) => viewedMe.apiStatus);
