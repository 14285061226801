import { useState } from "react";
import i18next from "i18next";
import { Button, Col, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";

import { getUserPublicProfile } from "../../redux/publicProfiles/selectors";
import CallModal from "../CallModal";
import CallsTypes from "consts/callsTypes";
import isMobile from "helpers/isMobile";
import { TransformImg } from "helpers/cloudinary";
import { Face, Fill } from "consts/cloudinaryOptions";
import UserBlocks from "./blocks";
import LetPictures from "components/search/card/LetPictures";

export default ({ showUserDetails }) => {
	const [Callmodal, setCallModal] = useState(false);
	const [Videomodal, setVideoModal] = useState(false);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const chatUserUri = activeUserProfile.pictures?.[activeUserProfile.mainPictureName]?.url || "";
	const chatUserpath = TransformImg(chatUserUri, null, null, Fill.Thumb, Face.Face, "r_max");

	const toggleCallModal = () => setCallModal(!Callmodal);
	const toggleVideoModal = () => setVideoModal(!Videomodal);

	return (
		<>
			<Col className="wrapMenuChat">
				<ul className="list-inline user-chat-nav text-end mb-0 jsmartIconsMenu">
					<li>
						<LetPictures user={activeUserProfile} />
					</li>
					<div>
						<li id="user-profile" className="list-inline-item d-none d-lg-inline-block">
							<Button
								type="button"
								color="none"
								onClick={showUserDetails}
								className="nav-btn user-profile-show">
								<i className="ri-user-2-line jsmartIcon jsmartUser"></i>
							</Button>
						</li>
						<li className="list-inline-item ">
							<UserBlocks />
						</li>
					</div>
				</ul>
			</Col>
			<CallModal
				isOpen={Callmodal}
				toogle={toggleCallModal}
				image={chatUserpath}
				callType={CallsTypes.Audio}
			/>
			<CallModal
				isOpen={Videomodal}
				toogle={toggleVideoModal}
				image={chatUserpath}
				callType={CallsTypes.Video}
			/>
			{!isMobile() ? (
				<>
					<UncontrolledTooltip trigger="hover" target="user-profile">
						{i18next.t("Top Menu.User profile")}
					</UncontrolledTooltip>
					<UncontrolledTooltip trigger="hover" target="user-blocks">
						{i18next.t("Top Menu.User blocks")}
					</UncontrolledTooltip>
				</>
			) : (
				<></>
			)}
		</>
	);
};
