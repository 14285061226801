import produce from "immer";

const initialState = {
	open: false,
	message: "",
};

export default produce((state, action) => {
	switch (action.type) {
		case "openNotification":
			state.open = true;
			state.message = action.payload;
			break;
		case "closeNotification":
			state.open = false;
			state.message = "";
			break;
	}
}, initialState);
