import i18next from "i18next";
import SelectButton from "./SelectButton";
export default ({ items, onClick, translatePrefix }) => {
	return (
		<div className="buttonsSection2">
			{items &&
				Object.keys(items).map((itemKey) => [
					<SelectButton
						key={`${translatePrefix}_${itemKey}`}
						itemKey={itemKey}
						selected={items[itemKey]}
						onClick={onClick}>
						{i18next.t(`${translatePrefix}.${itemKey}`)}
					</SelectButton>,
				])}
		</div>
	);
};
