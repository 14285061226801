import * as types from "./actionTypes";
import ChatReceivedService from "services/chatReceived";
import ChatSentService from "services/chatSent";
import ChatReceivedFilteredService from "services/chatReceivedFiltered";
import ChatTabs from "consts/chatTabs";
import localStorage from "services/localStorage";
import chatMeta from "services/chatMeta";

export const virtualDeleteChat = (colleagueUid, tab) => {
	tab === ChatTabs.Received && ChatReceivedService.virtualDeleteChat(colleagueUid);
	tab === ChatTabs.Sent && ChatSentService.virtualDeleteChat(colleagueUid);
	tab === ChatTabs.ReceivedFiltered && ChatReceivedFilteredService.virtualDeleteChat(colleagueUid);
	chatMeta.updateRead(colleagueUid);

	return {
		type: types.CHAT_RECEIVED_VIRTUAL_DELETE_CHAT,
		payload: colleagueUid,
	};
};

export const cancelDeleteChat = (colleagueUid) => {
	ChatReceivedService.cancelDeleteChat(colleagueUid);
	ChatSentService.cancelDeleteChat(colleagueUid);
	ChatReceivedFilteredService.cancelDeleteChat(colleagueUid);
	return {
		type: types.CHAT_RECEIVED_CANCEL_DELETE_CHAT,
		payload: colleagueUid,
	};
};

export const updateReadMassleter = (colleagueUid) => {
	return (dispatch, getState) => {
		const loginFromManager = localStorage.get("loginFrommanager");
		if (!loginFromManager) ChatReceivedService.updateReadMassleter(colleagueUid);
	};
};

export const addChatReceivedUser = (payload) => {
	return { type: types.CHAT_RECEIVED_ADDED, payload };
};

export const updateLoadMore = (payload) => {
	return { type: types.UPDATE_MORE_NUMBER, payload };
};
export const resetChatReceivedUsers = (payload) => {
	return { type: types.RESET_CHAT_RECEIVED_USERS, payload };
};
