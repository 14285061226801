import { useState } from "react";
import UploadImage from "../UploadImage";
import Question from "./Question";

export default ({
  title,
  subTitle,
  onSubmit,
  onBackButtonClick,
  showBackButton,
  isClickedNext,
}) => {
  const [value, setValue] = useState();
  const [isValid, setIsValid] = useState();
  const handleOnSubmit = () => {
    onSubmit(value);
  };

  const handleOnChange = (value) => {
    setValue(value);
  };

  const handleOnValidityChange = (_isValid) => {
    setIsValid(_isValid);
  };

  return (
    <Question
      isValid={isValid && !isClickedNext}
      title={title}
      subTitle={subTitle}
      onSubmit={handleOnSubmit}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    >
      <UploadImage
        onChange={handleOnChange}
        onValidityChange={handleOnValidityChange}
      />
    </Question>
  );
};
