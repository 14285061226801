import { mergeMap, catchError, repeat, map, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import AppService from "services/app";
import FcmTokenService from "services/fcmToken";
import { getLoggedInUserUID } from "redux/auth/selectors";
import * as authTypes from "redux/auth/actionTypes";
import * as types from "./actionTypes";
import { getIsPlatformWeb } from "./selectors";

export const initAppPlatform = (action$) => {
	return action$.pipe(
		ofType(authTypes.AUTH_APP_INIT),
		mergeMap(async () => {
			try {
				const platform = await AppService.getPlatform();
				return {
					type: types.INIT_PLATFORM_SUCCESSFULLY,
					payload: { platform },
				};
			} catch (error) {
				return {
					type: types.INIT_PLATFORM_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.INIT_PLATFORM_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);
};

export const listenToFcmToken = (action$, store) => {
	return action$.pipe(
		ofType(types.INIT_PLATFORM_SUCCESSFULLY),
		filter(() => {
			const state = store.value;
			const isWeb = getIsPlatformWeb(state);
			return !isWeb;
		}),
		mergeMap(() => {
			return AppService.listenFCMToken().pipe(
				map((token) => {
					const state = store.value;
					const loggedInUserUID = getLoggedInUserUID(state);
					FcmTokenService.saveFcmToken(loggedInUserUID, token);
					return {
						type: types.INIT_FCM_TOKEN_SAVE_SUCCESSFULLY,
					};
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.INIT_FCM_TOKEN_SAVE_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);
};

export const fetchFcmToken = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const isWeb = getIsPlatformWeb(state);
			if (!isWeb) {
				AppService.registerFCMPush();
				return {
					type: types.INIT_FCM_TOKEN_FETCH_SUCCESSFULLY,
				};
			} else {
				return {
					type: types.INIT_FCM_TOKEN_FETCH_UNNECESSARY,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.INIT_FCM_TOKEN_FETCH_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);
