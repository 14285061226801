import firebase from "firebase/app";
import "firebase/auth";

class AuthFacebookService {
  async login() {
    const { facebookData, authResponse } =
      await this.getFacebookDataAfterLogin();
    const credential = firebase.auth.FacebookAuthProvider.credential(
      authResponse.authResponse.accessToken
    );
    const firebaseUserCredential = await firebase
      .auth()
      .currentUser.linkWithCredential(credential);
    return {
      facebookAccessToken: authResponse.authResponse.accessToken,
      authUser: firebaseUserCredential.user,
      facebookData,
    };
  }

  async getFacebookDataAfterLogin() {
    return new Promise((resolve, reject) => {
      try {
        window.FB.login(
          (authResponse) => {
            if (authResponse.status === "connected") {
              window.FB.api(
                "/me?fields=id,name,birthday,email,picture.width(9999).height(9999)",
                function (response) {
                  resolve({ facebookData: response, authResponse });
                }
              );
            }
          },
          { scope: "email" }
        );
      } catch (error) {
        reject(error.message);
      }
    });
  }
}

export default new AuthFacebookService();
