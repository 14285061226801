import i18next from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const currentUser = useSelector(getUserProfile);
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>
				<i className="ri-archive-line float-end text-muted jsmartDrop jsmartBlock"></i>
				{i18next.t(`User successfully blocked ${currentUser.gender}`)}
			</ModalHeader>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn"
				>
					{i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
