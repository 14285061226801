import Notification from "./Notification";
import { useDispatch, useSelector } from "react-redux";
import { openNotification, closeNotification } from "redux/notification/actions";
import viewedMe from "services/viewedMe";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { getUserProfile } from "redux/profile/selectors";
import { isEmpty } from "lodash";
import likedMe from "services/likedMe";
import chatMessages from "services/chatMessages";
import publicProfile from "services/publicProfile";
import { Link } from "react-router-dom";
import { updateReadMassleter } from "redux/chatReceived/actions";
import { activeUserId, cancelActiveUser } from "redux/chat/actions";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";

export default function NotificationWrapper() {
	const dispatch = useDispatch();
	const usersSendMeNotifications = useRef({
		viewedMe: [],
		likedMe: [],
	});
	const loggedInUserProfile = useSelector(getUserProfile);
	const { showUserProfileSideBar } = usePopups();

	useEffect(() => {
		//new view me

		if (!isEmpty(loggedInUserProfile) && loggedInUserProfile?.uid) {
			const listenViewMeAdded = viewedMe
				.listenViewedMeAdded(loggedInUserProfile.uid)
				.subscribe((data) => {
					if (!isEmpty(data)) {
						Object.entries(data).map(async ([key, value]) => {
							if (usersSendMeNotifications.current.viewedMe.includes(key) || value?.unView == false)
								return;
							usersSendMeNotifications.current = {
								...usersSendMeNotifications.current,
								viewedMe: [...usersSendMeNotifications.current.viewedMe, key],
							};
							await publicProfile.fetchProfile(key).then((userViewMe) => {
								if (!isEmpty(userViewMe)) {
									dispatch(
										openNotification(
											<Link
												to="/viewedme"
												className="wrapNotificationMsg"
												onClick={() => dispatch(closeNotification())}>
												{i18next.t("notifications.newView")}
												{userViewMe?.name}
												{!!userViewMe.pictures?.[userViewMe?.mainPictureName]?.url && (
													<img
														src={`${userViewMe.pictures?.[userViewMe.mainPictureName]?.url}`}
														alt="img"
													/>
												)}
											</Link>
										)
									);
								}
							});
						});
					}
				});

			return () => {
				listenViewMeAdded.unsubscribe();
			};
		}
	}, [loggedInUserProfile]);

	useEffect(() => {
		//new like me

		if (!isEmpty(loggedInUserProfile) && loggedInUserProfile?.uid) {
			const listenLikedMeAdded = likedMe
				.listenLikedMeAddedFromNow(loggedInUserProfile.uid)
				.subscribe((data) => {
					if (!isEmpty(data)) {
						Object.entries(data).map(async ([key, value]) => {
							if (
								usersSendMeNotifications.current.likedMe.includes(key) ||
								value?.deleted == true ||
								value?.unView == false
							)
								return;
							usersSendMeNotifications.current = {
								...usersSendMeNotifications.current,
								likedMe: [...usersSendMeNotifications.current.likedMe, key],
							};

							await publicProfile.fetchProfile(key).then((userLikedMe) => {
								if (!isEmpty(userLikedMe)) {
									dispatch(
										openNotification(
											<Link
												to="/likedme"
												className="wrapNotificationMsg"
												onClick={() => dispatch(closeNotification())}>
												{i18next.t("notifications.newLike")}
												{userLikedMe?.name}
												{!!userLikedMe.pictures?.[userLikedMe?.mainPictureName]?.url && (
													<img
														src={`${userLikedMe.pictures?.[userLikedMe.mainPictureName]?.url}`}
														alt="img"
													/>
												)}
											</Link>
										)
									);
								}
							});
						});
					}
				});

			return () => {
				listenLikedMeAdded.unsubscribe();
			};
		}
	}, [loggedInUserProfile]);

	const onClickUser = (uid, isMassletter, chatID, user) => {
		dispatch(closeNotification());
		isMassletter && dispatch(updateReadMassleter(uid));
		dispatch(activeUserId(uid, chatID));
		!isMobile() && showUserProfileSideBar(user);
	};
	useEffect(() => {
		//new msg

		if (!isEmpty(loggedInUserProfile) && loggedInUserProfile?.uid) {
			const listenMsgMeAdded = chatMessages
				.listenNewMessagesRecived(loggedInUserProfile.uid)
				.subscribe(async (data) => {
					if (!isEmpty(data) && !!data.id) {
						if (window.location.pathname.includes("/messages") || data?.deleted == true) return;
						dispatch(cancelActiveUser());

						await publicProfile.fetchProfile(data.id).then((userSendMeMsg) => {
							if (!isEmpty(userSendMeMsg)) {
								dispatch(
									openNotification(
										<Link
											to="/messages"
											className="wrapNotificationMsg"
											onClick={() =>
												onClickUser(userSendMeMsg.uid, null, data.chatID, userSendMeMsg)
											}>
											{i18next.t("notifications.newMsg")}
											{userSendMeMsg?.name}
											{!!userSendMeMsg.pictures?.[userSendMeMsg?.mainPictureName]?.url && (
												<img
													src={`${userSendMeMsg.pictures?.[userSendMeMsg.mainPictureName]?.url}`}
													alt="img"
												/>
											)}
										</Link>
									)
								);
							}
						});
					}
				});

			return () => {
				listenMsgMeAdded.unsubscribe();
			};
		}
	}, [loggedInUserProfile]);

	return <Notification />;
}
