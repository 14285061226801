export const LIKES_FETCH_SUCCESSFULLY = "likes.LIKES_FETCH_SUCCESSFULLY";
export const LIKES_FETCH_FAILED = "likes.LIKES_FETCH_FAILED";
export const LIKES_ADDED = "likes.LIKES_ADDED";
export const LIKES_ADDED_ERROR = "likes.LIKES_ADDED_ERROR";
export const LIKES_REMOVED = "likes.LIKES_REMOVED";
export const LIKES_REMOVED_ERROR = "likes.LIKES_REMOVED_ERROR";
export const LIKES_ADD = "likes.LIKES_ADD";
export const LIKE_ADD_SUCCESSFULLY = "likes.LIKE_ADD_SUCCESSFULLY";
export const LIKES_ADD_FAILED = "likes.LIKES_ADD_FAILED";
export const LIKES_REMOVE = "likes.LIKES_REMOVE";
export const LIKE_REMOVE_SUCCESSFULLY = "likes.LIKE_REMOVE_SUCCESSFULLY";
export const LIKES_REMOVE_FAILED = "likes.LIKES_REMOVE_FAILED";
export const LIKES_USERS_FETCH = "likes.LIKES_USERS_FETCH";
export const LIKES_USERS_FETCH_LOAD_MORE = "likes.LIKES_USERS_FETCH_LOAD_MORE";
export const LIKES_USERS_FETCH_SUCCESSFULLY =
  "likes.LIKES_USERS_FETCH_SUCCESSFULLY";
export const LIKES_USERS_FETCH_LOAD_MORE_SUCCESSFULLY =
  "likes.LIKES_USERS_FETCH_LOAD_MORE_SUCCESSFULLY";
  export const LIKES_USERS_FETCH_FAILED = "likes.LIKES_USERS_FETCH_FAILED";
  export const LIKES_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY = "likes.LIKES_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY";
  export const LIKES_ADDED_FETCH_USER_PROFILE_FAILED = "likes.LIKES_ADDED_FETCH_USER_PROFILE_FAILED";
  
