import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";

import {
	viewLikedMeList,
	fetchLikedMeUsersLoadMore,
	virtualDeleteLike,
} from "redux/likedMe/actions";
import CardsView from "components/CardsView";
import { getHasMoreResults, getIsLoadingMore, getLikedMeStatus } from "redux/likedMe/selectors";
import { fetchLikedMeUsers } from "redux/likedMe/actions";
import { getLikedMeUsers } from "redux/likedMe/selectors";
import usePopups from "hooks/usePopups";
import Loader from "components/Loader";
import API_REQUEST_STATUS from "consts/apiRequestStatus";

export default ({ scrollToTop }) => {
	const users = useSelector(getLikedMeUsers);
	const dispatch = useDispatch();
	const isLoadingMore = useSelector(getIsLoadingMore);
	const hasMoreResults = useSelector(getHasMoreResults);
	const [selectedUsers, setSelectedUsers] = useState(new Set());
	const { showDeleteLikedMeUsersPopup } = usePopups();
	const status = useSelector(getLikedMeStatus);

	useEffect(() => {
		dispatch(fetchLikedMeUsers());
		scrollToTop();
		dispatch(viewLikedMeList());
	}, []);

	const fetchData = () => {
		if (!isLoadingMore && hasMoreResults) {
			dispatch(fetchLikedMeUsersLoadMore());
		}
	};

	const updateSelectedUser = (add, userUid) => {
		add && selectedUsers.add(userUid);
		!add && selectedUsers.delete(userUid);
		setSelectedUsers(new Set(selectedUsers));
	};

	const onDeleteLikedMe = () => {
		selectedUsers.forEach((userUid) => dispatch(virtualDeleteLike(userUid)));
		setSelectedUsers(new Set());
	};

	const onClickDeleteLikedMe = () => showDeleteLikedMeUsersPopup({ onDeleteLikedMe });

	return (
		<>
			<InfiniteScroll dataLength={users ? users.length : 0} next={fetchData} hasMore={true}>
				{status === API_REQUEST_STATUS.SUCCESSFULLY ? (
					<CardsView
						users={users}
						deletable
						updateSelectedUser={updateSelectedUser}
						selectedUsers={selectedUsers}
						addLikesTime //likes timestamp of collage user is equal to liked_me timestamp
						likedMePage
					/>
				) : (
					<Loader className="loaderBeforeResults" />
				)}
			</InfiniteScroll>
			<div className={`bottomPanel ${selectedUsers.size ? "slideInto" : ""}`}>
				<button type="button" className="" color="secondary" onClick={onClickDeleteLikedMe}>
					{i18next.t("Delete Selected Users")}
				</button>
			</div>
		</>
	);
};
