import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import i18next from "i18next";

import { activeUserId } from "redux/chat/actions";
import sendBl from "assets/images/icons/chatB.svg";
import { getChatReceivedUser } from "redux/chatReceived/selectors";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import { getPopupUnderPopupType } from "redux/popups/selectors";
import PopupsTypes from "consts/popupsTypes";
import { cancelDeleteChat } from "redux/chatReceived/actions";
import { getChatSentUser } from "redux/chatSent/selectors";

export default ({ user, chatID = "" }) => {
	const userUid = user.uid;
	const dispatch = useDispatch();
	const history = useHistory();
	const { gender } = useSelector(getUserProfile);
	const chatReceivedUser = useSelector(getChatReceivedUser(userUid));
	const chatSentUser = useSelector(getChatSentUser(userUid));
	const redirectPath = chatReceivedUser
		? "/messages"
		: chatSentUser
		? "/messages/sent"
		: "/messages/filtered";

	const isFilteredChat = !chatSentUser && !chatReceivedUser;

	const { showUserProfileSideBar, _closePopupUnderPopup } = usePopups();
	const popupUnderPopupType = useSelector(getPopupUnderPopupType);

	const onClickChat = () => {
		dispatch(cancelDeleteChat(userUid));
		dispatch(activeUserId(userUid, chatID, isFilteredChat));

		//Open the profile automatically only if it is not a mobile
		!isMobile() && showUserProfileSideBar(user);

		popupUnderPopupType === PopupsTypes.UserProfileSideBar && _closePopupUnderPopup();
		history.push(redirectPath);
	};

	return (
		<Button onClick={onClickChat} outline className="btnText chatButton redirect">
			<span>{i18next.t(`Search Card.Continue Correspondence_${gender}`)}</span>
			<div>
				<img src={sendBl} />
			</div>
		</Button>
	);
};
