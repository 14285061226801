import moment from "moment";
import i18next from "i18next";

import Subtitle from "components/Subtitle";
import MultirangeSlider from "../MultiRangeSlider/MultirangeSlider";
import SearchFilterKeys from "consts/searchFilterKeys";
import FilterLimits from "services/fiterLimits";

export default ({ onChange, profile, filter }) => {
	const { min, max } = FilterLimits.getDefaultAgeRange(profile.gender, profile.dob);
	const initMin = filter
		? moment()
				.startOf("year")
				.diff(filter.to * 1000, "year")
		: min;
	const initMax = filter
		? moment()
				.endOf("year")
				.diff(filter.from * 1000, "year")
		: max;

	const _onChange = ({ min, max }) => {
		onChange(SearchFilterKeys.Age, {
			from: moment().subtract(max, "years").startOf("year").unix(),
			to: moment().subtract(min, "years").startOf("year").unix(),
		});
	};

	return (
		<div className="section range">
			<Subtitle className="colorLabel">{i18next.t("Search.Age range")}</Subtitle>
			<MultirangeSlider
				min={18}
				max={100}
				step={1}
				onChange={_onChange}
				initMin={initMin}
				initMax={initMax}
			/>
		</div>
	);
};
