import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class LikesService {
	BASE_REF = "likes";

	async fetchLikes(uid) {
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("timeStamp")
					.startAt(null)
					.once("value")
			).val() || {}
		);
	}

	async checkIfLikedUser(colleagueUid) {
		const uid = firebase.auth().currentUser?.uid;
		const res = (
			await firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).once("value")
		).val();

		return res ? { [colleagueUid]: res } : null;
	}
	like(uid, colleagueUid) {
		return firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set({
			timeStamp: firebase.database.ServerValue.TIMESTAMP,
		});
	}

	unlike(uid, colleagueUid) {
		return firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set(null);
	}

	listenLikesAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("timeStamp")
				.startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenLikesRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}
}

export default new LikesService();
