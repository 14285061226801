import { useEffect, useState } from "react";
import { Button, Nav, Badge } from "reactstrap";
import { useSelector } from "react-redux";
import i18next from "i18next";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";

import logo from "assets/images/icons/logoHebrew.svg";
import search from "assets/images/icons/searchUserNav.svg";
import messages from "assets/images/icons/chatNav.svg";
import likes from "assets/images/icons/heartNav.svg";
import settings from "assets/images/icons/settingsB.svg";
import user from "assets/images/icons/userIconNav.svg";
import views from "assets/images/icons/viewsNav.svg";
import { getUserProfile } from "redux/profile/selectors";
import { getAgeByDob } from "helpers/date";
import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import NavLinkItem from "components/NavLinkItem";
import JoinNowButton from "components/JoinNowButton";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";

import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import AppService from "services/app";
import AppPlatforms from "consts/AppPlatforms";
import localStorage from "services/localStorage";
import userNotificationsService from "services/unreadNotification.service";
import notificationUnreadTypes from "consts/notificationUnreadTypes";

export default () => {
	const loggedInUserProfile = useSelector(getUserProfile);
	const [headerClassName, setHeaderClassName] = useState("header");
	const [userNotification, setUserNotification] = useState({
		[notificationUnreadTypes.chat]: 0,
		[notificationUnreadTypes.likedMe]: 0,
		[notificationUnreadTypes.viewedMe]: 0,
	});
	const history = useHistory();
	const currentPath = history.location.pathname;
	const hideSettingsLinks = currentPath.indexOf("/account") !== -1;
	const showJoinNowBtn = useShowJoinNowBtn();
	const mainPictureUri =
		loggedInUserProfile.pictures?.[loggedInUserProfile.mainPictureName]?.url || "";

	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const city = loggedInUserProfile[ProfileFields.Cites[currentLanguage()]];

	const loginFromManager = localStorage.get("loginFrommanager");

	useEffect(() => {
		AppService.getPlatform().then((platform) => {
			if (platform === AppPlatforms.IOS) {
				setHeaderClassName("header iosFix");
			}
		});
	}, []);

	const navItems = [
		{
			content: (
				<Button outline color="primary">
					<img src={settings} />
				</Button>
			),
			to: "/account",
		},
		{
			content: (
				<div className="userImage">
					<img
						src={
							mainPictureUri
								? mainPictureUri
								: loggedInUserProfile.gender
								? placeholderWomanRound
								: placeholderManRound
						}
					/>
				</div>
			),
			to: "/account/pictures",
		},
		{
			content: (
				<div className="userDtails">
					<span>{loggedInUserProfile.name}</span>
					{", "}
					<span>{getAgeByDob(loggedInUserProfile.dob)}</span>
					{loginFromManager && <span style={{ color: "#f7239a" }}> (manager)</span>}
					<br />
					<span>{city}</span>
				</div>
			),
			to: "/account",
		},
	];
	const navItems1 = [
		{
			content: (
				<NavLinkContent
					dataText={i18next.t("Home Tabs.Messages")}
					text={i18next.t("Home Tabs.Messages")}
					count={userNotification[notificationUnreadTypes.chat]}
					img={messages}
					className={"messagesIconNav"}
				/>
			),
			to: "/messages",
		},
		{
			content: (
				<NavLinkContent
					dataText={i18next.t("Home Tabs.Search")}
					text={i18next.t("Home Tabs.Search")}
					img={search}
					className={"searchIconNav"}
				/>
			),
			to: "/search",
		},
		{
			content: (
				<NavLinkContent
					dataText={i18next.t("Home Tabs.Likes")}
					text={i18next.t("Home Tabs.Likes")}
					count={userNotification[notificationUnreadTypes.likedMe]}
					img={likes}
					className={"likesIconNav"}
				/>
			),
			to: "/likedme",
		},
		{
			content: (
				<NavLinkContent
					dataText={i18next.t("Home Tabs.Views")}
					text={i18next.t("Home Tabs.Views")}
					count={userNotification[notificationUnreadTypes.viewedMe]}
					img={views}
					className={"viewsIconNav"}
				/>
			),
			to: "/viewedme",
		},
		{
			content: (
				<NavLinkContent
					dataText={i18next.t("Home Tabs.Profile")}
					text={i18next.t("Home Tabs.Profile")}
					img={user}
					className={"userIconNav mobileOnly"}
				/>
			),
			to: "/account",
		},
	];

	useEffect(() => {
		if (!loggedInUserProfile?.uid) return;

		const subscription = userNotificationsService
			.listenUserNotificationCount(loggedInUserProfile.uid)
			.subscribe((userData) => {
				if (!userData) return;

				let dataObj = {};
				Object.keys(userData).map((key) => (dataObj[key] = userData[key]?.count || 0));
				setUserNotification({ ...userNotification, ...dataObj });
			});
		return () => {
			subscription?.unsubscribe();
		};
	}, [loggedInUserProfile?.uid]);

	return (
		<>
			{true && (
				<div className={headerClassName}>
					<div className="innerHeader">
						<div className="siteLogo">
							<a href="#">
								<img src={logo} />
							</a>
						</div>
						{showJoinNowBtn && (
							<JoinNowButton
								text={i18next.t(`Pages.Join Now_${loggedInUserProfile.gender}`)}
								className="purchaseMinuyBtn"
							/>
						)}
						<Nav>
							{isUserLoggedIn ? (
								<div className="userTab">
									{navItems.map((item, index) => (
										<NavLinkItem
											key={index}
											currentPath={currentPath}
											{...item}
											className={hideSettingsLinks ? "hide" : ""}>
											{item.content}
										</NavLinkItem>
									))}
								</div>
							) : (
								<div className="userTab"></div>
							)}
							{navItems1.map((item, index) => (
								<NavLinkItem key={index} currentPath={currentPath} {...item}>
									{item.content}
								</NavLinkItem>
							))}
						</Nav>
					</div>
				</div>
			)}
		</>
	);
};

const NavLinkContent = ({ text, count, img, className = {}, dataText }) => {
	return (
		<>
			<div className={"wrapBadge" + " " + className}>
				{!!count && <Badge pill>{count}</Badge>}
				<img src={img} />
			</div>
			<p data-text={dataText}>{text}</p>
		</>
	);
};
