import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import LocalStorageService from "services/localStorage";

import usePopups from "hooks/usePopups";

export default () => {
  const { closePopup } = usePopups();

  const onChangeLanguage = () => {
    const langPopup = LocalStorageService.get("langPopup");
    LocalStorageService.set("lang", langPopup);
    window.location.reload();
  };

  return (
    <Modal isOpen={true} toggle={closePopup} className='simpleDialog'>
      <ModalHeader>{i18next.t("Language.Are you sure")}</ModalHeader>

      <ModalFooter>
        <Button outline size='sm' color='primary' onClick={onChangeLanguage} className='btn-rounded littleBtn'>
          {i18next.t("yes")}
        </Button>
        <Button outline size='sm' color='primary' onClick={closePopup} className='btn-rounded littleBtn'>
          {i18next.t("no")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
