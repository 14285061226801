import i18next from "i18next";

import DetailsAreaContainer from "./DetailsAreaContainer";
import Contact from "./Contact";
import SubjectsTypes from "consts/subjectsTypes";

export default () => {
	return (
		<DetailsAreaContainer className="contactPage" title={i18next.t("Pages.Contact")}>
			<Contact subject={SubjectsTypes.General} />
		</DetailsAreaContainer>
	);
};