import "assets/scss/custom/components/email.scss";
import i18next from "i18next";
import { Modal, Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { useEffect } from "react";
import ProfileService from "services/profile";
import { getPopups } from "redux/popups/selectors";

export default () => {
	const { closePopup } = usePopups();
	const loggedInUserProfile = useSelector(getUserProfile);
	const { user } = useSelector(getPopups);

	useEffect(async () => {
		await ProfileService.updateReadPopupExplanationEffort(loggedInUserProfile.uid);
	}, []);

	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className="simpleDialog"
			style={{ maxWidth: "450px", padding: "0 15px" }}>
			<Box className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</Box>
			<h5
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					fontSize: "1.4rem",
					fontWeight: 400,
					lineHeight: 1.2,
					textAlign: "center",
					padding: "0 1.2rem 1.2rem",
				}}>
				<Box
					style={{
						marginBottom: "4px",
					}}>
					{i18next.t("Popups.explainEffort.hey", { name: loggedInUserProfile.name })},
				</Box>
				<Box
					style={{
						fontSize: "1.2rem",
						marginBottom: "4px",
					}}>
					{i18next.t("Popups.explainEffort.Message", { name2: user.name })}
				</Box>
			</h5>
		</Modal>
	);
};
