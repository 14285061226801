import Question from "./Question";
import SocialButtons from "../SocialButtons";
import LogoutButton from "../LogoutButton";

export default ({
	title,
	subTitle,
	onFacebookClick,
	onGoogleClick,
	onAppleClick,
	showBackButton,
	onBackButtonClick,
}) => {
	return (
		<div className="innerLoginWrapper">
			<Question
				title={title}
				subTitle={subTitle}
				showSubmitButton={false}
				showBackButton={showBackButton}
				onBackButtonClick={onBackButtonClick}
			>
				<SocialButtons
					onFacebookClick={onFacebookClick}
					onGoogleClick={onGoogleClick}
					onAppleClick={onAppleClick}
				/>{" "}
				<LogoutButton />
			</Question>
		</div>
	);
};
