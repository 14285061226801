import { getAgeByDob } from "helpers/date";
import usePopups from "hooks/usePopups";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import privatePicture from "assets/images/emptyImages/private.jpg";

export default ({ user }) => {
	const { showUnblockQuestionPopup } = usePopups();
	const mainPicPath = user?.pictures ? user?.pictures[user?.mainPictureName]?.url : null;
	const path = user?.isPicturesPrivate
		? privatePicture
		: mainPicPath
		? mainPicPath
		: user?.gender
		? placeholderWomanRound
		: placeholderManRound;

	return (
		<div className="oneBlockedUser">
			<div className="imageUserBtn">
				<div className="imageUser">
					<img src={path} />
				</div>
				<button
					onClick={() => showUnblockQuestionPopup(user)}
					type="button"
					className="nav-btn closeButton btn btn-none">
					<i className="ri-close-line"></i>
				</button>
			</div>
			<div className="blockedDetails">
				<h4>{user.name}</h4>
				<h5>{user.age || getAgeByDob(user.dob)}</h5>
			</div>
		</div>
	);
};
