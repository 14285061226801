import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import MaritalStatus from "consts/maritalStatus";
import SearchMultiSelect from "./SearchMultiSelect";
import SearchFilterKeys from "consts/searchFilterKeys";

export default ({ onChange, profile, filter }) => {
	const spouseGender = 1 - profile.gender;

	return (
		<SearchMultiSelect
			onChange={onChange}
			field={ProfileFields.MaritalStatus}
			fieldOptions={Object.values(MaritalStatus)}
			translatePrefix={`MaritalStatus_${spouseGender}`}
			SearchFilterKey={SearchFilterKeys.MaritalStatus}
			initSelected={filter}
			title={i18next.t("Edit Profile.MaritalStatus")}
		/>
	);
};
