import _ from "lodash";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { getMessagesFilter } from "redux/general/selectors";
import UserGeneralService from "services/userGeneral";
import QueueService from "services/queue";
import ChatEvents from "consts/chatEvents";
import * as types from "./actionTypes";
import FilterLimits from "services/fiterLimits";
import { getUserProfile } from "redux/profile/selectors";

export function updateMessagesFilter(filter) {
	return (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		const messagesFilter = getMessagesFilter(state);
		const updateMessagesFilter = { ...messagesFilter, ...filter };
		UserGeneralService.updateMessagesFilter(updateMessagesFilter);
		QueueService.sendEvent(uid, ChatEvents.ChangeFiltersUser, updateMessagesFilter);
		return dispatch({ type: types.UPDATE_MESSAGES_FILTER_SUCCESFULLY });
	};
}

export function updateDefaultMessagesFilter() {
	return (dispatch, getState) => {
		const state = getState();
		const profile = getUserProfile(state);
		if (!_.isNil(profile.gender) && !_.isNil(profile.dob)) {
			const { max, min } = FilterLimits.getDefaultAgeRange(profile.gender, profile.dob);
			return dispatch(
				updateMessagesFilter({
					ageFrom: min,
					ageTo: max,
				})
			);
		}
	};
}

export function updateMessageFilterInit() {
	return { type: types.UPDATE_MESSAGES_FILTER_INIT };
}

export function updateMessageFilterSubmit() {
	return { type: types.UPDATE_MESSAGES_FILTER_SUBMITED };
}

export const addChatReceivedFilteredUser = (payload) => {
	return { type: types.CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY, payload };
};

export const updateLoadMoreReceivedFiltered = (payload) => {
	return { type: types.UPDATE_MORE_NUMBER, payload };
};

export const resetChatReceivedFilteredUsers = (payload) => {
	return { type: types.RESET_CHAT_RECEIVED_FILTERED_USERS, payload };
};
