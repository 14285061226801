import produce from "immer";

import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import RESEND_CODE_STATUS from "consts/resendCodeStatus";
import * as types from "./actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {
	isAuthChecked: false,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.AUTH_LOGGED_IN:
			state.user = action.user;
			state.isAuthChecked = true;
			break;
		case types.AUTH_LOGIN_WITH_PHONE:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING;
			state.loginPhone = action.phone;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_WAITING_FOR_VERIFICATION:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.WAIT_FOR_VERIFICATION;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_VERIFICATION:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.VERIFICATION_PENDING;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_VERIFICATION_SUCCESSFULLY:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.LOGIN_SUCCESSFULLY;
			break;

		case types.AUTH_LOGIN_WITH_TOKEN_SUCCESSFULLY:
			state.loginWithTokenStatus = LOGIN_WITH_PHONE_STATUS.LOGIN_SUCCESSFULLY;
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.LOGIN_SUCCESSFULLY;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_VERIFICATION_FAILED:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.VERIFICATION_FAILED;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_FAILED:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.LOGIN_FAILED;
			state.loginWithPhoneErrorMessage = action.error;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_RESET:
			state.loginWithPhoneStatus = LOGIN_WITH_PHONE_STATUS.INIT;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE:
			state.resendCodeStatus = RESEND_CODE_STATUS.RESEND_CODE_PENDING;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE_SUCCESSFULLY:
			state.resendCodeStatus = RESEND_CODE_STATUS.RESEND_CODE_SUCCESSFULLY;
			break;
		case types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE_FAILED:
			state.resendCodeStatus = RESEND_CODE_STATUS.RESEND_CODE_FAILED;
			break;
		case types.AUTH_LOGGED_OUT:
		case types.AUTH_LOG_OUT:
		case types.AUTH_ALREADY_LOGGED_IN:
			state.isAuthChecked = true;
			break;
		case types.AUTH_LOGIN_SET_CHECKED:
			state.isAuthChecked = action.payload.isChecked;
			break;
		case types.AUTH_REGISTER_WITH_FACEBOOK_SUCCESSFULLY:
			state.loginWithFacebookStatus = API_REQUESTS_STATUS.SUCCESSFULLY;
			state.facebookAccessToken = action.payload.facebookAccessToken;
			state.isNewUser = true;
			break;
		case types.AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY:
			state.loginWithGoogleStatus = API_REQUESTS_STATUS.SUCCESSFULLY;
			break;
		case types.AUTH_LOGIN_WITH_PASSWORD_SUCCESSFULLY:
			state.loginWithPasswordStatus = API_REQUESTS_STATUS.SUCCESSFULLY;
			break;
		case types.AUTH_LOGIN_WITH_PASSWORD_FAILED:
			state.loginWithPasswordStatus = API_REQUESTS_STATUS.FAILED;
			break;
		case types.AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY:
			state.loginWithGoogleStatus = API_REQUESTS_STATUS.SUCCESSFULLY;
			break;
		case types.AUTH_REGISTER_WITH_GOOGLE_FAILED:
			state.loginWithGoogleStatus = API_REQUESTS_STATUS.FAILED;
			break;
		case types.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
