import i18next from "i18next";

import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import block from "assets/images/icons/block.svg";
import report from "assets/images/icons/report.svg";

export default ({ profile }) => {
	const currentUser = useSelector(getUserProfile) || {};

	const { showBlockUserPopup, showReportUserPopup } = usePopups();

	return (
		<div className="listOfDesc reportUsers">
			<div className="rowOfDesc reportUsersImage" onClick={() => showBlockUserPopup(profile)}>
				<div className="iconOfDesc">
					<img src={block} alt="block image" className="reportUsersImage" />
				</div>
				<p className="txtOfDesc reportUsersImage">{i18next.t(`Block ${currentUser?.gender}`)}</p>
			</div>
			<div className="rowOfDesc reportUsersImage" onClick={() => showReportUserPopup(profile)}>
				<div className="iconOfDesc">
					<img src={report} alt="report image" className="reportUsersImage" />
				</div>
				<p className="txtOfDesc reportUsersImage">{i18next.t(`Report ${currentUser?.gender}`)}</p>
			</div>
		</div>
	);
};
