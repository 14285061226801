import i18next from "i18next";
import { Form, FormGroup } from "reactstrap";
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import ProfileFields from "consts/profileFields";
import { getUserProfile } from "redux/profile/selectors";
import Stance from "consts/stance";
import Switch from "components/Switch";
import Subtitle from "components/Subtitle";
import { updateAllowSharedContact, updateModestyFilter } from "redux/profile/actions";
import contactsService from "services/contactsService";
import { getIsPlatformAndroid, getIsPlatformIOS } from "redux/init/selectors";
import usePopups from "hooks/usePopups";

export default () => {
	const dispatch = useDispatch();
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const profile = useSelector(getUserProfile);
	const { showMobileStorePopup } = usePopups();
	const [modestyFilter, setModestyFilter] = useState(profile.ModestyFilter);
	const [shareContacts, setShareContacts] = useState(profile.allowSharedContact || false);
	const showModestyFilter =
		profile[ProfileFields.Stance] === Stance.Dati ||
		profile[ProfileFields.Stance] === Stance.DatiLeumi;

	const onModestySettingsChange = (val) => {
		const filter = val ? 0 : 1;
		setModestyFilter(filter);
		dispatch(updateModestyFilter(filter));
	};

	const toggleSharedContact = () => {
		dispatch(updateAllowSharedContact(shareContacts));
	};

	const toggleSyncContactsToNeo4j = async () => {
		if (!isPlatformIOS && !isPlatformAndroid) {
			showMobileStorePopup();
		} else if (!profile.syncContactsToNeo4j && !shareContacts) {
			return await contactsService.setContacts(profile?.uid);
		}
	};

	useEffect(async () => {
		toggleSharedContact();
	}, [shareContacts]);

	return (
		<Form inline>
			<FormGroup>
				{showModestyFilter && (
					<>
						<Subtitle className>{i18next.t("Additional Settings.View Filters Settings")}</Subtitle>
						<Switch
							title={i18next.t("View Filters Settings.ModestyFilter")}
							description={i18next.t("View Filters Settings.ModestyFilterDescription")}
							value={modestyFilter === 0}
							onChange={(e) => onModestySettingsChange(e.target.checked)}
						/>
					</>
				)}
				{true && (
					<Switch
						title={i18next.t("View Filters Settings.shareContacts")}
						value={shareContacts}
						onChange={(e) => {
							setShareContacts(e.target.checked);
							!shareContacts && toggleSyncContactsToNeo4j();
						}}
					/>
				)}
			</FormGroup>
		</Form>
	);
};
