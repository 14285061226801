import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

import usePopups from "hooks/usePopups";
import LikesTabs from "consts/likesTabs";
import ViewsTabs from "consts/viewsTabs";

export default ({ to, currentPath, className = "", children }) => {
	const { _closePopupUnderPopup } = usePopups();
	const _to =
		to === `/${LikesTabs.LikedMeTab}` ? "like" : to === `/${ViewsTabs.ViewedMeTab}` ? "view" : to;

	return (
		<NavItem active={currentPath.indexOf(_to) !== -1} className={className}>
			<NavLink tag={Link} to={to} onClick={_closePopupUnderPopup}>
				{children}
			</NavLink>
		</NavItem>
	);
};
