import { useEffect, useState } from "react";
import speedDatingService from "services/speedDatingService";
import { Box, CircularProgress, Rating, Typography } from "@mui/material";
import feedbackTypes from "consts/feedbackTypes";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";

export default function Feedback({ currentDate, feddbackQuestions, speedData }) {
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState(null);
	const { showFittedMessagePopup } = usePopups();

	const sendForm = async () => {
		if (!values) return;
		setLoading(true);

		const promises = [];

		Object.entries(values || {}).map(([key, value]) => {
			promises.push(
				speedDatingService.setUserFeedback({
					speedDatingKey: speedData.key,
					feedbackKey: key,
					uidDate: currentDate.uidMatch,
					value,
				})
			);
		});
		await Promise.all(promises).then((res) => {
			showFittedMessagePopup("הפעולה בוצעה בהצלחה!");
			setLoading(false);
		});
	};

	return (
		<>
			{!!feddbackQuestions && !!feddbackQuestions?.length && (
				<>
					{feddbackQuestions.map((item, index) => (
						<FeedbackItem item={item} key={index} setValues={setValues} />
					))}

					{!!loading ? (
						<CircularProgress size={40} className="text-secondary" />
					) : (
						<Button style={{ marginTop: "10px" }} color="none" type="button" onClick={sendForm}>
							<span>{"שלח"}</span>
						</Button>
					)}
				</>
			)}
		</>
	);
}

const FeedbackItem = ({ item, setValues }) => {
	const [value, setValue] = useState(null);

	const displayItemByType = (item) => {
		switch (item?.feedbackType) {
			case feedbackTypes.boolean:
				return (
					<Box sx={{ border: "1px solid", p: 1 }}>
						<Box>{item?.question}</Box>
						<Box>
							<Button color="none" type="button" onClick={() => setValue(1)}>
								<span>{item?.yesText}</span>
							</Button>
							<Button color="none" type="button" onClick={() => setValue(0)}>
								<span>{item?.noText}</span>
							</Button>
							{item?.thirdText && (
								<Button color="none" type="button" onClick={() => setValue(2)}>
									<span>{item?.thirdText}</span>
								</Button>
							)}
						</Box>
					</Box>
				);
			case feedbackTypes.rating:
				return (
					<Box sx={{ border: "1px solid", p: 1 }}>
						<Box sx={{}}>
							<Typography component="legend">{item?.question}</Typography>
							<Rating
								style={{ direction: "ltr" }}
								value={value}
								onChange={(event, newValue) => {
									setValue(newValue);
								}}
							/>
						</Box>
					</Box>
				);

			default:
				return <></>;
		}
	};

	useEffect(() => {
		setValues((prev) => {
			return { ...prev, [item.key]: value };
		});
	}, [value]);

	return <>{displayItemByType(item)}</>;
};
