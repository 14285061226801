import React from "react";

export default ({ children }) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-offset="-400"
      data-aos-duration="300"
      data-aos-easing="ease-in-out"
      data-aos-mirror="false"
      data-aos-once="true"
    >
      {children}
    </div>
  );
};
