import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

import StorageService from "services/storage";
import PendingStatus from "consts/pendingStatus";
import ProfileFields from "consts/profileFields";

class ProfileService {
	BASE_REF = "users_profile";

	updateProfile(uid, details) {
		return firebase.database().ref(this.BASE_REF).child(uid).update(details);
	}

	fetchUserProfile(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	async deleteImage(userAuth, imageKey, imageData) {
		if (imageData?.isExternalLink === false) {
			await StorageService.deleteImage(userAuth, imageKey);
		}
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(userAuth)
			.child("pictures")
			.child(imageKey)
			.set(null);
	}

	setMainImage(imagekey) {
		const uid = firebase.auth().currentUser?.uid;
		return this.updateProfile(uid, {
			mainPictureName: imagekey,
		});
	}

	async addImage(uid, file, isExternalLink) {
		const imageName = `profilePic_${uid}_${Date.now()}`;
		const { url, size } = await StorageService.saveImage(uid, imageName, file);
		const image = {
			url,
			isExternalLink,
			name: imageName,
			status: PendingStatus.Wait,
			file_size: size,
		};

		await firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child("pictures")
			.child(image.name)
			.set(image);
		return image;
	}

	async saveEditImage(uid, key, path) {
		const { url, size } = await StorageService.saveImage(uid, key, path);
		await firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child("pictures")
			.child(key)
			.update({ url, file_size: size, status: PendingStatus.Wait });
		return url;
	}

	async isUserProfileExists(uid) {
		const val = (await firebase.database().ref(this.BASE_REF).child(uid).once("value")).val();
		return !!val && val.approved !== undefined;
	}
	async updateCompleteRegistration(uid) {
		await firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.update({ hasCompleteRegistration: true });
	}

	async updateReadPopupExplanationEffort(uid) {
		await firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.update({ readPopupExplanationEffort: true });
	}

	addTag(tag) {
		const uid = firebase.auth().currentUser?.uid;
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child(ProfileFields.Tags)
			.child(tag.key)
			.set(tag.text);
	}

	async updateSkipEmail(uid) {
		await firebase.database().ref(this.BASE_REF).child(uid).update({ emailSkiped: true });
	}

	async updateSkipImage(uid) {
		await firebase.database().ref(this.BASE_REF).child(uid).update({ imageSkiped: true });
	}
}

export default new ProfileService();
