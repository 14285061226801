export default {
	DatiLeumi: 5,
	Chiloni: 15,
	Mesorati: 1,
	DatiLite: 12,
	Datlash: 13,
	DatiLeumiTorani: 6,
	Orthodox: 7,
	ModernOrthodox: 8,
	Breslev: 9,
	Chabad: 10,
	BaalTshuva: 11,
	Dati: 2,
};
