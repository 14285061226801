export default {
	None: "None",
	BlockUser: "BlockUser",
	QuestionReport: "QuestionReport",
	ReportUser: "ReportUser",
	ThanksReportUser: "ThanksReportUser",
	UnmatchUser: "UnmatchUser",
	UserSuccessfullyBlocked: "UserSuccessfullyBlocked",
	Gallery: "Gallery",
	QRCode: "QRCode",
	UpdatesSavedSuccessfully: "UpdatesSavedSuccessfully",
	ContactFormSentSuccessfully: "ContactFormSentSuccessfully",
	FreezeAccountSuccessfullyPopup: "FreezeAccountSuccessfullyPopup",
	FreezeAccountPopup: "FreezeAccountPopup",
	DeleteAccountPopup: "DeleteAccountPopup",
	DeleteAccountSelectReasonPopup: "DeleteAccountSelectReasonPopup",
	DeleteAccountAddMessagePopup: "DeleteAccountAddMessagePopup",
	DeleteAccountSuccessfullyPopup: "DeleteAccountSuccessfullyPopup",
	LanguageSwitchPopup: "LanguageSwitchPopup",
	DeleteImagePopup: "DeleteImagePopup",
	CropImagePopup: "CropImagePopup",
	CardUserModal: "CardUserModal",
	UserProfileSideBar: "UserProfileSidebar",
	SubscriptionModal: "SubscriptionModal",
	SubscriptionSuccessfullyPopup: "SubscriptionSuccessfullyPopup",
	UnblockUserPopup: "UnblockUserPopup",
	DeleteLikedMeUsersPopup: "DeleteLikedMeUsersPopup",
	DeleteChatsPopup: "DeleteChatsPopup",
	MassletterPopup: "MassletterPopup",
	MassletterSentPopup: "MassletterSentPopup",
	ChatReceivedFilterPopup: "ChatReceivedFilterPopup",
	SendMessagePopup: "SendMessagePopup",
	SendTemplateMessagePopup: "SendTemplateMessagePopup",
	BlurMessagePopup: "BlurMessagePopup",
	NonSubscriberToNonSubscriberPopup: "NonSubscriberToNonSubscriberPopup ",
	NonSubscriberToSubscriberPopup: "NonSubscriberToSubscriberPopup ",
	MessageSuccessfullySentPopup: "MessageSuccessfullySentPopup",
	NotApprovedMessagePopup: "NotApprovedMessagePopup",
	SubscriberLimitMessagesPopup: "SubscriberLimitMessagesPopup",
	NonSubscriberLimitMessagesPopup: "NonSubscriberLimitMessagesPopup",
	TagAddedSuccessfullyPopup: "TagAddedSuccessfullyPopup",
	ContactFromFeedbackButtonPopup: "ContactFromFeedbackButtonPopup",
	UnsubscribeSuccessfullySavedPopup: "UnsubscribeSuccessfullySavedPopup",
	UnsubscribeErrorPopup: "UnsubscribeErrorPopup",
	UnsubscribeCancelPopup: "UnsubscribeCancelPopup",
	BigImage: "BigImage",
	MobileStoreModal: "MobileStoreModal",
	VerifiedEmail: "VerifiedEmail",
	ExplanationEffort: "ExplanationEffort",
	EffortSuccessfully: "EffortSuccessfully",
	LowQualityImage: "LowQualityImage",
	FittedMessagePopup: "FittedMessagePopup",
	LoginPopup: "LoginPopup",
	JaaSMeeting: "JaaSMeeting",
};
