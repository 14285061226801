export const CHAT_SENT_FETCH_SUCCESSFULLY = "chatSent.CHAT_SENT_FETCH_SUCCESSFULLY";
export const CHAT_SENT_FETCH_FAILED = "chatSent.CHAT_SENT_FETCH_FAILED";

export const CHAT_SENT_UPDATED_SUCCESSFULLY = "chatSent.CHAT_SENT_UPDATED_SUCCESSFULLY";
export const CHAT_SENT_ADDED_SUCCESSFULLY = "chatSent.CHAT_SENT_ADDED_SUCCESSFULLY";
export const CHAT_SENT_REMOVED_SUCCESSFULLY = "chatSent.CHAT_SENT_REMOVED_SUCCESSFULLY";
export const CHAT_SENT_UPDATED_ERROR = "chatSent.CHAT_SENT_UPDATED_ERROR";
export const CHAT_SENT_ADDED_ERROR = "chatSent.CHAT_SENT_ADDED_ERROR";
export const CHAT_SENT_REMOVED_ERROR = "chatSent.CHAT_SENT_REMOVED_ERROR";

export const CHAT_SENT_ADD_TEMPORARY_CHAT_SENT = "chatSent.CHAT_SENT_ADD_TEMPORARY_CHAT_SENT";
export const UPDATE_MORE_NUMBER = "chatSent.UPDATE_MORE_NUMBER";
export const RESET_CHAT_SENT_USERS = "chatSent.RESET_CHAT_SENT_USERS";
export const RESET_CHAT_SENT_USERS_SUCCESSFULLY = "chatSent.RESET_CHAT_SENT_USERS_SUCCESSFULLY";
