import * as types from './actionTypes';


export const setActiveIndex = index => ({
	type: types.SET_ACTIVE_INDEX , payload: index
});

export const setGalleryType = galleryType => ({
	type: types.SET_GALLERY_TYPE , payload: galleryType
});
