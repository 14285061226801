import i18next from "i18next";

import EnumBtn from "components/EnumBtn";
import MaritalStatus from "consts/maritalStatus";
import ProfileFields from "consts/profileFields";
import RequiredPointContainer from "../RequiredPointContainer";
import Warnings from "../Warnings";
import PendingStatus from "consts/pendingStatus";

export default ({ onChange, profile, pending }) => {
	const _onChange = (key, value) => onChange(key, Number(value));

	return (
		<div className="wrapInputValidation">
			<div className="wrapRequiredPoint">
				<RequiredPointContainer>
					<EnumBtn
						field={ProfileFields.MaritalStatus}
						fieldOptions={Object.values(MaritalStatus)}
						onChange={_onChange}
						initSelected={profile.maritalStatus}
						title={i18next.t("Edit Profile.MaritalStatus")}
						translatePrefix={`MaritalStatus_${profile.gender}`}
					/>
				</RequiredPointContainer>
			</div>
			<Warnings
				warning={pending?.status === PendingStatus.Wait && i18next.t("Edit Profile.Pending")}
				error={pending?.status === PendingStatus.Rejected && i18next.t("Edit Profile.Rejected")}
			/>
		</div>
	);
};
