import { useSelector } from "react-redux";
import i18next from "i18next";
import { ModalBody, Modal, ModalHeader, Button } from "reactstrap";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";

export default () => {
	const currentUser = useSelector(getUserProfile) || {};

	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("Not Approved Message Popup.Title")}</ModalHeader>
			<ModalBody>
				<h4>
					{i18next.t(`Not Approved Message Popup.Hi`, {
						name: currentUser.name,
					})}
				</h4>
				<span>{i18next.t(`Not Approved Message Popup.Your profile waiting for approve`)}</span>
			</ModalBody>
		</Modal>
	);
};
