export const PROMOTIONS_FETCH_SUCCESSFULLY = "promotions.PROMOTIONS_FETCH_SUCCESSFULLY";
export const PROMOTIONS_FETCH_FAILED = "promotions.PROMOTIONS_FETCH_FAILED";
export const PROMOTIONS_VIEWED = "promotions.PROMOTIONS_VIEWED";
export const PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE =
	"promotions.PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE";
export const PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE_ERROR =
	"promotions.PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE_ERROR";
export const PROMOTIONS_CHAT_RECEIVED_VIRTUAL_DELETE_CHAT =
	"promotions.PROMOTIONS_CHAT_RECEIVED_VIRTUAL_DELETE_CHAT";
export const PROMOTIONS_UPDATED = "promotions.PROMOTIONS_UPDATED";
export const PROMOTIONS_UPDATED_ERROR = "promotions.PROMOTIONS_UPDATED_ERROR";
export const PROMOTIONS_ADDED = "promotions.PROMOTIONS_ADDED";
export const PROMOTIONS_ADDED_ERROR = "promotions.PROMOTIONS_ADDED_ERROR";
