import React from 'react';
const ThemeLtr = React.lazy(() => import('./themeLtr'));

const ThemeSelector = ({ children , lang }) => {
    
    return (
        <>
            <React.Suspense fallback={() => null}>
                {lang === 'he' && <ThemeLtr />}
            </React.Suspense>
            {children}
        </>)
};


export default ThemeSelector;