//get user birthDay within those 7 days

export default (userDob) => {
	if (!userDob) return false;
	if (userDob < 1000000000) userDob = userDob * 1000;

	const currentDate = new Date();
	const startDate = new Date(currentDate.getTime() - 3 * 24 * 60 * 60 * 1000); // 3 days before current date
	const endDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000); // 3 days after current date

	// Convert userDob string into a Date object
	const userBirthday = new Date(userDob);
	const userBirthdayThisYear = new Date(
		currentDate.getFullYear(),
		userBirthday.getMonth(),
		userBirthday.getDate()
	);

	const startDateThisYear = new Date(
		currentDate.getFullYear(),
		startDate.getMonth(),
		startDate.getDate()
	);
	const endDateThisYear = new Date(
		currentDate.getFullYear(),
		endDate.getMonth(),
		endDate.getDate()
	);

	if (userBirthdayThisYear >= startDateThisYear && userBirthdayThisYear <= endDateThisYear) {
		return true;
	}
	return false;
};
