import { mergeMap, map, takeUntil, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import { getLoggedInUserUID } from "../auth/selectors";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";

import LetPicturesService from "services/letPictures";

export const fetchLetPictures = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(async () => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      try {
        const payload = await LetPicturesService.fetchLetPictures(loggedInUserUID);
        return {
          type: types.LET_PICTURES_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.LET_PICTURES_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.LET_PICTURES_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchLetPicturesAdded = (action$, store) =>
  action$.pipe(
    ofType(types.LET_PICTURES_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      return LetPicturesService.listenLetPicturesAdded(loggedInUserUID).pipe(
        map((change) => {
          return {
            type: types.LET_PICTURES_ADDED,
            payload: change,
          };
        })
      );
    }),
    catchError((error) => {
      return of({
        type: types.LET_PICTURES_ADDED_FAILED,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );

export const fetchLetPicturesRemoved = (action$, store) =>
  action$.pipe(
    ofType(types.LET_PICTURES_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      return LetPicturesService.listenLetPicturesRemoved(loggedInUserUID).pipe(
        map((change) => {
          return {
            type: types.LET_PICTURES_REMOVED,
            payload: change,
          };
        })
      );
    }),
    catchError((error) => {
      return of({
        type: types.LET_PICTURES_REMOVED_FAILED,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );
