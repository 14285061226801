import i18next from "i18next";
import { useSelector } from "react-redux";

import { getInternalID } from "redux/general/selectors";

export default () => {
  const internalId = useSelector(getInternalID);
  return (
    <h6>
      {i18next.t("Account Settings.Account Id")} {internalId}
    </h6>
  );
};
