import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { FormGroup, Input, InputGroup, InputGroupText, FormFeedback } from "reactstrap";
import { isValidSelectNumber } from "helpers/mobileNumber";
import SelectPhoneInput from "helpers/selectMobileNumber";
export default ({ onValidityChange, onEnterPressed, onChange, hasError, errMsg }) => {
	const [value, setValue] = useState("");
	const [isValid, setIsValid] = useState(false);

	const handleOnChange = (val) => {
		setValue(val);
	};

	const handleKeyPress = (keyPressed) => {
		if (keyPressed === "Enter" && !!isValid) {
			onEnterPressed();
		}
	};

	useEffect(() => {
		const _isValidNumber = isValidSelectNumber(value);
		setIsValid(_isValidNumber);
		onChange(value);
	}, [value]);

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	return (
		<FormGroup className="phone-container">
			<InputGroup className="phone-inner-container">
				<SelectPhoneInput handleOnChange={handleOnChange} handleKeyPress={handleKeyPress} />

				{hasError && <FormFeedback>{errMsg}</FormFeedback>}
			</InputGroup>
		</FormGroup>
	);
};
