import firebase from 'firebase/app';
import "firebase/database";
import { Observable } from 'rxjs';
class BlockedMeService {
  BASE_REF = 'blocked_me';

  async fetchBlockedMe(uid) {
    return (
      (
        await firebase.database()
          .ref(this.BASE_REF)
          .child(uid)
          .once('value')
      ).val() || {}
    );
  }

  listenBlockedMeAdded(uid) {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(uid)
        .orderByChild('timeStamp')
        .startAt(Date.now());

      const callbackFn = ref.on(
        'child_added',
        snapshot => subscriber.next({ key: snapshot.key, payload: snapshot.val() }),
        error => subscriber.error(error)
      );
      return () => ref.off('child_added', callbackFn);
    });
  }
}

export default new BlockedMeService();
