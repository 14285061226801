import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";

import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { Face, Fill } from "consts/cloudinaryOptions";
import { TransformImg } from "helpers/cloudinary";
import { isUserOnline } from "redux/presence/selectors";
import TopMenu from "./TopMenu";
import usePopups from "hooks/usePopups";
import GalleryTypes from "consts/galleryTypes";
import { cancelActiveUser } from "redux/chat/actions";
import { viewUser } from "redux/search/actions";
import isMobile from "helpers/isMobile";
import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import { getAgeByDob } from "helpers/date";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import privatePictureRound from "assets/images/emptyImages/privateRound.png";
import { useHistory } from "react-router-dom";
import arrow from "assets/images/arrow.png";

export default () => {
	const history = useHistory();
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const chatUserUri = activeUserProfile.pictures?.[activeUserProfile.mainPictureName]?.url || "";
	const chatUserpath = TransformImg(chatUserUri, null, null, Fill.Thumb, Face.Face, "r_max");
	const dispatch = useDispatch();
	const isOnline = useSelector(isUserOnline(active_userId));
	const userStatus = isOnline ? "online" : "offline";
	const { showGalleryPopup, showUserProfileSideBar, showUserCardPopup } = usePopups();
	const long_city = activeUserProfile[ProfileFields.Cites[currentLanguage()]];
	const city = long_city?.split(",")[0];
	const defaultImage = activeUserProfile.gender ? placeholderWomanRound : placeholderManRound;

	const showUserDetails = () => {
		dispatch(viewUser(activeUserProfile.uid));
		!isMobile()
			? showUserCardPopup(activeUserProfile, true)
			: showUserProfileSideBar(activeUserProfile);
	};

	const closeUserChat = () => {
		const userChat = document.getElementsByClassName("user-chat");
		userChat && userChat[0].classList.remove("user-chat-show");
		dispatch(cancelActiveUser());
	};

	useEffect(() => {
		const unblock = history.block((location, action) => {
			if (action === "POP") {
				closeUserChat();
			}
		});
		return () => {
			unblock();
		};
	}, [history]);

	return (
		<React.Fragment>
			<div className="p-3 p-lg-4 border-bottom chatHeader">
				<Row className="align-items-center">
					<Col className="user-head-let-pictures-container">
						<div className="d-flex align-items-center">
							<div className="d-block d-lg-none me-2 ms-0">
								<Link to="#" onClick={closeUserChat} className="user-chat-remove text-muted">
									<img src={arrow} alt="" height={"30px"} />
									{/* <i className="ri-arrow-left-s-line backArrowChat"></i> */}
								</Link>
							</div>
							{
								<div className={"chat-user-img " + userStatus + " align-self-center me-3 ms-0"}>
									<img
										src={
											chatUserpath
												? chatUserpath
												: activeUserProfile.isPicturesPrivate
												? privatePictureRound
												: defaultImage
										}
										onClick={() => showGalleryPopup(GalleryTypes.Public)}
										style={{ cursor: "pointer" }}
										className="rounded-circle avatar-sm"
									/>
									<span className="user-status"></span>
								</div>
							}
							<Link to="#" onClick={showUserDetails} className="text-reset user-profile-show">
								<div className="flex-1 overflow-hidden">
									<h5 className="font-size-16 mb-0 text-truncate headerChatName">
										{activeUserProfile.name}
										<span className="userDetailsChatMobile age text-truncate font-size-16 mb-1">
											{", " + (activeUserProfile.age || getAgeByDob(activeUserProfile.dob))}
										</span>
										{userStatus === "online"}
									</h5>
									<div className="userDetailsChatMobile">
										<p className="text-truncate font-size-12 mb-1">
											{i18next.t(
												`MaritalStatus_${activeUserProfile.gender}.${activeUserProfile.maritalStatus}`
											) +
												", " +
												i18next.t(`Stance_${activeUserProfile.gender}.${activeUserProfile.stance}`)}
										</p>
										<p className="text-truncate font-size-12 mb-1">{city}</p>
									</div>
									<h5 className="text-truncate font-size-12 mb-1"></h5>
								</div>
							</Link>
						</div>
					</Col>
					<TopMenu showUserDetails={showUserDetails} />
				</Row>
			</div>
		</React.Fragment>
	);
};
