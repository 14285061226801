import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import Cohen from "consts/cohen";
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, value) => onChange(key, Number(value));

    return <DropDown
                field={ProfileFields.Cohen} 
                fieldOptions={Object.values(Cohen)} 
                onChange={_onChange} 
                initSelected={profile.cohen}
                title={i18next.t('Edit Profile.Cohen')}
                translatePrefix="Cohen"
            />
};
