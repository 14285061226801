export const SUBSCRIPTIONS_FETCH_SUCCESSFULLY = "subscriptions.SUBSCRIPTIONS_FETCH_SUCCESSFULLY";
export const SUBSCRIPTIONS_FETCH_FAILED = "subscriptions.SUBSCRIPTIONS_FETCH_FAILED";
export const SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY";
export const SUBSCRIPTIONS_FETCH_PLANS_FAILED = "subscriptions.SUBSCRIPTIONS_FETCH_PLANS_FAILED";
export const SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM =
	"subscriptions.SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM";
export const SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY";
export const SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED =
	"subscriptions.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED";
export const SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED =
	"subscriptions.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED";
export const SUBSCRIPTIONS_CARDCOM_PURCHASE_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_CARDCOM_PURCHASE_SUCCESSFULLY";
export const SUBSCRIPTIONS_PURCHASE_WITH_GOOGLE =
	"subscriptions.SUBSCRIPTIONS_PURCHASE_WITH_GOOGLE";
export const SUBSCRIPTIONS_GOOGLE_STATUS_CHANGED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_STATUS_CHANGED";
export const SUBSCRIPTIONS_GOOGLE_PLANS_REGISTER_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_PLANS_REGISTER_SUCCESSFULLY";
export const SUBSCRIPTIONS_GOOGLE_PLANS_REGISTER_FAILED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_PLANS_REGISTER_FAILED";
export const SUBSCRIPTIONS_GOOGLE_ORDERED_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_ORDERED_SUCCESSFULLY";
export const SUBSCRIPTIONS_GOOGLE_ORDERED_FAILED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_ORDERED_FAILED";
export const SUBSCRIPTIONS_GOOGLE_APPROVED_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_APPROVED_SUCCESSFULLY";
export const SUBSCRIPTIONS_GOOGLE_ALREADY_REDEEMED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_ALREADY_REDEEMED";
export const SUBSCRIPTIONS_GOOGLE_APPROVED_FAILED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_APPROVED_FAILED";
export const SUBSCRIPTIONS_GOOGLE_CANCELLED = "subscriptions.SUBSCRIPTIONS_GOOGLE_CANCELLED";
export const SUBSCRIPTIONS_GOOGLE_LISTEN_FAILED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_LISTEN_FAILED";
export const SUBSCRIPTIONS_GOOGLE_LISTEN_UNNECESSARY =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_LISTEN_UNNECESSARY";
export const SUBSCRIPTION_POPUP_INIT_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTION_POPUP_INIT_SUCCESSFULLY";
export const SUBSCRIPTION_POPUP_INIT_UNNECESSARY =
	"subscriptions.SUBSCRIPTION_POPUP_INIT_UNNECESSARY";
export const SUBSCRIPTION_POPUP_INIT_FAILED = "subscriptions.SUBSCRIPTION_POPUP_INIT_FAILED";
export const SUBSCRIPTIONS_GOOGLE_STORE_ERROR = "subscriptions.SUBSCRIPTIONS_GOOGLE_STORE_ERROR";
export const SUBSCRIPTIONS_GOOGLE_STORE_ERROR_FAILED =
	"subscriptions.SUBSCRIPTIONS_GOOGLE_STORE_ERROR_FAILED";
export const SUBSCRIPTIONS_FETCH_SALE_SUCCESSFULLY =
	"subscriptions.SUBSCRIPTIONS_FETCH_SALE_SUCCESSFULLY";
export const SUBSCRIPTIONS_FETCH_SALE_FAILED = "subscriptions.SUBSCRIPTIONS_FETCH_SALE_FAILED";
export const SUBSCRIPTIONS_FETCH_SALE_EMPTY = "subscriptions.SUBSCRIPTIONS_FETCH_SALE_EMPTY";
