import { useState } from "react";
import i18next from "i18next";

import Subtitle from "components/Subtitle";
import SearchFilterKeys from "consts/searchFilterKeys";
import RangeSlider from "../RangeSlider/RangeSlider";
import LivingCity from "components/account/ProfileInputs/LivingCity";

const MAX = 800;

export default ({ onChange, filter, onChangeSearchLocation }) => {
	const [showAllText, setShowAllText] = useState(filter ? false : true);
	const [show, setShow] = useState(false);

	const handleOnChange = (_, place) => onChangeSearchLocation(place);

	const _onChange = (newNumber) => {
		if (Number(newNumber) === MAX) {
			setShowAllText(true);
			onChange(SearchFilterKeys.Range, undefined);
		} else {
			setShowAllText(false);
			onChange(SearchFilterKeys.Range, newNumber);
		}
	};

	return (
		<div className="section range">
			<Subtitle className="colorLabel">
				{i18next.t("Search.Range1")}
				<span className="edit-location" onClick={() => setShow((v) => (v === true ? false : true))}>
					<u>
						{i18next.t("Search.Range2")}
						<span className="mdi mdi-pencil-outline" />{" "}
					</u>
				</span>
				{i18next.t("Search.Range3")}{" "}
				{showAllText && <span className="search-all">- {i18next.t("Search.All")}</span>}
				<div className="editDistance">
					{show && <LivingCity onChange={handleOnChange} hideLabel={true} />}
				</div>
			</Subtitle>
			<RangeSlider min={0} max={800} step={1} onChange={_onChange} filter={filter} />
		</div>
	);
};
