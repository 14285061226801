import React, { useState, useEffect } from "react";
import moment from "moment";
import i18next from "i18next";

import FilterLimits from "services/fiterLimits";
import Number from "./Number";
import SearchFilterKeys from "consts/searchFilterKeys";

export default ({ onChange, profile, filter, className = "" }) => {
	const { min, max } = FilterLimits.getDefaultAgeRange(profile.gender, profile.dob);
	const [currentMin, setCurrentMin] = useState(min);
	const [currentMax, setCurrentMax] = useState(max);
	const initMin = filter.from && filter.from < 100 ? filter.from : min;
	const initMax = filter.to && filter.to < 100 ? filter.to : max;

	const handleFromChange = (val) => {
		setCurrentMin(val);
	};

	const handleToChange = (val) => {
		setCurrentMax(val);
	};

	useEffect(() => {
		onChange(SearchFilterKeys.Age, {
			from: currentMin,
			to: currentMax,
		});
	}, [currentMin, currentMax]);

	return (
		<div className={`section range ${className}`}>
			<Number
				field="from"
				title={i18next.t("FromAge")}
				startAt={18}
				endAt={currentMax - 1}
				initVal={initMin}
				onChange={handleFromChange}
				className="ageRangeBtn"
			/>
			<Number
				field="to"
				title={i18next.t("ToAge")}
				startAt={currentMin + 1}
				endAt={100}
				initVal={initMax}
				onChange={handleToChange}
				className="ageRangeBtn"
			/>
		</div>
	);
};
