import produce from 'immer';

import * as types from './actionTypes';
import AppSettings from 'consts/appSettings';

const initialState = {
  data: AppSettings,
  isLoaded: false,
  isTagsFetched: false,  
};

export default produce((state , action) => {
  switch (action.type) {
    case types.APP_SETTINGS_FETCH_SUCCESSFULLY:
        state.data = action.payload;
        state.isLoaded = true;
        break;
    case types.APP_SETTINGS_FETCH_TAGS:
        state.isTagsFetched = action.payload;
        break;
  }
} , initialState);