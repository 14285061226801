export const BLOCKS_FETCH_SUCCESSFULLY = "blocks.BLOCKS_FETCH_SUCCESSFULLY";
export const BLOCKS_FETCH_FAILED = "blocks.BLOCKS_FETCH_FAILED";

export const BLOCKS_BLOCKED_ME_FETCH_SUCCESSFULLY = "blocks.BLOCKS_BLOCKED_ME_FETCH_SUCCESSFULLY";
export const BLOCKS_BLOCKED_ME_FETCH_FAILED = "blocks.BLOCKS_BLOCKED_ME_FETCH_FAILED";

export const BLOCKS_BLOCKED_ME_BY_USER_ADDED = "blocks.BLOCKS_BLOCKED_ME_BY_USER_ADDED";
export const BLOCKS_BLOCKED_ME_BY_USER_ADDED_ERROR = "blocks.BLOCKS_BLOCKED_ME_BY_USER_ADDED_ERROR";

export const BLOCKS_BLOCK_USER_SUCCESSFULLY = "blocks.BLOCKS_BLOCK_USER_SUCCESSFULLY";
export const BLOCKS_BLOCK_USER_FAILED = "blocks.BLOCKS_BLOCK_USER_FAILED";

export const BLOCKS_BLOCK_USER = "blocks.BLOCKS_BLOCK_USER";
export const BLOCKS_UNBLOCK_USER = "blocks.BLOCKS_UNBLOCK_USER";
export const BLOCKS_UNBLOCK_USER_SUCCESSFULLY = "blocks.BLOCKS_UNBLOCK_USER_SUCCESSFULLY";
export const BLOCKS_UNBLOCK_USER_FAILED = "blocks.BLOCKS_UNBLOCK_USER_FAILED";
export const BLOCKS_ADDED = "blocks.BLOCKS_ADDED";
export const BLOCKS_ADDED_ERROR = "blocks.BLOCKS_ADDED_ERROR";
