import React, { useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

export default ({ text = "", type }) => {
	return (
		<>
			<i
				style={{ color: "gray", margin: "2px", cursor: "pointer" }}
				id={`info-${type}`}
				class="ri-information-line"></i>
			<span id={`info-${type}`}></span>
			<UncontrolledTooltip trigger="hover" target={`info-${type}`}>
				{text}
			</UncontrolledTooltip>
		</>
	);
};
