import { createSelector } from "reselect";
import { isNil } from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

const getLikes = (state) => state.likes;

const getLikesUsersObj = (state) => state.likes?.users;

const getLikesData = createSelector(getLikes, (likes) => likes.data || {});

export const isLike = (userUid) =>
	createSelector(getLikesData, (likesData) => !isNil(likesData[userUid]));

export const getPitId = createSelector(getLikes, (likes) => likes.pitId);

export const getSearchAfter = createSelector(getLikes, (likes) => likes.searchAfter);

export const getLikesUsers = createSelector(getLikesUsersObj, getBlocks, (likes, blocks) => {
	const users = Object.keys(likes)
		.map((uid) => likes[uid])
		.filter((user) => !user.deleted && !blocks[user.uid])
		.sort((item1, item2) => item1.sort[0] - item2.sort[0]);
	return users;
});

export const getIsLoadingMore = createSelector(
	getLikes,
	(likes) => likes.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getLikes, (likes) => likes.hasMoreResults);

export const getLikesStatus = createSelector(getLikes, (likes) => likes.apiStatus);
