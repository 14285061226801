import { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

import ConfirmBox from "./ConfirmBox";

export default ({ navigate, when, shouldBlockNavigation }) => {
	const [modalVisible, updateModalVisible] = useState(false);
	const [lastLocation, updateLastLocation] = useState();
	const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
	const history = useHistory();

	const showModal = (location) => {
		updateModalVisible(true);
		updateLastLocation(location);
	};

	const closeModal = (cb) => {
		updateModalVisible(false);
		cb && cb();
	};

	const handleBlockedNavigation = (nextLocation) => {
		if (lastLocation?.pathname === history.location.pathname) return;
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			showModal(nextLocation);
			return false;
		}
		return true;
	};

	const handleConfirmNavigationClick = () => {
		closeModal(() => {
			if (lastLocation) {
				updateConfirmedNavigation(true);
			}
		});
	};

	useEffect(() => {
		if (confirmedNavigation) {
			navigate(lastLocation.pathname);
			updateConfirmedNavigation(false);
		}
	}, [confirmedNavigation]);

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<ConfirmBox
				visible={modalVisible}
				onCancel={closeModal}
				onConfirm={handleConfirmNavigationClick}
			/>
		</>
	);
};
