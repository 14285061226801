import { createSelector } from "reselect";
import _ from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

export const getSearch = (state) => state.search;

export const getIsLoadingSearch = createSelector(
	getSearch,
	(search) => search.users.length === 0 && search.apiStatus === API_REQUESTS_STATUS.PENDING
);

export const showFetchUsersErrorMessage = createSelector(
	getSearch,
	(search) => search.apiStatus === API_REQUESTS_STATUS.FAILED
);
export const getSearchUsers = createSelector(getSearch, (search) => {
	return search.users;
});

export const getSearchBlockUsers = createSelector(getSearchUsers, getBlocks, (users, blocks) => {
	const res = users.filter((user) => !blocks[user.uid]);
	return res;
});

export const getStatus = createSelector(getSearch, (search) => search.apiStatus);

export const getUpdateRedPoint = createSelector(getSearch, (search) => search.updateRedPoint);

export const getIsLoadingMore = createSelector(
	getSearch,
	(search) => search.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getSearch, (search) => search.hasMoreResults);

export const getSearchFreeTextFilter = createSelector(getSearch, (search) => search.freeTextFilter);

export const getPitId = createSelector(getSearch, (search) => search.pitId);
export const getSearchAfter = createSelector(getSearch, (search) => search.searchAfter);

export const getLastSortOption = createSelector(getSearch, (search) => search.lastSortOption);
