import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useEffect } from "react";

export default () => {
  const { closePopup } = usePopups();

  useEffect(() => {
    setTimeout(() => {
      closePopup();
    }, 2000);
  });

  return (
    <Modal isOpen={true} toggle={closePopup} className="simpleDialog">
      <ModalHeader>
        {i18next.t("Freeze.Account successfully freezed")}
      </ModalHeader>
      <ModalFooter>
        <Button
          outline
          size="sm"
          color="primary"
          onClick={closePopup}
          className="btn-rounded littleBtn"
        >
          {i18next.t("Ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
