import { createSelector } from "reselect";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

const getViews = (state) => state.views;

export const getPitId = createSelector(getViews, (views) => views.pitId);

export const getSearchAfter = createSelector(getViews, (views) => views.searchAfter);

export const getViewsUsers = createSelector(getViews, getBlocks, (views, blocks) =>
	Object.keys(views.users)
		.map((uid) => views.users[uid])
		.filter((user) => !blocks[user.uid])
		.sort((item1, item2) => item1.sort[0] - item2.sort[0])
);

export const getIsLoadingMore = createSelector(
	getViews,
	(views) => views.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getViews, (views) => views.hasMoreResults);

export const getViewsStatus = createSelector(getViews, (views) => views.apiStatus);
