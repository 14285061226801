import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import PrayerTime from "consts/prayerTime";
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, value) => onChange(key, Number(value));

    return <DropDown
                field={ProfileFields.PrayerTime} 
                fieldOptions={Object.values(PrayerTime)} 
                onChange={_onChange} 
                initSelected={profile.prayerTime}
                title={i18next.t('Edit Profile.PrayerTime')}
                translatePrefix="PrayerTime"
            />
};
