import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Progress } from "reactstrap";
import _ from "lodash";
import { useSelector } from "react-redux";
import Wizard from "components/login/Steps/Wizard";
import { getUserProfile } from "redux/profile/selectors";
import { getLoginSteps, getIsAuthChecked, isUserLoggedIn } from "redux/auth/selectors";
import useNavigator from "hooks/useNavigator";
import Loader from "components/Loader";
import { updateCompleteRegistration } from "redux/profile/actions";
//import LoginSteps from "consts/loginSteps";

export default () => {
	const dispatch = useDispatch();
	const navigator = useNavigator();
	const isLoggedIn = useSelector(isUserLoggedIn);
	const profile = useSelector(getUserProfile);
	const loginSteps = useSelector(getLoginSteps);
	//const [loginSteps] = useState(Object.keys(LoginSteps));

	const isAuthChecked = useSelector(getIsAuthChecked);
	const handleOnStepsFinish = () => {
		dispatch(updateCompleteRegistration());
		navigator.navigateToSearchPage();
	};

	useEffect(() => {
		if (!_.isEmpty(profile) && loginSteps.length === 0) {
			handleOnStepsFinish();
		}
	}, [profile?.uid, loginSteps]);

	return isLoggedIn && loginSteps.length > 0 ? (
		<div className="loginPagesWrapper">
			<Progress value="25" className="loginSteps" style={{ display: "none" }} />
			<Wizard
				profile={profile}
				onStepsFinish={handleOnStepsFinish}
				stepNames={loginSteps}
				className="loginPopup loginStepsClass"
			/>
		</div>
	) : (
		<div className="loginPagesWrapper">
			<Loader />
		</div>
	);
};
