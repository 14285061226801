import { useState } from "react";
import RangeInput from "./RangeInput";
import RangeVisibleSlider from "./RangeVisibleSlider";

export default ({ min, max, step = 1, onChange, filter }) => {
  const [minVal] = useState(min);
  const [maxVal, setMaxVal] = useState(filter || max);
  const [maxThumbState, setMaxThumbState] = useState({});

  const handleMaxChange = (e) => {
    const value = e.target.value;
    setMaxVal(value);
    onChange(value);
  };

  const addMaxThumbState = (action) => {
    setMaxThumbState((prev) => ({
      ...prev,
      [action]: action,
    }));
  };

  const removeMaxThumbState = (action) => {
    setMaxThumbState((prev) => {
      const prevState = { ...prev };
      delete prevState[action];
      return prevState;
    });
  };

  return (
    <div className="multi-range-slider one-range">
      <div>
        <RangeInput
          min={min}
          max={max}
          step={step}
          maxVal={maxVal}
          onMaxChange={handleMaxChange}
          OnMaxInputMouseOver={() => addMaxThumbState("hover")}
          OnMaxInputMouseOut={() => removeMaxThumbState("hover")}
          OnMaxInputMouseDown={() => addMaxThumbState("active")}
          OnMaxInputMouseUp={() => removeMaxThumbState("active")}
        />
        <RangeVisibleSlider
          min={min}
          max={max}
          minVal={minVal}
          maxVal={maxVal}
          maxThumbState={maxThumbState}
        />
      </div>
    </div>
  );
};
