import firebase from "firebase/app";
import "firebase/database";

class AuthAppleService {
	async login() {
		const provider = new firebase.auth.OAuthProvider("apple.com");
		provider.addScope("email");
		provider.addScope("name");
		try {
			const authUser = firebase.auth().currentUser;
			const result = await authUser.linkWithPopup(provider);
			return {
				data: result.additionalUserInfo?.profile,
				token: result.credential.idToken,
			};
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
}

export default new AuthAppleService();
