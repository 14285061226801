import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import Question from "./Question";
import DatePicker from "../DatePicker";

export default ({
  title,
  subTitle,
  onSubmit,
  onBackButtonClick,
  showBackButton,
  initValue,
}) => {
  const minDate = moment().subtract(18, "year").format("YYYY-MM-DD");
  const maxDate = moment().subtract(99, "year").format("YYYY-MM-DD");
  const currentDateWithAnotherYear = "1990" + moment().format("YYYY-MM-DD").slice(4);
	const [initDate] = useState(initValue || currentDateWithAnotherYear);
  const [value, setValue] = useState(initValue || minDate);
  const [isValid, setIsValid] = useState();

  const handleOnChange = (val) => {
    setValue(val);
  };

  const handleOnSubmit = () => {
    onSubmit(value);
  };

  const handleOnValidityChange = (_isValid) => {
    setIsValid(_isValid);
  };

  return (
    <Question
      isValid={isValid}
      title={title}
      subTitle={subTitle}
      onSubmit={handleOnSubmit}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    >
      <DatePicker
        onChange={handleOnChange}
        initDate={initDate}
        minDate={minDate}
        maxDate={maxDate}
        onValidityChange={handleOnValidityChange}
      />
    </Question>
  );
};
