import produce from 'immer';

import * as types from './actionTypes';

const initialState = {
	activeIndex : 0,
};

export default produce((state , action) => {
  switch (action.type) {
	case types.SET_ACTIVE_INDEX:
		state.activeIndex = action.payload;
		break;
	case types.SET_GALLERY_TYPE:
			state.galleryType = action.payload;
			break;
  }
} , initialState);