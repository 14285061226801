import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import Profession from 'consts/profession';
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {

    const _onChange = (key, value) => onChange(key, Number(value));

    return <DropDown
                field={ProfileFields.Profession} 
                fieldOptions={Object.values(Profession)} 
                onChange={_onChange} 
                initSelected={profile.profession}
                title={i18next.t('Edit Profile.Profession')}
                translatePrefix="Profession"
            />
};
