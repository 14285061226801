export const VIEWED_ME_USERS_FETCH = "viewedMe.VIEWED_ME_USERS_FETCH";
export const VIEWED_ME_USERS_FETCH_LOAD_MORE = "viewedMe.VIEWED_ME_USERS_FETCH_LOAD_MORE";
export const VIEWED_ME_USERS_FETCH_SUCCESSFULLY = "viewedMe.VIEWED_ME_USERS_FETCH_SUCCESSFULLY";
export const VIEWED_ME_USERS_FETCH_LOAD_MORE_SUCCESSFULLY =
	"viewedMe.VIEWED_ME_USERS_FETCH_LOAD_MORE_SUCCESSFULLY";
export const VIEWED_ME_USERS_FETCH_FAILED = "viewedMe.VIEWED_ME_USERS_FETCH_FAILED";
export const VIEWED_ME_ADDED = "viewedMe.VIEWED_ME_ADDED";
export const VIEWED_ME_ADDED_ERROR = "viewedMe.VIEWED_ME_ADDED_ERROR";
export const VIEWED_ME_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY =
	"viewedMe.VIEWED_ME_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY";
export const VIEWED_ME_ADDED_FETCH_USER_PROFILE_FAILED =
	"viewedMe.VIEWED_ME_ADDED_FETCH_USER_PROFILE_FAILED";
export const VIEWED_ME_UNVIEWED_FETCH_SUCCESSFULLY =
	"viewedMe.VIEWED_ME_UNVIEWED_FETCH_SUCCESSFULLY";
export const VIEWED_ME_UNVIEWED_FETCH_FAILED = "viewedMe.VIEWED_ME_UNVIEWED_FETCH_FAILED";
export const VIEWED_ME_UNVIEWED_REMOVE_SUCCESSFULLY =
	"viewedMe.VIEWED_ME_UNVIEWED_REMOVE_SUCCESSFULLY";
export const VIEWED_ME_UNVIEWED_REMOVE_FAILED = "viewedMe.VIEWED_ME_UNVIEWED_REMOVE_FAILED";
export const VIEWED_ME_UNVIEWED_CHANGE_SUCCESSFULLY =
	"viewedMe.VIEWED_ME_UNVIEWED_CHANGE_SUCCESSFULLY";
export const VIEWED_ME_UNVIEWED_CHANGE_FAILED = "viewedMe.VIEWED_ME_UNVIEWED_CHANGE_FAILED";
export const VIEWED_ME_UNVIEWED_ADDED_SUCCESSFULLY =
	"viewedMe.VIEWED_ME_UNVIEWED_ADDED_SUCCESSFULLY";
export const VIEWED_ME_UNVIEWED_ADDED_FAILED = "viewedMe.VIEWED_ME_UNVIEWED_ADDED_FAILED";
