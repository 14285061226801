import i18next from 'i18next';
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

import usePopups from 'hooks/usePopups';


export default () => {
    const { closePopup } = usePopups();

    return (
        <Modal isOpen={true} toggle={closePopup} className="simpleDialog">                                            
            <ModalHeader>
                {i18next.t('Edit Profile.Successfully')}
            </ModalHeader>
            <ModalFooter >
                <Button outline size="sm" color="primary" onClick={closePopup} className="btn-rounded littleBtn">{i18next.t('Ok')}</Button>
            </ModalFooter>
        </Modal>
    )
}