import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import { blockUser } from "redux/blocks/actions";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";

export default () => {
	const dispatch = useDispatch();
	const { currentUserProfile, currentUserPromotedProfile } = useSelector(getPopups);
	const user = currentUserPromotedProfile || currentUserProfile;

	const currentUser = useSelector(getUserProfile);
	const { showReportQuestionPopup } = usePopups();
	const { closePopup, _closePopupUnderPopup } = usePopups();

	const blockUserHandler = () => {
		dispatch(blockUser(user.uid));
		showReportQuestionPopup();
		_closePopupUnderPopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>
				<i className="ri-archive-line float-end text-muted jsmartDrop jsmartBlock"></i>
				{i18next.t(`Block ${currentUser?.gender}`)}
			</ModalHeader>
			<ModalBody>
				<span>
					{i18next.t(`Block Message ${currentUser.gender}`, {
						name: user.name,
					})}
				</span>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={blockUserHandler}
					className="btn-rounded littleBtn"
				>
					{i18next.t("yes")}
				</Button>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn"
				>
					{i18next.t("no")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
