import i18next from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export default ({ show = false, toggle }) => {
	const [isOpen, setIsOpen] = useState(show);

	useEffect(() => {
		setIsOpen(show);
	}, [show]);
	return (
		<>
			{isOpen && (
				<div className="uneditable-message">
					<Button
						color="none"
						type="button"
						onClick={toggle}
						className="nav-btn"
						id="user-profile-hide">
						<i className="ri-close-line"></i>
					</Button>
					<div className="title">{i18next.t("Account Settings.Uneditable Message.Title")}</div>
					<div className="message">
						{i18next.t("Account Settings.Uneditable Message.Message1")}
						<Link to="/account/contact">
							{i18next.t("Account Settings.Uneditable Message.Message2")}
						</Link>
						{i18next.t("Account Settings.Uneditable Message.Message3")}
					</div>
				</div>
			)}
		</>
	);
};
