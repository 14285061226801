import SubscriptionEvents from "consts/subscriptionEvents";
import * as types from "./actionTypes";
import SubscriptionService from "services/subscription";
import QueueService from "services/queue";

export const purchaseWithCardcomSuccessfully = (finishPurchaseResponse, showSuccessfullyPopup) => {
	showSuccessfullyPopup();
	return {
		type: types.SUBSCRIPTIONS_CARDCOM_PURCHASE_SUCCESSFULLY,
		response: finishPurchaseResponse,
	};
};

export const purchaseWithCardcom = (plan, showSuccessfullyPopup) => {
	return {
		type: types.SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM,
		plan,
		showSuccessfullyPopup,
	};
};

export const purchaseWithGoogle = (plan) => {
	return {
		type: types.SUBSCRIPTIONS_PURCHASE_WITH_GOOGLE,
		plan,
	};
};

export const toggleRenewable = (uid, state) => {
	return async () => {
		QueueService.sendEvent(uid, SubscriptionEvents.ChangeSubscriptionRenewableSettings, {
			new_val: state,
		});
		await SubscriptionService.updateRenewable(uid, state);
	};
};
