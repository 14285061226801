import firebase from "firebase/app";
import "firebase/database";

class PublicProfileService {
	BASE_REF = "users_public_profile";
	BASE_PICTURES_REF = "users_public_profile_pictures";

	async fetchProfile(uid) {
		const fetchProfile = async () => {
			return (await firebase.database().ref(this.BASE_REF).child(uid).once("value")).val();
		};
		const fetchPics = async () => {
			try {
				const res = (
					await firebase.database().ref(this.BASE_PICTURES_REF).child(uid).once("value")
				).val();
				return res;
			} catch (error) {
				return Promise.resolve();
			}
		};

		const [profile, pictures] = await Promise.all([fetchProfile(), fetchPics()]);

		if (pictures) {
			profile["pictures"] = pictures;
		}
		return profile;
	}
}

export default new PublicProfileService();
