import i18next from "i18next";

export default ({
  url,
  className,
  cleanRejectedImages,
  refLastEmptyImageInput,
}) => {
  const onClick = () => {
    refLastEmptyImageInput.current.click();
    cleanRejectedImages();
  };

  return (
    <div className={`wrapOneImage blocked ${className}`}>
      <h6 className="blockText">{i18next.t("Edit Pictures.Rejected Image")}</h6>
      <img src={url} onClick={onClick} />
    </div>
  );
};
