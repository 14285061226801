import { Helmet } from "react-helmet";

export default function ({ title, description, structuredData }) {
	return (
		<Helmet>
			‍{!!title && <title>{title}</title>}
			{!!description && <meta name="description" content={description} />}
			{!!structuredData && <script type="application/ld+json">{`${structuredData}`}</script>}
		</Helmet>
	);
}
