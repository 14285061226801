import i18next from "i18next";
import { Button, PopoverBody, PopoverHeader, Popover } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import defaultProfileMan from "assets/images/emptyImages/placeholderManRound.png";
import defaultProfileWoman from "assets/images/emptyImages/placeholderWomanRound.png";
import { getContactsMatchingByTalentId } from "redux/contacts/selectors";
import { useEffect, useState } from "react";
import { getUserProfile } from "redux/profile/selectors";
import { isUserLoggedIn } from "redux/auth/selectors";
import aiService from "services/ai.service";
import isMobile from "helpers/isMobile";
import { getIsPlatformAndroid, getIsPlatformIOS, getPlatform } from "redux/init/selectors";
import AppServices from "services/app";
import contactsService from "services/contactsService";
import { updateAllowSharedContact } from "redux/profile/actions";

export default ({ colleagueId = "", colleague = {} }) => {
	const userProfile = useSelector(getUserProfile);
	//if (!userProfile?.tester) return <></>;
	const platform = useSelector(getPlatform);
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const [contactFriends, setContactFriends] = useState([]);
	const isLoggedIn = useSelector(isUserLoggedIn);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	// const contactsMatchingObject = useSelector(getContactsMatchingByTalentId(colleagueId)) ?? {};
	const { showMobileStorePopup } = usePopups();

	const addContactMatching = async () => {
		return aiService.getSharedContactFriends(colleagueId);
	};

	const openApp = async () => {
		if (!userProfile?.allowSharedContact) dispatch(updateAllowSharedContact(true));

		if (!isPlatformIOS && !isPlatformAndroid) {
			showMobileStorePopup();
		} else {
			await contactsService.setContacts(userProfile?.uid);
			if (isPlatformIOS) {
				alert("You need to give access permission to contacts in the device settings");
			}
		}
	};

	useEffect(async () => {
		setLoading(true);
		if (!!userProfile.syncContactsToNeo4j) {
			if (!colleagueId) return setLoading(false);

			const res = addContactMatching();
			if (!res) return setLoading(false);
			res
				.then((data) => {
					console.log(data);
					setLoading(false);
					if (data?.data && !!data.data?.data?.length) {
						setContactFriends(data.data.data);
					}
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [userProfile.syncContactsToNeo4j]);

	return (
		<div className="shareContacts">
			<h4 className="text-left text-primary font-size-16 ">{i18next.t("Mutual contact")}</h4>
			{!!userProfile?.syncContactsToNeo4j ? (
				<>
					{!userProfile?.allowSharedContact ? (
						<>
							<p className="shareContactsText">{i18next.t("friends.youCanSee")}</p>
							<Button
								onClick={() => dispatch(updateAllowSharedContact(true))}
								outline
								size="md"
								color="primary"
								className="btn-rounded btnCenter"
								id="accessPermissionToContacts">
								{i18next.t("friends.givePermitionToDisplayContacts")}
							</Button>
						</>
					) : (
						<>
							{/* if user sync contacts  */}
							{!!loading ? (
								<CircularProgress size={40} className="text-primary" />
							) : (
								<>
									{!!contactFriends && !!contactFriends?.length ? (
										<>
											<Box className="wrapAcquaintances">
												<Swiper
													style={{ width: "100%" }}
													slidesPerView={4}
													spaceBetween={0}
													pagination={{
														clickable: true,
													}}
													modules={[Pagination]}
													className="mySwiper">
													{!contactFriends.length && (
														<div style={{ textAlign: "center" }}>
															{i18next.t("talent.dontFoundMaching")}
														</div>
													)}
													{!!contactFriends.length &&
														contactFriends.map((item, index) => {
															const targetId = `key${index}`;
															return (
																<SwiperSlide id={targetId}>
																	<UserWithPopover
																		key={item.phoneId}
																		item={item}
																		targetId={targetId}
																	/>
																</SwiperSlide>
															);
														})}
												</Swiper>
											</Box>
										</>
									) : (
										<>
											<p className="shareContactsText">{i18next.t("friends.noShareContacts")}</p>
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			) : (
				<>
					<div>
						<p className="shareContactsText">{i18next.t("Permission Request Required")}</p>
					</div>
					<Button
						onClick={openApp}
						outline
						size="md"
						color="primary"
						className="btn-rounded btnCenter"
						id="connectContact">
						{i18next.t("Connect Contacts")}
					</Button>
				</>
			)}
		</div>
	);
};

const UserWithPopover = ({ item, targetId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { name, phone, photo } = item;

	const whatsappLink = () => {
		if (!phone) return "";
		if (toString(phone).includes("972")) {
			return `https://wa.me/${toString(phone).replace("+", "")}`;
		}
		return `https://wa.me/972${phone}`;
	};
	const openWhatsapp = (e) => {
		e.preventDefault();
		window.open(whatsappLink(), "_blank");
		return toggle();
	};
	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<a
				onClick={toggle}
				className="center"
				style={{
					textDecoration: "none",
					color: "#000",
					flexDirection: "column",
				}}>
				<img src={photo ? photo : defaultProfileMan} height="70px" width="70px" />
				<span>{name}</span>
			</a>
			<Popover
				target={targetId}
				className="userTagsPopover"
				isOpen={isOpen}
				placement="top"
				trigger="legacy"
				toggle={toggle}>
				<PopoverHeader>
					<span className="tagsPopoverHeader">{i18next.t("friends.whatToDo") + name}</span>
					<Button
						color="none"
						type="button"
						onClick={toggle}
						className="nav-btn closeButton"
						id="user-profile-hide">
						<i className="ri-close-line"></i>
					</Button>
				</PopoverHeader>
				<PopoverBody>
					<Button
						size="sm"
						color="primary"
						onClick={toggle}
						className="btn-rounded littleBtn tagsButtonPop searchTag">
						<a href={`tel:${phone}`} className="innerBtnTxt" style={{ color: "#fff" }}>
							{i18next.t("friends.call")}
							<span className="tagText">{name}</span>
						</a>
					</Button>
					<Button
						size="sm"
						color="primary"
						onClick={toggle}
						className="btn-rounded littleBtn tagsButtonPop addTag">
						<a href="" onClick={openWhatsapp} className="innerBtnTxt" style={{ color: "#fff" }}>
							{i18next.t("friends.whatsapp")}
							<span className="tagText">{name}</span>
						</a>
					</Button>
				</PopoverBody>
			</Popover>
		</>
	);
};
