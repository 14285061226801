import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { logOut } from "redux/auth/actions";
import useNavigator from "hooks/useNavigator";
import Loader from "components/Loader";

export default () => {
	const dispatch = useDispatch();
	const navigator = useNavigator();

	useEffect(() => {
		dispatch(logOut()).then(() => {
			navigator.redirectToHomePage();
		});
	}, []);

	return <Loader />;
};
