import "assets/scss/custom/components/loginDialog.scss";

import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getLoginSteps, getLoginWithPhoneStatus } from "redux/auth/selectors";
import LoginEnterPhonePage from "pages/LoginEnterPhonePage";
import LoginPhoneVerificationPage from "pages/LoginPhoneVerificationPage";
import loginWithPhoneStatus from "consts/loginWithPhoneStatus";
import { resetLoginWithPhone } from "redux/auth/actions";

import _ from "lodash";
import Wizard from "components/login/Steps/Wizard";
import { getUserProfile } from "redux/profile/selectors";
import { updateCompleteRegistration } from "redux/profile/actions";
import { getPopups } from "redux/popups/selectors";

export default () => {
	const { closePopup } = usePopups();
	const { funcAfterLogin } = useSelector(getPopups);

	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const profile = useSelector(getUserProfile);
	const loginSteps = useSelector(getLoginSteps);
	const dispatch = useDispatch();

	const handleClosePopup = () => {
		if (
			loginStatus == loginWithPhoneStatus.WAIT_FOR_VERIFICATION ||
			loginStatus == loginWithPhoneStatus.VERIFICATION_FAILED ||
			loginStatus == loginWithPhoneStatus.VERIFICATION_FAILED
		)
			return dispatch(resetLoginWithPhone());

		return closePopup();
	};

	const handleOnStepsFinish = () => {
		if (!profile?.hasCompleteRegistration) dispatch(updateCompleteRegistration());
		if (!!funcAfterLogin) funcAfterLogin();
		return closePopup();
	};

	useEffect(() => {
		if (
			loginStatus == loginWithPhoneStatus.LOGIN_SUCCESSFULLY &&
			!_.isEmpty(profile) &&
			loginSteps.length === 0
		) {
			handleOnStepsFinish();
		}
	}, [profile?.uid, loginSteps, loginStatus]);

	return (
		<Modal isOpen={true} className="simpleDialog  loginDialog">
			<Box onClick={handleClosePopup} className="closePopup">
				{loginStatus == loginWithPhoneStatus.WAIT_FOR_VERIFICATION ||
				loginStatus == loginWithPhoneStatus.VERIFICATION_FAILED ||
				loginStatus == loginWithPhoneStatus.VERIFICATION_FAILED
					? "חזור"
					: "סגור"}
			</Box>
			<ModalBody>
				{(!loginStatus ||
					loginStatus == loginWithPhoneStatus.INIT ||
					loginStatus == loginWithPhoneStatus.LOGIN_PENDING ||
					loginStatus == loginWithPhoneStatus.LOGIN_FAILED) && (
					<LoginEnterPhonePage isFromPopup={true} />
				)}
				{(loginStatus == loginWithPhoneStatus.WAIT_FOR_VERIFICATION ||
					loginStatus == loginWithPhoneStatus.VERIFICATION_FAILED ||
					loginStatus == loginWithPhoneStatus.VERIFICATION_PENDING) && (
					<LoginPhoneVerificationPage isFromPopup={true} />
				)}

				{loginStatus == loginWithPhoneStatus.LOGIN_SUCCESSFULLY && (
					<Wizard
						profile={profile}
						onStepsFinish={handleOnStepsFinish}
						stepNames={loginSteps}
						className="loginPopup loginStepsClass"
					/>
				)}
			</ModalBody>
		</Modal>
	);
};
