import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";

class BlogService {
	BASE_REF = "articles";
	ARTICLES_CATEGORY = "articles_category";

	async getBlogs(maxBlogs = 19) {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("publishData")
				.limitToLast(maxBlogs)
				.once("value")
		).val();
	}

	async getBlogsCategory() {
		return (await firebase.database().ref(this.ARTICLES_CATEGORY).once("value")).val();
	}

	async getArticleByCategoryKey(key) {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("categoryKey")
				.equalTo(key)
				.once("value")
		).val();
	}

	async getArticleByUrlName(urlName) {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("slug")
				.equalTo(urlName)
				.once("value")
		).val();
	}

	async updateArticleViews(key) {
		const result = await httpsCallable.call("updateArticleViews", {
			extraData: { key },
		});
		return result;
	}
}

export default new BlogService();
