import { ButtonGroup } from "reactstrap";

export default ({ children: buttons, className = "" }) => {
  return (
    <div className={`filterButtons ${className}`}>
      <span className="desktopOnly" >מיין לפי</span>
      <ButtonGroup style={{ display: "block" }}>{buttons}</ButtonGroup>
    </div>
  );
};
