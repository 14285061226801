import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAppDetails,
  getShowMaintenancePage,
  getUserMustUpdateFromStore,
  getUserMustUpdateToVersions,
} from "redux/system/selectors";
import _ from "lodash";
import AppService from "services/app";
import AppPlatforms from "consts/AppPlatforms";
import useNavigator from "hooks/useNavigator";
const semver = require("semver");

export default ({ children }) => {
  const navigator = useNavigator();
  const showMaintenancePage = useSelector(getShowMaintenancePage);
  const userMustUpdateFromStore = useSelector(getUserMustUpdateFromStore);
  const userMustUpdateToVersions = useSelector(getUserMustUpdateToVersions);
  const appDetails = useSelector(getAppDetails);

  const getVersion = async (platform) => {
    if (platform === AppPlatforms.ANDROID || platform === AppPlatforms.IOS) {
      return await AppService.getAppVersion();
    }
    return await Promise.resolve();
  };
  const isNavigateToMaintenancePage = (platform, version) => {
    return (
      !!showMaintenancePage &&
      (showMaintenancePage[platform] === true ||
        (typeof showMaintenancePage[platform] === "object" &&
          showMaintenancePage[platform][version] === true))
    );
  };
  const isNavigateToForceAppDownloadPage = (platform, version) => {
    if (version == "0.1.01") {
      return true;
    }
    const isLowerVersion =
      !!userMustUpdateToVersions[platform] &&
      !!version &&
      semver.gt(userMustUpdateToVersions[platform], version);

    return (
      (platform === AppPlatforms.ANDROID || platform === AppPlatforms.IOS) &&
      userMustUpdateFromStore &&
      isLowerVersion
    );
  };
  useEffect(() => {
    if (appDetails) {
      AppService.getPlatform().then((platform) => {
        getVersion(platform).then((version) => {
          if (isNavigateToForceAppDownloadPage(platform, version)) {
            navigator.navigateToForceAppDownloadPage();
          } else if (isNavigateToMaintenancePage(platform, version)) {
            navigator.navigateToMaintenancePage();
          }
        });
      });
    }
  }, [appDetails]);

  return children;
};
