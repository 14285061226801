import AppPlatforms from "consts/AppPlatforms";
import React, { useEffect, useState } from "react";
import AppService from "services/app";

export default function Nagishli() {
	const [platform, setPlatform] = useState();
	useEffect(() => {
		AppService.getPlatform().then((platform) => {
			setPlatform(platform);
		});
	}, []);

	useEffect(() => {
		if (platform && (platform === AppPlatforms.WEB || platform === AppPlatforms.MOBILEWEB)) {
			window.interdeal = {
				sitekey: "2f658225c8465807fae3c2425921a3dc",
				Position: "Right",
				Menulang: "HE",
				domains: {
					js: "https://js.nagich.co.il/",
					acc: "https://access.nagich.co.il/",
				},
				isPartial: true,
				btnStyle: {
					color: {
						main: "#1F509A",
						second: "#fff",
					},
					vPosition: ["0%", "50%"],
					scale: ["0.6", "0.6"],
				},
			};
			(function (doc, head, body) {
				var coreCall = doc.createElement("script");
				coreCall.src = "https://js.nagich.co.il/core/4.3.5/accessibility.js";
				coreCall.defer = true;
				coreCall.integrity =
					"sha512-30Chwp4k6gvr11Nui4Sw5tDn1GV6CmQCpEe6dfCT8s9Mj7vVhno2pu5hUPsR86QVFmqLEohG9h2wYw+rCVSWwQ==";
				coreCall.crossOrigin = "anonymous";
				coreCall.setAttribute("data-cfasync", true);
				body ? body.appendChild(coreCall) : head.appendChild(coreCall);
			})(document, document.head, document.body);
		}
	}, [platform]);

	return <></>;
}
