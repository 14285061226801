import "assets/scss/custom/selectPhoneInput/selectPhoneInput.scss";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";

function SelectPhoneInput({ handleOnChange = () => {}, handleKeyPress = () => {} }) {
	const [phoneNumber, setPhoneNumber] = useState("");

	const handlePhoneChange = (value) => {
		if (!value) return setPhoneNumber("");
		const val = value.replace(/[^0-9]/g, "");
		if (!val) return setPhoneNumber("");

		setPhoneNumber(value);
		handleOnChange(value);
	};

	return (
		<>
			<PhoneInput
				autoFocus={true}
				onKeyPress={(e) => handleKeyPress(e.key)}
				placeholder={i18next.t("Type")}
				value={phoneNumber}
				onChange={handlePhoneChange}
				countries={["US", "FR", "IL", "AT", "GB", "ES", "NL", "DE", "GR", "CY", "AU", "CA", "IT"]}
				defaultCountry="IL"
				inputClassName="phone-input"
				// countryCallingCodeEditable={false}
				// enableAreaCodes={true} // Show area codes in the dropdown for countries that have them
				// international={true} // Format the phone number in international format (e.g. "+972 55-123-4567")
			/>
		</>
	);
}

export default SelectPhoneInput;
