import i18next from "i18next";
import { Modal, ModalHeader, Button, ModalBody } from "reactstrap";
import AppServices from "services/app";
import usePopups from "hooks/usePopups";
import { Box } from "@mui/material";
import apple from "assets/images/apple.png";
import android from "assets/images/android.png";
export default () => {
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog" style={{ maxWidth: "450px" }}>
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("MobileStore.popupTitle")}</ModalHeader>
			<ModalBody>
				<Box
					style={{
						margin: "0 0 20px",
						display: "flex",
						justifyContent: "space-around",
						alignItems: "center",
					}}>
					<a
						onClick={async (e) => {
							e.preventDefault();
							return await AppServices.openInbrowser(
								"https://apps.apple.com/us/app/jsmart-%D7%94%D7%99%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-%D7%93%D7%99%D7%99%D7%98%D7%99%D7%A0%D7%92-%D7%A6%D7%90%D7%98/id1554731425"
							);
						}}
						href=""
						className="apple">
						<img src={apple} alt="" />
					</a>
					<a
						onClick={async (e) => {
							e.preventDefault();
							return await AppServices.openInbrowser(
								"https://play.google.com/store/apps/details?id=com.meetsmart.jsmart&hl=iw&gl=US"
							);
						}}
						href=""
						className="android">
						<img src={android} alt="" />
					</a>
				</Box>
			</ModalBody>
		</Modal>
	);
};
