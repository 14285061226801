import firebase from "firebase/app";
import "firebase/database";

import * as types from "./actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import ChatMessagesService from "services/chatMessages";
import QueueService from "services/queue";
import BaseChatEvents from "consts/baseChatEvents";

export const fetchChatMessagesSuccessfully = (chatID, messages) => {
	return {
		type: types.CHAT_MESSAGES_FETCH_SUCCESSFULLY,
		chatID,
		messages,
	};
};

export const fetchChatMessagesFailed = (chatID, error) => {
	return { type: types.CHAT_MESSAGES_FETCH_FAILED, chatID, error };
};

export const fetchChatMessages = (collegueUid, chatID) => {
	return { type: types.CHAT_MESSAGES_CLICK_NEW_USER, collegueUid, chatID };
};

export const sendChatMessage = (
	chatId = ChatMessagesService.generateNewChatId(),
	messageText,
	colleagueUid,
	isNewChat,
	system = false,
	isTemplate = false,
	classImg = null
) => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		sendMessage(
			dispatch,
			chatId,
			uid,
			messageText,
			colleagueUid,
			isNewChat,
			system,
			false,
			isTemplate,
			classImg
		);
	};
};

export const readChatMessage = (userUid, colleagueUid, chatID) => {
	QueueService.sendEvent(userUid, BaseChatEvents.ChatMessageRead, {
		uidOfUserToChatWith: colleagueUid,
		chatID,
	});
	return { type: types.CHAT_MESSAGES_READ, user: colleagueUid, chatID };
};

const sendMessage = async (
	dispatch,
	chatId,
	uid,
	messageText,
	currentCollageUid,
	isNewChat,
	system = false,
	audio = false,
	isTemplate = false,
	classImg = null
) => {
	const message = {
		image: "",
		read: false,
		text: messageText,
		timeStamp: firebase.database.ServerValue.TIMESTAMP,
		uid: uid,
		system: system,
		audio: audio,
		isTemplate,
		classImg,
	};

	dispatch({ type: types.CHAT_MESSAGES_SEND_MESSAGE, payload: messageText });

	try {
		await ChatMessagesService.sendMessage(chatId, message);
		QueueService.sendEvent(
			uid,
			isNewChat ? BaseChatEvents.NewChatMessage : BaseChatEvents.ChatMessage,
			{
				uidOfUserToChatWith: currentCollageUid,
				chatID: chatId,
				timeStamp: message.timeStamp,
				text: message.text,
				image: message.image,
				messageObj: message,
			}
		);
		if (isNewChat) {
			setTimeout(() => {
				dispatch({ type: types.CHAT_MESSAGES_START_NEW_CHAT, chatID: chatId });
			}, 1000);
		}
		dispatch(sendChatMessageSuccessfully());
	} catch (error) {
		dispatch(sendChatMessageFailed(error));
	}
};

const sendChatMessageSuccessfully = () => {
	return { type: types.CHAT_MESSAGES_SEND_MESSAGE_SUCCESSFULLY };
};

const sendChatMessageFailed = (error) => {
	return { type: types.CHAT_MESSAGES_SEND_MESSAGE_FAILED, error };
};

export const updateChatMessageSuccessfully = (chatID, message) => {
	return {
		type: types.CHAT_MESSAGES_UPDATED,
		chatID,
		message,
	};
};

export const updateChatMessageFailed = (chatID, error) => {
	return {
		type: types.CHAT_MESSAGES_UPDATED_ERROR,
		chatID,
		error,
	};
};

export const leaveChatMessagesScreen = () => {
	return { type: types.LEAVE_CHAT_MESSAGES_SCREEN };
};
