import i18next from "i18next";
import { Button, Form, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Switch from "components/Switch";
import Communications from "consts/communications";
import { saveNewCommunicationSettings } from "redux/general/actions";
import usePopups from "hooks/usePopups";

export default () => {
	const [newSettings, setNewSettings] = useState({});
	const dispatch = useDispatch();
	const location = useLocation();
	const {
		showUnsubscribeSuccessfullySavedPopup,
		showUnsubscribeErrorPopup,
		showUnsubscribeCancelPopup,
	} = usePopups();
	const params = new URLSearchParams(location.search);

	const onSubmit = () => {
		const userUid = params.get("u").split(" ").join("+");
		dispatch(
			saveNewCommunicationSettings(
				newSettings,
				userUid,
				showUnsubscribeSuccessfullySavedPopup,
				showUnsubscribeErrorPopup
			)
		);
	};

	const onCancel = () => {
		showUnsubscribeCancelPopup();
	};

	return (
		<div className="d-flex justify-content-center align-items-center container unsubscribe-page">
			<div className="d-flex flex-column innerContainer">
				<div>
					<div className="d-flex flex-column justify-content-around innerContainer align-items-center">
						<h1>{i18next.t("Unsubscribe.Header")}</h1>
						<div>
							<h4>{i18next.t("Unsubscribe.Text1")}</h4>
							<h5>{i18next.t("Unsubscribe.Text2")}</h5>
							<h5>{i18next.t("Unsubscribe.Text3")}</h5>
							<h5>{i18next.t("Unsubscribe.Text4")}</h5>
						</div>
						<CommunicationSettings
							{...{
								newSettings,
								setNewSettings,
							}}
						/>
						<h6>{i18next.t("Unsubscribe.Notify")}</h6>
						<div className="buttons">
							<Button
								onClick={onSubmit}
								size="md"
								color="primary"
								className="btn-rounded reportBtn mainBtn">
								{i18next.t("Edit Profile.Save")}
							</Button>
							<Button
								onClick={onCancel}
								outline
								size="md"
								color="primary"
								className="btn-rounded reportBtn mainBtn">
								{i18next.t("Edit Profile.Cancel")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const CommunicationSettings = ({ newSettings, setNewSettings }) => {
	const initNewSettings = () => {
		const initSettings = {};
		Communications.map((item) => (initSettings[item] = true));
		setNewSettings(initSettings);
	};

	useEffect(() => {
		initNewSettings();
	}, []);

	const onChange = (key, newVal) => {
		setNewSettings((settings) => ({ ...settings, [key]: newVal }));
	};

	return (
		<Form>
			<FormGroup>
				{
					<>
						{Communications.map((key) => (
							<Switch
								key={key}
								title={i18next.t(`Communications.${key}`)}
								value={newSettings[key]}
								onChange={(e) => onChange(key, e.target.checked)}
							/>
						))}
					</>
				}
			</FormGroup>
		</Form>
	);
};
