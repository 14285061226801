import { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { Button } from "reactstrap";

import socialImage from "assets/images/registerImg/social.png";
import SocialQuestion from "../Questions/SocialQuestion";
import {
	registerWithFacebook,
	registerWithGoogle,
	registerWithApple,
	emailSkip,
} from "redux/auth/actions";
import ProfileFields from "consts/profileFields";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";
import { loginWithPassword } from "redux/auth/actions";
import EmailLogin from "../EmailLogin";
import { getLoginWithPasswordStatus } from "redux/auth/selectors";
import { getLoginWithGoogleStatus } from "redux/auth/selectors";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getIsPasswordLoginHidden } from "redux/system/selectors";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const loginPasswordStatus = useSelector(getLoginWithPasswordStatus);
	const loginGoogleStatus = useSelector(getLoginWithGoogleStatus);
	const isPasswordLoginHidden = useSelector(getIsPasswordLoginHidden);

	const handleOnFacebookClick = () => {
		dispatch(registerWithFacebook());
	};
	const handleOnGoogleClick = () => {
		dispatch(registerWithGoogle());
	};
	const handleOnAppleClick = () => {
		dispatch(registerWithApple());
	};
	const handleOnEmailLoginClick = (email, password) => {
		dispatch(loginWithPassword(email, password));
	};
	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	const handleOnSkipButtonClick = async () => {
		dispatch(emailSkip());
		if (total - 1 <= index) {
			onFinalStepFinish();
		} else {
			goToNamedStep(steps[index + 1]);
		}
	};

	useEffect(() => {
		if (!_.isNil(profile[ProfileFields.Email])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [profile]);

	return (
		<div className="loginHolder">
			<Button
				onClick={handleOnSkipButtonClick}
				className="skipBtn"
				outline
				color="primary"
				text={i18next.t("Skip")}>
				{` ${i18next.t("Skip")}`}
			</Button>
			<div className="imageForPage">
				<img src={socialImage} />
			</div>
			{!isPasswordLoginHidden && (
				<div>
					<EmailLogin onSubmit={handleOnEmailLoginClick} />
					{loginPasswordStatus === API_REQUESTS_STATUS.FAILED && (
						<div style={{ color: "red" }}>{i18next.t("Login.User Not Exists")}</div>
					)}
					{loginGoogleStatus === API_REQUESTS_STATUS.FAILED && (
						<div style={{ color: "red" }}>{i18next.t("Login.Email already exists")}</div>
					)}
				</div>
			)}
			<SocialQuestion
				title={i18next.t("Login.To complete account registration")}
				subTitle={i18next.t("Login.Social network registration required")}
				onFacebookClick={handleOnFacebookClick}
				onGoogleClick={handleOnGoogleClick}
				onAppleClick={handleOnAppleClick}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
			/>
		</div>
	);
};
