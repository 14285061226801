import i18next from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { getCurrentPopupType } from "redux/popups/selectors";

export default () => {
	const { pathname } = useLocation();
	const { showContactFromFeedbackButtonPopup } = usePopups();
	const [showFeedbackButton, setShowFeedbackButton] = useState(true);
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const currentPopupType = useSelector(getCurrentPopupType);

	const updateShowFeedback = () => {
		["/account/contact"].includes(pathname) ||
		["ContactFromFeedbackButtonPopup"].includes(currentPopupType)
			? setShowFeedbackButton(false)
			: setShowFeedbackButton(true);
	};

	useEffect(() => {
		updateShowFeedback();
	}, [pathname, currentPopupType]);

	const onClick = () => {
		showContactFromFeedbackButtonPopup();
	};

	return (
		<>
			{showFeedbackButton && isUserLoggedIn && (
				<Button className="feedbackButton" color="primary" onClick={onClick}>
					<span>{i18next.t("Feedback")}</span>
				</Button>
			)}
		</>
	);
};