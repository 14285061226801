import firebase from 'firebase/app';
import "firebase/database";

class SystemService {
  BASE_REF = 'system/appSettings';

  async fetchAppSettings() {
    return (
      await firebase.database()
        .ref(this.BASE_REF)
        .once('value')
    ).val();
  }
}

export default new SystemService();
