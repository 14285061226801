import i18next from "i18next";
import { Link } from "react-router-dom";

import Contact from "components/account/Contact";
import SubjectsTypes from "consts/subjectsTypes";
import useNavigator from "hooks/useNavigator";

export default () => {
	const navigator = useNavigator();

	const closePage = () => {
		navigator.navigateToPreviousPage();
	};
	return (
		<div className="contactPage Feedback">
			<Link to="#" onClick={closePage} className="user-chat-remove text-muted font-size-16 p-2">
				<i className="ri-arrow-left-s-line"></i>
				<h5 className="mainTitle">{i18next.t("Pages.Contact")}</h5>
			</Link>
			<Contact subject={SubjectsTypes.Feedback} />
		</div>
	);
};
