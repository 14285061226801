import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import i18next from "i18next";
import { useRef, useEffect } from "react";

import UserAvatar from "../UserAvatar";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { hasTyping } from "redux/chatMeta/selectors";
import isMobile from "helpers/isMobile";
import Typing from "../Typing";
import { getAgeByDob } from "helpers/date";
import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import Subscription from "consts/subscription";
import VipRibbon from "components/VipRibbon1";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import ChatTabs from "consts/chatTabs";
import { getUserProfile } from "redux/profile/selectors";
import chatMessages from "services/chatMessages";
import _ from "lodash";
import chatMeta from "services/chatMeta";
import localStorage from "services/localStorage";

export default ({
	chat,
	onClickUser,
	active_userId,
	updateSelectedChat,
	selectedChats,
	activeTab,
	countUnread = 0,
}) => {
	const loginFromManager = localStorage.get("loginFrommanager");
	const chatUid = chat.uid;
	const myUserProfile = useSelector(getUserProfile);
	const user = useSelector(getUserPublicProfile(chatUid)) || {};
	const isActiveUser = !isMobile() && active_userId === chatUid;
	const isTyping = useSelector(hasTyping(chat.chatID));
	const long_city = user[ProfileFields.Cites[currentLanguage()]];
	const inpRef = useRef();
	const city = long_city?.split(",")[0];
	const isCollageSubscribed =
		user.subscription &&
		(user.subscription === Subscription.PAID || user.subscription === Subscription.FREE);
	const subscriptionType = useSelector(getUserSubscriptionType);
	const isLoggedInUserSubscribed =
		subscriptionType === Subscription.PAID || subscriptionType === Subscription.FREE;

	const generateLastMessage = () => {
		if (chat.lastMessage?.audio) {
			return i18next.t("New audio Record");
		} else if (chat.lastMessage?.system) {
			return chat.lastMessage?.text;
		} else {
			return chat.lastMessage?.text;
		}
	};

	useEffect(() => {
		let listenSendMeMgs;

		if (
			!!active_userId &&
			chatUid == active_userId &&
			!!myUserProfile?.uid &&
			!!chat?.uid &&
			!loginFromManager
		) {
			listenSendMeMgs = chatMessages.listenChatConversation(active_userId).subscribe(() => {
				chatMeta.updateRead(chat.uid);
			});
		}
		return () => {
			listenSendMeMgs?.unsubscribe();
		};
	}, [active_userId]);

	return (
		<li
			id={"conversation " + chatUid}
			className={`wrapChatLine ${
				chat.unRead ? "unread" : isTyping ? "typing" : isActiveUser ? "active" : ""
			}`}>
			<div className="checkDelete" onClick={() => inpRef.current.click()}>
				<input
					ref={inpRef}
					type="checkbox"
					checked={selectedChats.has(user.uid)}
					onClick={() => inpRef.current.click()}
					onChange={(e) => updateSelectedChat(e.target.checked, user.uid)}
				/>
			</div>
			{isCollageSubscribed && <VipRibbon />}
			<Link to="#" onClick={() => onClickUser(user)}>
				<div className="d-flex wrapperOfchatItem">
					<UserAvatar chat={chat} user={user} />
					<div className="wrapLiChatTxt">
						<div className="flex-1 overflow-hidden wrapDetailsAvatar">
							<h5 className="text-truncate font-size-15 mb-1">
								{user.name + ", " + (user.age || getAgeByDob(user.dob))}
							</h5>
							<p className="text-truncate font-size-12 mb-1">
								{i18next.t(`MaritalStatus_${user.gender}.${user.maritalStatus}`) +
									", " +
									i18next.t(`Stance_${user.gender}.${user.stance}`)}
							</p>
							<p className="text-truncate font-size-12 mb-1">{city}</p>
							<p className="chat-user-message text-truncate mb-0">
								{isTyping ? <Typing gender={user?.gender} /> : generateLastMessage()}
							</p>
							{isLoggedInUserSubscribed && chat.isMassletter && !chat.isMassleterRead && (
								<p className="massletter text-truncate font-size-12 mb-1">
									{i18next.t("Massletter")}
								</p>
							)}
						</div>
						<div className="font-size-11 beforeTime">
							{!!chat.timeStamp && moment(chat.timeStamp).fromNow()}
						</div>
						{!chat.isPromotion && countUnread > 0 && (
							<div className="unread-message" id={"unRead" + chatUid}>
								<span className="badge badge-dark rounded-pill">
									{countUnread > 99 ? "99+" : countUnread}
								</span>
							</div>
						)}
						{chat.isPromotion && !!countUnread && (
							<div className="promoted">
								<span extraBold className="font-size-11">
									{i18next.t("Chat.Promoted")}
								</span>
							</div>
						)}
					</div>
				</div>
			</Link>
		</li>
	);
};
