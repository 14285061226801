import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { getSubscriptionPlansExists, getUserSubscriptionType } from "redux/subscription/selectors";
import { getSubscriptionsIsOn } from "redux/system/selectors";
import Subscription from "consts/subscription";

export default () => {
	const [result, setResult] = useState(null);
	const subscriptionIsOn = useSelector(getSubscriptionsIsOn);
	const subscriptionType = useSelector(getUserSubscriptionType);
	const plansExists = useSelector(getSubscriptionPlansExists);
	useEffect(() => {
		if (!_.isNil(subscriptionIsOn) && !_.isNil(subscriptionType) && plansExists) {
			setResult(
				subscriptionIsOn &&
					plansExists &&
					subscriptionType !== Subscription.PAID &&
					subscriptionType !== Subscription.FREE
			);
		}
	}, [subscriptionIsOn, subscriptionType, plansExists]);

	return result;
};
