import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import Header from "components/search/TemplateMessage/Header";
import Body from "components/search/TemplateMessage/Body";

export default () => {
	const { currentUserProfile, isFromCardUserSidebar } = useSelector(getPopups);
	const { showMessageSuccessfullySentPopup, closePopup } = usePopups();
	const history = useHistory();

	const isChatPage = () => currentPath.indexOf("messages") !== -1;
	const currentPath = history.location.pathname;

	const afterMessageSentHandler = () => {
		isChatPage() && !isFromCardUserSidebar ? closePopup() : showMessageSuccessfullySentPopup();
	};

	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className="simpleDialog endeavorWrapper"
			backdropClassName="fadeBlurEndeavor">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				<Header user={currentUserProfile} />
			</ModalHeader>
			<ModalBody>
				<Body
					user={currentUserProfile}
					afterMessageSentHandler={afterMessageSentHandler}
					closePopup={closePopup}
				/>
			</ModalBody>
		</Modal>
	);
};
