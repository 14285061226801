import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useEffect, useRef } from "react";

import Content from "views/Content";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import TopBar from "views/TopBar";
import ViewedMeCards from "components/search/ViewedMeCards";
import BottomStrip from "components/BottomStrip";
import isMobile from "helpers/isMobile";
import ViewsTopBar from "components/likedMe/ViewsTopBar";
import userNotificationsService from "services/unreadNotification.service";
import notificationUnreadTypes from "consts/notificationUnreadTypes";

export default (className = "") => {
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const ref = useRef();

	const scrollToTop = () => {
		ref.current?.scrollIntoView();
	};

	useEffect(() => {
		if (!!isUserLoggedIn)
			return userNotificationsService.resetCountNotification(notificationUnreadTypes.viewedMe);
	}, [isUserLoggedIn]);

	return isUserLoggedIn ? (
		<>
			<TopBar>
				<ViewsTopBar />
			</TopBar>
			<div className="viewedMePage" ref={ref}>
				<Content>
					<ViewedMeCards scrollToTop={scrollToTop} className={className} />
					{isMobile() && <BottomStrip />}
				</Content>
			</div>
		</>
	) : (
		<Loader />
	);
};
