import { mergeMap, catchError, switchMap, takeUntil, repeat, map } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import BlocksService from "services/blocks";
import BlockedMeService from "services/blockedMe";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import {
	blockedMeAdded,
	fetchBlockedMeFail,
	fetchBlockedMeSuccessfully,
	sendUnBlockUserFail,
	sendUnBlockUserSuccessfully,
} from "./actions";
import QueueService from "services/queue";
import blockAndReportEvents from "consts/blockAndReportEvents";

export const fetchBlocks = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			try {
				const payload = {};
				return {
					type: types.BLOCKS_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.BLOCKS_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.BLOCKS_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const fetchBlocksAdded = (action$, store) =>
	action$.pipe(
		ofType(types.BLOCKS_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return BlocksService.listenBlocksAdded(uid).pipe(
				map((change) => ({
					type: types.BLOCKS_ADDED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.BLOCKS_ADDED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchBlockedMe = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			try {
				const payload = await BlockedMeService.fetchBlockedMe(loggedInUserUID);
				return fetchBlockedMeSuccessfully(payload);
			} catch (error) {
				return fetchBlockedMeFail(error);
			}
		}),
		catchError((error) => {
			return of({
				type: types.BLOCKS_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const fetchBlockedMeAdded = (action$, store) =>
	action$.pipe(
		ofType(types.BLOCKS_BLOCKED_ME_FETCH_SUCCESSFULLY),
		switchMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return BlockedMeService.listenBlockedMeAdded(loggedInUserUID).pipe(
				map(({ key, payload }) => blockedMeAdded(key, payload))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.BLOCKS_BLOCKED_ME_BY_USER_ADDED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const unBlockUser = (action$, store) =>
	action$.pipe(
		ofType(types.BLOCKS_UNBLOCK_USER),
		mergeMap(async ({ user }) => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			try {
				await BlocksService.unblockUser(loggedInUserUID, user?.uid);
				QueueService.sendEvent(loggedInUserUID, blockAndReportEvents.UnBlockUser, {
					uid: user.uid,
				});
				return sendUnBlockUserSuccessfully(user);
			} catch (error) {
				return sendUnBlockUserFail(error, user);
			}
		}),
		catchError((error) => {
			return of({
				type: types.BLOCKS_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);
