import i18next from "i18next";

import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import usePopups from "hooks/usePopups";

export default () => {
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="qrModal">
			<ModalHeader>
				{i18next.t("QRcode.Title")}
				<Button color="primary" outline type="button" onClick={closePopup} className="closeBtn">
					<i className="ri-close-line"></i>
				</Button>
			</ModalHeader>
			<ModalBody>{i18next.t("QRcode.Body")}</ModalBody>
		</Modal>
	);
};
