import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logoB from "assets/images/icons/logoW.svg";
import heart from "assets/images/icons/heart1W.svg";
import AppServices from "services/app";
import { getIsPlatformAndroid, getIsPlatformIOS, getPlatform } from "redux/init/selectors";

export default () => {
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const platform = useSelector(getPlatform);
	const [url, setUrl] = useState();
	const handleOnClick = async (e) => {
		e.preventDefault();
		await AppServices.openInbrowser(url);
	};

	useEffect(() => {
		if (platform) {
			if (isPlatformAndroid) {
				setUrl("https://play.google.com/store/apps/details?id=com.meetsmart.jsmart&hl=iw&gl=US");
			} else if (isPlatformIOS) {
				setUrl(
					"https://apps.apple.com/us/app/jsmart-%D7%94%D7%99%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-%D7%93%D7%99%D7%99%D7%98%D7%99%D7%A0%D7%92-%D7%A6%D7%90%D7%98/id1554731425"
				);
			}
		}
	}, [platform]);

	return (
		<div className="loginPagesWrapper homeConnect">
			<div className="wrapMaintenance forcePage">
				<div className="wrapGradient">
					<div className="siteLogo">
						<img src={logoB} />
					</div>
					<div className="messageMain">
						<h3> העלנו גירסה חדשה עם שיפורים ופיצ׳רים נוספים </h3>
						<div className="heartWhite">
							<img src={heart} />
						</div>
						{url && (
							<a className="desktop" onClick={handleOnClick}>
								לחץ לעדכון גירסה
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
