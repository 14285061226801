export default ({
  min,
  max,
  step,
  minVal,
  maxVal,
  onMinChange,
  onMaxChange,
  OnMinInputMouseOver,
  OnMaxInputMouseOver,
  OnMinInputMouseOut,
  OnMaxInputMouseOut,
  OnMinInputMouseDown,
  OnMaxInputMouseDown,
  OnMinInputMouseUp,
  OnMaxInputMouseUp,
}) => {
  return (
    <>
      <input
        type="range"
        id="input-left"
        min={min}
        max={max}
        step={step}
        value={minVal}
        onChange={(e) => Number(e.target.value) <= maxVal && onMinChange(e)}
        onMouseOver={OnMinInputMouseOver}
        onMouseOut={OnMinInputMouseOut}
        onMouseDown={OnMinInputMouseDown}
        onMouseUp={OnMinInputMouseUp}
      />
      <input
        type="range"
        id="input-right"
        min={min}
        max={max}
        step={step}
        value={maxVal}
        onChange={(e) => Number(e.target.value) >= minVal && onMaxChange(e)}
        onMouseOver={OnMaxInputMouseOver}
        onMouseOut={OnMaxInputMouseOut}
        onMouseDown={OnMaxInputMouseDown}
        onMouseUp={OnMaxInputMouseUp}
      />
    </>
  );
};
