import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class LetPicturesService {
  BASE_REF = "let_pictures";

  async fetchLetPictures(uid) {
    return (
      (
        await firebase
          .database()
          .ref(this.BASE_REF)
          .child(uid)
          .orderByChild("timeStamp")
          .startAt(null)
          .once("value")
      ).val() || {}
    );
  }

  letPictures(uid, colleagueUid) {
    return firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set({
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  unLetPictures(uid, colleagueUid) {
    return firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set(null);
  }

  listenLetPicturesAdded(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid).orderByChild("timeStamp");
      //.startAt(Date.now());

      const callbackFn = ref.on(
        "child_added",
        (snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_added", callbackFn);
    });
  }

  listenLetPicturesRemoved(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_removed",
        (snapshot) => subscriber.next(snapshot.key),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_removed", callbackFn);
    });
  }
}

export default new LetPicturesService();
