import { useEffect, useState } from "react";
import speedDatingService from "services/speedDatingService";
import PublicProfileService from "services/publicProfile";
import moment from "moment";
import i18next from "i18next";
import CountdownTimer from "./CountdownTimer";
const dateDuration = 10;

export default function DateList({ eventKey, selectDate, speedData, currentDate }) {
	const [dates, setDates] = useState(null);
	const [displayDates, setDisplayDates] = useState([]);
	const [datesTimeObj, setDatesTimeObj] = useState({});
	const [datesTimesList, setDatesTimesList] = useState([]);
	const [nextMeetingTime, setNextMeetingTime] = useState(null);
	const [displayTicks, setDisplayTicks] = useState(null);

	const getNextMeeting = (list) => {
		const currentTime = moment();

		if (!datesTimesList?.length) return currentTime.valueOf();

		if (moment(list[0], "HH:mm").isBefore(currentTime)) {
			list.shift();
			return getNextMeeting(list);
		}
		return moment(list[0], "HH:mm").valueOf();
	};

	const handleNextMeeting = () => {
		if (!datesTimesList || !datesTimesList?.length) return;

		const meeting = getNextMeeting(datesTimesList);
		return setNextMeetingTime(meeting);
	};

	const checkTimeArrival = (targetTime) => {
		const eventsDate = speedData?.eventDate;
		const [targetHours, targetMinutes] = targetTime.split(":");

		const targetDateTime = moment(eventsDate);
		targetDateTime.set({ hours: targetHours, minutes: targetMinutes });

		if (eventsDate > targetDateTime.valueOf()) {
			return true;
		} else {
			const timeDifference = eventsDate - targetDateTime.valueOf();
			const timeDifferenceInMinutes = timeDifference / (1000 * 60); // Convert milliseconds to minutes

			if (timeDifference >= 0 && timeDifferenceInMinutes <= dateDuration) {
				return true;
			} else {
				return false;
			}
		}
	};

	const openVideoChat = async (date) => {
		selectDate(null);
		const timeArrival = checkTimeArrival(date.time);
		if (timeArrival) {
			const data = await speedDatingService.getChatVideoRoom(eventKey, date?.uidMatch);
			if (data?.data?.success) {
				selectDate({ token: data.data.token, roomName: data.data.roomName, ...date });
			}
		} else {
			alert("time of date is not arrival");
		}
	};

	useEffect(async () => {
		if (eventKey) {
			const data = await speedDatingService.getMyDating(eventKey);
			if (data?.data?.dates) {
				const datesData = data.data.dates;

				let datesTimes = [];
				let datesObj = {};
				Object.values(datesData).map((item) => {
					datesTimes.push(`${item.time}`);
					datesObj[`${item.time}`] = item;
				});
				setDatesTimesList(datesTimes);
				setDatesTimeObj(datesObj);

				const datesSorted = Object.values(datesData).sort((a, b) => a.time - b.time);
				setDates(datesSorted);
			}
		}
		return () => {};
	}, [eventKey]);

	useEffect(() => {
		if (!speedData.key || !speedData?.eventDate || !dates || !dates?.length) return;

		const jumpInterval = 15; // 15 minutes
		const numberOfJumps = 5;

		let timeObj = {};

		let currentTimeUnix = speedData.eventDate;
		for (let i = 0; i < numberOfJumps; i++) {
			const currentTime = moment.unix(currentTimeUnix / 1000).format("HH:mm");
			timeObj[currentTime] = { time: currentTime };
			currentTimeUnix += jumpInterval * 60 * 1000;
		}
		timeObj = { ...timeObj, ...datesTimeObj };

		const arrTime = Object.values(timeObj).map(async (val) => {
			let valObj = { ...val };
			if (val?.uidMatch) {
				const user = await PublicProfileService.fetchProfile(val.uidMatch);

				valObj.name = user?.name || "";
			} else {
				valObj.name = i18next.t("speedDate.break");
			}

			return valObj;
		});
		Promise.all(arrTime).then((data) => {
			setDisplayDates(data);
		});
	}, [dates]);

	useEffect(() => {
		if (!nextMeetingTime) return;
		const currentTime = moment().valueOf();
		const formattedTargetTime = moment(nextMeetingTime).valueOf();
		const timeDifferenceInSeconds = Math.floor((formattedTargetTime - currentTime) / 1000);

		setDisplayTicks(timeDifferenceInSeconds);
	}, [nextMeetingTime]);

	useEffect(() => {
		handleNextMeeting();
	}, [datesTimesList]);

	return (
		<>
			{!!displayTicks && displayTicks > 0 && !currentDate && (
				<CountdownTimer
					initCount={displayTicks}
					handleFinishCount={() => {
						setDisplayTicks(0);
						handleNextMeeting();
					}}
					fullView={true}
				/>
			)}
			{!!displayDates &&
				!!displayDates?.length &&
				displayDates.map((item, index) => {
					return (
						<>
							<div
								key={item?.uidMatch || index}
								onClick={() => {
									if (!item?.uidMatch) return;
									return openVideoChat(item);
								}}
								style={{ cursor: !!item?.uidMatch ? "pointer" : "default" }}>
								{item.time} - {item?.name}
							</div>
						</>
					);
				})}
		</>
	);
}
