import { getLoggedInUserUID } from "../auth/selectors";
import { isSkipped } from "./selectors";
import { isLike } from "../likes/selectors";
import { unlike } from "../likes/actions";
import * as types from "./actionTypes";
import QueueService from "services/queue";
import SkipsService from "services/skips";
import SkipEvents from "consts/skipEvents";

export const skipToggle = (
  colleagueUid,
  forceSkip = false,
  forceUnlike = false
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = getLoggedInUserUID(state);
    const hadSkip = isSkipped(colleagueUid)(state) || false;
    const hadLike = isLike(colleagueUid)(state) || false;

    if (!hadSkip) {
      await skip(dispatch, uid, colleagueUid);
    } else if (!forceSkip) {
      await unSkip(dispatch, uid, colleagueUid);
    }
    if (hadLike || forceUnlike) {
      await unlike(dispatch, uid, colleagueUid);
    }
  };
};

export const skip = async (dispatch, uid, colleagueUid) => {
  dispatch({ type: types.SKIPPED_ADD, user: colleagueUid });
  QueueService.sendEvent(uid, SkipEvents.Skip, colleagueUid);
  try {
    await SkipsService.skip(uid, colleagueUid);
    dispatch({ type: types.SKIPPED_ADD_SUCCESSFULLY, payload: colleagueUid });
  } catch (e) {
    dispatch({
      type: types.SKIPPED_ADD_FAILED,
      payload: colleagueUid,
      error: e?.message,
    });
  }
};

export const unSkip = async (dispatch, uid, colleagueUid) => {
  dispatch({ type: types.SKIPPED_REMOVE, payload: colleagueUid });
  QueueService.sendEvent(uid, SkipEvents.UnSkip, colleagueUid);
  try {
    await SkipsService.unSkip(uid, colleagueUid);
    dispatch({
      type: types.SKIPPED_REMOVE_SUCCESSFULLY,
      payload: colleagueUid,
    });
  } catch (e) {
    dispatch({
      type: types.SKIPPED_REMOVE_FAILED,
      payload: colleagueUid,
      error: e?.message,
    });
  }
};
