export default ({
  min,
  max,
  step,
  maxVal,
  onMaxChange,
  OnMaxInputMouseOver,
  OnMaxInputMouseOut,
  OnMaxInputMouseDown,
  OnMaxInputMouseUp,
}) => {
  return (
      <input
        type='range'
        id='input-right'
        min={min}
        max={max}
        step={step}
        value={maxVal}
        onChange={onMaxChange}
        onMouseOver={OnMaxInputMouseOver}
        onMouseOut={OnMaxInputMouseOut}
        onMouseDown={OnMaxInputMouseDown}
        onMouseUp={OnMaxInputMouseUp}
      />
  );
};
