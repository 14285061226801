import "assets/scss/custom/pages/registerSpeedDate.scss";
import { Box } from "@mui/system";
import useNavigator from "hooks/useNavigator";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import i18next from "i18next";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import speedDateStatus from "consts/speedDateStatus";

export default () => {
	let { urlName } = useParams();
	const currentUser = useSelector(getUserProfile) || {};
	const navigate = useNavigator();
	const { showFittedMessagePopup, showLoginPopup } = usePopups();

	const [speedData, setSpeedDate] = useState({});
	const [userData, setUserData] = useState({});
	const [isUserInSpeedDate, setIsUserInSpeedDate] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingUser, setLoadingUser] = useState(true);

	const navigateToHomePage = () => {
		return navigate.navigateToHomePage();
	};

	const registerSpeedDateUser = () => {
		setLoading(true);
		speedDatingService.registerUserToSpeedDating(speedData?.key).then((data) => {
			if (data?.data?.success || data?.data?.msg == "user already register") {
				showFittedMessagePopup("ההרשמה בוצעה בהצלחה!");
			} else {
				showFittedMessagePopup("ההרשמה נכשלה");
			}
			setLoading(false);
		});
	};

	const handleRegisterBtn = () => {
		if (!currentUser?.uid) return showLoginPopup(registerSpeedDateUser);
		if (!speedData?.key) return;
		registerSpeedDateUser();
	};

	useEffect(() => {
		if (!urlName) return navigateToHomePage();
		speedDatingService.getSpeedDateInfoByUrlName(urlName).then((data) => {
			if (!data) return navigateToHomePage();
			const sData = Object.values(data || {});
			if (!sData || !sData?.length) return navigateToHomePage();
			setSpeedDate(sData[0]);
		});
	}, []);

	useEffect(() => {
		if (!speedData?.key || !currentUser?.uid) return;
		setLoadingUser(true);

		const subscription = speedDatingService
			.listenToUserInSpeedDate$(speedData.key, currentUser.uid)
			.subscribe((userData) => {
				console.log(userData);
				setUserData(userData);
				setLoadingUser(false);
				setIsUserInSpeedDate(!!userData?.uid);
			});
		return () => {
			subscription?.unsubscribe();
		};
	}, [speedData?.key, currentUser?.uid]);

	useEffect(() => {
		setTimeout(() => {
			if (!currentUser?.uid) setLoadingUser(false);
		}, 4000);
	}, []);

	return (
		<Box className="registerSpeedDateWrapper">
			<h1>הרשמה לספיד דייט - Jsmart</h1>
			{!!speedData?.key && (
				<Box className="wrapDescAndRegBtn">
					<Box className="wrapRegDesc">
						<h2>{speedData?.title}</h2>
						<h3>{speedData?.description}</h3>
						{speedData?.eventDate && (
							<h4> {moment(speedData?.eventDate).format("בתאריך - DD/MM/YYYY בשעה - HH:mm:ss")}</h4>
						)}
					</Box>
					<Box className="wrapRegSpeed">
						{speedData?.status !== speedDateStatus.start ||
						(speedData?.endUsersRegister &&
							moment().isAfter(moment(speedData?.endUsersRegister))) ? (
							<Box>{i18next.t(`speedDate.registrationClosed`)}</Box>
						) : (
							<>
								{!!loadingUser ? (
									<CircularProgress size={40} className="text-secondary" />
								) : (
									<>
										{!!isUserInSpeedDate ? (
											<Box>
												{i18next.t(`speedDate.signedUpSuccessfully_${currentUser.gender || 0}`)}
											</Box>
										) : (
											<Box>
												<Box>{i18next.t(`speedDate.registerNow`)}</Box>
												{!!loading ? (
													<CircularProgress size={40} className="text-secondary" />
												) : (
													<Button
														color="none"
														type="button"
														className="regSpeedBtn"
														onClick={handleRegisterBtn}>
														<span>{i18next.t(`speedDate.forRegisterPressHere`)}</span>
													</Button>
												)}
											</Box>
										)}
									</>
								)}
							</>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
};
