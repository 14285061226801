import i18next from 'i18next';
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

import usePopups from 'hooks/usePopups';
import useNavigator from "hooks/useNavigator";

export default () => {
    const { closePopup } = usePopups();
	const navigator = useNavigator();

    const toggle = () =>{
        closePopup();
        navigator.navigateToPreviousPage();
    }

    return (
        <Modal isOpen={true} toggle={toggle} className="simpleDialog">                                            
            <ModalHeader>
                {i18next.t('Contact.Sent Successfully')}
            </ModalHeader>
            <ModalFooter >
                <Button outline size="sm" color="primary" onClick={toggle} className="btn-rounded littleBtn">{i18next.t('Ok')}</Button>
            </ModalFooter>
        </Modal>
    )
}