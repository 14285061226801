import { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";

import { getUserProfile } from "redux/profile/selectors";
import sendBl from "assets/images/icons/chatW.svg";
import { sendChatMessage } from "redux/chatMessages/actions";
import { getChat, getIsNewChat } from "redux/chatReceived/selectors";
import SendTemplateMessageButton from "./card/SendTemplateMessageButton";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";

const MAX_LENGTH = 140;

export default ({ user, onAfterSendMessage = () => {}, fromCardUserModal }) => {
	const dispatch = useDispatch();
	const { gender } = useSelector(getUserProfile);
	const [message, setMessage] = useState("");
	const chatID = useSelector(getChat(user.uid));
	const promotionChatId = user.promotions_received_chatId;
	const isNewChat = useSelector(getIsNewChat(user.uid));
	const subscriptionType = useSelector(getUserSubscriptionType);
	const {
		showNonSubscriberToSubscriberPopup,
		showNonSubscriberToNonSubscriberPopup,
		showMessageSuccessfullySentPopup,
	} = usePopups();

	const isLoggedInUserSubscribed =
		subscriptionType === Subscription.PAID || subscriptionType === Subscription.FREE;

	const isCollageSubscribed = user.isSubscribed;

	const sendMessage = () => {
		dispatch(sendChatMessage(chatID || promotionChatId, message, user.uid, isNewChat));
		onAfterSendMessage();
		!isLoggedInUserSubscribed && isCollageSubscribed && showNonSubscriberToSubscriberPopup(user);
		!isLoggedInUserSubscribed &&
			!isCollageSubscribed &&
			showNonSubscriberToNonSubscriberPopup(user);
		isLoggedInUserSubscribed && showMessageSuccessfullySentPopup();
	};

	return (
		<>
			<div className="wrapTextDecoration">
				<span>
					{i18next.t(`Send Hello.Write to_${user?.gender}`, {
						name: user?.name,
					})}
				</span>
				<div className="inputAndQuote grow-wrap" data-replicated-value={message}>
					<Input
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder={i18next.t(`Send Hello.Write a message_${gender}`)}
						type="textarea"
						rows={4}
						maxLength={MAX_LENGTH}
					/>
					<SendTemplateMessageButton
						user={user}
						usePopover={!isMobile() && fromCardUserModal ? true : false}
						className="popoverUser"
					/>
				</div>
			</div>
			<Button disabled={message.trim().length < 2} onClick={sendMessage}>
				{i18next.t(`Search Card.Say Hi_${gender}`)} <img src={sendBl} />
			</Button>
		</>
	);
};
