import { useSelector } from "react-redux";

import { Face, Fill } from "consts/cloudinaryOptions";
import { TransformImg } from "helpers/cloudinary";
import usePopups from "hooks/usePopups";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import GalleryTypes from "consts/galleryTypes";
import { getUserProfile } from "redux/profile/selectors";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import privatePictureRound from "assets/images/emptyImages/privateRound.png";

export default ({ currentMessage, nextMessage }) => {
	const loggedInUserUID = useSelector(getLoggedInUserUID);
	const isSender = currentMessage.uid === loggedInUserUID;
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const chatUserUri = activeUserProfile.pictures?.[activeUserProfile.mainPictureName]?.url || "";
	const chatUserpath = TransformImg(chatUserUri, null, null, Fill.Thumb, Face.Face, "r_max");
	const loggedInUserProfile = useSelector(getUserProfile);
	const loggedInUserUri =
		loggedInUserProfile.pictures?.[loggedInUserProfile.mainPictureName]?.url || "";
	const loggedInUserpath = TransformImg(
		loggedInUserUri,
		null,
		null,
		Fill.Thumb,
		Face.Face,
		"r_max"
	);

	const defaultLoggedInImage = loggedInUserProfile.gender
		? placeholderWomanRound
		: placeholderManRound;
	const defaultActiveUserImage = activeUserProfile.gender
		? placeholderWomanRound
		: placeholderManRound;

	const { showGalleryPopup } = usePopups();

	return (
		<>
			{nextMessage && nextMessage.uid === currentMessage.uid ? (
				<div className="chat-avatar">
					<div className="blank-div"></div>
				</div>
			) : (
				<div className="chat-avatar">
					{isSender ? (
						<img
							onClick={() => showGalleryPopup(GalleryTypes.Private)}
							style={{ cursor: "pointer" }}
							src={loggedInUserpath ? loggedInUserpath : defaultLoggedInImage}
						/>
					) : (
						<img
							onClick={() => showGalleryPopup(GalleryTypes.Public)}
							style={{ cursor: "pointer" }}
							src={
								chatUserpath
									? chatUserpath
									: activeUserProfile.isPicturesPrivate
									? privatePictureRound
									: defaultActiveUserImage
							}
						/>
					)}
				</div>
			)}
		</>
	);
};
