import i18next from "i18next";

import EnumBtn from "components/EnumBtn";
import KeepShabbat from "consts/keepShabbat";
import ProfileFields from "consts/profileFields";
import RequiredPointContainer from "../RequiredPointContainer";

export default ({ onChange, profile }) => {
	const _onChange = (key, value) => onChange(key, Number(value));

	return (
		<RequiredPointContainer>
			<EnumBtn
				field={ProfileFields.KeepShabbat}
				fieldOptions={Object.values(KeepShabbat)}
				onChange={_onChange}
				initSelected={profile.keepShabbat}
				title={i18next.t("Edit Profile.KeepShabbat")}
				translatePrefix="KeepShabbat"
			/>
		</RequiredPointContainer>
	);
};