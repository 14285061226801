import i18next from "i18next";
import { useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";

export default () => {
	const currentUser = useSelector(getUserProfile) || {};
	const { closePopup, showSubscriptionPopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				{i18next.t("Blur Message Modal.Header", { name: currentUser.name })}
			</ModalHeader>
			<ModalBody>
				<span>{i18next.t(`Blur Message Modal.Content_${currentUser.gender}`)}</span>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={showSubscriptionPopup}
					className="btn-rounded littleBtn">
					{i18next.t("Blur Message Modal.Tell me more")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
