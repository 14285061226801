import moment from "moment";

import Gender from "consts/gender";

const CONFIG = {
	ageDefaults: {
		male: {
			max: 1.2,
			min: 0.6,
		},
		female: {
			max: 1.4,
			min: 0.75,
		},
	},
};

class FilterLimits {
	getMaxAgeRange(gender, dob) {
		const age = moment().diff(dob * 1000, "years");
		let max = 100;
		let min = 18;
		if (gender === Gender.Male) {
			max = age + 12;
			min = Math.floor(age / 2 / 10) * 10;
		} else {
			max = age * 2;
			min = age - 10;
		}
		return {
			min: Math.max(18, min),
			max: Math.min(100, max),
		};
	}

	getDefaultAgeRange(gender, dob) {
		const age = moment().diff(dob * 1000, "years");
		const { male, female } = CONFIG.ageDefaults;

		return {
			min: Math.max(18, Math.round(age * (gender === Gender.Male ? male.min : female.min))),
			max: Math.min(100, Math.round(age * (gender === Gender.Male ? male.max : female.max))),
		};
	}
}

export default new FilterLimits();
