import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import _ from "lodash";

import ArrowUpImage from "assets/images/purchase_account_arrow_up.svg";
import usePopups from "hooks/usePopups";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import { getIsPlatformWeb } from "redux/init/selectors";
import { getUserProfile } from "redux/profile/selectors";
import { getSale } from "redux/subscription/selectors";
import { Button } from "reactstrap";

export default () => {
	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const { gender } = useSelector(getUserProfile);
	const showJoinNowBtn = useShowJoinNowBtn();
	const { showSubscriptionPopup } = usePopups();
	const sale = useSelector(getSale);
	const animatedName = "animate__bounceInDown";

	const goToTheStore = () => window.open("https://jsmart.app/invite/Ewq2", "_blank");

	const showSaleSubscriptionPopup = () => {
		return showSubscriptionPopup(undefined, undefined, true);
	};
	const [current, setCurrent] = useState();

	useEffect(() => {
		if (_.isNil(gender)) {
			return;
		}
		if (!_.isNil(showJoinNowBtn) && !_.isNil(isPlatformWeb) && !_.isNil(sale)) {
			if (showJoinNowBtn) {
				if (!!sale && !!sale.label) {
					setCurrent({
						type: "sale",
						header: sale.label,
						func: showSaleSubscriptionPopup,
						color: "secondary",
					});
				} else {
					setCurrent({
						type: "joinNow",
						header: i18next.t(`Strip.Join Now_${gender}`),
						func: showSubscriptionPopup,
						color: "success",
					});
				}
			} else if (isPlatformWeb) {
				setCurrent({
					type: "downloadApp",
					header: i18next.t(`Strip.Download app_${gender}`),
					func: goToTheStore,
					color: "info",
				});
			}
		}
	}, [showJoinNowBtn, isPlatformWeb, sale, gender]);

	return (
		!!current && (
			<Button className="bottomStrip" onClick={current.func} color={current.color}>
				<img src={ArrowUpImage} />
				<div className={`animate__animated ${animatedName}`}>{current.header}</div>
				<img src={ArrowUpImage} />{" "}
			</Button>
		)
	);
};
