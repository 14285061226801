import { getLoggedInUserUID } from "redux/auth/selectors";
import { getInternalID } from "./selectors";
import QueueService from "services/queue";
import * as types from "./actionTypes";
import FreezeEvents from "consts/freezeEvents";
import SettingsEvents from "consts/settingsEvents";
import UserGeneralService from "services/userGeneral";
import UserStatus from "consts/userStatus";
import DeleteEvents from "consts/deleteEvents";
import HTTPFunctionService from "services/httpFunction";
import massletterEvents from "consts/massletterEvents";

export const freeze = (freeze_reason) => {
	return (dispatch, getState) => {
		const state = getState();
		const user = getLoggedInUserUID(state);
		const user_internal = getInternalID(state);
		QueueService.sendEvent(user, FreezeEvents.FreezeMe, {
			user_internal,
			freeze_reason,
		});
		dispatch({ type: types.GENERAL_FREEZE_USER });
	};
};

export const unFreeze = () => {
	return (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		QueueService.sendEvent(uid, FreezeEvents.UnfreezeMe, {
			oldStatus: UserStatus.Freezed,
		});
		return dispatch({ type: types.GENERAL_UNFREEZE_USER });
	};
};

export const updateCommunicationSettings = (key, newVal) => {
	return (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		UserGeneralService.updateCommunicationSettings(uid, key, newVal);
		QueueService.sendEvent(uid, SettingsEvents.SaveCommunicationSettings, {
			key,
			newVal,
		});
		dispatch({
			type: types.USER_GENERAL_UPDATE_COMMUNICATION_SETTINGS,
			key,
			newVal,
		});
	};
};

export const updateNotificationsSettings = (key, newVal) => {
	return (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		UserGeneralService.updateNotificationsSettings(uid, key, newVal);
		QueueService.sendEvent(uid, SettingsEvents.SaveNotificationsSettings, {
			key,
			newVal,
		});
		dispatch({
			type: types.USER_GENERAL_UPDATE_SETTINGS_SETTINGS,
			key,
			newVal,
		});
	};
};

export const deleteAccount = (reason, message) => {
	return (dispatch, getState) => {
		const state = getState();
		const user = getLoggedInUserUID(state);
		const user_internal = getInternalID(state);
		QueueService.sendEvent(user, DeleteEvents.Delete, { user_internal, reason, message });
		dispatch({ type: types.GENERAL_DELETE_USER });
	};
};

export const wantToDeleteAccount = (reason, message) => {
	return (dispatch, getState) => {
		const state = getState();
		const user = getLoggedInUserUID(state);
		const user_internal = getInternalID(state);
		QueueService.sendEvent(user, DeleteEvents.ReportWantToDelete, {
			user_internal,
			reason,
			message,
		});
	};
};

export const updateMassletterShowCount = () => {
	return (dispatch, getState) => {
		const state = getState();
		const user = getLoggedInUserUID(state);
		QueueService.sendEvent(user, massletterEvents.MassLetterShowPopupCountUpdate);
	};
};

export const saveNewCommunicationSettings = (
	newSettings,
	userUid,
	showUnsubscribeSuccessfullySavedPopup,
	showUnsubscribeErrorPopup
) => {
	return async (dispatch, getState) => {
		try {
			await HTTPFunctionService.saveNewCommunicationSettings(newSettings, userUid);
			showUnsubscribeSuccessfullySavedPopup();
		} catch (err) {
			console.log(err);
			showUnsubscribeErrorPopup();
		}
	};
};
