import { useState, useEffect, useRef } from "react";
import i18next from "i18next";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import ProfileFields from 'consts/profileFields';
import TagsService from 'services/tags';



export const SelectedTags = ({ onChange, profile }) => {
    const [ tags , setTags ] = useState(profile.tags || {});
    const wrapper = useRef();
    const inner = useRef();
  
    useEffect(()=>{
        if(wrapper.current.clientHeight < inner.current.clientHeight) {
            wrapper.current.classList.add('ellipsis');
        } else {
            wrapper.current.classList.remove('ellipsis');     
        }    
    },[ tags ]);

    const onRemove = key => {
        const newTags = { ...tags }
        delete newTags[key];
        setTags(newTags);
        onChange(ProfileFields.Tags , newTags);
    }

   return <div className="tagsWrap">
               <label className="form-label">{i18next.t('Pages.Interest')}</label>              
                  <div ref={wrapper} className="tags"> 
                    <div ref={inner} className="tagsInline">                       
                        {Object.entries(tags).map(([key , value]) => 
                            <Button size="sm" className="tagsBtn" outline color="primary">   
                                <Button close onClick={()=>onRemove(key)}/> 
                                {value} 
                            </Button>
                        )}
                    </div>
                </div>
               <span>{i18next.t('Edit Profile.For Edit Tags')}{' '}<Link to="/account/tags">{i18next.t(`Edit Profile.Click Here_${profile.gender}`)}</Link></span> 
           </div>
};

export const TagsPool = ({ onChange, profile }) => {
    const tags = TagsService.getTagsList();
    const [ selectedTags , setSelectedTags ] = useState(profile.tags || {});

    const onTagClick = tag => {
        const isSelected = selectedTags[tag.key];
        const newSelectedTags = { ...selectedTags };
        isSelected && (delete newSelectedTags[tag.key]);
        !isSelected && (newSelectedTags[tag.key] = tag.text);
        setSelectedTags(newSelectedTags);
        onChange(ProfileFields.Tags , newSelectedTags);
    }

    return (
        <div className="tagsWrap">
            <div className="tags">
                {tags.map(tag => 
                    <Button onClick={()=>onTagClick(tag)} size="sm" className={`tagsBtn newTag ${selectedTags[tag.key] ? 'selected': ''}`} outline color="primary">                 
                        {tag.text}
                    </Button>
                )}
            </div>
        </div> 
    )
}