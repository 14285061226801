import * as types from './actionTypes';

export const loadAppSettingsSuccessfully = settings => {
  return {
    type: types.APP_SETTINGS_FETCH_SUCCESSFULLY,
    payload: settings,
  };
}

export const loadTags = fetchNewTags => {
  return {
    type: types.APP_SETTINGS_FETCH_TAGS,
    payload: fetchNewTags,
  };
}