import { Helmet } from "react-helmet";
import { isEmpty } from "lodash";

export default function ({ profile }) {
	let userImg = "";
	if (
		!!profile?.mainPictureName &&
		!isEmpty(profile?.pictures) &&
		!!profile?.pictures[profile?.mainPictureName]
	) {
		userImg = profile?.pictures[profile?.mainPictureName].url;
	}

	return (
		<Helmet>
			<meta content={userImg} property="og:image" />
		</Helmet>
	);
}
