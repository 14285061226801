import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useEffect } from "react";

export default () => {
	const { closePopup } = usePopups();

	useEffect(() => {
		setTimeout(() => {
			closePopup();
		}, 1000);
	});

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("Delete account.Alert.Header")}</ModalHeader>
			<ModalBody>{i18next.t("Delete account.Alert.Message")}</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
