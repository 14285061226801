import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import Clothing from "consts/clothing";
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
	const _onChange = (key, value) => onChange(key, Number(value));

	return (
		<DropDown
			field={ProfileFields.Clothing}
			fieldOptions={Object.values(Clothing)}
			onChange={_onChange}
			initSelected={profile.clothing}
			title={i18next.t("Edit Profile.Clothing")}
			translatePrefix="Clothing"
		/>
	);
};
