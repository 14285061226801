import i18next from "i18next";

export const Reasons = [
  'Report Reasons.User Has Wrong Details',
  'Report Reasons.Dangerous User',
  'Report Reasons.Inappropriate Messages',
  'Report Reasons.Inappropriate Pictures',
  'Report Reasons.Behavior out of the app',
  'Report Reasons.Other',
];

export const WrongPicturesReasons = [ 
 'Report Wrong Details.Not His Picture', 
 'Report Wrong Details.Public figure Picture',
];

export const WrongAgeReasons = [ 
 'Report Wrong Details.Wrong Age From App',
 'Report Wrong Details.Wrong Age Out of App',
 'Report Wrong Details.Wrong Age Regardless of the app',
]