import i18next from "i18next";
import { useState } from "react";

import UneditableMessage from "./UneditableMessage";

export default ({ profile }) => {
	const [show, setShow] = useState(false);

	const toggle = () => setShow((v) => !v);
	return (
		<h6>
			<div onClick={toggle}>
				{i18next.t("Account Settings.Gender")}
				{i18next.t(`Gender.${profile.gender}`)}
			</div>
			<UneditableMessage show={show} toggle={toggle} />
		</h6>
	);
};
