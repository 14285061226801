import React, { useState } from "react";
import i18next from "i18next";
import {
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	Label,
	UncontrolledDropdown,
} from "reactstrap";

export default ({ field, fieldOptions, onChange, initSelected = [], title, translatePrefix }) => {
	const [values, setValues] = useState(initSelected);

	const onNext = (item, checked) => {
		const selectedValues = checked ? values.concat(item) : values.filter((val) => val !== item);
		setValues(selectedValues);
		onChange(field, selectedValues);
	};

	return (
		<div className="wraptxt mb-3">
			<UncontrolledDropdown className="dropDown multiSelect">
				<DropdownToggle tag="a" id="collect" className="nav-link">
					{values.length > 0
						? values.map((val) => i18next.t(`${translatePrefix}.${val}`)).join(",  ")
						: title}
				</DropdownToggle>
				<DropdownMenu>
					{fieldOptions.map((item) => (
						<FormGroup key={item}>
							<Label>
								<Input
									type="checkbox"
									onChange={(e) => onNext(item, e.target.checked)}
									checked={values.includes(item)}
									value={item}
								/>
								{i18next.t(`${translatePrefix}.${item}`)}
							</Label>
						</FormGroup>
					))}
				</DropdownMenu>
			</UncontrolledDropdown>
		</div>
	);
};
