import React from "react";

export default ({ title, subTitle }) => {
	return (
		<div className="wrapTitleOfQuest">
			<h1>{title}</h1>
			{!!subTitle && <h5>{subTitle}</h5>}
		</div>
	);
};
