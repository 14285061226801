import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import CallsTypes from 'consts/callsTypes';
import usePopups from 'hooks/usePopups';
import { getUserPublicProfile } from 'redux/publicProfiles/selectors';

export default ({ isOpen , toogle , image , callType }) => {
    const active_userId = useSelector(state => state.chat.active_userId);
    const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};

    const { showQrcodePopup } = usePopups();

    return (
        <Modal tabIndex="-1" isOpen={isOpen} toggle={toogle} centered>
            <ModalBody>
                <div className="text-center p-4">
                    <div className="avatar-lg mx-auto mb-4">
                        <img src={image} alt="" className="img-thumbnail rounded-circle" />
                    </div>
                    <h5 className="text-truncate">{activeUserProfile.name}</h5>
                    <p className="text-muted">{i18next.t(`Start ${callType} Call ${activeUserProfile?.gender}`)}</p>
                    <div className="mt-5">
                        <ul className="list-inline mb-1">
                            <li className="list-inline-item px-2 me-2 ms-0">
                                <button type="button" className="btn btn-danger avatar-sm rounded-circle" onClick={toogle}>
                                    <span className="avatar-title bg-transparent font-size-20">
                                        <i className="ri-close-fill"></i>
                                    </span>
                                </button>
                            </li>
                            <li className="list-inline-item px-2">
                                <button onClick={()=>showQrcodePopup(i18next.t(`${callType} Qrcode Text`))} type="button" className="btn btn-success avatar-sm rounded-circle">
                                    <span className="avatar-title bg-transparent font-size-20">
                                        {callType === CallsTypes.Audio && <i className="ri-phone-fill"></i>}
                                        {callType === CallsTypes.Video && <i className="ri-vidicon-fill"></i>}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
