
import StorageService from 'services/storage';
import HTTPFunctionService from 'services/httpFunction';

class TagsService {
  tags = [];
  
   async loadTags() {
    const url = await StorageService.getTagsURL();
    const tags = JSON.parse((await HTTPFunctionService.GetUrl(url)) || '{}');
    this.tags = tags;
    return true;
  }

  getTagsList() {
    return this.tags;
  }
}

export default new TagsService();
