import { Observable } from "rxjs";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { PushNotifications } from "@capacitor/push-notifications";
import { Browser } from "@capacitor/browser";
import { isMobile } from "react-device-detect";

import RegistrationSource from "consts/registrationSource";
import AppPlatforms from "consts/AppPlatforms";

class AppService {
	_info = null;
	async getInfo() {
		this._info = this._info || (await App.getInfo());
		return this._info;
	}

	async getState() {
		const state = await App.getState();
		return state.isActive ?? null;
	}
	async getPlatform() {
		let info = await Capacitor.getPlatform();
		if (info === AppPlatforms.WEB) {
			const _isMobile = isMobile;
			if (_isMobile) {
				info = AppPlatforms.MOBILEWEB;
			}
		}
		return info;
	}

	async getOperatingSystem() {
		const info = await this.getInfo();
		return info.operatingSystem;
	}

	async getAppVersion() {
		const info = await this.getInfo();
		return info.version;
	}
	async openInbrowser(url) {
		await Browser.open({ url: url });
	}

	async registerFCMPush() {
		try {
			let permStatus = await PushNotifications.checkPermissions();
			if (permStatus.receive === "prompt") {
				permStatus = await PushNotifications.requestPermissions();
			}
			if (permStatus.receive === "granted") {
				//permStatus = await PushNotifications.requestPermissions();
				await PushNotifications.register();
			} else {
				console.log("User denied permissions!");
			}
		} catch (error) {
			console.log("no permissions for fcm granted");
		}
	}

	listenFCMToken() {
		return new Observable((subscriber) => {
			PushNotifications.addListener("registration", (token) => {
				if (!!token) {
					subscriber.next(token.value);
				}
			});
			PushNotifications.addListener("registrationError", (err) => {
				console.error(err);
			});
		});
	}

	async reloadApp() {
		await App.reload();
	}
}

export default new AppService();
