import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "redux/notification/actions";
import "assets/scss/notifications/notification.scss";
import { isIOS, isMobile } from "react-device-detect";

export default function Notification() {
	const { open, message } = useSelector((state) => state.notification);
	const dispatch = useDispatch();

	const handleCloseNotification = () => {
		dispatch(closeNotification());
	};

	return (
		<Snackbar
			className={`notificationWrapper ${!!isMobile && !!isIOS && "IOSView"}`}
			open={open}
			autoHideDuration={4000}
			onClose={handleCloseNotification}
			message={message || ""}></Snackbar>
	);
}
