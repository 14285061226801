import { JaaSMeeting } from "@jitsi/react-sdk";
import { useEffect, useState } from "react";
import CountdownTimer from "./CountdownTimer";
import Feedback from "./Feedback";
const dateDuration = 10;

export default function VideoChat({ currentDate, feddbackQuestions, speedData }) {
	const [remainingTime, setRemainingTime] = useState();
	const [showFeedback, setShowFeedback] = useState(false);

	const calculateTimeRange = (start, duration, currentTime = new Date()) => {
		const [startHour, startMinute] = start.split(":");
		const endHour = Number(startHour) + Math.floor((Number(startMinute) + duration) / 60);
		const endMinute = Number(startMinute) + (duration % 60);

		const currentHours = currentTime.getHours();
		const currentMinutes = currentTime.getMinutes();

		const isWithinHours = currentHours >= Number(startHour) && currentHours <= endHour;
		const isWithinMinutes =
			(currentHours === Number(startHour) && currentMinutes >= Number(startMinute)) ||
			(currentHours === endHour && currentHours != startHour && currentMinutes <= endMinute);

		const isInTimeRange = isWithinHours && isWithinMinutes;

		let remainingTime = 0;

		if (isInTimeRange) {
			const totalRemainingSeconds =
				(endHour - currentHours) * 3600 +
				(endMinute - currentMinutes) * 60 -
				currentTime.getSeconds();
			remainingTime = Math.max(totalRemainingSeconds, 0);
		}

		return {
			isInRange: isInTimeRange,
			remainingTime: remainingTime,
		};
	};

	useEffect(() => {
		if (currentDate) {
			const time = calculateTimeRange(currentDate.time, dateDuration);
			if (time && time.isInRange) {
				console.log("time.remainingTime", time.remainingTime);
				setRemainingTime(time.remainingTime);
			}
		}
	}, [currentDate]);

	useEffect(() => {
		setShowFeedback(!remainingTime);
	}, [remainingTime]);

	return (
		<>
			{!!remainingTime && (
				<CountdownTimer
					initCount={remainingTime}
					handleFinishCount={() => setRemainingTime(0)}></CountdownTimer>
			)}
			{!!currentDate && !!remainingTime && (
				<JaaSMeeting
					appId={"vpaas-magic-cookie-666af2da7dc64003ac0ba5e77b50b790"}
					roomName={currentDate.roomName}
					configOverwrite={{
						disableDeepLinking: true,
						//toolbarButtons:["chat"]
						breakoutRooms: { hideAddRoomButton: true, hideJoinRoomButton: true },
					}}
					jwt={currentDate.token}
					//spinner={CustomSpinner}
					getIFrameRef={(iframeRef) => {
						iframeRef.style.height = "700px";
					}}
					onApiReady={(externalApi) => console.log("test", externalApi)}
				/>
			)}

			{!!currentDate && !remainingTime && showFeedback && (
				<Feedback
					currentDate={currentDate}
					feddbackQuestions={feddbackQuestions}
					speedData={speedData}
				/>
			)}
		</>
	);
}
