import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getPopupUnderPopupType } from "redux/popups/selectors";
import ChatContentAndFooter from "./ChatContentAndFooter";
import UserHead from "./UserHead";
import PopupsTypes from "consts/popupsTypes";

export default () => {
  const [showClass, setShowClass] = useState("");
  const popupUnderPopupType = useSelector(getPopupUnderPopupType);

  useEffect(() => {
    setShowClass("user-chat-show");
  }, []);

  return (
    <div className={"user-chat w-100 " + showClass}>
      <div className="d-lg-flex">
        <div
          className={
            popupUnderPopupType === PopupsTypes.UserProfileSideBar
              ? "w-70 slide"
              : "w-100 slide"
          }
        >
          <UserHead />
          <ChatContentAndFooter />
        </div>
      </div>
    </div>
  );
};
