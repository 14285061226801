import { useState } from "react";
import i18next from "i18next";

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export default ({ field, fieldOptions, onChange, initSelected = "", title, translatePrefix , itemsValueTranslate, className="" }) => {
    const [ value , setValue ] = useState(initSelected);

    const onNext = selectedItem => {
        setValue(selectedItem);
        field ? onChange(field, selectedItem) : onChange(selectedItem);
    }

    return <div className={`wraptxt ${className}`}>
                <label className="form-label">{title}</label>       
                <UncontrolledDropdown className="dropDown">
                    <DropdownToggle tag='a' className='nav-link'>
                        {i18next.t(itemsValueTranslate ? itemsValueTranslate: `${translatePrefix}.${value}`, { value: value })}
                    </DropdownToggle>
                    <DropdownMenu>
                        {fieldOptions.map(item =>
                            <DropdownItem key={item} active={item === value} onClick={()=>onNext(item)} value={item}>
                                {i18next.t(itemsValueTranslate ? itemsValueTranslate: `${translatePrefix}.${item}`, { value: item })}
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown> 
            </div>
};
