import { hotjar } from "react-hotjar";

class HotjarService {
  constructor() {
    this.isProduction = process.env.NODE_ENV === "production";
    if (this.isProduction) {
      this.hotjarId = process.env.REACT_APP_HOTJAR_ID;
      this.hotjarSv = process.env.REACT_APP_HOTJAR_SV;
    }
  }

  init(uid) {
    if (this.isProduction) {
      hotjar.initialize(this.hotjarId, this.hotjarSv);
      if (uid) {
        hotjar.identify("USER_ID", { userProperty: uid });
      }
    }
  }
}

export default new HotjarService();
