import i18next from "i18next";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import {
	getCropImageName,
	getCropImageType,
	getImageToCrop,
	getPopups,
} from "redux/popups/selectors";
import ImageCropInput from "./EditImages/ImageCropInput";
import { useState, useEffect } from "react";
import ProfileFields from "consts/profileFields";
import Warnings from "./Warnings";
import CropImageTypes from "consts/cropImageTypes";
import useProfileImages from "hooks/useProfileImages";
import isMobile from "helpers/isMobile";

const MIN_WIDTH_IMAGE = 400;
const MIN_HEIGHT_IMAGE = 482;

export const resize = (base64Image) => {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () => {
			const isBigImage = img.width > 1600 || img.height > 1928;
			if (isBigImage || isMobile()) {
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				let width, height;
				if (isBigImage) {
					const results = calculateWidth(1600, 1928, img.width, img.height);
					width = results.width;
					height = results.height;
				} else {
					//In mobile - increasing the image size in order to make it possible to reduce the default area of the selection
					width = img.width * (MIN_WIDTH_IMAGE / 0.9 / MIN_WIDTH_IMAGE);
					height = img.height * (MIN_HEIGHT_IMAGE / 0.9 / MIN_HEIGHT_IMAGE);
				}
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);
				const res = canvas.toDataURL("image/jpeg");
				ctx = null;
				canvas = null;
				resolve(res);
			} else {
				resolve(base64Image);
			}
		};
		img.src = base64Image;
	});
};

const calculateWidth = (maxWidth, maxHeight, imageWidth, imageHeight) => {
	const conf = Math.max(imageWidth / maxWidth, imageHeight / maxHeight);
	return {
		width: imageWidth / conf,
		height: imageHeight / conf,
	};
};

export default () => {
	const { closePopup } = usePopups();
	const { handleSaveNewImage } = useSelector(getPopups);
	const imageToCrop = useSelector(getImageToCrop);
	const [imgToCrop, setImgToCrop] = useState();
	const cropImageType = useSelector(getCropImageType);
	const cropImageName = useSelector(getCropImageName);
	const { editProfileImage, saveNewImage } = useProfileImages();

	const [file, setFile] = useState();
	const [isValidSize, setIsValidSize] = useState(false);
	const [isSelectedFile, setIsSelectedFile] = useState(false);

	useEffect(() => {
		if (imageToCrop) {
			resize(imageToCrop).then((im) => {
				setImgToCrop(im);
			});
		}
	}, [imageToCrop]);

	const handleOk = () => {
		cropImageType === CropImageTypes.NewImage &&
			(handleSaveNewImage ? handleSaveNewImage(file) : saveNewImage(ProfileFields.Pictures, file));
		cropImageType === CropImageTypes.EditedImage && editProfileImage(cropImageName, file);
		closePopup();
	};

	return (
		<Modal className="cropImagesModal" isOpen={true} toggle={closePopup}>
			<ModalBody>
				<ImageCropInput
					upImg={imgToCrop}
					setFile={setFile}
					isValidSize={isValidSize}
					setIsValidSize={setIsValidSize}
					setIsSelectedFile={setIsSelectedFile}
				/>
				<Warnings
					error={isSelectedFile && !isValidSize && i18next.t("Validations.not valid image size")}
				/>
			</ModalBody>
			<ModalFooter>
				<Button outline onClick={closePopup} className="backBtn">
					{i18next.t("Return")}
				</Button>
				<Button
					size="lg"
					color="secondary"
					onClick={isValidSize ? handleOk : closePopup}
					className="btn-rounded buttonCrop">
					{i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
