import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";

export default () => {
  const { closePopup } = usePopups();

  return (
    <Modal isOpen={true} toggle={closePopup} className='simpleDialog'>
      <div className='user-chat-nav '>
        <Button color='none' type='button' onClick={closePopup} className='nav-btn' id='user-profile-hide'>
          <i className='ri-close-line'></i>
        </Button>
      </div>
      <ModalBody>
        <div>{i18next.t("Popups.Massletter Sent.Message")}</div>
      </ModalBody>
      <ModalFooter>
        <Button outline size='sm' color='primary' onClick={closePopup} className='btn-rounded littleBtn'>
          {i18next.t("Close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
