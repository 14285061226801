import i18next from "i18next";
import moment from "moment";
import { useState } from "react";

import UneditableMessage from "./UneditableMessage";
import ProfileFields from "consts/profileFields";

export default ({ profile }) => {
	const [show, setShow] = useState(false);

	const toggle = () => setShow((v) => !v);

	return (
		<h6>
			<div onClick={toggle}>
				{i18next.t("Account Settings.Birthday")}
				{moment.unix(profile[ProfileFields.Dob]).format("YYYY-MM-DD")}
			</div>
			<UneditableMessage show={show} toggle={toggle} />
		</h6>
	);
};
