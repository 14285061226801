import { ofType } from "redux-observable";
import { mergeMap, catchError, repeat } from "rxjs/operators";
import { of } from "rxjs";
import moment from "moment";

import * as types from "./actionTypes";
import SearchService from "services/search";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";
import { getUserProfile } from "redux/profile/selectors";
import ProfileFields from "consts/profileFields";
import { getPitId, getSearchAfter } from "./selectors";
import PublicProfileService from "services/publicProfile";

export const fetchViewsUsers = (action$, store) =>
	action$.pipe(
		ofType(types.VIEWS_USERS_FETCH, types.VIEWS_USERS_FETCH_LOAD_MORE),
		mergeMap(async () => {
			const state = store.value;
			const profile = getUserProfile(state);
			const pitId = getPitId(state);
			const searchAfter = getSearchAfter(state);
			try {
				const payload = await SearchService.search(
					pitId,
					searchAfter,
					{ userLocation: profile.location || profile[ProfileFields.CityLoc] },
					ElasticSortOptions.SortByViewTimeStampDESC,
					SearchQueryBuilderType.Views
				);

				payload.users = {};
				payload.items.map((user) => (payload.users[user.uid] = user));

				return {
					type: !pitId
						? types.VIEWS_USERS_FETCH_SUCCESSFULLY
						: types.VIEWS_USERS_FETCH_LOAD_MORE_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.VIEWS_USERS_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);

export const fetchUserProfileForViewsAdded = (action$, store) =>
	action$.pipe(
		ofType(types.VIEWS_ADDED),
		mergeMap(async ({ payload }) => {
			const uid = Object.keys(payload)[0];
			const user = await PublicProfileService.fetchProfile(uid);
			user.viewed_me_timeStamp = moment.utc(payload[uid].timeStamp).format("YYYYMMDDhhmmss");

			return {
				type: types.VIEWS_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY,
				user,
			};
		}),
		catchError((error) => {
			return of({
				type: types.VIEWS_ADDED_FETCH_USER_PROFILE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
