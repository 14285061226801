export default ({ length, selected }) => {
  return (
    <div className="imageTapIndicator">
      <div className="dotsContainer">
        {length > 1 &&
          new Array(length)
            .fill(null)
            .map((_, i) => (
              <div className={`dot ${i === selected ? "selected" : ""}`}></div>
            ))}
      </div>
    </div>
  );
};
