import i18next from "i18next";

import DetailsAreaContainer from "./DetailsAreaContainer";
import FilterSettings from "./AdditionalSettings/ViewFiltersSettings";
import CommunicationSettings from "./AdditionalSettings/CommunicationSettings";
import NotificationsSettings from "./AdditionalSettings/NotificationsSettings";
import LanguageSwitch from "./AdditionalSettings/LanguageSwitch";

export default () => {

  return  <DetailsAreaContainer title={`${i18next.t('Pages.System Settings')} - ${i18next.t('Pages.Additional Settings')}`}>
              <FilterSettings/>
              <CommunicationSettings/>
              <NotificationsSettings/>
              <LanguageSwitch/>
          </DetailsAreaContainer>
};
