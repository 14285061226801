import { Modal, Button } from "reactstrap";

import usePopups from "hooks/usePopups";
import Contact from "components/account/Contact";
import SubjectsTypes from "consts/subjectsTypes";

export default () => {
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="bigSlideDialog ContactFeedbackModal">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<div className="ContactModalContainer">
				<Contact subject={SubjectsTypes.General} />
			</div>
		</Modal>
	);
};
