import { useState, useEffect } from "react";

import usePopups from "hooks/usePopups";
import CropImageTypes from "consts/cropImageTypes";
import getImageBase64FromURl from "helpers/getImageBase64FromURl";
import useProfileImages from "hooks/useProfileImages";
import profileFields from "consts/profileFields";
import { isIOS, isMobile } from "react-device-detect";

export default ({ onClick, refInput, handleSaveNewImage, style = {}, urlForAutoOpening }) => {
	const MAX_SIZE_5MB = 1024 * 1024 * 5;
	const MIN_WIDTH_IMAGE = 400;
	const MIN_HEIGHT_IMAGE = 482;

	const [upImg, setUpImg] = useState(null);
	const [openCrop, setOpenCrop] = useState(1);
	const { showCropImagePopup } = usePopups();
	const { showBigImagePopup, showLowQualityImage } = usePopups();
	const { saveNewImage } = useProfileImages();

	useEffect(() => {
		if (!upImg) return;

		showCropImagePopup({
			image: upImg,
			cropImageType: CropImageTypes.NewImage,
			handleSaveNewImage,
		});
	}, [openCrop]);

	useEffect(async () => {
		if (!urlForAutoOpening) return;
		setUpImg(await getImageBase64FromURl(urlForAutoOpening));
		setOpenCrop(openCrop + 1);
	}, []);

	const onSelectFileInput = (e) => {
		if (!(e.target.files && e.target.files.length > 0)) return;
		// const reader = new FileReader();
		// reader.readAsDataURL(e.target.files[0]);
		// reader.addEventListener("load", () => {

		if ((e.target.files[0]?.size || 0) >= MAX_SIZE_5MB) {
			return showBigImagePopup();
		}

		if (!!isMobile && !!isIOS) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const base64String = reader.result.replace(/^data:image\/(png|jpg);base64,/, "");

				const img = new Image();
				img.onload = () => {
					if (img.naturalWidth < MIN_WIDTH_IMAGE || img.naturalHeight < MIN_HEIGHT_IMAGE) {
						return showLowQualityImage();
					} else {
						if (!!handleSaveNewImage) handleSaveNewImage(base64String);
						return saveNewImage(profileFields.Pictures, base64String);
					}
				};
				img.src = base64String;
			});
			reader.readAsDataURL(e.target.files[0]);

			return (e.target.value = "");
		}

		const data = URL.createObjectURL(e.target.files[0]);
		setUpImg(data);
		setOpenCrop(openCrop + 1);
		e.target.value = "";
	};

	return (
		<input
			style={style}
			ref={refInput}
			className=" empty"
			accept="image/*"
			id="contained-button-file"
			type="file"
			onChange={onSelectFileInput}
			onClick={onClick}
		/>
	);
};
