import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.BLOCKS_FETCH_SUCCESSFULLY:
			return {
				...Object.keys(action.payload).reduce((value, key) => {
					value[key] = {
						...action.payload[key],
						isBlock: true,
					};
					return value;
				}, {}),
			};
		case types.BLOCKS_ADDED:
			return { ...state, ...{ ...action.payload } };
		case types.BLOCKS_BLOCKED_ME_FETCH_SUCCESSFULLY:
			return {
				...action.payload,
				...state,
			};
		case types.BLOCKS_BLOCKED_ME_BY_USER_ADDED:
			return {
				...state,
				...action.payload,
			};
		case types.BLOCKS_BLOCK_USER_SUCCESSFULLY:
			return {
				...state,
				[action.user]: { ...action.payload, isBlock: true },
			};
		case types.BLOCKS_UNBLOCK_USER_SUCCESSFULLY:
			if (state[action?.user?.uid]) delete state[action.user.uid];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
