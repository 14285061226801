import i18next from "i18next";
import { useRef } from "react";

import DetailsAreaContainer from "./DetailsAreaContainer";
import useProfileImages from "hooks/useProfileImages";
import ImageBox from "./EditImages/ImageBox";
import usePopups from "hooks/usePopups";
import PicturesPrivateSwitch from "./PicturesPrivateSwitch";

export default () => {
	const { showDeleteImagePopup } = usePopups();
	const { images, setMainImage, deleteProfileImage } = useProfileImages();
	const refLastEmptyImageInput = useRef();
	const imageBoxCount = Math.max(~~((images.length + 1) / 3) * 3 + 3, 9);

	const _setMainImage = (index) => setMainImage(images[index].key);

	const cleanRejectedImages = () => {
		images.map((img) => img.rejected && deleteProfileImage(img.key));
	};

	return (
		<>
			<DetailsAreaContainer title={i18next.t("Pages.Edit Pictures")} className="wrapAllImages">
				{new Array(imageBoxCount).fill().map((_, index) => (
					<ImageBox
						{...images[index]}
						index={index}
						setMainImage={_setMainImage}
						onDeleteImage={() => showDeleteImagePopup(images[index].key)}
						cleanRejectedImages={cleanRejectedImages}
						refLastEmptyImageInput={refLastEmptyImageInput}
						lastEmptyImageIndex={imageBoxCount - 1}
					/>
				))}
				<div className="wrapSwitchForEdirImages">
					<PicturesPrivateSwitch />
				</div>
			</DetailsAreaContainer>
		</>
	);
};
