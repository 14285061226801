import { useState, useEffect } from "react";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";
import TextArea from "components/TextArea";
import MaritalStatusMulti from "components/search/SearchFiltersInputs/MaritalStatusMulti";
import StanceMulti from "components/search/SearchFiltersInputs/StanceMulti";
import AgeRangeInputs from "components/AgeRangeInputs";

import MassLetterKeys from "consts/massLetterKeys";
import { getUserProfile } from "redux/profile/selectors";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { sendMassLetter, cancelMassLetter } from "redux/massLetter/actions";
import { updateMassletterShowCount } from "redux/general/actions";

export default () => {
	const dispatch = useDispatch();
	const profile = useSelector(getUserProfile);
	const loggedInUid = useSelector(getLoggedInUserUID);
	const { showMassletterSentPopup } = usePopups();
	const [filters, setFilters] = useState({ message: "" });

	const { closePopup } = usePopups();

	const handleSend = () => {
		dispatch(sendMassLetter(filters));
		dispatch(updateMassletterShowCount());
		closePopup();
		showMassletterSentPopup();
	};

	const handleCancel = () => {
		dispatch(cancelMassLetter());
		closePopup();
	};

	const handleChange = (key, value, key2, value2) => {
		const _filters = { ...filters, [key]: value };
		if (!!key2 && !!value2) {
			_filters[key2] = value2;
		}
		setFilters(_filters);
	};

	useEffect(() => {
		if (loggedInUid) {
			dispatch(updateMassletterShowCount());
		}
	}, [loggedInUid]);

	return (
		<Modal isOpen={true} toggle={handleCancel} className="simpleDialog spreadModal">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={handleCancel}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				<h4 className="mainTitle">{i18next.t(`Popups.Massletter.Title_${profile.gender}`)}</h4>
				<h4>{i18next.t("Popups.Massletter.Subtitle", { name: profile.name })}</h4>
			</ModalHeader>
			<ModalBody>
				<div className="wrapTxtArea">
					<TextArea
						rows={5}
						className="formIn"
						initValue={filters.text}
						onChange={(val) => handleChange(MassLetterKeys.Message, val)}
						placeholder=""
					/>
					<div
						className="placeholder1"
						attr={
							i18next.t(`Popups.Massletter.PlaceholderStart_${profile.gender}`) +
							"\n" +
							i18next.t(`Popups.Massletter.Placeholder_${profile.gender}`)
						}></div>
				</div>

				<div>{i18next.t("Popups.Massletter.Filter Title")}</div>
				<div className="wrapInputsOfCentered">
					<AgeRangeInputs
						onChange={(key, val) => {
							handleChange(MassLetterKeys.AgeTo, val.to, MassLetterKeys.AgeFrom, val.from);
						}}
						profile={profile}
						filter={{ from: filters[MassLetterKeys.AgeFrom], to: filters[MassLetterKeys.AgeTo] }}
					/>

					<MaritalStatusMulti
						onChange={(key, val) => handleChange(MassLetterKeys.MaritalStatus, val)}
						profile={profile}
						filter={filters[MassLetterKeys.MaritalStatus]}
					/>

					<StanceMulti
						onChange={(key, val) => handleChange(MassLetterKeys.Stance, val)}
						profile={profile}
						filter={filters[MassLetterKeys.Stance]}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="md"
					color="secondary"
					onClick={handleSend}
					className="btn-rounded "
					disabled={filters[MassLetterKeys.Message]?.trim().length < 2}>
					{i18next.t(`Send_${profile.gender}`)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
