import moment from "moment";
import { createSelector } from "reselect";
import { isNil } from "lodash";

import SubscriptionResetTime from "helpers/subscriptionResetTime";
import { getUserSubscriptionType } from "../subscription/selectors";
import { getSubscriptionLimits } from "../system/selectors";
import { getProfileGender, getProfileStance } from "../profile/selectors";
import Gender from "consts/gender";
import Stance from "consts/stance";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

import { getSkips } from "../skips/selectors";

const getLetPictures = (state) => state.letPictures;

const getLetPicturesData = createSelector(getLetPictures, (letPictures) => letPictures.data || {});

export const isLetPictures = (userUid) =>
  createSelector(getLetPicturesData, (letPicturesData) => {
    return !isNil(letPicturesData[userUid]);
  });

export const getPitId = createSelector(getLetPictures, (letPictures) => letPictures.pitId);

export const getSearchAfter = createSelector(
  getLetPictures,
  (letPictures) => letPictures.searchAfter
);

export const getIsLoadingMore = createSelector(
  getLetPictures,
  (letPictures) => letPictures.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(
  getLetPictures,
  (letPictures) => letPictures.hasMoreResults
);
