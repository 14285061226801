import _ from "lodash";
import { createSelector } from "reselect";

export const getMassletter = (state) => state.massletter;

export const getStatus = createSelector(getMassletter, (massLetter) => massLetter.apiStatus);

export const getLastMassLetterCreateDate = createSelector(
	getMassletter,
	(massLetter) => massLetter.last_massletter_create_date
);

export const getLastMassLetterCencelDate = createSelector(
	getMassletter,
	(massLetter) => massLetter.last_cencel_sequence_date
);

export const getOpenMassletterModal = createSelector(
	getMassletter,
	(massLetter) => massLetter.open_massletter_modal
);
