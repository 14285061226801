import React from "react";
import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import usePopups from "hooks/usePopups";

export default () => {
	const currentUser = useSelector(getUserProfile);
	const { showUserSuccessfullyBlockedPopup, showReportUserPopup, closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>
				<i className="ri-archive-line text-muted jsmartDrop jsmartReport bigIcon"></i>
				{i18next.t(`Report User Question ${currentUser.gender}`)}
			</ModalHeader>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={() => showReportUserPopup(null)}
					className="btn-rounded littleBtn"
				>
					{i18next.t("yes")}
				</Button>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={showUserSuccessfullyBlockedPopup}
					className="btn-rounded littleBtn"
				>
					{i18next.t("no")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
