import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useRef } from "react";

import Content from "views/Content";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import TopBar from "views/TopBar";
import ViewsCards from "components/search/ViewsCards";
import BottomStrip from "components/BottomStrip";
import isMobile from "helpers/isMobile";
import ViewsTopBar from "components/likedMe/ViewsTopBar";

export default () => {
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const ref = useRef();

	const scrollToTop = () => {
		ref.current?.scrollIntoView();
	};

	return isUserLoggedIn ? (
		<>
			<TopBar>
				<ViewsTopBar />
			</TopBar>
			<div className="viewedMePage" ref={ref}>
				<Content>
					<ViewsCards scrollToTop={scrollToTop} />
					{isMobile() && <BottomStrip />}
				</Content>
			</div>
		</>
	) : (
		<Loader />
	);
};
