import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {
	users: {},
	apiStatus: null,
	loadMoreStatus: null,
	freeTextFilter: null,
	pitId: null,
	searchAfter: null,
	hasMoreResults: true,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.MATCHES_FETCH:
			return {
				...state,
				apiStatus: API_REQUESTS_STATUS.PENDING,
				freeTextFilter: action.freeText,
				pitId: null,
				searchAfter: null,
				hasMoreResults: true,
			};
		case types.MATCHES_FETCH_LOAD_MORE:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.PENDING,
			};

		case types.MATCHES_FETCH_LOAD_MORE:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.PENDING,
			};

		case types.MATCHES_FETCH_SUCCESSFULLY:
			return {
				...state,
				users: { ...action.payload.users, ...state.users },
				apiStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
				lastSortOption: action.payload.lastSortOption,
			};
		case types.MATCHES_FETCH_LOAD_MORE_SUCCESSFULLY:
			return {
				...state,
				users: { ...action.payload.users, ...state.users },
				loadMoreStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
				lastSortOption: action.payload.lastSortOption,
			};
		case types.MATCHES_FETCH_FAILED:
			return {
				...state,
				apiStatus: API_REQUESTS_STATUS.FAILED,
			};
		case types.MATCHES_FETCH_LOAD_MORE_FAILED:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.FAILED,
			};
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
