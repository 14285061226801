import { useState } from "react";

import Question from "./Question";
import LivingCity from "components/account/ProfileInputs/LivingCity";

export default ({
	title,
	subTitle,
	onSubmit,
	onBackButtonClick,
	showBackButton,
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();

	const handleOnChange = (...val) => {
		setValue(val);
    setIsValid(true);
	};

	const handleOnSubmit = () => {
		onSubmit(value);
	};

	return (
		<Question
			isValid={isValid}
			title={title}
			subTitle={subTitle}
			onSubmit={handleOnSubmit}
			showBackButton={showBackButton}
			onBackButtonClick={onBackButtonClick}>
			<LivingCity onChange={handleOnChange} hideLabel={true}/>
		</Question>
	);
};
