import { useState, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import isMobile from "helpers/isMobile";

const MIN_WIDTH_IMAGE = 400;
const MIN_HEIGHT_IMAGE = 482;
const IMAGE_HEIGHT = 100;

export default ({ upImg, setFile, isValidSize, setIsValidSize, setIsSelectedFile }) => {
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const [crop, setCrop] = useState({
		unit: "px",
		aspect: MIN_WIDTH_IMAGE / MIN_HEIGHT_IMAGE,
	});
	const [completedCrop, setCompletedCrop] = useState(null);
	const [minWidthCrop, setMinWidthCrop] = useState();
	const [minHeightCrop, setMinHeightCrop] = useState();
	const [isLoadedNow, setIsLoadedNow] = useState(true);

	const CalcCropDimensions = (img) => {
		const ratioWidth = img.width / img.naturalWidth;
		const ratioHeight = img.height / img.naturalHeight;
		setMinWidthCrop(MIN_WIDTH_IMAGE * ratioWidth);
		setMinHeightCrop(MIN_HEIGHT_IMAGE * ratioHeight);
	};

	useEffect(() => {
		const noX = crop.width && !crop.x;
		const noY = crop.height && !crop.y;
		if (isMobile() && isLoadedNow && (noX || noY)) {
			setCrop((oldVal) => ({
				...oldVal,
				...(noX
					? { x: (imgRef.current.width - crop.width) / 2 }
					: { y: (imgRef.current.height - crop.height) / 2 }),
			}));
			setIsLoadedNow(false);
		}
	}, [crop]);

	const onLoad = (img) => {
		imgRef.current = img;
		!isMobile() && setCrop((oldVal) => ({ ...oldVal, height: img.height }));
		isMobile() &&
			setCrop((v) => ({
				...v,
				...(img.height < img.width
					? { height: img.height * 0.9, y: 0.1 * img.height * 0.5 }
					: { width: img.width * 0.9, x: 0.1 * img.width * 0.5 }),
			}));
		setIsSelectedFile(true);
		setIsLoadedNow(true);
		setIsValidSize(
			img.naturalWidth < MIN_WIDTH_IMAGE || img.naturalHeight < MIN_HEIGHT_IMAGE ? false : true
		);
		CalcCropDimensions(img);
		return false;
	};

	const handleSetCrop = (crop) => {
		if (crop.height !== 0 && crop.width !== 0) setCrop(crop);
	};

	useEffect(() => {
		setIsValidSize(true);
	}, [upImg]);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) return;
		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext("2d");
		const pixelRatio = 1; // window.devicePixelRatio;
		canvas.width = Math.round(crop.width * pixelRatio * scaleX);
		canvas.height = Math.round(crop.height * pixelRatio * scaleY);
		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = "high";
		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);
		const base64Image = canvas.toDataURL("image/jpeg");
		setFile(base64Image);
	}, [completedCrop]);

	return (
		<div className="App">
			{upImg && isValidSize && (
				<ReactCrop
					src={upImg}
					onImageLoaded={onLoad}
					crop={crop}
					onChange={handleSetCrop}
					onComplete={(c) => setCompletedCrop(c)}
					minWidth={minWidthCrop}
					minHeight={minHeightCrop}
					imageStyle={{ height: IMAGE_HEIGHT + "%" }}
				/>
			)}
			<canvas
				ref={previewCanvasRef}
				style={{
					display: "none",
					width: Math.round(completedCrop?.width ?? 0),
					height: Math.round(completedCrop?.height ?? 0),
				}}
			/>
		</div>
	);
};
