import React from "react";

import classNames from "classnames";
import SideBarHeader from "./SideBar/SideBarHeader";
import SideBarBody from "./SideBar/SideBarBody";
import SideBarFooter from "./SideBar/SideBarFooter";

const SideBar = ({ isOpen = false }) => (
	<div className={classNames("sidebar", { "is-open": isOpen })}>
		<SideBarHeader />
		<SideBarBody />
		<SideBarFooter />
	</div>
);

export default SideBar;
