import Cookies from "js-cookie";

class LocalStorageService {
  set(key, value, expires = 365) {
    Cookies.set(key, value, { expires });
  }
  get(key) {
    return Cookies.get(key);
  }
  remove(key) {
    Cookies.remove(key);
  }
}

export default new LocalStorageService();
