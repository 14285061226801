export const AUTH_APP_INIT = "auth.AUTH_APP_INIT";
export const AUTH_LOGGED_IN = "auth.AUTH_LOGGED_IN";
export const AUTH_LOGGED_OUT = "auth.AUTH_LOGGED_OUT";
export const AUTH_LOG_OUT = "auth.AUTH_LOG_OUT";
export const AUTH_LOGIN_WITH_PHONE = "auth.AUTH_LOGIN_WITH_PHONE";
export const AUTH_LOGIN_WITH_TOKEN = "auth.AUTH_LOGIN_WITH_TOKEN";
export const AUTH_LOGIN_WITH_TOKEN_SUCCESSFULLY = "auth.AUTH_LOGIN_WITH_TOKEN_SUCCESSFULLY";

export const AUTH_LOGIN_WITH_PHONE_RESET = "auth.AUTH_LOGIN_WITH_PHONE_RESET";
export const AUTH_LOGIN_WITH_PHONE_FAILED = "auth.AUTH_LOGIN_WITH_PHONE_FAILED";
export const AUTH_LOGIN_WITH_PHONE_WAITING_FOR_VERIFICATION =
	"auth.AUTH_LOGIN_WITH_PHONE_WAITING_FOR_VERIFICATION";

export const AUTH_LOGIN_WITH_PHONE_VERIFICATION = "auth.AUTH_LOGIN_WITH_PHONE_VERIFICATION";
export const AUTH_LOGIN_WITH_PHONE_VERIFICATION_SUCCESSFULLY =
	"auth.AUTH_LOGIN_WITH_PHONE_VERIFICATION_SUCCESSFULLY";
export const AUTH_LOGIN_WITH_PHONE_VERIFICATION_FAILED =
	"auth.AUTH_LOGIN_WITH_PHONE_VERIFICATION_FAILED";

export const AUTH_LOGIN_WITH_PHONE_RESEND_CODE = "auth.AUTH_LOGIN_WITH_PHONE_RESEND_CODE";
export const AUTH_LOGIN_WITH_PHONE_RESEND_CODE_FAILED =
	"auth.AUTH_LOGIN_WITH_PHONE_RESEND_CODE_FAILED";
export const AUTH_LOGIN_WITH_PHONE_RESEND_CODE_SUCCESSFULLY =
	"auth.AUTH_LOGIN_WITH_PHONE_RESEND_CODE_SUCCESSFULLY";

export const AUTH_ALREADY_LOGGED_IN = "auth.AUTH_ALREADY_LOGGED_IN";

export const AUTH_CHANGE_FAILED = "auth.AUTH_CHANGE_FAILED";

export const AUTH_LOGIN_SET_CHECKED = "auth.AUTH_LOGIN_SET_CHECKED";

export const AUTH_REGISTER_WITH_FACEBOOK = "auth.AUTH_REGISTER_WITH_FACEBOOK";
export const AUTH_REGISTER_WITH_FACEBOOK_SUCCESSFULLY =
	"auth.AUTH_REGISTER_WITH_FACEBOOK_SUCCESSFULLY";

export const AUTH_LOGIN_WITH_FACEBOOK_FAILED = "auth.AUTH_LOGIN_WITH_FACEBOOK_FAILED";

export const AUTH_MIGRATION_NO_USER = "auth.AUTH_MIGRATION_NO_USER";

export const AUTH_REGISTER_WITH_GOOGLE = "auth.AUTH_REGISTER_WITH_GOOGLE";
export const AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY = "auth.AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY";
export const AUTH_REGISTER_WITH_GOOGLE_FAILED = "auth.AUTH_REGISTER_WITH_GOOGLE_FAILED";

export const AUTH_REGISTER_WITH_APPLE = "auth.AUTH_REGISTER_WITH_APPLE";
export const AUTH_REGISTER_WITH_APPLE_SUCCESSFULLY = "auth.AUTH_REGISTER_WITH_APPLE_SUCCESSFULLY";
export const AUTH_REGISTER_WITH_APPLE_FAILED = "auth.AUTH_REGISTER_WITH_APPLE_FAILED";

export const AUTH_HOTJAR_INIT_SUCCESSFULLY = "auth.AUTH_HOTJAR_INIT_SUCCESSFULLY";
export const AUTH_HOTJAR_INIT_FAILED = "auth.AUTH_HOTJAR_INIT_FAILED";

export const AUTH_LOGIN_WITH_PASSWORD_SUCCESSFULLY = "auth.AUTH_LOGIN_WITH_PASSWORD_SUCCESSFULLY";
export const AUTH_LOGIN_WITH_PASSWORD_FAILED = "auth.AUTH_LOGIN_WITH_PASSWORD_FAILED";

export const AUTH_LOG_AUTH_ERROR_SENT_SUCCESSFULLY = "auth.AUTH_LOG_AUTH_ERROR_SENT_SUCCESSFULLY";
export const AUTH_LOG_AUTH_ERROR_SENT_FAILED = "auth.AUTH_LOG_AUTH_ERROR_SENT_FAILED";

export const EMAIL_SKIPED = "auth.EMAIL_SKIPED";
export const IMAGE_SKIPED = "auth.IMAGE_SKIPED";
