import i18next from "i18next";
import { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";

export default ({ field, onChange, initValue = "", title, type = {}, disabled = false }) => {
	const [value, setValue] = useState(initValue);

	const onNext = (text) => {
		setValue(text);
		onChange(field, text);
	};

	return (
		<Form inline>
			<FormGroup>
				<Label for={field}>{title}</Label>
				<Input
					id={field}
					value={value}
					disabled={disabled}
					onChange={(e) => onNext(e.target.value)}
					placeholder={i18next.t("Type")}
					type="text"
				/>
			</FormGroup>
		</Form>
	);
};
