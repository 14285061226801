import "assets/scss/custom/components/email.scss";
import i18next from "i18next";
import { Modal, Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import Email from "components/account/AccountSettings/Email";
import { useState } from "react";
import { Box } from "@mui/system";
import TextInput from "components/TextInput";
import DoneIcon from "@mui/icons-material/Done";
import profileFields from "consts/profileFields";

export default () => {
	const { closePopup } = usePopups();
	const loggedInUserProfile = useSelector(getUserProfile);
	const [profile, setProfile] = useState(loggedInUserProfile);

	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className="simpleDialog"
			style={{ maxWidth: "450px", padding: "0 15px" }}>
			<Box className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</Box>
			<Box className="mb-3 wrapEmailPopup" style={{ padding: "0 3rem" }}>
				{/* {!profile?.email ? <Header profile={profile} /> : <HeaderWithEmail profile={profile} />} */}
				<HeaderWithEmail profile={profile} />
				{!!profile && (
					<Box className="wrapMailVerified" style={{ margin: "10px 0", position: "relative" }}>
						<Email profile={profile} />
					</Box>
				)}
				{!profile?.email && (
					<span
						style={{
							color: "#8b8b8d",
							fontSize: "0.8rem",
						}}>
						{i18next.t("email.acceptTerms")}
					</span>
				)}
			</Box>
		</Modal>
	);
};

const Header = ({ profile }) => {
	return (
		<Box className="wrapTitle">
			<Box>{i18next.t("email.Hi", { name: profile?.name })},</Box>
			<Box>{i18next.t("email.subtitle")}</Box>
		</Box>
	);
};

const HeaderWithEmail = ({ profile }) => {
	return (
		<Box className="wrapMailVerified">
			<Box className="editMail">
				<Box>{i18next.t("email.editMail")}</Box>
			</Box>
			{!!profile && (
				<div className="wrapInputValidation" style={{ position: "relative" }}>
					<TextInput
						field={profileFields.Email}
						initValue={profile?.email || ""}
						title={i18next.t("email.current")}
						disabled={true}
					/>
					{!!profile?.email && (
						<span
							style={{ position: "absolute", left: "0.5rem", top: "50px", width: "min-content" }}>
							{<DoneIcon sx={{ color: "green" }} />}
						</span>
					)}
				</div>
			)}
		</Box>
	);
};
