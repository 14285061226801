import i18next from "i18next";
import EmptyImage from "./ImageTypes/EmptyImage";
import RejectedImage from "./ImageTypes/RejectedImage";
import Image from "./Image";

export default ({ url, isMain, rejected, pending, isNewImageInProcess, ...rest }) => {
	if (!url && !isNewImageInProcess) {
		return <EmptyImage {...rest} />;
	}
	if (rejected) {
		return <RejectedImage url={url} {...rest} className={isMain ? "mainImg" : ""} />;
	}

	let className = "";
	pending && (className = "waiting");
	isMain && (className += " mainImg");
	const header = pending && (
		<h6 className="waitText">{i18next.t("Edit Pictures.Pending Image")}</h6>
	);

	return (
		<Image
			className={className}
			header={header}
			url={url}
			isNewImageInProcess={isNewImageInProcess}
			{...rest}
		/>
	);
};
