import i18next from "i18next";

import imgUserEmpty from "assets/images/profilesImages/empty.png";
import UploadImageInput from "../UploadImageInput";

export default ({
  refLastEmptyImageInput,
  lastEmptyImageIndex,
  index,
  cleanRejectedImages,
}) => {
  return (
    <div className="wrapOneImage empty" onClick={cleanRejectedImages}>
      <span>{i18next.t("Edit Pictures.Add Image")}</span>
      <UploadImageInput
        refInput={index === lastEmptyImageIndex ? refLastEmptyImageInput : null}
      />
      <img src={imgUserEmpty} />
    </div>
  );
};
