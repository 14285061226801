import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	viewViewedMeList,
	fetchViewedMeUsersLoadMore,
	fetchViewedMeUsers,
} from "redux/viewedMe/actions";
import CardsView from "components/CardsView";
import { getHasMoreResults, getIsLoadingMore, getViewedMeStatus, getViewedMeUsers } from "redux/viewedMe/selectors";
import API_REQUEST_STATUS from "consts/apiRequestStatus";
import Loader from "components/Loader";

export default ({ scrollToTop }) => {
	const users = useSelector(getViewedMeUsers);
	const dispatch = useDispatch();
	const isLoadingMore = useSelector(getIsLoadingMore);
	const hasMoreResults = useSelector(getHasMoreResults);
	const status = useSelector(getViewedMeStatus);

	const fetchData = () => {
		if (!isLoadingMore && hasMoreResults) {
			dispatch(fetchViewedMeUsersLoadMore());
		}
	};

	useEffect(() => {
		dispatch(fetchViewedMeUsers());
		scrollToTop();
		dispatch(viewViewedMeList());
	}, []);

	return (
		<InfiniteScroll dataLength={users ? users.length : 0} next={fetchData} hasMore={true}>
			{status === API_REQUEST_STATUS.SUCCESSFULLY ? (
				<CardsView
					users={users}
					addViewsTime //views timestamp of collage user is equal to viewed_me timestamp
					viewedMePage
					className="wrapViewedMeCards"
				/>
			) : (
				<Loader className="loaderBeforeResults" />
			)}
		</InfiniteScroll>
	);
};
