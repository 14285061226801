import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import currentLanguage from "./helpers/currentLanguage";
import lang from "./locales/lang";

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (cb) => {
    let lang = currentLanguage();
    cb(lang);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "he", // use he if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en: {
        translation: lang.en,
      },
      he: {
        translation: lang.he,
      },
    },
  });

export default i18n;
