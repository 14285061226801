import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
  const { closePopup } = usePopups();
  const { onDeleteChats } = useSelector(getPopups);
  const loggedInUserProfile = useSelector(getUserProfile);

  const handleOk = () => {
    onDeleteChats();
    closePopup();
  };

  return (
    <Modal isOpen toggle={closePopup} className="simpleDialog">
      <ModalHeader>
        {i18next.t(`Chat.Are you sure_${loggedInUserProfile.gender}`)}
      </ModalHeader>
      <ModalFooter>
        <Button
          outline
          size="sm"
          color="primary"
          onClick={handleOk}
          className="btn-rounded littleBtn"
        >
          {i18next.t("yes")}
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={closePopup}
          className="btn-rounded littleBtn"
        >
          {i18next.t("no")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
