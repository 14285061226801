import produce from 'immer';

import * as types from './actionTypes';

const initialState = {
  users: {},
};

export default produce((state , action) => {
  switch (action.type) {
    case types.PRESENCE_FETCH_SUCCESSFULLY:
      return { 
        ...state , 
        users: action.payload || {},
      };
    case types.PRESENCE_CHILD_ADDED:
      state.users[action.payload] = true;
      break;
    case types.PRESENCE_CHILD_REMOVED:
      delete state.users[action.payload];
      break;
  }
} , initialState);