const LoginSteps = {
	Social: "Social",
	Name: "Name",
	Gender: "Gender",
	Age: "Age",
	MartialStatus: "MartialStatus",
	Children: "Children",
	ChildrenCount: "ChildrenCount",
	Stance: "Stance",
	KeepShabbat: "KeepShabbat",
	KosherFood: "KosherFood",
	Height: "Height",
	AboutMe: "AboutMe",
	LivingCity: "LivingCity",
	UploadImage: "UploadImage",
	Email: "Email",
};

export default LoginSteps;

export const LoginStepsMap = {
	[LoginSteps.Social]: 1,
	[LoginSteps.Name]: 2,
	[LoginSteps.Gender]: 3,
	[LoginSteps.Age]: 4,
	[LoginSteps.MartialStatus]: 5,
	[LoginSteps.Children]: 6,
	[LoginSteps.ChildrenCount]: 7,
	[LoginSteps.Stance]: 8,
	[LoginSteps.KeepShabbat]: 9,
	[LoginSteps.KosherFood]: 10,
	[LoginSteps.Height]: 11,
	[LoginSteps.AboutMe]: 12,
	[LoginSteps.LivingCity]: 13,
	[LoginSteps.UploadImage]: 14,
	[LoginSteps.Email]: 15,
};
