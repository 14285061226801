import { useState, useEffect } from "react";

import MultirangeInputs from "./MultirangeInputs";
import MultirangeVisibleSlider from "./MultirangeVisibleSlider";

export default ({ min, max, step = 1, onChange, initMin, initMax }) => {
  const [minVal, setMinVal] = useState(initMin || min);
  const [maxVal, setMaxVal] = useState(initMax || max);
  const [minThumbState, setMinThumbState] = useState({});
  const [maxThumbState, setMaxThumbState] = useState({});

  const handleMinChange = (e) => {
    const value = e.target.value;
    setMinVal(value);
  };
  const handleMaxChange = (e) => {
    const value = e.target.value;
    setMaxVal(value);
  };
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  useEffect(() => {
    setMinVal(initMin);
  }, [initMin]);

  useEffect(() => {
    setMaxVal(initMax);
  }, [initMax]);

  const addMinThumbState = (action) => {
    setMinThumbState((prev) => ({
      ...prev,
      [action]: action,
    }));
  };
  const removeMinThumbState = (action) => {
    setMinThumbState((prev) => {
      const prevState = { ...prev };
      delete prevState[action];
      return prevState;
    });
  };
  const addMaxThumbState = (action) => {
    setMaxThumbState((prev) => ({
      ...prev,
      [action]: action,
    }));
  };
  const removeMaxThumbState = (action) => {
    setMaxThumbState((prev) => {
      const prevState = { ...prev };
      delete prevState[action];
      return prevState;
    });
  };

  return (
    <div className="multi-range-slider">
      <div>
        <MultirangeInputs
          min={min}
          max={max}
          step={step}
          minVal={minVal}
          maxVal={maxVal}
          onMinChange={handleMinChange}
          onMaxChange={handleMaxChange}
          OnMinInputMouseOver={() => addMinThumbState("hover")}
          OnMinInputMouseOut={() => removeMinThumbState("hover")}
          OnMinInputMouseDown={() => addMinThumbState("active")}
          OnMinInputMouseUp={() => removeMinThumbState("active")}
          OnMaxInputMouseOver={() => addMaxThumbState("hover")}
          OnMaxInputMouseOut={() => removeMaxThumbState("hover")}
          OnMaxInputMouseDown={() => addMaxThumbState("active")}
          OnMaxInputMouseUp={() => removeMaxThumbState("active")}
        />
        <MultirangeVisibleSlider
          min={min}
          max={max}
          minVal={minVal}
          maxVal={maxVal}
          minThumbState={minThumbState}
          maxThumbState={maxThumbState}
        />
      </div>
    </div>
  );
};
