export const LIKED_ME_VIEW_LIST = "likedMe.LIKED_ME_VIEW_LIST";
export const LIKED_ME_FETCH_SUCCESSFULLY =
  "likedMe.LIKED_ME_FETCH_SUCCESSFULLY";
export const LIKED_ME_FETCH_FAILED = "likedMe.LIKED_ME_FETCH_FAILED";

export const LIKED_ME_ADDED = "likedMe.LIKED_ME_ADDED";
export const LIKED_ME_REMOVED = "likedMe.LIKED_ME_REMOVED";
export const LIKED_ME_ADDED_ERROR = "likedMe.LIKED_ME_ADDED_ERROR";
export const LIKED_ME_REMOVED_ERROR = "likedMe.LIKED_ME_REMOVED_ERROR";

export const LIKED_ME_USERS_FETCH = "likedMe.LIKED_ME_USERS_FETCH";
export const LIKED_ME_USERS_FETCH_LOAD_MORE =
  "likedMe.LIKED_ME_USERS_FETCH_LOAD_MORE";
export const LIKED_ME_USERS_FETCH_SUCCESSFULLY =
  "likedMe.LIKED_ME_USERS_FETCH_SUCCESSFULLY";
export const LIKED_ME_USERS_FETCH_LOAD_MORE_SUCCESSFULLY =
  "likedMe.LIKED_ME_USERS_FETCH_LOAD_MORE_SUCCESSFULLY";
export const LIKED_ME_USERS_FETCH_FAILED =
  "likedMe.LIKED_ME_USERS_FETCH_FAILED";
export const LIKED_ME_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY =
  "likedMe.LIKED_ME_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY";
export const LIKED_ME_ADDED_FETCH_USER_PROFILE_FAILED =
  "likedMe.LIKED_ME_ADDED_FETCH_USER_PROFILE_FAILED";
export const LIKED_ME_CHANGED = "likedMe.LIKED_ME_CHANGED";
export const LIKED_ME_CHANGED_ERROR = "likedMe.LIKED_ME_CHANGED_ERROR";
export const LIKED_ME_VIRTUAL_DELETE = "likedMe.LIKED_ME_VIRTUAL_DELETE";
