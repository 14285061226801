import firebase from "firebase/app";

let firebaseConfig = {};
if (process.env.REACT_APP_ENV === "jsmart") {
	firebaseConfig = {
		apiKey: "AIzaSyCCMmm5tOL_G3ShOzBhmIA6yCnoyeeAtOw",
		authDomain: "jsmart-app.firebaseapp.com",
		databaseURL: "https://jsmart-app-default-rtdb.firebaseio.com",
		projectId: "jsmart-app",
		storageBucket: "jsmart-app.appspot.com",
		messagingSenderId: "571471787168",
		appId: "1:571471787168:web:5e7a3ea70d20e48a4905c5",
		measurementId: "G-JXFVBE77HY",
	};
} else if (process.env.REACT_APP_ENV === "jsmartDev") {
	firebaseConfig = {
		apiKey: "AIzaSyAknaMZssQLW1x3_omvGs07uvjQzufTD20",
		authDomain: "doovdevan-dev.firebaseapp.com",
		databaseURL: "https://doovdevan-dev.firebaseio.com",
		projectId: "doovdevan-dev",
		storageBucket: "doovdevan-dev.appspot.com",
		messagingSenderId: "347119642460",
		appId: "1:347119642460:web:520b62b88acb02fb",
	};
} else if (process.env.REACT_APP_ENV === "shlish") {
	firebaseConfig = {
		apiKey: "AIzaSyDAEjHR5UuYY7bwrD5GMV-mY0i_G3ooL9Q",
		authDomain: "shlish-friends.firebaseapp.com",
		databaseURL: "https://shlish-friends.firebaseio.com",
		projectId: "shlish-friends",
		storageBucket: "shlish-friends.appspot.com",
		messagingSenderId: "351632630732",
		appId: "1:351632630732:web:def5995585894abc213369",
		measurementId: "G-GYLH8EVD76",
	};
}

console.log(process.env.REACT_APP_ENV);

const isProductionEnviroment = process.env.NODE_ENV === "production";

firebase.initializeApp(firebaseConfig);
