import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import Kosher from "consts/kosher";
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, value) => onChange(key, Number(value));

    return <DropDown
                field={ProfileFields.Kosher} 
                fieldOptions={Object.values(Kosher)} 
                onChange={_onChange} 
                initSelected={profile.kosher}
                title={i18next.t('Edit Profile.Kosher')}
                translatePrefix="Kosher"
            />
};
