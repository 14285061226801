export const CHAT_RECEIVED_FETCH_SUCCESSFULLY = "chatReceived.CHAT_RECEIVED_FETCH_SUCCESSFULLY";
export const CHAT_RECEIVED_FETCH_FAILED = "chatReceived.CHAT_RECEIVED_FETCH_FAILED";
export const CHAT_RECEIVED_UPDATED = "chatReceived.CHAT_RECEIVED_UPDATED";
export const CHAT_RECEIVED_UPDATED_ERROR = "chatReceived.CHAT_RECEIVED_UPDATED_ERROR";
export const CHAT_RECEIVED_ADDED = "chatReceived.CHAT_RECEIVED_ADDED";
export const CHAT_RECEIVED_ADDED_ERROR = "chatReceived.CHAT_RECEIVED_ADDED_ERROR";
export const CHAT_RECEIVED_REMOVED = "chatReceived.CHAT_RECEIVED_REMOVED";
export const CHAT_RECEIVED_REMOVED_ERROR = "chatReceived.CHAT_RECEIVED_REMOVED_ERROR";
export const CHAT_RECEIVED_VIRTUAL_DELETE_CHAT = "chatReceived.CHAT_RECEIVED_VIRTUAL_DELETE_CHAT";
export const CHAT_RECEIVED_CANCEL_DELETE_CHAT = "chatReceived.CHAT_RECEIVED_CANCEL_DELETE_CHAT";
export const UPDATE_MORE_NUMBER = "chatReceived.UPDATE_MORE_NUMBER";
export const RESET_CHAT_RECEIVED_USERS = "chatReceived.RESET_CHAT_RECEIVED_USERS";
export const RESET_CHAT_RECEIVED_USERS_SUCCESSFULLY =
	"chatReceived.RESET_CHAT_RECEIVED_USERS_SUCCESSFULLY";
