import i18next from "i18next";
import { Button } from "reactstrap";

export default ({ onClick, options, translatePrefix }) => {
  return (
    <div className="buttonGroup">
      {options.map((item) => (
        <Button onClick={() => onClick(item)} size="sm" outline color="primary">
          {i18next.t(`${translatePrefix}.${item}`)}
        </Button>
      ))}
    </div>
  );
};
