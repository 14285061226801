import { mergeMap, catchError, map, repeat, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import SkipsService from "services/skips";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchSkips = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(async () => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      try {
        const payload = await SkipsService.fetchSkipped(loggedInUserUID);
        return {
          type: types.SKIPPED_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.SKIPPED_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.SKIPPED_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchSkipAdded = (action$, store) =>
  action$.pipe(
    ofType(types.SKIPPED_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return SkipsService.listenSkippedAdded(uid).pipe(
        map((change) => ({
          type: types.SKIPPED_ADDED,
          payload: change,
        }))
      );
    }),
    catchError((error) => {
      return of({
        type: types.SKIPPED_ADDED_ERROR,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );

export const fetchSkipRemoved = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return SkipsService.listenSkippedRemoved(uid).pipe(
        map((change) => ({
          type: types.SKIPPED_REMOVED,
          payload: change,
        }))
      );
    }),
    catchError((error) => {
      return of({
        type: types.SKIPPED_REMOVED_ERROR,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );
