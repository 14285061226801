import { createSelector } from "reselect";
import _ from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

export const getMatches = (state) => state.matches;

export const getMatchesUsers = createSelector(
	getMatches,
	getBlocks,
	(matches, blocks) =>
		Object.keys(matches.users)
			.map((uid) => matches.users[uid])
			.filter((user) => !blocks[user.uid])
);

export const getStatus = createSelector(getMatches, (matches) => matches.apiStatus);

export const getIsLoadingMore = createSelector(
	getMatches,
	(matches) => matches.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getMatches, (matches) => matches.hasMoreResults);

export const getPitId = createSelector(getMatches, (matches) => matches.pitId);

export const getSearchAfter = createSelector(getMatches, (matches) => matches.searchAfter);

export const getLastSortOption = createSelector(getMatches, (matches) => matches.lastSortOption);
