import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";

import { getUserPublicProfile } from "redux/publicProfiles/selectors";

export default ({ gender }) => {
  const active_userId = useSelector((state) => state.chat.active_userId);
  const activeUserProfile =
    useSelector(getUserPublicProfile(active_userId)) || {};

  return (
    <p className="mb-0">
      {i18next.t(
        `Typing_${gender !== undefined ? gender : activeUserProfile?.gender}`
      )}
      <span className="animate-typing">
        <span className="dot ms-1"></span>
        <span className="dot ms-1"></span>
        <span className="dot ms-1"></span>
      </span>
    </p>
  );
};
