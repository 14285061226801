import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import Origin from 'consts/origin';
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, value) => onChange(key, Number(value));

    return <DropDown
                field={ProfileFields.Origin} 
                fieldOptions={Object.values(Origin)} 
                onChange={_onChange} 
                initSelected={profile.origin}
                title={i18next.t('Edit Profile.Origin')}
                translatePrefix={`Origin_${profile.gender}`}
            />
};
