import firebase from "firebase/app";
import "firebase/database";
import localStorage from "./localStorage";

class QueueService {
	BASE_REF = "queue/tasks";
	ip = "";

	constructor() {}

	async sendEvent(uid, eventName, extraData = {}) {
		const loginFromManager = localStorage.get("loginFrommanager");
		const boId = localStorage.get("boId");
		try {
			if (loginFromManager) {
				extraData.loginFromManager = true;
			}
			if (loginFromManager) {
				extraData.boId = boId;
			}
			return firebase.database().ref(this.BASE_REF).push({
				uid: uid,
				eventName: eventName,
				timeStamp: firebase.database.ServerValue.TIMESTAMP,
				extraData: extraData,
				ip: this.ip,
			});
		} catch (error) {
			console.error(error);
		}
	}
}

export default new QueueService();
