import i18next from "i18next";
import { Modal, ModalHeader, Button } from "reactstrap";

import usePopups from "hooks/usePopups";
import { useEffect } from "react";

export default () => {
	const { closePopup } = usePopups();

	useEffect(() => {
		setTimeout(() => {
			closePopup();
		}, 1000);
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<h5
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: "1.4rem",
					fontWeight: 400,
					lineHeight: 1.2,
					textAlign: "center",
					paddingBottom: "1.2rem",
				}}>
				{i18next.t("Message successfully sent")}
			</h5>
		</Modal>
	);
};
