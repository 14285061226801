import { catchError, filter, map, mergeMap, repeat, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import ChatMessagesService from "services/chatMessages";

import {
  fetchChatMessagesSuccessfully,
  fetchChatMessagesFailed,
  updateChatMessageSuccessfully,
  updateChatMessageFailed,
} from "./actions";

import * as AuthTypes from "../auth/actionTypes";
import * as ChatMessagesTypes from "../chatMessages/actionTypes";
import * as types from "./actionTypes";
import { getLastMessageId } from "./selectors";

export const fetchChatMessages = (action$, store) =>
  action$.pipe(
    ofType(types.CHAT_MESSAGES_CLICK_NEW_USER),
    mergeMap(async ({ chatID }) => {
      try {
        const messages = await ChatMessagesService.fetchChatMessages(chatID);
        return fetchChatMessagesSuccessfully(chatID, messages);
      } catch (error) {
        return fetchChatMessagesFailed(chatID, error?.message);
      }
    })
  );

export const listenUpdateChatMessages = (action$) =>
  action$.pipe(
    ofType(ChatMessagesTypes.CHAT_MESSAGES_CLICK_NEW_USER, types.CHAT_MESSAGES_START_NEW_CHAT),
    mergeMap(({ chatID }) => {
      return ChatMessagesService.listenToChatMessages(chatID).pipe(
        map((chatMessage) => {
          return updateChatMessageSuccessfully(chatID, chatMessage);
        }),
        catchError((error) => {
          return of(updateChatMessageFailed(chatID, error?.message));
        })
      );
    }),
    takeUntil(action$.pipe(ofType(ChatMessagesTypes.LEAVE_CHAT_MESSAGES_SCREEN, AuthTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of(updateChatMessageFailed("", error?.message));
    }),
    repeat()
  );

export const listenToNewMessages = (action$, store) =>
  action$.pipe(
    ofType(types.CHAT_MESSAGES_FETCH_SUCCESSFULLY, types.CHAT_MESSAGES_START_NEW_CHAT),
    mergeMap(({ chatID }) => {
      return ChatMessagesService.listenNewMessages(chatID).pipe(
        filter((message) => {
          const state = store.value;
          const lastMessageId = getLastMessageId(chatID)(state);
          return lastMessageId !== message.id;
        }),
        mergeMap((message) => {
          return of({
            type: types.CHAT_MESSAGES_ADDED,
            chatID,
            message,
          });
        })
      );
    }),
    takeUntil(action$.pipe(ofType(ChatMessagesTypes.LEAVE_CHAT_MESSAGES_SCREEN, AuthTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.CHAT_MESSAGES_ADDED_ERROR,
        payload: error?.message,
      });
    }),
    repeat()
  );
