

export default ({ className="", children }) => {

  return <label className={`form-label ${className}`}>
           {children}
        </label>   
};


