import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { DBPlanItem } from "./PlanItem";
import { getSubscriptionPlans, getSale } from "redux/subscription/selectors";
import { isSale } from "redux/popups/selectors";
import SelectPlanFooter from "./SelectPlanFooter";

export default ({ onSelect, selectedPlanKey, setSelectedPlanKey, selectedPlan }) => {
	const dbPlans = useSelector(getSubscriptionPlans);
	const sale = useSelector(getSale);
	const _isSale = useSelector(isSale);
	const [plans, setPlans] = useState([]);

	useEffect(() => {
		let activePlans = [];
		if (_isSale && sale?.switch) {
			activePlans = sale.plans;
		} else {
			activePlans = dbPlans?.filter((plan) => plan.active);
		}
		const sortedPlans = activePlans.sort((a, b) => a?.days_amount - b?.days_amount);
		setPlans(sortedPlans);
		const plan =
			selectedPlan || sortedPlans.find(({ mostProfitable }) => mostProfitable) || sortedPlans[0];
		setSelectedPlanKey(plan?.key);
		onSelect(plan, true);
	}, []);

	const onPressPlan = (skey) => {
		setSelectedPlanKey(skey);
		const plan = plans.find(({ key, productId }) => key === skey || skey === productId);
		onSelect(plan, false);
	};

	return (
		<>
			<div className="wrapPlans">
				<DBPlans plans={plans} selectedPlanKey={selectedPlanKey} onPressPlan={onPressPlan} />
			</div>
			<SelectPlanFooter plan={selectedPlan} />
		</>
	);
};

const DBPlans = ({ plans, selectedPlanKey, onPressPlan }) => {
	return plans.map(({ key, mostProfitable, sub_long, sub_time, monthly_price }) => (
		<DBPlanItem
			key={key}
			pkey={key}
			selectedPlanKey={selectedPlanKey}
			mostProfitable={mostProfitable}
			sub_long={sub_long}
			sub_time={sub_time}
			monthly_price={monthly_price}
			onPress={onPressPlan}
		/>
	));
};
