import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import MultiSelect from "components/MultiSelect";
import Characteristics from "consts/characteristics";

export default ({ onChange, profile }) => {
	const _onChange = (key, values) =>
		onChange(
			key,
			values.map((item) => Number(item))
		);

	return (
		<MultiSelect
			field={ProfileFields.Characteristics}
			fieldOptions={Object.values(Characteristics)}
			onChange={_onChange}
			initSelected={profile.characteristics}
			title={i18next.t("Edit Profile.Characteristics")}
			translatePrefix="Characteristics"
		/>
	);
};
