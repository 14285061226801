import useNavigator from "hooks/useNavigator";
import i18next from "i18next";

export default () => {
	const navigator = useNavigator();

	const onClick = () => {
		navigator.navigateToLogoutPage();
	};

	return (
		<h5 className="link-to-other-phone" onClick={onClick}>
			{i18next.t("Login.Connect with other phone")}
		</h5>
	);
};
