import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import KindKipa from "consts/kindKipa";
import DropDown from "components/DropDown";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, value) => onChange(key, Number(value));

    return <DropDown
                field={ProfileFields.KindKipa} 
                fieldOptions={Object.values(KindKipa)} 
                onChange={_onChange} 
                initSelected={profile.kindKipa}
                title={i18next.t('Edit Profile.KindKipa')}
                translatePrefix="KindKipa"
            />
};
