import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import TextArea from "components/TextArea";
import { deleteAccount, wantToDeleteAccount } from "redux/general/actions";
import useNavigator from "hooks/useNavigator";

export default () => {
	const navigator = useNavigator();
	const [message, setMessage] = useState("");
	const { reason } = useSelector(getPopups);
	const dispatch = useDispatch();
	const { closePopup } = usePopups();

	const _onDelete = () => {
		dispatch(deleteAccount(reason, message));
		navigator.navigateToProfileBlockedPage();
		closePopup();
	};

	const _onClickBackToMe = () => {
		dispatch(wantToDeleteAccount(reason, message));
		closePopup();
	};

	return (
		<Modal isOpen toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("Delete account.Type Review")}</ModalHeader>
			<ModalBody>
				<TextArea
					onChange={setMessage}
					rows={4}
					placeholder={i18next.t("Delete account.Type Placeholder")}
				/>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={_onClickBackToMe}
					className="btn-rounded littleBtn">
					{i18next.t("Delete account.Back to me")}
				</Button>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={_onDelete}
					className="btn-rounded littleBtn">
					{i18next.t("Delete account.Delete")}
				</Button>
				<div />
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Delete account.Cancel")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
