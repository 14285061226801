import i18next from "i18next";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { getGeneralPending, getGeneralRejectedPending } from "redux/general/selectors";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import DetailsAreaContainer from "./DetailsAreaContainer";
import ProfileFields from "consts/profileFields";
import Name from "./AccountSettings/Name";
import Birthday from "./AccountSettings/Birthday";
import Gender from "./AccountSettings/Gender";
import Email from "./AccountSettings/Email";
import FreezeAccount from "./AccountSettings/FreezeAccount";
import DeleteAccount from "./AccountSettings/DeleteAccount";
import AccountId from "./AccountSettings/AccountId";
import PhoneNumber from "./AccountSettings/PhoneNumber";
import UserGeneralService from "services/userGeneral";
import TextInput from "components/TextInput";
import DoneIcon from "@mui/icons-material/Done";

export default () => {
	const [data, setData] = useState({});
	const [errors, setErrors] = useState({});
	const { showVerifiedEmailPopup } = usePopups();
	const loggedInUserProfile = useSelector(getUserProfile);
	const generalPending = useSelector(getGeneralPending);
	const generalRejectedPending = useSelector(getGeneralRejectedPending);
	const savedRejectedField = useRef(generalRejectedPending);

	useEffect(() => {
		savedRejectedField.current = generalRejectedPending;
	}, [generalRejectedPending]);

	useEffect(() => {
		return () => {
			UserGeneralService.clearPendingFields(savedRejectedField.current);
		};
	}, []);

	const _setErrors = (key, value) => {
		const newErrors = { ...errors, [key]: value };
		const allIsNull = !Object.entries(newErrors).find(([_, value]) => value !== null);
		setErrors(allIsNull ? {} : newErrors);
	};

	const onChange = (key, value) => {
		data[key] = value;
		setData({ ...data });
	};

	return (
		<DetailsAreaContainer
			addSaveDataButton={true}
			clearDataAfterSave={() => setData({})}
			data={data}
			title={`${i18next.t("Pages.System Settings")} - ${i18next.t("Pages.Account Settings")}`}
			errors={errors}>
			<Name
				onChange={onChange}
				profile={loggedInUserProfile}
				pending={generalPending[ProfileFields.Name]}
				setErrors={_setErrors}
			/>

			{!!loggedInUserProfile && <Email profile={loggedInUserProfile} />}
			<Gender profile={loggedInUserProfile} />
			<Birthday profile={loggedInUserProfile} />
			<AccountId />
			<PhoneNumber profile={loggedInUserProfile} />
			<FreezeAccount />
			<DeleteAccount />
		</DetailsAreaContainer>
	);
};
