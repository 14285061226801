import { catchError, map, mergeMap, repeat, switchMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import ChatReceivedService from "services/chatReceived";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchChatReceived = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(async () => {
			try {
				const payload = {};

				return {
					type: types.CHAT_RECEIVED_FETCH_SUCCESSFULLY,
					payload: {
						users: { ...payload },
						hasMoreChats: !(Object.keys(payload || {})?.length < 30),
					},
				};
			} catch (error) {
				return {
					type: types.CHAT_RECEIVED_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const resetChatReceived = (action$, store) =>
	action$.pipe(
		ofType(types.RESET_CHAT_RECEIVED_USERS),
		switchMap(async () => {
			try {
				const payload = await ChatReceivedService.fetchChatReceived();

				return {
					type: types.RESET_CHAT_RECEIVED_USERS_SUCCESSFULLY,
					payload: {
						users: { ...payload },
						hasMoreChats: !(Object.keys(payload || {})?.length < 30),
						loadChatsNumber: 30,
						status: "SUCCESSFULLY",
					},
				};
			} catch (error) {
				return {
					type: types.CHAT_RECEIVED_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const fetchChatReceivedUpdate = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatReceivedService.listenChatReceivedUpdates(uid).pipe(
				map((change) => ({
					type: types.CHAT_RECEIVED_UPDATED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_UPDATED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatReceivedAdded = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatReceivedService.listenChatReceivedAdded(uid).pipe(
				map((change) => ({
					type: types.CHAT_RECEIVED_ADDED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_ADDED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatReceivedRemoved = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatReceivedService.listenChatReceivedRemoved(uid).pipe(
				map((change) => ({
					type: types.CHAT_RECEIVED_REMOVED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_REMOVED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);
