export default {
  ArielUniversity: 1,
  BenGurionUniversityintheNegev: 2,
  BarIlanUniversity: 3,
  HaifaUniversity: 4,
  TelAvivUniversity: 5,
  Lightsofisrael: 6,
  FaithEphrata: 7,
  AfekaTelAvivAcademicCollegeofEngineering: 8,
  BezalelJerusalemAcademyofArtsandDesign: 9,
  HebrewUniversityofJerusalem: 10,
  TheOpenUniversity: 11,
  AcademyofMusicandDanceinJerusalem: 12,
  TheTechnionIsraeliInstituteofTechnoogy: 13,
  AcademicCollegeAchva: 14,
  AshkelonAcademicCollege: 15,
  WingateAcademicCollege: 16,
  BeitBerlCollege: 17,
  WesternGalileeAcademicCollege: 18,
  HadassahAcademicCollegeJerusalem: 19,
  ReligiousCollegeofReligiousEducation: 20,
  HerzogAcademicCollege: 21,
  KinneretAcademicCollegeintheJordanValley: 22,
  OrtBraudeCollegeofEngineering: 23,
  SamiShimonAcademicCollegeofEngineering: 24,
  AcademicCollegeofSocietyandtheArts: 25,
  WashingtonDCCollegeofEducation: 26,
  AcademicCollegeofEducationandSportTentinKatzrin: 27,
  AcademicCollegeofSouthernHematEducation: 28,
  TheCollegeofEducationaDGordon: 29,
  DavidYellinCollegeofEducation: 30,
  KayCollegeofEducation: 31,
  AcademicCollegeofIsraelinRamatGan: 32,
  NetanyaAcademicCollege: 33,
  SapphireAcademicCollege: 34,
  MaxSternValleyAcademicCollege: 35,
  SafedAcademicCollege: 36,
  TelHaiAcademicCollege: 37,
  TelAvivJaffaAcademicCollege: 38,
  AcademictrackCollegeofManagement: 39,
  HeartAcademicCenter: 40,
  AcademicCenterforLawandBusiness: 41,
  NeriBloomfieldAcademicDesignandEducationCenterHaifa: 42,
  PeresAcademicCenter: 43,
  RuppinAcademicCenter: 44,
  Theacademiccenteriscomplete: 45,
  TheCenterforScienceandLaw: 46,
  hertzeliaInterdisciplinarycenter: 47,
  OnoAcademicCollege: 48,
  AdlerInstitute: 49,
  WeitzmanInstitutionofScience: 50,
  HolonInstituteofTechnology: 51,
  SchechterInstituteofJewishStudies: 52,
  AcademicCollegeofEducationOranim: 53,
  JerusalemCollege: 54,
  LevinskyCollegeofEducation: 55,
  KibbutzSeminarCollegeofTechnologyandArtsEducation: 56,
  AzrieliAcademicCollegeofEngineeringJerusalem: 57,
  ShankarengineeringDesignart: 58,
  TalpiotTheCollegeofEducation: 59,
};
