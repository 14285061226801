import i18next from "i18next";
import { Label } from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import currentLanguage from "helpers/currentLanguage";
import Autocomplete from "react-google-autocomplete";
import ProfileFields from "consts/profileFields";
import RequiredPointContainer from "../RequiredPointContainer";
import Warnings from "../Warnings";
import { getUserProfile } from "redux/profile/selectors";

const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export default ({ onChange, profile, hideLabel = false }) => {
	const [isEmpty, setIsEmpty] = useState(false);
	const [key, setKey] = useState(false);
	const { gender } = useSelector(getUserProfile);
	const [defaultValue, setDefaultValue] = useState(
		profile ? profile[ProfileFields.Cites[currentLanguage()]] : ""
	);

	useEffect(() => {
		setDefaultValue(profile ? profile[ProfileFields.Cites[currentLanguage()]] : "");
	}, [profile]);

	const handleChangeAutoComplete = (event) => {
		if (!event.geometry) return;
		onChange(
			currentLanguage(),
			{
				lng: event.geometry.location.lng(),
				lat: event.geometry.location.lat(),
			},
			event.place_id,
			event.formatted_address
		);
	};

	const onTextChange = (e) => {
		const value = e.target.value;
		if (value.trim() === "") setIsEmpty(true);
		if (value.trim() !== "") setIsEmpty(false);
	};

	const onBlur = () => {
		if (isEmpty) {
			profile &&
				onChange(
					currentLanguage(),
					{
						lng: profile[ProfileFields.CityLoc].lng,
						lat: profile[ProfileFields.CityLoc].lat,
					},
					profile[ProfileFields.CityPlaceId],
					profile[ProfileFields.Cites[currentLanguage()]]
				);
			setIsEmpty(false);
			setKey(!key); //Set a new key in order to render a new autocomplete component with the default value
		}
	};

	return (
		<div className="wrapInputValidation city">
			<RequiredPointContainer fromForm={hideLabel}>
				{!hideLabel && <Label>{i18next.t("Edit Profile.Living City")}</Label>}
				<Autocomplete
					apiKey={googleMapsKey}
					language="he"
					defaultValue={defaultValue}
					onPlaceSelected={handleChangeAutoComplete}
					placeholder={i18next.t(`Type City_${gender}`)}
					className="form-control"
					onChange={onTextChange}
					onBlur={onBlur}
					key={key}
				/>
			</RequiredPointContainer>
			{!hideLabel && (
				<Warnings error={isEmpty && i18next.t("Edit Profile.Required Field")} errorClass="name" />
			)}
		</div>
	);
};
