import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import apiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	users: {},
	status: apiRequestStatus.INIT,
	loadChatsNumber: 30,
	hasMoreChats: false,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY:
			return { ...state, ...action.payload, status: apiRequestStatus.SUCCESSFULLY };
		case types.CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY:
		case types.CHAT_RECEIVED_FILTERED_UPDATED_SUCCESSFULLY:
			return { ...state, users: { ...state.users, ...action.payload } };
		case types.CHAT_RECEIVED_FILTERED_REMOVED_SUCCESSFULLY:
			delete state.users[action.payload];

			break;
		case blocksTypes.BLOCKS_BLOCK_USER:
			delete state.users[action.payload];

			break;
		case types.UPDATE_MESSAGES_FILTER_SUCCESFULLY:
			return { ...state, status: apiRequestStatus.PENDING };
		case types.UPDATE_MESSAGES_FILTER_SUBMITED:
			return { ...state, status: apiRequestStatus.SUCCESSFULLY };
		case types.UPDATE_MESSAGES_FILTER_INIT:
			return { ...state, status: apiRequestStatus.INIT };
		case authTypes.AUTH_LOG_OUT:
			return initialState;
		case types.UPDATE_MORE_NUMBER:
			return {
				...state,
				hasMoreChats: !!action.payload?.hasMoreChats,
				loadChatsNumber: action.payload?.loadChatsNumber,
			};
		case types.RESET_CHAT_RECEIVED_FILTERED_USERS_SUCCESSFULLY:
			return {
				...action.payload,
			};
	}
}, initialState);
