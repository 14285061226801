import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import Switch from "components/Switch";
import { letPicturesToggle } from "redux/letPictures/actions";
import { isLetPictures } from "redux/letPictures/selectors";
import { geIsPicturesPrivate } from "redux/profile/selectors";

export default ({ user }) => {
	const userUid = user.uid;
	const dispatch = useDispatch();
	const _isLetPictures = (userUid && useSelector(isLetPictures(userUid))) || false;
	const [letPictures, setLetPictures] = useState(_isLetPictures);
	const isPicturesPrivate = useSelector(geIsPicturesPrivate);

	const handleChangeLetPictures = () => {
		setLetPictures(!letPictures);
		dispatch(letPicturesToggle(user, userUid));
	};

	return (
		<>
			{isPicturesPrivate ? (
				<Switch
					title={i18next.t(letPictures ? "Show private pictures" : "Not Show private pictures", {
						name: user.name,
					})}
					value={letPictures}
					onChange={handleChangeLetPictures}
				/>
			) : (
				<></>
			)}
		</>
	);
};
