import i18next from "i18next";
import Button from "../Button";
import Title from "../Title";

export default ({
	children,
	isValid,
	title,
	subTitle,
	onSubmit,
	className = "",
	showSubmitButton = true,
	showBackButton = false,
	onBackButtonClick = null,
}) => {
	return (
		<>
			{showBackButton && (
				<Button onClick={onBackButtonClick} className="backBtn ri-arrow-right-s-line" />
			)}
			<div className={`innerLoginWrapper ${className}`}>
				<Title {...{ title, subTitle }} />
				{children}
				{showSubmitButton && (
					<Button
						className="question-button"
						text={i18next.t("Continue")}
						disabled={!isValid}
						onClick={onSubmit}
					/>
				)}
			</div>
		</>
	);
};
