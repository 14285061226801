import i18next from "i18next"
import { Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Switch from "components/Switch";
import Subtitle from "components/Subtitle";
import Notifications from 'consts/notifications';
import { getUserNotifications } from "redux/general/selectors";
import { updateNotificationsSettings } from "redux/general/actions";


export default () => {
    const dispatch = useDispatch();
    const notificationSettings = useSelector(getUserNotifications);

    const onChange = (key, newVal) => {
        dispatch(updateNotificationsSettings(key, newVal));
    }

    return  <Form>           
                <FormGroup>
                    {   
                        <>
                                <Subtitle className>
                                {i18next.t('Additional Settings.Notification Settings')}
                                </Subtitle>
                            {Notifications.map(key => (
                                <Switch
                                    key={key}
                                    title={i18next.t(`Notifications.${key}`)}
                                    value={notificationSettings[key]}
                                    onChange={e => onChange(key, e.target.checked)}
                                />
                            ))}
                        </>
                    }
                </FormGroup>
            </Form>
}


