import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { shouldNavigateProfileBlockedInPage } from "redux/general/selectors";

export default () => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const isCurrentPathProfileBlocked = currentPath === "/ProfileBlocked";

  const _shouldNavigateProfileBlockedInPage = useSelector(
    shouldNavigateProfileBlockedInPage
  );

  if (_shouldNavigateProfileBlockedInPage) {
    return <Redirect to="/ProfileBlocked" />;
  }
  if (isCurrentPathProfileBlocked) {
    return <Redirect to="/" />;
  }
  return <></>;
};
