import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { getUserProfile } from "redux/profile/selectors";
import AboutMe from "./ProfileInputs/AboutMe";
import AboutSpouse from "./ProfileInputs/AboutSpouse";
import Height from "./ProfileInputs/Height";
import LivingCity from "./ProfileInputs/LivingCity";
import MaritalStatus from "./ProfileInputs/MaritalStatus";
import Stance from "./ProfileInputs/Stance";
import { SelectedTags } from "./ProfileInputs/Tags";
import ProfileFields from "consts/profileFields";
import { getEditProfilePending, getRejectedEditProfilePending } from "redux/general/selectors";
import DetailsAreaContainer from "./DetailsAreaContainer";
import { updateProfileCity } from "redux/profile/actions";
import currentLanguage from "helpers/currentLanguage";
import StanceOptions from "consts/stance";
import Gender from "consts/gender";
import Profession from "./ProfileInputs/Profession";
import EducationalInstitution from "./ProfileInputs/EducationalInstitution";
import Education from "./ProfileInputs/Education";
import LookingFor from "./ProfileInputs/LookingFor";
import ProfileLanguages from "./ProfileInputs/ProfileLanguages";
import Army from "./ProfileInputs/Army";
import Origin from "./ProfileInputs/Origin";
import Smoking from "./ProfileInputs/Smoking";
import Health from "./ProfileInputs/Health";
import Food from "./ProfileInputs/Food";
import Religion from "./ProfileInputs/Religion";
import PrayerTime from "./ProfileInputs/PrayerTime";
import Kosher from "./ProfileInputs/Kosher";
import ToraTime from "./ProfileInputs/ToraTime";
import Cohen from "./ProfileInputs/Cohen";
import HeadCover from "./ProfileInputs/HeadCover";
import KindKipa from "./ProfileInputs/KindKipa";
import Children from "./ProfileInputs/Children";
import KeepShabbat from "./ProfileInputs/KeepShabbat";
import EatKosherFood from "./ProfileInputs/EatKosherFood";
import RepresentativeSentence from "./ProfileInputs/RepresentativeSentence";
import Clothing from "./ProfileInputs/Clothing";
import Hobbies from "./ProfileInputs/Hobbies";
import Characteristics from "./ProfileInputs/Characteristics";
import ChildrenCount from "./ProfileInputs/ChildrenCount";
import UserGeneralService from "services/userGeneral";

export default () => {
	const [data, setData] = useState({});
	const [cityData, setCityData] = useState();
	const loggedInUserProfile = useSelector(getUserProfile);
	const pendingFields = useSelector(getEditProfilePending);
	const rejectedEditProfilePending = useSelector(getRejectedEditProfilePending);
	const savedRejectedField = useRef(rejectedEditProfilePending);

	useEffect(() => {
		savedRejectedField.current = rejectedEditProfilePending;
	}, [rejectedEditProfilePending]);

	useEffect(() => {
		return () => {
			UserGeneralService.clearPendingFields(savedRejectedField.current);
		};
	}, []);

	const dispatch = useDispatch();

	const onChange = (key, value) => {
		data[key] = value;
		setData({ ...data });
	};

	const onSaveCity = () => dispatch(updateProfileCity(...cityData));

	const onCityChange = (...cityData) => {
		onChange(ProfileFields.Cites[currentLanguage()], cityData[3]);
		setCityData(cityData);
	};

	return (
		<DetailsAreaContainer
			onSaveMore={cityData && onSaveCity}
			data={data}
			addSaveDataButton={true}
			clearDataAfterSave={() => setData({})}
			title={i18next.t("Pages.Edit Profile")}
			subTitle={<span className="requiredTitle">{i18next.t("Edit Profile.Required Fields")}</span>}>
			<AboutMe
				onChange={onChange}
				profile={loggedInUserProfile}
				pending={pendingFields[ProfileFields.AboutMe]}
			/>
			<SelectedTags onChange={onChange} profile={loggedInUserProfile} />
			<AboutSpouse
				onChange={onChange}
				profile={loggedInUserProfile}
				pending={pendingFields[ProfileFields.AboutSpouse]}
			/>
			<RepresentativeSentence
				onChange={onChange}
				profile={loggedInUserProfile}
				pending={pendingFields[ProfileFields.OneLiner]}
			/>
			<MaritalStatus
				onChange={onChange}
				profile={loggedInUserProfile}
				pending={pendingFields[ProfileFields.MaritalStatus]}
			/>
			<Children onChange={onChange} profile={loggedInUserProfile} />
			{(loggedInUserProfile[ProfileFields.HasChildren] || data[ProfileFields.HasChildren]) &&
				data[ProfileFields.HasChildren] !== false && (
					<ChildrenCount onChange={onChange} profile={loggedInUserProfile} />
				)}
			<Stance onChange={onChange} profile={loggedInUserProfile} />
			<Height onChange={onChange} profile={loggedInUserProfile} />
			<LivingCity onChange={onCityChange} profile={loggedInUserProfile} />
			<Profession onChange={onChange} profile={loggedInUserProfile} />
			<EducationalInstitution onChange={onChange} profile={loggedInUserProfile} />
			<Education onChange={onChange} profile={loggedInUserProfile} />
			<LookingFor onChange={onChange} profile={loggedInUserProfile} />
			<Army onChange={onChange} profile={loggedInUserProfile} />
			{loggedInUserProfile[ProfileFields.Stance] !== StanceOptions.Chiloni && (
				<Origin onChange={onChange} profile={loggedInUserProfile} />
			)}
			<ProfileLanguages onChange={onChange} profile={loggedInUserProfile} />
			<Smoking onChange={onChange} profile={loggedInUserProfile} />
			<Health onChange={onChange} profile={loggedInUserProfile} />
			<Food onChange={onChange} profile={loggedInUserProfile} />
			{(loggedInUserProfile.stance === StanceOptions.Mesorati ||
				loggedInUserProfile.stance === StanceOptions.Datlash) && (
				<KeepShabbat onChange={onChange} profile={loggedInUserProfile} />
			)}
			{(loggedInUserProfile.stance === StanceOptions.Mesorati ||
				loggedInUserProfile.stance === StanceOptions.Datlash) && (
				<EatKosherFood onChange={onChange} profile={loggedInUserProfile} />
			)}
			{loggedInUserProfile.stance !== StanceOptions.Chiloni &&
				loggedInUserProfile.stance !== StanceOptions.Datlash &&
				loggedInUserProfile.stance !== StanceOptions.Mesorati && (
					<Kosher onChange={onChange} profile={loggedInUserProfile} />
				)}
			{loggedInUserProfile.stance !== StanceOptions.Chiloni &&
				loggedInUserProfile.stance !== StanceOptions.Datlash &&
				loggedInUserProfile.stance !== StanceOptions.Mesorati && (
					<Religion onChange={onChange} profile={loggedInUserProfile} />
				)}
			{loggedInUserProfile.gender === Gender.Male &&
				loggedInUserProfile.stance !== StanceOptions.Chiloni &&
				loggedInUserProfile.stance !== StanceOptions.Datlash &&
				loggedInUserProfile.stance !== StanceOptions.Mesorati && (
					<PrayerTime onChange={onChange} profile={loggedInUserProfile} />
				)}
			{loggedInUserProfile.gender === Gender.Male &&
				loggedInUserProfile.stance !== StanceOptions.Chiloni &&
				loggedInUserProfile.stance !== StanceOptions.Datlash &&
				loggedInUserProfile.stance !== StanceOptions.Mesorati &&
				loggedInUserProfile.stance !== StanceOptions.DatiLite && (
					<ToraTime onChange={onChange} profile={loggedInUserProfile} />
				)}
			{loggedInUserProfile.gender === Gender.Male &&
				loggedInUserProfile.stance !== StanceOptions.Chiloni && (
					<Cohen onChange={onChange} profile={loggedInUserProfile} />
				)}
			{loggedInUserProfile.gender === Gender.Female && (
				<Clothing onChange={onChange} profile={loggedInUserProfile} />
			)}
			{loggedInUserProfile.gender === Gender.Female &&
				loggedInUserProfile.stance !== StanceOptions.Chiloni &&
				loggedInUserProfile.stance !== StanceOptions.Datlash &&
				loggedInUserProfile.stance !== StanceOptions.Mesorati && (
					<HeadCover onChange={onChange} profile={loggedInUserProfile} />
				)}
			{loggedInUserProfile.gender === Gender.Male &&
				loggedInUserProfile.stance !== StanceOptions.Chiloni &&
				loggedInUserProfile.stance !== StanceOptions.Datlash &&
				loggedInUserProfile.stance !== StanceOptions.Mesorati && (
					<KindKipa onChange={onChange} profile={loggedInUserProfile} />
				)}
			<Hobbies onChange={onChange} profile={loggedInUserProfile} />
			<Characteristics onChange={onChange} profile={loggedInUserProfile} />
		</DetailsAreaContainer>
	);
};
