import i18next from 'i18next';
import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { WrongAgeReasons, WrongPicturesReasons } from 'consts/reasonsReport';

export default ({ sectionIndex , message, setMessage, activeSubSection, setActiveSubSection }) => {

    const toggleActiveSubSection = clickedSection => {
        if(clickedSection!==activeSubSection) {
            setActiveSubSection(clickedSection);
            setMessage('');
        } else {
            setActiveSubSection(null);
        }
    }

    return (
        <AccordionItem>
            <AccordionHeader targetId={sectionIndex}>
                {i18next.t('Report Reasons.User Has Wrong Details')}
            </AccordionHeader>
            <AccordionBody accordionId={sectionIndex}>
                <Accordion open={activeSubSection} toggle={toggleActiveSubSection} className="subAccordion">
                    <WrongPictures message={message} setMessage={setMessage}/>
                    <WrongAge message={message} setMessage={setMessage}/>
                    <WrongStatus message={message} setMessage={setMessage}/>
                </Accordion>
            </AccordionBody>
        </AccordionItem>
    );
}

const WrongPictures = ({ message , setMessage }) => {
    const [ pictureMessage, setPictureMessage ] = useState();
    const [ currentReason, setCurrentReason ] = useState();

    const isActiveRadio = reason => message.indexOf(reason)!==-1;

    const onChangeRadioPicture = reason => {
        if (isActiveRadio(reason)) return;
        setMessage(reason);
        setCurrentReason(reason);
        setPictureMessage('');
    }

    const onChangeMessage = e => {
        setPictureMessage(e.target.value);
        setMessage(`${currentReason} , ${e.target.value}`);
    }

    return (

      <AccordionItem>
            <AccordionHeader targetId="6">
                {i18next.t('Report Wrong Details.Picture')}
            </AccordionHeader>
            <AccordionBody accordionId="6">
                {
                        WrongPicturesReasons.map(item => {
                            const reason = i18next.t(item);
                            return <div className="wrapradio">
                                        <span> 
                                            <input type="radio"
                                                onChange={e=>onChangeRadioPicture(e.target.value)}
                                                checked={isActiveRadio(reason)}
                                                name="image" 
                                                value={reason}/>
                                            {reason}
                                        </span>
                                        <input type="text" 
                                            onClick={()=>onChangeRadioPicture(reason)} 
                                            value={isActiveRadio(reason) ? pictureMessage : ''} 
                                            onChange={onChangeMessage} 
                                            placeholder={i18next.t('Detail')}/>
                                    </div>
                        }
                    )
                }
            </AccordionBody>
        </AccordionItem>
    );
};

const WrongAge = ({ message , setMessage }) => {
    const title = i18next.t('Report Wrong Details.Age');
    const onChangeMessage = e => setMessage(`${title} , ${e.target.value}`);

    return (
        <AccordionItem>
            <AccordionHeader targetId="7">
                {title}
            </AccordionHeader>
            <AccordionBody accordionId="7">
                {
                    WrongAgeReasons.map(item => {
                        const reason = i18next.t(item);
                        return <div className="wrapradio">
                                    <span> 
                                        <input type="radio" onChange={onChangeMessage} 
                                            checked={message.indexOf(reason)!==-1}
                                            name="age" value={reason}/>
                                        {reason}
                                    </span>
                                </div>
                    })
                }
            </AccordionBody>
        </AccordionItem>
    );
};

const WrongStatus = ({ message , setMessage }) => {
    const [ statusMessage, setStatusMessage ] = useState('');
    const title = i18next.t('Report Wrong Details.Status');

    const isActiveSection = () => message.indexOf(title)!==-1;

    const onChangeMessage= e => {
        const message = e.target.value;
        setStatusMessage(message);
        message && setMessage(`${title} , ${message}`);
        !message && setMessage('');
    }

    return (
        <AccordionItem>
            <AccordionHeader targetId="8">
                {title}
            </AccordionHeader>
            <AccordionBody accordionId="8">
                <div className="wrapradio wrapText">                 
                    <input value={ isActiveSection() ? statusMessage: ''} onChange={onChangeMessage} type="text" placeholder={i18next.t('Detail')}></input>
                </div>
            </AccordionBody>
        </AccordionItem>
    )
}