import i18next from "i18next";

import DetailsAreaContainer from "./DetailsAreaContainer";
import BlockUser from "components/account/AccountSettings/BlockUser";
import { useEffect, useState } from "react";
import { getBlockedUsers } from "redux/blocks/selectors";
import { useDispatch, useSelector } from "react-redux";
import blockService from "services/blocks";
import { addBlockUser } from "redux/blocks/actions";
export default ({}) => {
	const dispatch = useDispatch();
	const [noBlockResult, setNoBlockResult] = useState(false);
	const blockedUsers = useSelector(getBlockedUsers);

	useEffect(() => {
		blockService.fetchBlocks().then((data) => {
			const blocks = Object.keys(data || {});
			if (!blocks?.length) return setNoBlockResult(true);
			blocks.map((blockId) => {
				dispatch(addBlockUser({ [blockId]: { ...data[blockId], isBlock: true } }));
			});
		});
	}, []);

	return (
		<DetailsAreaContainer
			title={`${i18next.t("Pages.System Settings")} - ${i18next.t("Pages.Blocked Profiles")}`}>
			<div className="wrapForBlockedUsers">
				{noBlockResult && "אין חסומים"}
				{blockedUsers.map((user) => user?.uid && <BlockUser user={user} />)}
			</div>
		</DetailsAreaContainer>
	);
};
