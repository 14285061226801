export const VIEWS_USERS_FETCH = "views.VIEWS_USERS_FETCH";
export const VIEWS_USERS_FETCH_LOAD_MORE = "views.VIEWS_USERS_FETCH_LOAD_MORE";
export const VIEWS_USERS_FETCH_SUCCESSFULLY = "views.VIEWS_USERS_FETCH_SUCCESSFULLY";
export const VIEWS_USERS_FETCH_LOAD_MORE_SUCCESSFULLY =
	"views.VIEWS_USERS_FETCH_LOAD_MORE_SUCCESSFULLY";
export const VIEWS_USERS_FETCH_FAILED = "views.VIEWS_USERS_FETCH_FAILED";
export const VIEWS_ADDED = "views.VIEWS_ADDED";
export const VIEWS_ADDED_ERROR = "views.VIEWS_ADDED_ERROR";
export const VIEWS_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY =
	"views.VIEWS_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY";
export const VIEWS_ADDED_FETCH_USER_PROFILE_FAILED = "views.VIEWS_ADDED_FETCH_USER_PROFILE_FAILED";
export const VIEWS_FETCH_SUCCESSFULLY = "views.VIEWS_FETCH_SUCCESSFULLY";
export const VIEWS_FETCH_FAILED = "views.VIEWS_FETCH_FAILED";
