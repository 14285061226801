export default () => {
  return (
    <div className="wrapTakanon">
      <h5 className="form-label">
        <b> {`תקנון האתר ומדיניות פרטיות - סמארט להכיר חכם בע"מ `} </b>{" "}
      </h5>
      <h6>
        {" "}
        {`מסמך זה מהווה הסכם בינך לבין סמארט להכיר חכם בע"מ כתובת: גולדה מאיר 7, נס ציונה , (ח.פ 516320249 ) טלפון: 08-6223925 , פקס: 086217553 * דואר אלקטרוני info@jsmart.app מען למכתבים: ת.ד. 189 מיקוד 7410101 (להלן:"מפעילת האתר").
                בעת אישור התקנון הינך מצהיר בזאת כי קראת את התקנון בשלמותו והינך מתחייב לעמוד בכל תנאי האתר. יש לציין שהוראת תקנון זה יגברו על כל פרסום או מודעה במידה ויהיו כאלה באתר. במידה ואינך מסכים לסעיף כלשהו הינך מתבקש לא להירשם לאתר או להשתמש בשירותיו. כל האמור בתקנון מנוסח מטעמי נוחות בלשון זכר, אך מכוון לגברים ונשים כאחד.
                אנו עשויים לשנות ו/או לערוך ו/או לעדכן את תקנון האתר מעת לעת. אתה מוזמן לבדוק את העמוד מדי פעם כדי לוודא שאתה מכיר את כל השינויים. `}{" "}
      </h6>
      <h6>
        {" "}
        {` אתר ו/או אפליקציית jsmart להלן ביחד : "האתר"); הינו אתר המיועד לקשר רציני ו/או למטרות חתונה והוא מיועד לכלל הציבור היהודי בלבד, ומאפשר מיון וסינון על בסיס הגדרה אמונית של המשתמשים, ובכלל זה בקטגוריות לציבורים: חילוני, מסורתי, דתי, חרדי וכיו"ב, כפי שתמצא מנהלת האתר לנכון מעת לעת.
                באישור תקנון זה הינך מאשר שליחת sms ו/ או מייל פרסומיים, וכן הודעות באפליקציות הודעות שונות וברשתות חברתיות, ללא הגבלה, לרבות הודעה על "השקת האפליקצייה", שדרוגים, פרסומים מטעם מנהלת האתר ומטעמם של צדדים שלישיים וכמובן - הודעות ממשתמשי האתר והתראות בדבר קבלת הודעות ממשתמשי האתר.  `}{" "}
      </h6>

      <label className="form-label"> {`על האתר`} </label>
      <h6>
        {" "}
        {`    1. מפעילת האתר מספקת שירותי הכרויות אינטרנטיים תחת השם - JSMART (להלן: "האתר"). `}{" "}
      </h6>
      <h6>
        {" "}
        {` 2. ידוע לך כי השירות מיועד גם לציבור שומר מסורת ולכן הוא בעל צביון של קשר רציני ו/או חתונה ובהתאם להלכה היהודית. מבלי לגרוע מהוראות תקנון זה, הנך מתבקש לכבד את צביון האתר ואת כבוד המשתמשים בו.
                למען הסר ספק, אין באמור לעיל בכדי להתחייב לצביון דתי ו/או מסורתי כלשהו והאתר מיועד לכלל הציבור היהודי באשר הוא. `}{" "}
      </h6>

      <label className="form-label"> {` אחריות `} </label>
      <h6>
        {" "}
        {` 3. למרות הצלחות העבר של מפעילת האתר ו/או מי מטעמה בפרט ואתרי הכרויות בכלל, מובהר כי אין כל התחייבות להצלחת השירות, וכי השימוש בשירות ו/או תשלום המנוי אינו קשור להצלחה במציאת זיווג, אהבה, קשר או כל התקשרות כלשהי עם בני המין השני. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 4. מפעילת האתר אינה אחראית לנזק שיגרם למשתמשי האתר בעקבות שירותי ההיכרות אותם מספק האתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 5. מפעילת האתר אינה מביעה דעה ואינה תומכת בשום מידע, תוכן, שירות, מוצר או הצעה שהגישה אליהם ניתנת באמצעות האתר או בכל אמצעי אחר. אין לראות במפעילת האתר כמי שמעודדת או ממליצה להיענות להצעות או שירותים שניתנים דרך האתר. מפעילת האתר אינה בודקת את פרטי המשתמשים, תוכן, אמיתות וכדאיות ההצעות והמידע שמוחלף באתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 6. מפעילת האתר אינה מספקת את המידע והתוכן המופיעים באתר הנוגעים לצדדים שלישיים. יודגש, המידע והתוכן שהגישה מתאפשרת אליהם דרך האתר הוא מידע המועלה על ידי צדדים שלישיים ולא על ידי מפעילת האתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 7. מפעילת האתר אינה אחראית לפעולות או מחדלים של משתמשים אחרים העושים שימוש באתר. מפעילת האתר לא תישא על פי כל דין באחריות לכל פגיעה ו/או נזק שייגרם למשתמש באתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 8. שימוש באתר מהווה את הסכמתך לפטור את מפעילת האתר מכל אחריות בשל פעולות צד שלישי. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 9. בכפיפות להסתייגות של הוראות כל דין המורות אחרת, אין מפעילת האתר מביעה כל עמדה ואין היא אחראית, במפורש או מכללא לגבי:
                א. התוכן, המידע והחומר המצוי באתר, לרבות בנוגע למידע ולהצעות אותם מעלים צדדים שלישיים שונים;
                ב. לגבי התוכן, החומר, המידע והפעולות אשר הגישה אליהם מתאפשרת באמצעות הגישה לאתר;
                ג. לגבי כל מוצר, תוכנה או שירותים, שהגישה אליהם התבצעה באמצאות קישור אלקטרוני מן האתר;
                ד. לגבי כל נזק אשר נגרם או עלול להיגרם עקב המשלוח של מידע אישי, חסוי או רגיש דרך האתר ו/או בכל אמצעי אחר;
                ה. לגבי כל נזק אשר נגרם או עלול להיגרם עקב פגם או תקלה בתוכנה המפעילה את האתר או שמאפשרת גישה לאתר.
                אישור משתמשים ורישום משתמשים `}{" "}
      </h6>
      <h6>
        {" "}
        {` 10. הגלישה והשימוש באתר מותרים מגיל 18 ומעלה, בהירשמך לאתר הנך מצהיר שהנך מעל גיל 18. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 11. מפעילת האתר משאירה לעצמה את הזכות לאשר או לא לאשר כרטיסים מסוימים מסיבות השמורות עמה, על פי שיקול דעתה הבלעדי. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 12. מפעילת האתר משאירה לעצמה את הזכות לפסול לפי שיקול דעתה טקסט ו/או חלק מטקסט שהוזן ע"י משתמשים בכרטיסם באתר, וכן, תמונות של משתמשים מטעמי צניעות או מכל טעם אחר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 13. מובהר בזאת שעל המשתמש לצרף רק תמונה שלו. מפעילת האתר שומרת לעצמה את הזכות לעשות התאמות טכניות במידה ויהיה צורך בכך, על מנת להתאים את התמונה לאתר ולמשתמש לא תהא כל טענה בקשר לכך. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 14. פתיחת כרטיס באתר הינה לשימוש אישי בלבד של בעל הכרטיס, ולא תתאפשר כל העברה של כרטיס בין משתמשים, וכן לא יתאפשר שימוש של מספר משתמשים בכרטיס אחד. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 15. מפעילת האתר רשאית לא לאשר ו/או לחסום כרטיסי משתמשים מכל סיבה שבעיניה אינם מתאימים לרוחו או לתדמיתו של האתר. מפעילת האתר לא תתן הסבר למשתמשים שכרטיסם או תמונתם נדחתה. `}{" "}
      </h6>

      <label className="form-label"> {` השימוש באתר `} </label>
      <h6>
        {" "}
        {` 16. השימוש בשירותים המוצעים באתר ו/או חלק מהם עשוי להיות כרוך ברישום וברכישת מנוי ועל המשתמש לפעול בהתאם להוראות הרישום, ובכלל זה למסור פרטים נכונים ומדויקים ולעמוד בתנאי התשלום.`}{" "}
      </h6>
      <h6>
        {" "}
        {` 17. כל הדפים המצויים באתר הם רכושה של מפעילת האתר. אין להעתיק או לפרסם כל חלק מן הדפים ללא הסכמתה המפורשת של מפעילת האתר בכתב ומראש.`}{" "}
      </h6>
      <h6>
        {" "}
        {` 18. חל איסור חמור על כל פרסום מסחרי באתר ו/או על כל פנייה למשתמשי האתר שלא לצורך היכרות אישית בהתאם לתנאי ולאופי האתר ו/או כל פעילות אחרת מטעם גוף מסחרי, אשר אינה לצורכי היכרות אישית בלבד, ובכלל זה פרסום אתרים אחרים באמצעות האתר, במישרין ו/או בעקיפין ו/או פרסום קישוריות (לינקים) שיובילו לאתרים אחרים ושימוש בשירותי ההודעות של האתר לכל אחת מהמטרות האסורות המפורטות לעיל. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 19. האתר שומר לעצמו את הזכות לשנות את מבנה האתר, תוכן האתר, שאלון ההרשמה. כמו כן, תוכל מפעילת האתר לשנות כל דבר שקשור בקוד האתר או בהצגתו למשתמש. אין האתר אחראי לטעויות בהצגת הנתונים במידה ויווצרו בעקבות כך. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 20. משתמש הנרשם לשירות, מודע לכך ונותן את הסכמתו המפורשת לכך שהאתר מתכוון לפעול בפלטפורמות שונות שקיימות ו/או שיהיו קיימות בעתיד. וכי כרטיסו עתיד להיות מוצג בכל פלטפורמה כזו לכשתצא.`}{" "}
      </h6>
      <h6>
        {" "}
        {` 21. מפעילת האתר עשויה להשתמש בפונקציה בו מופיעים תמונות של גולשים בעמוד הבית של האתר (ניתן לבקש לא להופיע בעמוד הראשי). כמו כן, משתמשים מסוימים עשויים להופיע  בתוצאות החיפוש גם בכניסה לאתר ע"י משתמשים שאינם רשומים עדיין לאתרו מבצעים חיפוש. מפעילת האתר לא תהיה אחראית לנזק פגיעה עלבון או הפסד כלשהו שייגרם למשתמש קיים עקב שימוש בפונקציות אלה. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 22. מפעילת האתר רשאית לבחור את סדר הופעת המשתמשים בתוצאות החיפוש או לבחור להציג משתמשים מסוימים בעמוד הבית בהתאם לשיקול דעתה. בהירשמך לאתר, הינך מצהיר שאתה מסכים לזכות זאת של מפעילת האתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 23. מפעילת האתר אינה אחראית לכל נזק שעשוי להיגרם מהיעדר זמינותו התמידית של האתר, כמו כן, לא לתקלות טכניות שיהיו בו או למידע שיישמר באתר, וכן, לנזק שיכול להיגרם מהפסקת הפעלת האתר.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 24. מפעילת האתר רשאית לבצע למשתמשים סינון ראשוני של ההודעות ו/או משתמשים ו/או כל תוכן אחר והכל על פי הגדרות ברירת מחדל או אלגוריתם הנתון לשיקול דעתה. מיותר לציין  שאין תחייבות לסדר ו/או לכמות ההופעות של כל משתמש בתוצאות החיפוש.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 25. מבלי לגרוע מהאמור לעיל, בכניסתך לאתר אתה מתחייב במפורש שלא: 
                א. לעשות כל שימוש במידע על משתמשי האתר ו/או בתוכן מכל סוג שהוא, לרבות, אך מבלי להגביל, בתמונות, בסרטונים ובקטעי אודיו, שאין לך את הזכות ו/או הרשות לעשות בו שימוש.
                ב. למסור פרטיו של אחר, זולת המשתמש, למעט, בכפוף לאישורו המפורש של אותו משתמש.
                ג. להטריד, לאיים, "לעקוב", לפגוע, להעליב, ולהפר כל זכות משפטית שעומדת לאדם או לגוף אחר.
                ד. להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן בעל אופי שאינו הגון, ראוי, פורנוגראפי, משמיץ או שאינו חוקי.
                ה. להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שמטרתו היא מסחרית, שמטרתו פרסום, שידול לרכישה, מתן שירותים, תרומות או שמטרתו להוליך שולל משתמש, לרבות להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן מסחרי ללא קבלת היתר מפורש, בכתב ומראש ממפעילת האתר.
                ו. להעלות, לפרסם, להגיב, לכתוב, לאסוף כל חומר שעלול להוות עבירה פלילית, עלול ליצור עילה אזרחית, שמפר דין ישראלי או דין של מדינה אחרת. 
                ז. לאסוף, "לקצור" מידע לגבי משתמשים אחרים, לרבות, ולא רק, איסוף כתובות דואר אלקטרוני, מידע אישי, תמונות או חומרים אחרים ברשתות חברתיות של משתמשים אחרים.
                ח. להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שיש בו כדי לפגוע בביטחונה של מדינת ישראל ו/או שיש בו כדי להפר סוד צבאי ו/או צנזורה צבאית.
                ט. להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers, Robots וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך האתר. בכלל זה, אין ליצור ואין להשתמש באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מהאתר.
                י. להציג תכנים מהאתר בכל דרך שהיא ובכלל זה באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול תקשורת - המשנים את עיצובם באתר או מחסירים מהם תכנים כלשהם ובפרט פרסומות ותכנים מסחריים.
                יא. לבצע שינויים באתר או להעתיק, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם, לתת היתר או רישיון, ליצור עבודות נגזרות או למכור פריט מפריטי המידע, התוכנה, המוצרים או השירותים שמקורם באתר.
                יב. לשתול קודים ו/או וירוסים ו/או תוכנות באתר.
                יג. לשלוח דואר זבל לחברי האתר או להתחיל מכתבי שרשרת כלשהם.  `}{" "}
      </h6>

      <label className="form-label">
        {" "}
        {`פרסום, המלצות מערכת וקבלת דוא"ל `}{" "}
      </label>
      <h6>
        {" "}
        {` 26. בהירשמך לשירות הינך מסכים לכך שמפעילת האתר רשאית, על פי שיקול דעתה, לאפשר למשתמשים, ואף לגביך, לקבל, לשלוח, להפיץ מודעות שאינן מודעות אישיות (לרבות פרסומות), על פי פילוח אשר מותאם לאותם משתמשים. לשם המחשה: למשתמשים שאינם מנויים, מודעה כללית כאמור תוצג כ"מסר כללי" (בצירוף שם השולח) להבדיל מ"מסר אישי". בנוסף, בהירשמך לשירות הנך מסכים שמפעילת האתר תמליץ ו/או תשתף את כרטיסך עם משתמשים אחרים הרשומים לשירות ו/או עם משתמשים נוספים שמחפשים זוגיות ומצויים בפלטפורמות אחרות, ולהיפך, כל זאת על פי שיקול דעתה הבלעדי, שירות זה עשוי להופיע למשתמש כ"המלצת מערכת" או בכותרת מתאימה אחרת או בסימון מבדיל אחר, בהתאם להקשר הקידום הרלוונטי שמבוצע על כרטיס המשתמש. משתמש אשר אינו מעוניין בקבלת המלצות מערכת או מסרים כלליים או להיות מופץ בפלטפורמות אחרות באפשרותו לפנות לשירות הלקוחות בדרכים המקובלות ולבקש שלא לקבל פניות מעין אלו וכן לא להיות מופץ בפלטפורמות אחרות ללא הסכמתו מראש.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 27. דרכי יצירת קשר, לרבות מספרי טלפון וכתובות הדוא"ל שימסרו למפעילת האתר ע"י משתמשים או ע"י אורחים באתר, אשר תכליתם קבלת מידע או חומר פרסומי מחברות שונות, עשויים להימסר גם לחברות השונות לצורך פרסום. אין החברה אחראית על אופי השימוש של החברות בדרכי יצירת הקשר הנמסרים, ואין מפעילת האתר אחראית לנזק ישיר או עקיף, פיזי , נפשי, כספי או כל נזק שהוא שייגרם למשתמש או אורח שהשאיר את דרכי יצירת הקשר שלו באתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 28. מפעילת האתר תהא רשאית לצרף את המידע שקיבלה על המשתמש למאגר מידע שקיים אצלה, יודגש, כי על פי חוק לא חלה עליך חובה למסור את המידע. כמו כן, בברירת המחדל בהרשמה לאתר, יסומן שהחברה רשאית לשלוח למשתמש דיוור ו/או הודעות sms ו/או מסרונים בכל דרך אחרת כגון הודעות "וואצ אפ"  על הודעות חדשות ממשתשי האתר וכן הודעות שיכללו מידע פרסומי ועוד. על מנת לשנות הגדרות אלו על המשתמש לשנות את "הגדרות הדואר" כפי שהם מופיעות ב "פרופיל האישי" של המשתמש. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 29. אישור תקנון זה ומסירת פרטי המשתמש מהווה הסכמה לשיגור דברי פרסומת על פי חוק התקשורת (בזק ושידורים) (תיקון מס' 40), התשס"ח – 2008 (להלן:"החוק"). אף על פי כן, המשתמש רשאי לבטל את הסכמתו על ידי משלוח הודעה להנהלת האתר בכתובת  info@jsmart.app , וכן בשאר הדרכים המצויינות. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 30. כל עוד לא נמסרה הודעת ביטול להסכמה לקבלת דברי פרסומת, מפעילת האתר תהא רשאית, בהתאם להוראות החוק, להשתמש בפרטי המשתמש, כפי שנרשמו באתר, ו/או להעביר פרטים של משתמשים לצדדים שלישיים על מנת לחשוף את המשתמשים הרשומים לעדכונים ו/או חומר שיווקי רלבנטי בכל אמצעי מדיה. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 31. מפעילת האתר תהיה בקשר עם מפרסמים שונים, אין למפעילת האתר כל קשר לנזק כספי, גופני או נפשי שיכול להיגרם למשתמש באתר כתוצאה מהשימוש באחד מהמוצרים של המפרסמים באתר, או בפרסומות המפורסמות באמצעות האתר ובאמצעות מערכת הדיוור של האתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 32. כל שימוש של הגולש במספר הטלפון שלו באתר, לרבות בעת: רכישת מנוי, פנייה לשירות הלקוחות, הזנת פרטים באתר, הזנת פרטים לחזרה בעקבות מבצעים ו/או מודעות פרסומיות אחרות, וכדומה, יישמרו במאגר הנתונים של החברה ויוכלו לשמש את החברה לכל צורך עתידי, לרבות בעת משלוח/פנייה פרסומית. `}{" "}
      </h6>
      <h6>
        {" "}
        {` 33. מפעם לפעם יעלו פרסומים שיווקיים באתר, המוצעים לחברים בו. יובהר בזאת כי הנתונים, פרטי התקשרות, החבילה וטיב השירות אינם  באחריות  מפעילת האתר והיא  אינה מהווה צד להתקשרות ו/או לפרסום, אלא מול החברה המשווקת ובהסכמתך למבצע הנך מאשר בזאת כי אין ולא תהיה לך כל טענה כנגד מפעילת האתר  בגין ההתקשרות בינך לבין החברה המשווקת.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 34. מפעילת  האתר אינה אחראית לתוכן של הפרסומות האמורות ולמוצרים שיסופקו או לשירותים שיינתנו על-ידי צדדים שלישיים והמשתמש מסכים באופן בלתי חוזר וללא כל תנאי לא לראות במנהלת האתר אחראית, בכל דרך, בקשר לכך. `}{" "}
      </h6>

      <label className="form-label"> {`התוכן והמידע המוזן לאתר`}</label>
      <h6>
        {" "}
        {` 35. המונחים "מידע" ו\או "תוכן", כוללים מידע מכל מין וסוג, לרבות אך מבלי למצות, כל תוכן מילולי, חזותי, קולי, אור-קולי  (audio-visual) או כל שילוב שלהם, וכן עיצובם, עיבודם, עריכתם, הפצתם ודרך הצגתם, לרבות אך מבלי למצות, כל תמונה, צילום, איור, הנפשה (animation), תרשים, דמות, הדמיה, דגימה (sample), סרטון, קובץ קולי וקובץ מוסיקלי, כל תוכנה, קובץ, קוד מחשב, יישום, תסדיר (format), פרוטוקול, מאגר נתונים, ממשק וכל תו, סימן, סמל וצלמית (icon). כל אלה יכונו להלן: "תוכן".  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 36. העלאת כל תוכן לאתר, מהווה הסכמה מפורשת למתן רשות לידי מפעילת האתר לעשות כל שימוש בתוכן זה.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 37. הינך מתחייב שלא להעלות, לשלוף, לשדר, להפיץ או לפרסם כל תוכן, מידע או חומר אחר לרבות אך מבלי למצות סרטונים, סרטים, הודעות, תמונות, קבצים מסוגים שונים, קישורים, טקסטים וכל מידע אחר, אשר:
                א. עלולים להפר זכויות קניין רוחני של הנהלת האתר או של כל אדם אחר, לרבות, זכויות יוצרים, פטנטים, סימני מסחר, סודות מסחריים או כל זכות קניינית אחרת;
                ב. עלולים להגביל או למנוע מאחרים את השימוש באתר שלא בהתאם ליכולות האתר;
                ג. אסורים לפרסום או לשימוש, והם בגדר לשון הרע, גזענות, איום, פגיעה, העלבה, הוצאת דיבה, השמצה, פורנוגרפיה, ביטוי וולגרי אחר, תכנים בעלי אופי מיני בוטה;
                ד. הם בגדר דברים העלולים לעודד, לשדל, להמריץ או לסייע לאחר לבצע מעשה אסור על-פי חוק או עלולים לעורר אחריות משפטית, תכנים שיש בפרסומם עבירה פלילית או אזרחית, תכנים הפוגעים בפרטיות, תכנים מזיקים, תכנים שפוגעים ברגשות הציבור;
                ה. הם בגדר שליחת הודעות ספאם (Spam), הודעות שרשרת, הודעות אחידות, העלאה של קוד תוכנה לאתר, העברת מכתבי שרשרת או דואר זבל, מכל סוג, פרטי או מסחרי;
                ו. כוללים תוכנת מחשב, קוד מחשב או יישום הכוללים נגיף-מחשב ("וירוס"), סוסים טרויאניים, תולעים (Worms), ואנדלים (Vandals), יישומים מזיקים (Malicious Applications), פצצות זמן, רכיבי Cancelbot, קבצים פגומים או תוכנה אחרת או תוכניות דומות אחרות העלולות לפגוע בפעילות תקינה של האתר או של מחשב של אדם אחר או ברכושו של אחר;
                ז. כוללים תוכן המנוגד לכללי השימוש המקובלים באינטרנט או העלול לגרום נזק או לפגוע במשתמשי אינטרנט בכלל, ובמשתמשי האתר בפרט;
                ח. כוללים תוכן העלול להטעות משתמש לרבות כל פרט אישי שלך או של כל משתמש אחר באתר שאיננו נכון ומדויק;
                ט. כוללים תוכן הנוגע לקטינים ומזהה אותם, את פרטיהם האישיים או את מענם ודרכי ההתקשרות עימם;
                י. כוללים תוכן הידוע לך שהוא שקרי, מטעה או מסולף.
                יא. כל תוכן שההנהלה תמצא לנכון להסיר.   `}{" "}
      </h6>
      <h6>
        {" "}
        {` 38. מפעילת האתר אינה מנטרת ואינו בודקת את כלל המידע המועלה לאתר ואין היא יכולה לבדוק ולנטר את כלל מידע המועלה לאתר, לרבות נכונותן של פרטי המשתמשים, הצעות, לרבות  את השאלה האם יש בתוכן המועלה לאתר כדי לפגוע בצדדים שלישיים.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 39. מפעילת האתר רשאית להסיר כל תוכן שיש בו פגיעה בזכויות של צדדים שלישיים, בין באמצעות הפרה של זכות יוצרים, זכויות קניין רוחני בסימני מסחר, סודות מסחריים, הוצאת לשון הרע, פגיעה בפרטיות, פרסום תוכן פורנוגראפי ובלתי הולם בפני קטינים, פגיעה בשם הטוב ובמוניטין של צדדים שלישיים, פגיעה בביטחון המדינה וכל תוכן אחר שההנהלה תמצא לנכון להסיר או לחסום על פי שיקול דעתה הבלעדי.   `}{" "}
      </h6>
      <h6>
        {" "}
        {` 40. אתה מודע לכך שבמהלך השימוש באתר ייתכן ותיחשף לתכנים ממקורות שונים, שמפעילת האתר אינה אחראית להם בשום צורה.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 41. אין לראות בשום מידע ו/או תוכן ו/או המלצה ו/או הדרכה באתר כהתחייבות ו/או אבטחה של מפעילת האתר למימושם. האחריות לתכנים המפורסמים על ידי צדדים שלישיים באתר תהא על המשתמש בלבד, ומפעילת האתר לא תישא באחריות כלשהי בגינן, כאמור.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 42. מפעילת האתר שומרת את הזכות בכל עת למסור לאחרים כל מידע אשר יידרש ממנה לפי חוק, כמו גם על זכותה לערוך, להסיר ולסרב להעלות אל האתר כל מידע, תוכן והודעה, שלפי שיקול דעתה הבלעדי של ההנהלה בהתאם לרצונה לבנות קהילה המתאימה לרוח האתר וכן כל כל תוכן פוגע או מפר תנאי מתנאי הסכם זה.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 43. מפעילת האתר אינה נותנת חסות או מביעה כל דעה לגבי נכונותם או דיוקם של התכנים, הצהרות, הבעות עמדה, עצות או כל מידע אחר המוצג באתר ע"י המשתמשים. אתה מודע לכך שכל הסתמכות שלך על התכנים, הצהרות, הבעות עמדה, עצות או כל מידע אחר המוצג באתר נעשה לפי שיקול דעתך ובאחריותך הבלעדית.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 44. העלאת תכנים לאתר כפופה להצהרת הגורם אשר מעלה תוכן לאתר כי התוכן אינו מפר זכויות קניין רוחני, אינו מהווה לשון הרע, אינו פוגעני וכיו"ב.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 45. המשתמש מתחייב כי יעלה לאתר אך ורק תכנים ו/או תצלומים ו/או קטעי וידאו/אודיו הרלוונטיים לצורך מטרות האתר – היכרות אישית.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 46. מפעילת האתר אינה חברת איחסון או חברת שליחת הודעות ומיותר לציין שחלק מתכני האתר ימחקו אוטומטית כגון "הקלטות קוליות" וכן הודעות אשר עבר זמן רב החל ממועד כתיבתם    `}{" "}
      </h6>

      <label className="form-label"> {`נוהל הודעה והסרה  `} </label>
      <h6>
        {" "}
        {` 46. מפעילת האתר מקפידה על קיום הוראות החוק ומכבדת את זכותם של משתמשי האתר וצדדים שלישים. אם בכל זאת נמצא כי האתר מכיל תוכן המפר זכויות קניין רוחני, מהווה לשון הרע, פוגעני וכיו"ב, הנהלת האתר מתחייבת לפעול להסרת התוכן הרלוונטי לאחר מסירת הודעה כאמור, בהתאם ל"נוהל הודעה והסרה", על פי דין.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 47. על כן, אם אתה סבור כי פורסם באתר תוכן הפוגע בך או בצד ג', אנא פנה אלינו בהקדם האפשרי ואנו נשתדל לטפל בפנייתך בכל ההקדם. בפנייתך עלייך לפרט את טענותיך, לצרף הפנייה אל החומר הפוגע, לצרף כל מסמך אשר עשוי להעיד על זכויותיך ועל עצם הפגיעה בהן. פניות כאמור ניתן להעביר לכתובת הבאה:info@jsmart.app.  `}{" "}
      </h6>

      <label className="form-label">
        {" "}
        {`סודיות, זהירות ודיווח לרשויות על עבירה `}{" "}
      </label>
      <h6>
        {" "}
        {` 48. מפעילת האתר משתמשת בשיטות אבטחה הידועות לה ולחברה המקימה את האתר, על פי דרישות החוק וחברות כרטיסי האשראי, מפעילת האתר לא תישא באחריות למקרים של נזק שייגרם למשתמש בעקבות מידע שהשאיר באתר. ותעשה ככל יכולתה במידות ההיגיון הסבירות הטכנולוגיה הנפוצה הנהוגה ברוב אתרי המסחר וההיכרויות על מנת למנוע מקרים אלו. זאת ועוד פעילות האתר דורשת חשיפת פרטים חריגה, אפילו יחסית למקובל בתחום הדייטינג וזאת בשל אופי האתר והקהילה. החברה עושה מאמצים סבירים ומקובלים על מנת לאבטח את המידע היושב בשרתיה וכן לעשות שימוש באיחסון  עיבוד ושליפת המידע באמצעות חברות צד ג' מוכרות וידועות, אולם אם הנך רגיש לפרטיות שלך אל לך להירשם לאפליקציה ו/או למסור תוכן רגיש.  `}{" "}
      </h6>

      <h6>
        {" "}
        {` 49. מפעילת האתר תשמור בסודיות את המידע שירשם בכרטיס המשתמש באתר. אין מפעילת האתר תהיה אחראית על מידע שנפרץ, או נגנב מן המערכת או מהתרשלות של החברה המאחסנת את האתר. כמו כן, מפעילת האתר לא תהא אחראית לשום נזק שיגרם בשל בעיות בתוכנה או בקוד האתר שלא נעשו במטרת זדון. ידוע למשתמש שאתרי הכרויות רבים בעולם נפרצו ולכן אין לכתוב מידע רגיש ואישי מידי בצ'אט בין המשתמשים השונים.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 50. מפעילת האתר חוזרת ומבקשת לא להעביר למשתמשי האתר מידע העלול לגרום לפגיעה פיזית ו/או נפשית ו/או כספית או כל פגיעה אחרת בהם. אין להעביר למשתמשים מספר כרטיסי אשראי, יש מתחזים רבים באפליקציותהכרויות בייחוד ממדינות זרות נא להיזהר.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 51. מפעילת האתר תבדוק כרטיסים מדגמית וזאת על פי שיקול דעתה ולמטרת שמירה על הצביון המיוחד של האפליקצייה  וכן במקרה של דיווח ו/או חשד לעבירה פלילית ו/או הונאה ו/או הטרדה כל תוכן שנכתב ע"י גולשי האתר על מנת להגן על פעילותה ולשמור את האתר מנזקים שאותם יגרמו המשתמשים שייבדקו. ובמידת הצורך, החברה אף שומרת על זכותה להעביר את המידע לרשויות האכיפה על פי שיקול דעתה.  `}{" "}
      </h6>

      <label className="form-label"> {`חסימת משתמשים ומחיקת משתמשים`} </label>
      <h6>
        {" "}
        {` 52. מפעילת האתר שוקדת לשמור על רמה נאותה ובטוחה של תפעול האתר והשירותים הניתנים בו. אנא דווח על כל בעיה ו/או תוכן פוגעני ו/או כל הפרת מדיניות.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 53. אתה רשאי להשתמש בשירותים שלנו אך ורק כפי המותר בתנאי הסכם זה ועל-פי כל חוק הניתן להחלה.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 54. מפעילת האתר רשאית בכל עת, ומבלי לספק אזהרה או הודעה מוקדמת, להשעות את חשבונך או לסגור את חשבונך ו/או להקטין או לאפס את החשיפות שאתה מקבל , בשל פעילות לא חוקית ו/או אי עמידה בתנאי הסכם זה ו/או חוסר התאמה לרוח הקהילה של האתר.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 55. במידה שתפר את תנאי השימוש ו/או במידה שמפעילת האתר תשעה או תסגור את חשבונך, לא תורשה ליצור חשבון חדש.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 56. אם מפעילת האתר תסגור את חשבונך עקב פעילות לא חוקית או אי עמידה בתנאי הסכם זה, לא תהיה זכאי לכל החזר דמי מנוי.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 57. מפעילת האתר שומרת לעצמה את האפשרות למחוק ו/או להקפיא ו/או לחסום כרטיסים ו/או מידע מתוך כרטיסים מסוימים בהתאם לשיקול דעתה, ולרבות אך מבלי למצות במצבים הבאים:
                א. משתמשים שלא נכנסו זמן רב לאתר;
                ב. משתמשים שלא מתאימים לרוח האתר;
                ג. משתמשים שיש בנוכחתם בכדי פגיעה באתר ו/או פגיעה בגולשי האתר;
                ד. משתמשים שהואשמו בפלילים.
                ה. משתמשים אשר ירשמו בכרטיס שלהם בפירוט ה"על עצמי" או בכל מקום אחר, פרטים מזהים שאיתם אפשר ליצור קשר מחוץ לאתר כגון אי-מייל, טלפון, שם מלא, שם בפייסבוק או בתוכנות מסרים כלשהן וכדומה.
                פעולת המחיקה ו/או ההקפאה עשויה לכלול נתוני כרטיסים ו/או מידע בקשר לכרטיסים האמורים, ו/או חסימת ו/או מחיקת מידע בקשר להודעות, צפיות, חיוכים וכד'.  `}{" "}
      </h6>
      <h6>
        {" "}
        {` 58. אם מפעילת האתר תסגור או תשהה או תגביל או תמחק או תחסום או תקפיא את חשבונך ואתה חולק על כך, עליך להודיע למפעילת האתר בתוך 72 שעות  ממועד סגירת חשבונך כאמור על ידי הגשת הודעת ערעור. אם לא תמסור הודעה כאמור, נראה אותך כמי שוויתר על כל טענה בנוגע לשלילת הזכות להחזר דמי המנוי או לסגירת חשבונך.  `}{" "}
      </h6>

      <label className="form-label"> {`רכישת מנוי ומדיניות ביטולים`} </label>

      <h6>
        {" "}
        {`60. רכישת המנוי כוללת עסקאות של מסלולים לתקופות שונות ומחירים שונים, בהתאם למחירון החברה, כפי שנקבע על ידי מפעילת האתר מעת לעת, לרבות מנוי לתקופה בלתי מוגבלת בזמן. `}{" "}
      </h6>
      <h6> {`61. כל רכישת מנוי מהווה בכל מקרה רכישה בעסקה אחת.`} </h6>
      <h6>
        {" "}
        {`62. רכישת מנוי באתר מאפשרת למנויים אופציות שאינן ניתנות למשתמשים שאינם מנויים, מפעילת האתר שומרת לעצמה את הזכות לשנות את האופציה, לגרוע וכמובן להוסיף מהאפשרויות הפתוחות בפני בעלי המנוי וזאת בהתאם לשיקול דעתה הבלעדי, ומבלי הודעה מוקדמת למנויים.`}{" "}
      </h6>
      <h6>
        {" "}
        {`63. החל ממועד תשלום דמי המנוי ועד למועד פקיעת המנוי, נפתחות בפני המנוי אפשרויות וזכויות  השמורות למנויים בלבד, מפעילת האתר שומרת לעצמה את הזכות לשנות את מחיר חידוש המנוי בכל עת, ללא התראה או הודעה מוקדמת. וכן להוסיף ו/או לתמחר אפשרויות נוספות במחיר נוסף על גבי מחיר המנוי והכל כמקובל באפליקציות דייטינג מוכרות בעולם הפועלות בישראל.`}{" "}
      </h6>
      <h6>
        {" "}
        {`64. יובהר כי התשלום הינו בעבור זכות הגישה וזכות השימוש ולא בעבור שימוש בפועל, ובהתאם, העדר שימוש ו/או ניצול המנוי אינו מהווה עילה להחזר כספי מכל סיבה.`}{" "}
      </h6>
      <h6>
        {" "}
        {`65. כל רכישת מנוי תחויב בכל מקרה, גם במקרה של ביטול העסקה, בעלות מחיר המחירון לתקופה היחסית בה ניתנה למשתמש זכות הגישה.`}{" "}
      </h6>
      <h6>
        {" "}
        {`66. מחיר  המחירון למימוש זכות גישה/שימוש חד-פעמית לאפשרות שמציע  "מנוי ליום",  הינו 50 ₪.`}{" "}
      </h6>
      <h6>
        {" "}
        {`67. מחיר המחירון החודשי באתר, עומד כיום על 99 ש"ח לחודש, ומעודכן מעת לעת, על פי שיקול דעתה של החברה. כפי שמופיע בעמודי הרכישה באתר `}{" "}
      </h6>
      <h6>
        {" "}
        {`68. מפעילת האתר  רשאית להעניק הנחה ממחיר המחירון,והיא נוהגת לעשות זאת בין היתר, במסלולים עם התחייבות לתקופה קצובה.`}{" "}
      </h6>
      <h6>
        {" "}
        {`69. שים לב כי מדיניות ביטול רכישת המנוי באמצעות האפליקציות של 'jsmart למשתמשי הסלולר הגולשים בה באמצעות מכשירי חברת אפל (Apple) שונה וכפופה לפרטי ההסכם בין המשתמש ובין חברת אפל אליה הוא מנוי ודרכה ניתן השירות, ובה מדיניות ביטולים שונה מזו המפורטת כדלהלן.`}{" "}
      </h6>
      <h6>
        {" "}
        {`70. כמו כן מובהר בזאת כי הקפאת ו/או חסימת ו/או מחיקת החברות באתר לא מחייבים הפסקת ספירת הימים הנותרים למנוי ע"י המערכת. ללא קשר לאמור לעיל החברה תהיה רשאית להקפיא מנוי לזמן קצוב על דעתה בלבד.`}{" "}
      </h6>
      <h6>
        {" "}
        {`72. לא ניתן לבטל או להעביר מנוי לאחר הזמנה ותשלום באתר, אלא בהתאם להוראות חוק הגנת הצרכן, תשמ"א - 1981. `}{" "}
      </h6>
      <h6>
        {" "}
        {`73. בעסקאות תשלומים. כרטיס אשראי שאינו תומך באפשרות זאת יחויב בתשלום אחד. `}{" "}
      </h6>
      <h6>
        {" "}
        {`74. מפעילת האתר רשאית להעניק מנויים לאנשים או לבטלם את הענקתם ללא הודעה מוקדמת וללא תנאי. `}{" "}
      </h6>
      <h6>
        {" "}
        {`75. ברכישת מנוי מתמשך, נותן הרוכש הסכמתו לתשלום דמי המנוי החודשיים לתקופה בלתי קצובה. `}{" "}
      </h6>
      <h6>
        {" "}
        {`76. המנוי רשאי להודיע בכל עת בה יחפוץ על ביטול המנוי ללא קנס ו/או דמי ביטול, בכפוף לחיוב החלק היחסי בגין התקופה בה ניתנה זכות הגישה להודעות ו/או שחרור מגבלת ה"סוויפים" ו/או האפשרויות הנוספות (אולי להגדיר אפשרויות נוספות) , על פי המחירון היומי ו/או החודשי, בהתאם לעניין ולמשך הזמן שחלף עד למועד ביטול המנוי. -  `}{" "}
      </h6>
      <h6>
        {" "}
        {`77. למען הסר ספק, במקרה של ביטול ההתקשרות, במהלך תקופת התחייבות מינימאלית, תבוטל ההנחה ממחיר המחירון והמנוי יראה, לצורך קבלת זיכוי כספי, כמי שרכש מנוי על פי מחיר המחירון לתקופה הרלוונטית, ובהתאם, ההתחשבנות לצורך ביטול העסקה תתבצע על בסיס מחיר המחירון, כדלקמן:
                א. בוטל המנוי במהלך תקופת החודש הראשון להתקשרות - יחויב המנוי לפי מחיר המחירון של "מנוי ליום" בגין היום הראשון של המנוי, ובגין כל יום נוסף עד למועד ביטול המנוי, על פי החלק היחסי ממחיר המחירון החודשי של החברה, אך בכל מקרה החיוב לא יעלה על מחיר המחירון של מנוי חודשי באתר.
                ב. בוטל המנוי לאחר חודש ימים- יחויב המנוי לפי מחיר המחירון החודשי, הן בגין כל חודש מלא שחלף ממועד יום רכישת המנוי ועד למועד ביטול המנוי, והן בגין החלק היחסי של הימים אשר נוצלו במהלך החודש בו בוטל המנוי (חודש אשר לא נוצל במלואו). `}{" "}
      </h6>
      <h6>
        {" "}
        {`78. המנוי רשאי להודיע בכל עת בה יחפוץ על ביטול המנוי לאתר, באמצעות פניה למוקד שירות הלקוחות של המפעילה בטלפון 08-6223925 או באמצעות משלוח דואר אלקטרוני לכתובת info@jsmart.app או באמצעות פניה בדואר אל המפעילה (חברת סמארט להכיר חכם בע"מ) לכתובת: ת.ד. 189 מיקוד 7410101.  (להלן: "הודעת הביטול"). * המפעילה תחדל לחייב את המנוי בדמי המנוי החודשיים לאתר, בתוך שלושה ימי עסקים מיום מסירת הודעת הביטול, ובמידה והודעת הביטול נמסרה בדואר רשום - בתוך שישה ימי עסקים מיום מסירת הודעת הביטול למשלוח ("הביטול"). `}{" "}
      </h6>
      <h6>
        {" "}
        {`79. יודגש כי במקרה של ביטול מנוי לאחר תקופת ההתחייבות המינימאלית הקבועה במסלול, ככל שתהיה במסגרת תקופה קצובה לפי הגדרות שלעיל, המפעילה לא תגבה סכום כלשהו כדמי ביטול. במקרה כאמור, אם בוטל המנוי הרלוונטי במהלך החודש שעבורו שולמו דמי המנוי, יינתן למנוי החזר יחסי בלבד של דמי המנוי, בהתאם למחיר הרלוונטי אשר שולם על ידי המשתמש עד למועד ביטול המנוי, וזאת בתוך 14 ימים מיום מסירת הודעת הביטול. לא יינתן החזר כספי בגין התקופה בה נעשה שימוש בשירותים המוצעים באתר בתשלום ואשר קדמה למועד הביטול. `}{" "}
      </h6>
      <h6>
        {" "}
        {`80. מנוי רשאי להודיע בכל עת בה יחפוץ על רצונו להפסיק את החיוב החודשי על המנוי, כך שהוא יוכל להמשיך ולעשות שימוש בשירותים עד תום אותו חודש קלנדרי ומהחודש העוקב לא יחויב בגין המנוי, וזאת באמצעות פנייה לעמוד "הגדרות החשבון שלי" או באמצעות פניה למוקד שירות הלקוחות בדרכים המצוינות לעיל, ואולם כאמור במקרה של ביטול ההתקשרות במהלך תקופת ההתחייבות המינימאלית תהיה בעלת האתר רשאית לגבות את מלוא סכום ההתחייבות. `}{" "}
      </h6>

      <label className="form-label">
        {" "}
        {`אפליקציה סלולארית במכשיר אייפון או אנדרואיד`}{" "}
      </label>
      <h6>
        {" "}
        {`81. מפעילת האתר מפעילה עבור חברת Apple אפליקציות של "jsmart" (להלן:" האפליקציה"). מפעילת האתר תבהיר כי כל פעולה באפליקציה, לרבות רכישת מנוי, מבוצעת בין המנוי אל מול חברת אפל (Apple) בלבד, בהתאם לתנאי ההתקשרות אשר ייקבעו על ידי אפל מעת לעת ובהתאם ל – App Store Terms of Service, לרבות בכל הנוגע לדין החל על ההתקשרות והרכישה וכן בכל הנוגע למדיניות החזרות וזיכויים ומפעילת האתר כלל אינה צד להתקשרות הלקוח עם אפל,  `}{" "}
      </h6>
      <h6>
        {" "}
        {`82. בהתאם, מפעילת האתר אינה אחראית לכל בקשה ו/או טענה ו/או תביעה לביטול מנוי ו/או החזר כספי מכל סיבה שהיא וכל טענה ו/או דרישה בקשר עם האפליקציה יש להפנות ישירות לחברת Apple . `}{" "}
      </h6>
      <h6>
        {" "}
        {`83. במידת הרלוונטיות, הוראות תקנון זה תחשבנה כהסכם לטובת צד שלישי בכל הנוגע לאפל, כמוטב ובאישור תקנון זה הנך מעניק לאפל זכות לאכוף הסכם זה כנגדך ו/או כנגד צדדים שלישיים.`}{" "}
      </h6>
      <h6>
        {" "}
        {`84. משתמש הנרשם לאתר מאשר את קבלת Push Notifications ממפעילת האתר על מספר המכשיר הסלולרי שהוזן על ידו. משתמש יוכל לבטל את ההתראות אלו רק באמצעות הגדרת התראות אפליקציה שבמכשיר הסלולרי.`}{" "}
      </h6>
      <h6>
        {" "}
        {`85. החברה פועלת על מנת להנגיש את האתר עבור אנשים עם מוגבלות הנגשת האפליקציה נועדה להתאימה לשימוש, כך שיהיה זמין ונוח עבור אוכלוסיות בעלות צרכים מיוחדים. `}{" "}
      </h6>

      <label className="form-label">
        {" "}
        {`מדיניות פרטיות, אבטחת מידע, עוגיות (cookies)`}{" "}
      </label>
      <h6>
        {" "}
        {`85. השימוש ביישומי מפעילת האתר כפוף למדיניות הפרטיות בה. בעצם השימוש ביישומי האתר אתה נחשב כמי שקרא והסכים למדיניות הפרטיות של האתר. `}{" "}
      </h6>
      <h6> {`86. ניתן לעיין במדיניות הפרטיות של מפעילת האתר להלן. `} </h6>

      <label className="form-label"> {`שיפוי`} </label>
      <h6>
        {" "}
        {`90. אתה מסכים לשפות ולפצות את ההנהלה, עובדיה, מנהליה, בעלי מניותיה או מי מטעמם בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם, ובכלל זה שכר טרחת עו"ד והוצאות משפט, עקב הפרת תנאי שימוש אלה. `}{" "}
      </h6>

      <label className="form-label"> {`סמכות שיפוט והדין`} </label>
      <h6>
        {" "}
        {`91. תנאי שימוש אלו וכל הסכם אחר שנוצר על ידי ההנהלה הקשור בשימוש באתר, יהיו כפופים לדיני מדינת ישראל. סמכות השיפוט הבלעדית לדון בכל סכסוך העולה מהסכם זה תהיה נתונה לבתי המשפט בתל אביב.  `}{" "}
      </h6>
      <h6>
        {" "}
        {`92. תניית שיפוט זו גוברת על כל הסכמה או חוזה, ככל שהייתה, אם בכתב, בעל פה או בכל דרך אחרת, בין הצדדים. `}{" "}
      </h6>
      <h6>
        {" "}
        {`93. חוזה זה כפוף אך ורק לדין הישראלי, מבלי להשפיע על סוגיות במשפט בינלאומי פרטי. `}{" "}
      </h6>
      <h6>
        {" "}
        {`94. על חוזה זה יחולו הוראות חוק החוזים (תרופות בשל הפרת חוזה), התשל"א – 1970. בלי לגרוע מן הכלליות או לגרוע מן הסעדים הניתנים על פי הוראות כל דין. `}{" "}
      </h6>

      <br />
      <h5 className="form-label">
        <b> {`מדיניות פרטיות `} </b>
      </h5>
      <h6>
        {" "}
        {`מדיניות פרטיות זו מהווה חלק בלתי נפרד מתקנון האתר ותנאי השימוש בו. השימוש בשירותי האתר ו/או היישומים הנלווים לו, כפוף לתנאי השימוש ומדיניות פרטיות זו ומעיד על הסכמה לתנאים אלה.
                אם אינך מסכים למדיניות השמירה על הפרטיות, הינך נדרש שלא להירשם לשירותי האתר.
                לידיעתך מפעילת האתר התקשרה עם צד ג' - חברת "שליש גן עדן בע"מ" לקבלת שירותי ניהול, תפעול ואדמיניסטרציה, ובהתאם עובדיה ומנהליה של שליש גן עדן בע"מ מעניקים שירותים שונים למפעילת האתר ובמסגרת זו - חשופים למידע באתר, והנך מאשר ומסכים לכך באישור מדיניות פרטיות זו. 
                בהתאם, לצורך מדיניות פרטיות זו יהיה משמעות המונח "מפעילת האתר" ו/או "Jsmart" ו/או "החברה" - לרבות חברת שליש גן עדן ו/או כל ספק שירות אחר עימו תתקשר מפעילת האתר לצורך תפעול ו/או ניהול ו/או קבלת שירותים רלוונטים הכרוכים במתן זכות גישה למידע באתר.  `}{" "}
      </h6>

      <label className="form-label"> {`כללי`} </label>
      <h6>
        {" "}
        {`1. הפרטיות שלך חשובה לנו מאוד. על מנת להבין טוב יותר איזה מידע אנו אוספים ומהו השימוש שאנו עושים במידע זה, אנא קרא את מדיניות הפרטיות המפורטת להלן. `}{" "}
      </h6>
      <h6>
        {" "}
        {`2. מדיניות פרטיות זו חלה על מידע הנאסף במסגרת השימוש שלך ביישומי החברה בלבד ובהתאם להסכמת המשתמש בתנאים שנזכרים להלן. `}{" "}
      </h6>
      <h6>
        {" "}
        {`3.  בעצם השימוש באתר אתה מסכים ומאשר את מדיניות הפרטיות שלהלן.
                אנו עשויים לשנות ו/או לערוך ו/או לעדכן את מדיניות הפרטיות מעת לעת. אתה מוזמן לבדוק את העמוד מדי פעם כדי לוודא שאתה מכיר את כל השינויים. `}{" "}
      </h6>

      <label className="form-label"> {`מידע אודות המשתמש`} </label>
      <h6>
        {" "}
        {`4. מדיניות פרטיות זו מסבירה כיצד סמארט להכיר חכם בע"מ (להלן: "החברה") משתמשת, אוספת ושומרת את המידע שאתה מספק ומוסר באתרי/יישומי החברה (להלן: "האתר") והכל בכפוף לדיני מדינת ישראל. `}{" "}
      </h6>

      <label className="form-label"> {`סוג הפרטים והמידע שנאספים`} </label>
      <h6>
        {" "}
        {`5. החברה רשאית לאסוף, לעבד ולשמור (לרבות בשרתים מחוץ לישראל) את המידע המפורט להלן ולעשות בו שימוש על פי מדיניות פרטיות זו:
                א. כל מידע אשר נמסר לחברה בעת ההרשמה והשימוש באתר. המתבצעת באמצעות מילוי פרטים בטופס הרשמה, ובכלל זה עשויים לכלול פרטים אישיים, המוגדרים כ"מידע רגיש", על פי דין, כגון: שם, תצלומים, מקום מגורים, עיסוק, תחביבים, תחומי עניין, נתונים פיזיים ותכונות ומאפיינים אישיים שונים, תאריך לידה, מצב אישי, השכלה, תכתובות בינך לבין משתמשים, מידע פיננסי (כגון פרטי כרטיס אשראי) וכיו"ב (להלן: "הפרטים האישיים"), אשר ייאספו, יעובדו וישמרו ויעשה בהם שימוש על ידי החברה.
                ב. פעולות שונות באתר עשויות לדרוש ממך לספק פרטים שונים, כגון: שם מלא, מספרי טלפון, כתובות דואר ודואר אלקטרוני, מספר תעודת זהות ומספר כרטיס אשראי, אשר ייתכן שחלקם יאספו ויאוחסנו על ידי החברה.
                ג. מידע אודות השימוש שלך באתר, מידע אודות פעולות ו/או עסקאות הנעשות באמצעות האתר, מקור ההפניה לאתר, כל מידע בנוגע לשימוש ולצפיה באתר ו/או כל מידע אחר שאתה מוסר ו/או מעלה ו/או מפרסם על גבי האתר.
                ד. מידע אישי בדבר מיקום המשתמש, לרבות שמירה של היסטוריית המיקומים של המשתמש, כתובת IP, מידע בדבר איכון המיקום, מידע על מסלול המשתמש (לדוגמה בצורה של אותות GPS) ומידע אחר אשר נשלח על-ידי המכשיר הסלולארי שבו מותקן ומופעל יישום jsmart ו/או יישומים ו/או שירותים אחרים של החברה ו/או מי מטעמה. `}{" "}
      </h6>

      <label className="form-label"> {`הצגת מיקום ושימוש במיקום`} </label>
      <h6>
        {" "}
        {`6. ככל והמשתמש אישר את הצגת מיקומו, החברה רשאית להציג את המיקום האחרון של המשתמש, כמיקום היסטורי - המקום בו נצפה המשתמש לאחרונה. לרבות על ידי הצגת מיקום המכשיר הסלולארי שבו מותקן ומופעל יישום jsmart  ו/או יישומים ו/או שירותים אחרים של החברה ו/או מי מטעמה. `}{" "}
      </h6>
      <h6>
        {" "}
        {`7. בנוסף, מפעילת האתר רשאית לעשות שימוש במידע איכון המיקום, על מנת לנהל מסעות פרסום, בין היתר, על בסיס מיקום גיאוגרפי. `}{" "}
      </h6>

      <label className="form-label"> {`הצגת ושימוש במידע פרטי באתר `} </label>
      <h6>
        {" "}
        {`8. על מנת למצוא שידוך, מפעילת האתר תציג את פרטי כרטיס המשתמש והפרטים האישיים בפני משתמשי האתר (לרבות גולשים שאינם רשומים לאתר) בכלל הפלטפורמות השונות של החברה, העוסקות בכך. הן בפלטפורמות להצגת משתמשים לצורכי היכרויות שהחברה משווקת מתוצרתה, והן בפלטפורמות שיווק חיצוניות, של צדדים שלישיים, שהחברה עושה בהם שימוש. `}{" "}
      </h6>
      <h6>
        {" "}
        {`9.  בנוסף, המידע הנזכר לעיל עשוי בין השאר לשמש לצורך:
                א. שיפור הגישה שלך לאתר ולשימוש בשירותי החברה;
                ב. התאמה אישית של האתר ושירותיו וכן שירותים נוספים של החברה ו/או מי מטעמה;
                ג. שיפור שירות הלקוחות;
                ד. ניהול טוב יותר את החברה ויישומיה;
                ה. הספקת השירותים ו / או המוצרים שהחברה ו/או מי מטעמה מספקים;
                ו.  משלוח הדו"חות, תקשורת שיווקית, מיילים בדבר שירותים, פרסומות וכיו"ב;
                ז. עיבוד העסקאות שנעשו באמצעות יישומי החברה;
                ח. פרסום מידע ביישומי החברה אודותיך;
                ט. הצגה או משלוח של חומרי שיווק ופרסום, לרבות שימוש במידע איכון על מנת להציג פרסומות מותאמות מבוססות נתונים ו/או מיקום גיאוגרפי.`}{" "}
      </h6>
      <h6>
        {" "}
        {`10. אנו עשויים לעשות שימוש במידע פיננסי שלך באתר על מנת לבצע התאמה לשימוש באתר, לבצע חיובים עתידים באתר. `}{" "}
      </h6>

      <label className="form-label">
        {" "}
        {`שימוש במידע על ידי צדדים שלישיים`}{" "}
      </label>
      <h6>
        {" "}
        {`11. אנו עשויים לחשוף את המידע האישי שלך לסוכנים שלנו או לקבלני המשנה שלנו או לכל צד שלישי אחר למטרות אלה. `}{" "}
      </h6>
      <h6>
        {" "}
        {`12. בנוסף, אנו עשויים לחשוף מידע אישי ופרטי אם וכאשר אנו סבורים כי שחרור כזה הוא ראוי ו / או נחוץ בכדי לציית לחוק, כדי לקיים את פסיקת בית משפט ו / או צו בית משפט, כדי להגן על זכויותינו ועל זכויותיהם של צדדים אחרים ו / או על רכושם ועל מנת לאכוף את המדיניות באתר וביישומיו. `}{" "}
      </h6>
      <h6>
        {" "}
        {`13. בנוסף, אנו עשויים לעשות שימוש במידע אודותיך לצורך הצגה מותאמת של תכנים במערכות Google  , Facebook וכיו"ב. `}{" "}
      </h6>
      <h6>
        {" "}
        {`14. אנו עשויים לעשות שימוש בשירותי חברת פרסום כדי להציג מודעות מטעמנו ברשת. חברה זו עשויה לאסוף מידע פרטי על הגלישה שלך באתר זה. צד שלישי זה עשוי להשתמש במידע על ביקוריך באתרי האינטרנט שלנו לצורך פרסום על מוצרים ושירותים העשויים לעניין אותך. `}{" "}
      </h6>
      <h6>
        {" "}
        {`15. אנו עשויים לספק מידע פיננסי אודותיך לספקי השירותים שלנו ולכל צד שלישי וזאת לצורך תשלום על מנוי, תמיכה וייעול הפעילויות שלך באתר. צד שלישי זה עשוי להיות, חברות כרטיסי האשראי ומוסדות בנקאיים המטפלים ותומכים בעסקה או בפעילות האתר. אותם צדדים שלישיים יהיו רשאים לעשות שימוש במידע הפיננסי אודותיך, בכפוף להסדרים החוזיים שלנו עם צדדים שלישיים אלה, ובהתאם למדיניות לשמירה הפרטיות של אותם גופים.
                ככל ויוצגו בפניך פרסומות באתר הן עשויות להיות מוצגות באמצעות מערכת פרסום חיצונית של צד שלישי  (לדוגמא שירות של חברת גוגל בשם admob)  . `}{" "}
      </h6>

      <label className="form-label"> {`תקשורת עמך`} </label>
      <h6>
        {" "}
        {`16. מספר הטלפון שלך, ו/או כתובת דואר אלקטרוני שסופקה על ידך לחברה לצורך רישום ו/או עיבוד תקשורת ו/או הזמנות ו/או יצירת קשר ו/או זיהוי ו/או עסקאות מכל סוג שהוא, תשמש למטרה של שליחת מידע, פרסומים, ועדכונים לשימוש שלך באתר האינטרנט, כמו גם לתקשורת עמך ו/או להזמנות שלך ולצרכים המפורטים במדיניות פרטיות זו, כפי שתתעדכן מעת לעת. `}{" "}
      </h6>
      <h6>
        {" "}
        {`17. מפעילת האתר ו/או מי מטעמה, באמצעותה ובאמצעות צדדים נוספים מטעמה, רשאים לפנות למשתמשי האתר באמצעות הדואר האלקטרוני לצרכי: פרסום, תקשורת ומשלוח הודעות שונות. `}{" "}
      </h6>
      <h6>
        {" "}
        {`18. אם וכאשר אתה מעדיף להפסיק לקבל חומרי שיווק, פרסום ו/או הודעות דוא"ל עתידיות, אנא עקוב אחר ההוראות לביטול הרישום המפורטות בחלק התחתון של כל הודעת דוא"ל שאנו שולחים לך, או שלח לנו מייל לדואר info@jsmart.app. `}{" "}
      </h6>

      <label className="form-label"> {`אבטחת מידע`} </label>
      <h6>
        {" "}
        {`19. אנו ננקוט באמצעי זהירות טכניים וארגוניים סבירים כדי למנוע אובדן, שימוש לרעה או פגיעה במידע האישי שלך. `}{" "}
      </h6>
      <h6>
        {" "}
        {`20. אנו נוקטים באמצעים שונים כדי להבטיח את המידע האישי שהמשתמשים באתר נדרשים לספק לצורך גישה, כניסה, שליחה של מידע ותקשורת, או בכדי לשנות את המידע האישי שניתן על ידי המשתמש. `}{" "}
      </h6>
      <h6>
        {" "}
        {`21. בהתאם לאופי הפעילות באינטרנט, וכפי שקרה בעבר באתרים שונים בעולם. עלולים להיות מצבים שבו מידע רגיש מאתרי הכרויות דלף החוצה באמצעות שימוש לא חוקי, פריצה או מעשי זדון אחרים. עליך להימנע ממסירת מידע שאתה חושב שיש בו כדי לפגוע בפרטיותך. `}{" "}
      </h6>
      <h6>
        {" "}
        {`22. פעילות האתר דורשת חשיפת פרטים חריגה, אפילו יחסית למקובל בתחום הדייטינג וזאת בשל אופי האתר והקהילה. החברה עושה מאמצים סבירים ומקובלים על מנת לאבטח את המידע היושב בשרתיה וכן לעשות שימוש באיחסון  עיבוד ושליפת המידע באמצעות חברות צד ג' מוכרות וידועות, אולם אם הנך רגיש לפרטיות שלך אל לך להירשם לאפליקציה ו/או למסור תוכן רגיש. `}{" "}
      </h6>

      <label className="form-label"> {`עוגיות (cookies)`} </label>
      <h6>
        {" "}
        {`22. עוגייה היא מחרוזת אותיות ומספרים המשמשת לאימות, למעקב ולאגירת מידע על אודות גולש באתר אינטרנט, כגון שמירת העדפות המשתמש. `}{" "}
      </h6>
      <h6>
        {" "}
        {`23. ההסכמה לשימוש שלנו בעוגיות בהתאם לתנאים של מדיניות זו בזמן הביקור הראשון באתר שלנו, מאפשרת לנו להשתמש בעוגיות בכל פעם שאתם מבקרים באתר. `}{" "}
      </h6>
      <h6>
        {" "}
        {`24. אנו משתמשים בעוגיות למטרות שונות, לרבות, על מנת לפקח ולנתח נתונים על התנועה באתר, לזכור ולנתח פריטים המועברים על ידי המשתמש לאתר, כדי לשמור ולהתאים את העדפות המשתמש לשימוש עתידי באתר, חיסכון בצורך להזין מחדש את שם המשתמש והסיסמה בכל כניסה לשירות, על מנת להקל על השימוש באתר ו/או ביישום הסלולארי, על מנת לאסוף מידע סטטיסטי, לאמת את המידע, וכן לצורך אבטחת מידע וכל מטרה אחרת המפורטת במדיניות פרטיות זו. `}{" "}
      </h6>
      <h6>
        {" "}
        {`25. ניתן לחסום עוגיות דרך הגדרות הדפדפן. אפשרות זו עלולה לגרום לשירותים מסוימים שבאתר להציג תקלה ו/או שלא לפעול כראוי. במידת הצורך, המשתמש יכול עדיין לעשות שימוש באתר, במידת האפשר, על ידי פנייה ישירה לשירות הלקוחות בטלפון  08-6223925 או באמצעות פנייה לדוא"ל: info@jsmart.app. `}{" "}
      </h6>

      <label className="form-label"> {`מחיקת מידע אישי`} </label>
      <h6>
        {" "}
        {`26. עם קבלת בקשת משתמש למחיקת הכרטיס באתר והפסקת הפעילות של הלקוח באתר אנו נפעל להפסקת הצגת המידע הפרטי שלך באתר ואולם, מפעילת האתר שומרת על זכותה להמשיך לדוור ולפרסם ללקוח ואף לשמור את המידע האישי במאגרי החברה.  `}{" "}
      </h6>
      <h6>
        {" "}
        {`27. אם ברצונך כי פרטייך יימחקו כליל מן מהמאגר, או אם אין ברצונך להמשיך ולקבל מידע מסחרי כאמור, וברצונך לבטל את הסכמתך ולחדול מקבלת המידע המסחרי, תוכל לעשות זאת, בכל עת באמצעות פניה לכתובת דוא"ל  info@jsmart.app  ואולם מחיקה מוחלטת מהמאגרים שלנו כפופה לסייגים הבאים: 
                א. מפעילת האתר תעשה כל מאמץ למחוק המידע האפשרי, אולם ייתכן שהדבר לא יוכל להתבצע באופן מיידי ממערכות הגיבוי.
                ב. ייתכן ולא ניתן יהיה לבצע מחיקה של כל הנתונים ממערכות הגיבוי שלנו. 
                ג. כדי לאפשר לנו לבדוק, למנוע ולדווח לרשויות החוק על שימוש לרעה באתר אנו עשויים שלא לאפשר את המחיקה של הנתונים כגון: שם משתמש, מועד הרשמה, מועד ביצוע פעולות, כתובות IP. גם במקרה של חסימת משתמש על ידי האתר, הפרטים הרלוונטיים ישמרו בבסיס הנתונים וזאת בין היתר על מנת למנוע רישום מחודש לאתר.
                ד. אנו עשויים לשמור במאגר מידע שנועד לצורך יישוב מחלוקות, לאיתור וטיפול בבעיות ולאכיפת הכללים ותנאים שלנו. `}{" "}
      </h6>

      <label className="form-label">
        {" "}
        {`שירותים ומידע של צדדים שלישיים באתר`}{" "}
      </label>
      <h6>
        {" "}
        {`28. תיתכן אפשרות לאפשר לאתר זה גישה לפרטים אישיים ומדיה (תמונות, סרטים וכד') הנמצא באתרים חיצוניים (אתרי מדיה חברתית, וכד'). בנוסף, יתכנו דרכים שונות להתחבר וליצור משתמש (USER) ו/או להעלות ו/או ליצור קישור לפרטים אישיים באתר. במקרים מסוימים ניתן לבצע חיבור לאתר וזיהוי פרטי המשתמש דרך אתרים חיצוניים, כגון חיבור לחשבון Facebook או חשבון Google ואף יתכן שחלק מהמידע יאוחסן בשרתים חיצוניים, כגון  שימוש ב Cloudinary ו Algolia. `}{" "}
      </h6>
      <h6>
        {" "}
        {`29. יובהר, כי אין באפשרות האתר לפקח או לנטר על מידע שמועבר מאתרים חיצוניים ועל כן לא נהיה אחראים להפרות פרטיות הנגרמות עקב הזדהות או העברת נתונים מאתרים חיצוניים אלו. `}{" "}
      </h6>
      <h6>
        {" "}
        {`30. יובהר, כי במתן ההרשאה לחיבור האתר עם האתרים החיצוניים הנך מאפשר לאתר גישה ושימוש מלא לפרטים ולמדיה אשר העלית לאותם אתרים. `}{" "}
      </h6>

      <label className="form-label"> {`תנאי שימוש`} </label>
      <h6>
        {" "}
        {`31. השימוש ביישומי החברה כפוף לתנאי השימוש בה, המפרטים ומרחיבים את תנאי השימוש, הויתורים וההגבלות השונות. בעצם השימוש ביישומי החברה אתה נחשב כמי שקרא והסכים לתנאי השימוש בה. `}{" "}
      </h6>
      <h6>
        {" "}
        {`32. ניתן לעיין בתנאי השימוש של החברה בכתובת: /terms-privacy/. כתובת מלאה וקישור `}{" "}
      </h6>
      <h6>
        {" "}
        {`33. שים לב כי נציגי האתר ועובדיו בודקים דיווחים שונים של משתמשים שונים בקשר לתוכן ההודעות של משתמשי האתר. לרבות ביחס לאופיו של אתר, המתמחה בקהל מסורתי. התנהלות זו הכוללת שמירה על אופי האתר ועל תכניו הנם חלק מאבני היסוד של האתר. והינך מנוע מלטעון כל טענה בקשר לכך. `}{" "}
      </h6>

      <label className="form-label"> {`יצירת קשר`} </label>
      <h6>
        {" "}
        {`34.  בכל שאלה ו/או בקשה הקשורה לסוגיות פרטיות ומדיניות פרטיות זו, אנא אל תהססו ליצור עמנו קשר ב: חברת סמארט להכיר חכם בע"מ (ח.פ 516320249), מען למכתבים: ת.ד. 189 מיקוד 7410101, מספר טלפון: 08-6223925 , כתובת דואר אלקטרוני: info@jsmart.app `}{" "}
      </h6>
    </div>
  );
};
