import * as types from "./actionTypes";
import QueueService from "services/queue";
import BaseChatEvents from "consts/baseChatEvents";
import { getLoggedInUserUID } from "redux/auth/selectors";

export const openChatSentFromMassletter = (colleagueUid) => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		await QueueService.sendEvent(uid, BaseChatEvents.OpenChatSentFromMassletter, {
			colleagueUid: colleagueUid,
		});
	};
};

export const addLocalChatSent = (uid, chatID) => {
	return {
		type: types.CHAT_SENT_ADD_TEMPORARY_CHAT_SENT,
		payload: { [uid]: { chatID, deleted: false, isTemporary: true } },
	};
};

export const addChaSentUser = (payload) => {
	return { type: types.CHAT_SENT_ADDED_SUCCESSFULLY, payload };
};

export const updateLoadMoreSent = (payload) => {
	return { type: types.UPDATE_MORE_NUMBER, payload };
};

export const resetChatSentUsers = (payload) => {
	return { type: types.RESET_CHAT_SENT_USERS, payload };
};
