import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmBox = ({ visible, onCancel, onConfirm }) => {
  const confirm = () => {
    onCancel();
    onConfirm();
  };

  return (
    <Modal isOpen={visible} toggle={onCancel} className="simpleDialog">
      <ModalHeader>{i18next.t("Edit Profile.Unsaved Change")}</ModalHeader>
      <ModalFooter>
        <Button
          outline
          size="sm"
          color="primary"
          onClick={confirm}
          className="btn-rounded littleBtn"
        >
          {i18next.t("yes")}
        </Button>
        <Button
          outline
          size="sm"
          color="primary"
          onClick={() => onCancel()}
          className="btn-rounded littleBtn mainBtn"
        >
          {i18next.t("no")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmBox;
