import firebase from "firebase/app";
import "firebase/functions";
import AnonymousCallTypes from "../consts/anonymousCallTypes";
const functions = firebase.functions();

class AnonymousCallService {
	call = async (type, data) => {
		const _data = { type, data };
		const result = await functions.httpsCallable("anonymousCall")(_data);
		return result.data;
	};
	clickEvent = async (affId) => {
		return await this.call(AnonymousCallTypes.click_event, {
			affId,
		});
	};
}

export default new AnonymousCallService();
