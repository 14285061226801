export const INIT_PLATFORM_SUCCESSFULLY = "system.INIT_PLATFORM_SUCCESSFULLY";
export const INIT_PLATFORM_FAILED = "system.INIT_PLATFORM_FAILED";

export const INIT_FCM_TOKEN_FETCH_SUCCESSFULLY = "system.INIT_FCM_TOKEN_FETCH_SUCCESSFULLY";
export const INIT_FCM_TOKEN_FETCH_FAILED = "system.INIT_FCM_TOKEN_FETCH_FAILED";

export const INIT_FCM_TOKEN_SAVE_SUCCESSFULLY = "system.INIT_FCM_TOKEN_SAVE_SUCCESSFULLY";
export const INIT_FCM_TOKEN_SAVE_FAILED = "system.INIT_FCM_TOKEN_SAVE_FAILED";

export const INIT_FCM_TOKEN_SAVE_UNNECESSARY = "system.INIT_FCM_TOKEN_SAVE_UNNECESSARY";
export const INIT_FCM_TOKEN_FETCH_UNNECESSARY = "system.INIT_FCM_TOKEN_FETCH_UNNECESSARY";
