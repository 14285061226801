import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import Education from "consts/education";
import MultiSelect from "components/MultiSelect";

export default ({ onChange, profile }) => {
	const _onChange = (key, values) =>
		onChange(
			key,
			values.map((item) => Number(item))
		);

	return (
		<MultiSelect
			field={ProfileFields.Education}
			fieldOptions={Object.values(Education)}
			onChange={_onChange}
			initSelected={profile.education}
			title={i18next.t("Edit Profile.Education")}
			translatePrefix="Education"
		/>
	);
};
