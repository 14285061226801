import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { FormGroup, Input, InputGroup, FormFeedback } from "reactstrap";
export default ({
	type = "text",
	onValidityChange = () => {},
	onEnterPressed,
	onChange,
	hasError,
	errMsg,
	initVal,
	validateExpresion = () => true,
}) => {
	const [value, setValue] = useState(initVal);
	const [isValid, setIsValid] = useState(false);

	const handleOnChange = (val) => {
		setValue(val);
	};

	const handleKeyPress = (keyPressed) => {
		if (keyPressed === "Enter") {
			onEnterPressed();
		}
	};

	useEffect(() => {
		const _isValid = validateExpresion(value);
		setIsValid(_isValid);
		onChange(value);
	}, [value]);

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	return (
		<FormGroup>
			<InputGroup>
				<Input
					type={type}
					autoFocus={true}
					value={value}
					onChange={(e) => handleOnChange(e.target.value)}
					placeholder={i18next.t("Type")}
					onKeyPress={(e) => handleKeyPress(e.key)}
					{...(hasError && "invalid")}
				/>
				{hasError && <FormFeedback>{errMsg}</FormFeedback>}
			</InputGroup>
		</FormGroup>
	);
};
