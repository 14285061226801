import i18next from "i18next";
import { Link } from "react-router-dom";
import usePopups from "hooks/usePopups";
export default ({ show }) => {
	const { showChatRecievedFilterPopup } = usePopups();
	const handleOnClick = (e) => {
		e.preventDefault();
		showChatRecievedFilterPopup();
	};
	return (
		show && (
			<h6 className="centeredTitle">
				{i18next.t("Chat filtered.title")}
				<Link onClick={handleOnClick}>{i18next.t("Chat filtered.button")}</Link>
			</h6>
		)
	);
};
