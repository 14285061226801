import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class SubscriptionService {
  BASE_REF = "subscriptions";

  fetchUserSubscription(uid) {
    return new Observable((subscriber) => {
      const ref = firebase
        .database()
        .ref(this.BASE_REF)
        .child(uid)
        .child("public");

      const callbackFn = ref.on(
        "value",
        (snapshot) => subscriber.next(snapshot.val()),
        (error) => subscriber.error(error)
      );
      return () => ref.off("value", callbackFn);
    });
  }

  updateRenewable(uid, newVal) {
    return firebase
      .database()
      .ref(this.BASE_REF)
      .child(uid)
      .child("public")
      .child("renewable")
      .set(newVal);
  }
}

export default new SubscriptionService();
