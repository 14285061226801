import React from 'react';

export default ({ warning, error, errorClass }) => {

  return (
    (warning || error) && (
      <div className="validation" >
        {!error && warning && (
          <span className="warning">
              {warning}
          </span>
        )}
        {error && (
          <span className={`error ${errorClass}`}>
              {error}
          </span>
        )}
      </div>
    )
  );
};