export default {
	SaveToLog: "SaveToLog",
};

export const AuthLogTypes = {
	GoogleAuthError: "GoogleAuthError",
	FacebookAuthError: "FacebookAuthError",
	PasswordAuthError: "PasswordAuthError",
	PhoneAuthError: "PhoneAuthError",
};
