import React, { useState, useEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import { useDispatch } from "react-redux";

import SelectButtonsQuestion from "components/login/Questions/ButtonGroupQuestion";
import MaritalStatus from "consts/maritalStatus";
import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import qMark from "assets/images/registerImg/qMark.png";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);

	const handleOnSubmit = (value) => {
		dispatch(updateProfile(ProfileFields.MaritalStatus, value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.MaritalStatus])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	return (
		<div className="loginHolder">
			<div className="imageForPage bottom">
				<img src={qMark} />
			</div>
			<SelectButtonsQuestion
				options={Object.values(MaritalStatus)}
				title={i18next.t("Login.What is your maritalStatus")}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				translationPrefix={`MaritalStatus_${profile[ProfileFields.Gender] || 0}`}
			/>
		</div>
	);
};
