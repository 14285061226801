import i18next from "i18next";

import EnumBtn from "components/EnumBtn";
import EatKosherFood from "consts/eatKosherFood";
import ProfileFields from "consts/profileFields";
import RequiredPointContainer from "../RequiredPointContainer";

export default ({ onChange, profile }) => {
	const _onChange = (key, value) => onChange(key, Number(value));

	return (
		<RequiredPointContainer>
			<EnumBtn
				field={ProfileFields.EatKosherFood}
				fieldOptions={Object.values(EatKosherFood)}
				onChange={_onChange}
				initSelected={profile.eatKosherFood}
				title={i18next.t("Edit Profile.EatKosherFood")}
				translatePrefix="EatKosherFood"
			/>
		</RequiredPointContainer>
	);
};