import { useSelector } from "react-redux";
import i18next from "i18next";
import { Button } from "reactstrap";
import { getIsPlatformIOS } from "redux/init/selectors";

export default ({ onFacebookClick, onGoogleClick, onAppleClick }) => {
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	return (
		<div className="wrapButtonSocial">
			{/* <SocialButton
				text={i18next.t("Login.Register with Facebook")}
				onClick={onFacebookClick}
				className="faceBtn"
			/> */}
			<SocialButton
				text={i18next.t("Login.Register with Google")}
				onClick={onGoogleClick}
				className="googBtn"
			/>
			{isPlatformIOS && (
				<SocialButton
					text={i18next.t("Login.Register with Apple")}
					onClick={onAppleClick}
					className="appleBtn"
				/>
			)}
		</div>
	);
};

const SocialButton = ({ onClick, className, text }) => {
	return (
		<Button onClick={onClick} size="lg" outline color="primary" className={className}>
			{text}
		</Button>
	);
};
