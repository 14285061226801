import * as types from "./actionTypes";
import QueueService from "services/queue";
import ViewsEvents from "consts/viewEvents";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { getUnViewLikedMeUsers } from "./selectors";
import LikedMeService from "services/likedMe";
import localStorage from "services/localStorage";

export const viewLikedMeList = () => {
	return (dispatch, getState) => {
		const loginFromManager = localStorage.get("loginFrommanager");

		const state = getState();
		const userUid = getLoggedInUserUID(state);
		const unViewLikedMeUsers = getUnViewLikedMeUsers(state);
		if (!loginFromManager) {
			QueueService.sendEvent(userUid, ViewsEvents.ViewlikedMeList);
		}
		dispatch({ type: types.LIKED_ME_VIEW_LIST, payload: unViewLikedMeUsers });
	};
};

export const fetchLikedMeUsers = () => {
	return { type: types.LIKED_ME_USERS_FETCH };
};

export const fetchLikedMeUsersLoadMore = () => {
	return { type: types.LIKED_ME_USERS_FETCH_LOAD_MORE };
};

export const virtualDeleteLike = (colleagueUid) => {
	LikedMeService.virtualDeleteLike(colleagueUid);
	return { type: types.LIKED_ME_VIRTUAL_DELETE, payload: colleagueUid };
};
