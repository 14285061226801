import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import speedDateStatus from "consts/speedDateStatus";
import useNavigator from "hooks/useNavigator";
import DateList from "components/speedDate/DateList";
import VideoChat from "components/speedDate/VideoChat";
import { CircularProgress } from "@mui/material";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";

export default () => {
	const navigate = useNavigator();
	let { urlName } = useParams();
	const { showJaaSMeetingPopup } = usePopups();

	const currentUser = useSelector(getUserProfile) || {};
	const [feddbackQuestions, setFeddbackQuestions] = useState([]);
	const [currentDate, setCurrentDate] = useState(null);
	const [speedData, setSpeedDate] = useState({});
	const [loading, setLoading] = useState(false);
	const [eventEndTime, setEventEndTime] = useState(false);

	const handleFinishDate = () => {
		setCurrentDate(null);
	};
	const openTestJaaSMeeting = () => {
		if (!speedData?.key) return;
		setLoading(true);
		speedDatingService.getTestRoom(speedData.key).then((data) => {
			console.log(data);
			if (data.data?.success) {
				showJaaSMeetingPopup({ ...data.data, ...currentUser });
			}
			setLoading(false);
		});
	};

	const navigateToHomePage = () => {
		return navigate.navigateToHomePage();
	};
	useEffect(() => {
		if (!urlName) return navigateToHomePage();
		speedDatingService.getSpeedDateInfoByUrlName(urlName).then((data) => {
			if (!data) return navigateToHomePage();
			const sData = Object.values(data || {});
			if (
				!sData ||
				!sData?.length ||
				(sData[0]?.status !== speedDateStatus.inProgress &&
					sData[0]?.status !== speedDateStatus.completed)
			)
				return navigateToHomePage();

			const eventData = sData[0]?.eventDate;
			const eventEndTime = eventData + 3 * 60 * 60 * 1000; //3 hours after the start date

			if (eventEndTime < Date.now().valueOf()) {
				return setEventEndTime(true);
			}
			setSpeedDate(sData[0]);
		});
	}, []);

	useEffect(() => {
		speedDatingService.getFeddbackQuestions().then((data) => {
			if (!data) return;
			const fqData = Object.values(data || {}).filter((item) => !item?.deleted && !!item?.active);
			setFeddbackQuestions(fqData);
		});
	}, []);

	return (
		<div className="">
			speedDate
			{!!speedData?.key && !eventEndTime && (
				<>
					{!!currentUser && <div>{currentUser.name}</div>}
					{!!loading ? (
						<CircularProgress size={30} className="text-primary" style={{ margin: "10px 0" }} />
					) : (
						<Button
							style={{ margin: "10px 0", padding: 0 }}
							color="none"
							type="button"
							onClick={openTestJaaSMeeting}>
							<span>{"openTestJaaSMeeting"}</span>
						</Button>
					)}
					<DateList
						eventKey={speedData?.key}
						speedData={speedData}
						currentDate={currentDate}
						selectDate={(date) => setCurrentDate(date)}
					/>

					{!!currentDate && (
						<VideoChat
							currentDate={currentDate}
							feddbackQuestions={feddbackQuestions}
							handleFinishDate={handleFinishDate}
							speedData={speedData}></VideoChat>
					)}
				</>
			)}
			{eventEndTime && <div>האירוע הסתיים</div>}
		</div>
	);
};
