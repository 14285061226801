export default {
  Architect: 18,
  'Internet/Hi-Tech': 25,
  'Arts/Music/Illustrator': 19,
  'Biology/Biotechnology': 30,
  BetweenJobs: 24,
  CEO: 10,
  'Graphics/Design': 40,
  'Teaching/Education/Training': 11,
  PracticalEngineer: 45,
  Soldier: 23,
  Agriculture: 33,
  Pilot: 16,
  Technician: 43,
  'Technology/Hi-Tech': 27,
  'Consultant/Coaching': 32,
  'Economics/Investment Consulting': 35,
  'Learning/TeachingTorah': 28,
  Engineering: 15,
  Sales: 26,
  RestaurantAndCafe: 48,
  'HumanRelations(HR)': 47,
  'Management/Training': 20,
  'Senior Management': 37,
  Student: 1,
  SocialWork: 39,
  'Editor/Author/Translator': 29,
  Lawyer: 13,
  'Politics/Organizations': 34,
  Psychology: 14,
  Clerk: 36,
  'PermanentArmyService/Officer/Security': 22,
  Cosmetics: 38,
  'Accountant/Finance': 12,
  'Physical/Occupational Therapy': 42,
  Medical: 21,
  FieldOfMedicalCare: 46,
  SpeechTherapy: 31,
  'Media/Theater': 41,
  Other: 44,
};
