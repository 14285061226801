import React from "react";
import { useHistory } from "react-router-dom";

const useNavigator = () => {
	const history = useHistory();

	const redirectToPath = (path, paramsObj) => {
		history.push({
			pathname: path,
			state: paramsObj,
		});
	};

	const redirectWithReplaceTo = (path, paramsObj) => {
		history.replace({
			pathname: path,
			state: paramsObj,
		});
	};

	const redirectWithRefreshTo = (path) => {
		window.location = path;
	};

	const redirectWithReload = (path) => {
		redirectToPath("/empty");
		setTimeout(() => {
			redirectToPath(path);
		}, 1);
	};
	const redirectToPreviousPath = () => {
		history.goBack();
	};

	const navigateToSearchPage = (paramsObj) => redirectToPath("/search", paramsObj);
	const navigateToLoginEnterPhonePage = () => redirectToPath("/login/phone");
	const navigateToLoginPhoneVerificationPage = () => redirectToPath("/login/phoneverify");
	const navigateToLogoutPage = () => redirectToPath("/logout");
	const navigateToLoginQuestionsPage = () => redirectToPath("/login/questions");
	const navigateToHomePage = () => redirectToPath("/");
	const redirectToHomePage = () => redirectWithRefreshTo("/");
	const navigateToMassagesReceived = () => redirectToPath("/messages/received");
	const navigateToMassagesSent = (paramsObj) => redirectToPath("/messages/sent", paramsObj);
	const navigateToMassagesFiltered = () => redirectToPath("/messages/filtered");
	const navigateToPreviousPage = () => redirectToPreviousPath();
	const navigateToMaintenancePage = () => redirectToPath("/maintenance");
	const navigateToForceAppDownloadPage = () => redirectToPath("/forcedownload");
	const navigateToProfileBlockedPage = () => redirectWithReplaceTo("/ProfileBlocked");

	const navigateToPath = (path) => redirectToPath(path);

	return {
		redirectWithReload,
		navigateToPath,
		navigateToSearchPage,
		navigateToLoginEnterPhonePage,
		navigateToLoginPhoneVerificationPage,
		navigateToLogoutPage,
		navigateToLoginQuestionsPage,
		navigateToHomePage,
		redirectToHomePage,
		navigateToMassagesReceived,
		navigateToMassagesSent,
		navigateToMassagesFiltered,
		navigateToPreviousPage,
		navigateToMaintenancePage,
		navigateToForceAppDownloadPage,
		navigateToProfileBlockedPage,
	};
};

export default useNavigator;
