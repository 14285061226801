import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";

export default ({ title, children, collapsHeight, className = "" }) => {
  const btnSpanRef = useRef();
  const collapseMeRef = useRef();
  const contentSpanRef = useRef();
  const [contentBig, setContentBig] = useState(false);
  const [collapseMeStyle, setCollapseMeStyle] = useState({
    maxHeight: `${collapsHeight}px`,
  });

  useEffect(() => {
    const notRoundedHeight = contentSpanRef.current?.getBoundingClientRect().height;
    setContentBig(notRoundedHeight > collapsHeight);
    collapseMeRef.current.style.height =
      notRoundedHeight < collapsHeight ? `${notRoundedHeight}px` : `${collapsHeight}px`;
  }, []);

  const collapseMe = () => {
    if (collapseMeRef.current.clientHeight === collapsHeight) {
      collapseMeRef.current.style.height = contentSpanRef.current.clientHeight + "px";
      collapseMeRef.current.classList.remove("ellipsis");
      btnSpanRef.current.className = "mdi-chevron-up";
      setCollapseMeStyle({ maxHeight: "100%" });
    } else {
      collapseMeRef.current.style.height = `${collapsHeight}px`;
      collapseMeRef.current.classList.add("ellipsis");
      btnSpanRef.current.className = "mdi-chevron-down";
    }
  };

  return (
    <div className={`${className} userDetailsCollapse`}>
      <div className="wrapTitleOFCollapse">
        <h4 className="text-left text-primary font-size-16" onClick={collapseMe}>
          {" "}
          {title}{" "}
        </h4>
        {contentBig && (
          <Button outline color="secondary" onClick={collapseMe} className="littleToggleIconBtn ">
            <span ref={btnSpanRef} className="mdi-chevron-down"></span>
          </Button>
        )}
      </div>
      <div
        ref={collapseMeRef}
        className={`collapseMe tags ${contentBig ? "contentBig ellipsis" : ""}`}
        style={collapseMeStyle}>
        <span ref={contentSpanRef}>{children}</span>
      </div>
    </div>
  );
};
