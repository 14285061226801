import i18next from "i18next";
import { Modal, ModalHeader, Button } from "reactstrap";

import usePopups from "hooks/usePopups";

export default () => {
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("Unsubscribe.Error Modal Text")}</ModalHeader>
		</Modal>
	);
};
