import { Button } from "reactstrap";

export default ({ onClick, text, ...rest }) => {
  return (
    <Button onClick={onClick} size='lg' color='secondary' {...rest}>
      {" "}
      {text}
    </Button>
  );
};
