import firebase from 'firebase/app';
import "firebase/database";
import { Observable } from 'rxjs';

class publicProfileUpdater {
  BASE_REF = 'users_last_update_time';

  listenChildAdded() {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .orderByValue()

      const callbackFn = ref.on(
        'child_added',
        snapshot => 
        subscriber.next(snapshot.key),
        error => subscriber.error(error)
      );
      return () => ref.off('child_added', callbackFn);
    });
  }
  

  listenChildChanged() {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .orderByValue()

      const callbackFn = ref.on(
        'child_changed',
        snapshot =>  subscriber.next(snapshot.key),
        error => subscriber.error(error)
      );
      return () => ref.off('child_changed', callbackFn);
    });
  }
}

export default new publicProfileUpdater();
