import moment from "moment";
import "moment/locale/he";
moment.locale("he");

moment.updateLocale("en", {
	relativeTime: {
		future: "in %s",
		past: "%s",
		s: "a few seconds ago",
		ss: "%d seconds ago",
		m: "a minute ago",
		mm: "%d minutes ago",
		h: "an hour ago",
		hh: "today", //%d hours
		d: "yesterday",
		dd: "%d days ago",
		w: "a week",
		ww: "%d weeks ago",
		M: "a month ago",
		MM: "%d months ago",
		y: "a year ago",
		yy: "%d years ago",
	},
});

moment.updateLocale("he", {
	relativeTime: {
		future: "%s",
		past: "%s",
		s: "לפני כמה שניות",
		ss: "%d  לפני שניות",
		m: "לפני דקה",
		mm: "לפני %d דקות",
		h: "לפני שעה",
		hh: "היום",
		d: "אתמול",
		dd: "לפני %d ימים",
		w: "לפני שבוע",
		ww: "לפני %d שבועות",
		M: "לפני חודש",
		MM: "לפני %d חודשים",
		y: "לפני שנה",
		yy: "לפני %d שנים",
	},
});
