import i18next from "i18next";

import ProfileFields from 'consts/profileFields';
import ProfileLanguages from "consts/profileLanguages";
import MultiSelect from "components/MultiSelect";

export default ({ onChange, profile }) => {
   
    const _onChange = (key, values) => onChange(key, values.map(item => Number(item)));
    return <MultiSelect
                field={ProfileFields. Languages} 
                fieldOptions={Object.values(ProfileLanguages)} 
                onChange={_onChange} 
                initSelected={profile.languages}
                title={i18next.t('Edit Profile.Profile Languages')}
                translatePrefix="Profile Languages"
            />
};
