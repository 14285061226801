import i18next from "i18next"
import { useState } from "react";

export default ({ field, fieldOptions, onChange, initSelected = "", title, translatePrefix, isLang }) => {

    const [ value , setValue ] = useState(initSelected);

    const onNext = selectedItem => {
        !isLang && setValue(selectedItem);
        field ? onChange(field, selectedItem): onChange(selectedItem);
    }

    return <div className="wraptxt">
                <label className="form-label">{title}</label>
                <div className="wrapBtns">
                    {fieldOptions.map(item =>
                        <button 
                            size="sm" 
                            className={`regBtn ${item === value ? 'selected': ''}`}
                            color="primary"
                            key={item} 
                            onClick={()=>onNext(item)} 
                            value={item}> {i18next.t(`${translatePrefix}.${item}`)}
                        </button>
                    )}
                </div>
            </div>      
}