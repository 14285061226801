import React, { useState } from "react";
import i18next from "i18next";
import Email from "./Email";
import Password from "./Password";
import Button from "./Button";

export default ({ onSubmit }) => {
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const handleOnSubmit = () => {
		onSubmit(email, password);
	};
	const handleEmailChange = (val) => {
		setEmail(val);
	};
	
	const handlePasswordChange = (val) => {
		setPassword(val);
	};

	return (
		<>
			<Email onChange={handleEmailChange} hasError={true} />
			<Password onChange={handlePasswordChange} hasError={true} />
			<Button onClick={handleOnSubmit} text={i18next.t("Enter")} />
		</>
	);
};
