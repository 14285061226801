import { useSelector } from "react-redux";
import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const { gender } = useSelector(getUserProfile);
	const { closePopup, showDeleteAccountSelectReasonPopup, showFreezeAccountPopup } = usePopups();

	return (
		<Modal isOpen toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t(`Delete account.Header_${gender || 0}`)}</ModalHeader>
			<ModalBody>{i18next.t(`Delete account.Message_${gender || 0}`)}</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={showFreezeAccountPopup}
					className="btn-rounded littleBtn">
					{i18next.t("Delete account.Freeze")}
				</Button>

				<Button
					outline
					size="sm"
					color="primary"
					onClick={showDeleteAccountSelectReasonPopup}
					className="btn-rounded littleBtn">
					{i18next.t("Delete account.Delete") + " > "}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
