import { createSelector } from "reselect";
import { orderBy, isNil } from "lodash";

import { getPublicProfiles } from "../publicProfiles/selectors";
import { getBlocksUIDs } from "../blocks/selectors";
import { getChatMeta } from "../chatMeta/selectors";

export const getChatSentData = (state) => state.chatSent;
export const getChatSent = (state) => state.chatSent.users;

export const getChatSentSortByDate = createSelector(
	getChatSent,
	getPublicProfiles,
	getBlocksUIDs,
	(chatSent, publicProfiles, blockedUsers) => {
		if (!publicProfiles) return [];

		const chatReceiveArray = Object.entries(chatSent).map(([key, value]) => ({
			...value,
			uid: key,
		}));
		const ChatFilteredDeleted = chatReceiveArray.filter(
			(chat) =>
				!chat.deleted &&
				chat.chatID &&
				publicProfiles[chat.uid]?.approved &&
				isNil(blockedUsers[chat.uid])
		);
		return orderBy(ChatFilteredDeleted, "timeStamp", "desc");
	}
);

export const getChatSentUsers = createSelector(
	getChatSentSortByDate,
	getChatMeta,
	(chatSentSortByDate, chatMeta) => {
		const res = chatSentSortByDate.map((chat) => ({
			...chat,
			unread: chatMeta[chat.chatID]?.newMessages,
		}));
		return res;
	}
);

export const hasChatSent = createSelector(
	getChatSent,
	(chatSent) => Object.keys(chatSent).length > 0
);

export const getChatSentUser = (userUid) =>
	createSelector(getChatSent, (chatSent) => chatSent[userUid]);

export const getUserByChatID = (chatId) =>
	createSelector(getChatSent, (chatSent) =>
		Object.keys(chatSent).find((userId) => chatSent[userId].userID === chatId)
	);

export const getLoadChatSentNumber = createSelector(
	getChatSentData,
	(chatSent) => chatSent?.loadChatsNumber
);

export const getHasMoreChatsSent = createSelector(
	getChatSentData,
	(chatSent) => !!chatSent?.hasMoreChats
);
