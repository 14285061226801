import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import TermsLink from "components/login/TermsLink";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import { loginWithPhone } from "redux/auth/actions";
import {
	getLoginWithPhoneStatus,
	isUserLoggedIn,
	getIsAuthChecked,
	getLoginWithPhoneErrorMessage,
} from "redux/auth/selectors";
import { getPhoneWithPrefix } from "helpers/mobileNumber";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import phone1Image from "assets/images/registerImg/hello_man.png";
import Loader from "components/Loader";

export default ({ isFromPopup = false }) => {
	const dispatch = useDispatch();
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const navigator = useNavigator();
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const loginWithPhoneErrorMessage = useSelector(getLoginWithPhoneErrorMessage);

	const handleSubmit = (value) => {
		// const phoneWithPrefix = getPhoneWithPrefix(value);
		dispatch(loginWithPhone(value));
	};

	useEffect(() => {
		if (isLoggedIn && isAuthChecked) {
			navigator.navigateToLoginQuestionsPage();
		}
	}, [isLoggedIn, isAuthChecked]);

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.WAIT_FOR_VERIFICATION:
				if (!isFromPopup) navigator.navigateToLoginPhoneVerificationPage();
				break;
			case LOGIN_WITH_PHONE_STATUS.LOGIN_FAILED:
				setHasError(true);
				setErrMsg(
					i18next.t(
						`Phone Error.${
							loginWithPhoneErrorMessage.indexOf("blocked") !== -1 ? "Many Requests" : ""
						}`
					)
				);
				break;
			default:
				break;
		}
	}, [loginStatus]);

	const [hasFocus, setFocus] = useState(false);

	return isAuthChecked ? (
		<div className="loginPagesWrapper">
			<div className="loginPopup">
				<div className="loginHolder phoneEnter">
					<div className="imageForPage">
						<img src={phone1Image} />
					</div>
					<PhoneQuestion
						title={i18next.t("Login.Enter your phone")}
						subTitle={i18next.t("Login.Enter phone text")}
						onSubmit={handleSubmit}
						hasError={hasError}
						errMsg={errMsg}
						showLoader={loginStatus === LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING}
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
					/>
					<TermsLink />
				</div>
			</div>
		</div>
	) : (
		<Loader />
	);
};
