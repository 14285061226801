import React from 'react';
import i18next from "i18next";

function Notes(props)  {
  const classes = 'wrapNotes ' + props.className;
  return (
  <div className={classes}>
        <h6>
           {props.children}
        </h6>     
    </div> 
    );
}

export default Notes;
