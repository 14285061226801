export default {
  INIT: "INIT",
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_FAILED: "LOGIN_FAILED",
  WAIT_FOR_VERIFICATION: "WAIT_FOR_VERIFICATION",
  VERIFICATION_PENDING: "VERIFICATION_PENDING",
  VERIFICATION_FAILED: "VERIFICATION_FAILED",
  LOGIN_SUCCESSFULLY: "LOGIN_SUCCESSFULLY",
  USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
};
