import { createSelector } from "reselect";
import { mapValues } from "lodash";

import { getPublicProfiles } from "redux/publicProfiles/selectors";

export const getBlocks = (state) => state.blocks;

export const getBlocksUIDs = createSelector(getBlocks, (blocks) =>
  mapValues(blocks, () => true)
);

export const getBlockedUsers = createSelector(
  getPublicProfiles,
  getBlocks,
  (publicProfiles, blocks) =>
    Object.keys(blocks)
      .filter((uid) => blocks[uid].isBlock)
      .map((uid) => publicProfiles[uid])
);
