import i18next from "i18next";

import Subtitle from "components/Subtitle";
import MultirangeSlider from "../MultiRangeSlider/MultirangeSlider";
import SearchFilterKeys from "consts/searchFilterKeys";
import fillProfileDetailsValidations from "consts/fillProfileDetailsValidations";

export default ({ onChange, filter = {} }) => {
  const heightLimits = fillProfileDetailsValidations.height.numericality;

  const _onChange = ({ min, max }) => onChange(SearchFilterKeys.Height, { from: min, to: max });

  return (
    <div className="section range">
      <Subtitle className="colorLabel">{i18next.t("Search.Height")}</Subtitle>
      <MultirangeSlider
        min={heightLimits.greaterThan}
        max={heightLimits.lessThanOrEqualTo}
        step={1}
        onChange={_onChange}
        initMin={filter.from || heightLimits.greaterThan}
        initMax={filter.to || heightLimits.lessThanOrEqualTo}
      />
    </div>
  );
};
