import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {
	users: [],
	typeSearch: null,
	apiStatus: null,
	loadMoreStatus: null,
	freeTextFilter: null,
	pitId: null,
	searchAfter: null,
	hasMoreResults: true,
	updateRedPoint: true,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.SEARCH_FETCH:
			return {
				...state,
				apiStatus: API_REQUESTS_STATUS.PENDING,
				freeTextFilter: action.freeText,
				pitId: null,
				searchAfter: null,
				hasMoreResults: true,
			};
		case types.SEARCH_FETCH_LOAD_MORE:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.PENDING,
			};

		case types.SEARCH_FETCH_LOAD_MORE:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.PENDING,
			};
		case types.SEARCH_TYPE:
			return {
				...state,
				typeSearch: action.payload,
			};

		case types.SEARCH_FETCH_SUCCESSFULLY:
			return {
				...state,
				users: [...action.payload.items],
				apiStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
				lastSortOption: action.payload.lastSortOption,
			};
		case types.SEARCH_FETCH_LOAD_MORE_SUCCESSFULLY:
			return {
				...state,
				users: [...state.users, ...action.payload.items],
				loadMoreStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
				lastSortOption: action.payload.lastSortOption,
			};
		// case blocksTypes.BLOCKS_UNBLOCK_USER_SUCCESSFULLY:
		// 	if (state?.users) state.users[action.user.uid] = action.user;
		// 	break;
		case types.SEARCH_FETCH_FAILED:
			return {
				...state,
				apiStatus: API_REQUESTS_STATUS.FAILED,
			};
		case types.SEARCH_FETCH_LOAD_MORE_FAILED:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.FAILED,
			};
		case types.SEARCH_UPDATE_RED_POINT:
			state.updateRedPoint++;
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
