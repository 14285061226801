import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Row, Col, Label, Form } from "reactstrap";
import "emoji-mart/css/emoji-mart.css";
import i18next from "i18next";

import ChatMetaService from "services/chatMeta";
import usePopups from "hooks/usePopups";
import SendTemplateMessageButton from "components/search/card/SendTemplateMessageButton";
import isMobile from "helpers/isMobile";
import templateMsgService from "services/templateMsg.service";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import _ from "lodash";

export default ({ chatID, userUid, gender, onSend, user }) => {
	const [message, setMessage] = useState("");
	const [typing, setTyping] = useState(false);
	const [inputClass, setInputClass] = useState("");
	const [templates, setTemplates] = useState([]);
	const inpRef = useRef();

	const { showQrcodePopup } = usePopups();

	useEffect(() => {
		if (userUid && chatID) {
			if (message) {
				ChatMetaService.updateTyping(userUid, chatID, true);
				setTyping(true);
			} else if (typing) {
				ChatMetaService.updateTyping(userUid, chatID, null);
			}
		}
	}, [message, userUid, chatID]);

	useEffect(() => {
		setInputClass(inpRef.current.clientHeight > 50 ? "multiRows" : "");
	}, [message]);

	const handleChange = (e) => {
		setMessage(e.target.value);
	};

	const handleSendMessage = () => {
		const trimMessage = message.trim();
		if (trimMessage !== "") {
			onSend(trimMessage);
			setMessage("");
		}
	};

	const onKeyPress = (e) => {
		e.key === "Enter" && handleSendMessage();
	};

	useEffect(() => {
		templateMsgService.fetchTemplateMsg().then((data) => {
			if (!!data && !_.isEmpty(data)) {
				const newTemplates = Object.values(data)
					.map((item) => {
						let newTemp = item;
						newTemp.messageMale = item.messageMale.replace("[name]", user?.name);
						newTemp.messageFemale = item.messageFemale.replace("[name]", user?.name);
						return newTemp;
					})
					.filter((item) => {
						if (
							!item.active ||
							(item.type == "Birthday" && !getIfUserBirtdayInThisWeek(user?.dob))
						) {
							return false;
						}
						return true;
					});

				const sortedTemplates = _.sortBy(newTemplates, [
					(item) => {
						switch (item.type) {
							case "Birthday":
								return 1;
							case "Holiday":
								return 2;
							default:
								return 3;
						}
					},
					"priority",
				]);

				setTemplates(sortedTemplates);
			}
		});
	}, []);

	return (
		<React.Fragment>
			<div className="p-3 p-lg-4 border-top mb-0 chatFooter">
				<Row noGutters className={"alignCenterHeight"}>
					<Col>
						<div className="inputAndQuote grow-wrap" data-replicated-value={message}>
							<Input
								innerRef={inpRef}
								type="textarea"
								value={message}
								onChange={handleChange}
								onKeyPress={onKeyPress}
								rows={1}
								className={`form-control bg-light border-light ${inputClass}`}
								placeholder={i18next.t(`Chat Message.Type message_${gender}`)}
							/>
							<SendTemplateMessageButton
								templates={templates}
								user={user}
								isFromCardUserSidebar={true}
								usePopover={!isMobile() ? true : false}
							/>
						</div>
					</Col>
					<Col xs="auto">
						<div className="chat-input-links ms-md-2">
							<ul className="list-inline mb-0 ms-0">
								<li className="list-inline-item input-file record">
									<Label
										id="jsmartRecord"
										onClick={() => showQrcodePopup(i18next.t("Recording Qrcode Text"))}
										className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect jsmartRecord"></Label>
								</li>
								<li className="list-inline-item">
									<Button
										onClick={handleSendMessage}
										color=""
										className="font-size-16 btn-lg chat-send waves-effect waves-light jsmartChatBtn">
										<span>{i18next.t(`Chat Message.Send Message_${gender}`)}</span>
									</Button>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};
