import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { FormGroup, Input, InputGroup, Button, Label } from "reactstrap";
export default ({ field, title, onChange, startAt, endAt, initVal, className = "" }) => {
	const [value, setValue] = useState(initVal);

	const handleOnChange = (val) => {
		_setValue(val);
	};

	const handleIncrease = (increaseBy) => {
		_setValue(value + increaseBy);
	};

	const _setValue = (val) => {
		setValue(val > endAt ? endAt : val < startAt ? startAt : val);
	};

	const handleKeyDown = ({ key }) => {
		if (key === "ArrowUp") {
			handleIncrease(1);
		} else if (key === "ArrowDown") {
			handleIncrease(-1);
		}
	};

	useEffect(() => {
		onChange(value);
	}, [value]);

	return (
		<FormGroup className={`${className}`}>
			<InputGroup>
				<Label for={field}>{title}</Label>
				<div className="wrapInputNumber">
					<Button className="minus" onClick={() => handleIncrease(-1)}>
						-
					</Button>
					<Input
						id={field}
						value={value}
						onChange={(e) => handleOnChange(e.target.value)}
						placeholder={i18next.t("Type")}
						type="text"
						onInput={(e) => {
							e.target.value = e.target.value.replace(/[^0-9]/g, "");
						}}
						onKeyDown={handleKeyDown}
					/>
					<Button className="plus" onClick={() => handleIncrease(1)}>
						+
					</Button>
				</div>
			</InputGroup>
		</FormGroup>
	);
};
