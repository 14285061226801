import { PopoverHeader } from "reactstrap";
import { PopoverBody, Popover } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";

import Header from "../TemplateMessage/Header";
import Body from "../TemplateMessage/Body";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";

export default ({ user, className = "" }) => {
	const { approved } = useSelector(getUserProfile);
	const { showNotApprovedMessagePopup } = usePopups();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const handleOnClick = () => {
		if (approved) {
			toggle();
		} else {
			showNotApprovedMessagePopup();
		}
	};

	return (
		<Popover
			className={`endeavorWrapper ${className}`}
			isOpen={isOpen}
			placement="top"
			target="qouteTrig"
			trigger="legacy"
			toggle={handleOnClick}>
			<PopoverHeader>
				<Header user={user} />
			</PopoverHeader>
			<PopoverBody>
				<Body user={user} afterMessageSentHandler={toggle} toggle={toggle}/>
			</PopoverBody>
		</Popover>
	);
};
