import i18next from "i18next";

import TextArea from "components/TextArea";
import ProfileFields from "consts/profileFields";
import PendingStatus from "consts/pendingStatus";
import Warnings from "../Warnings";

export default ({ onChange, profile, pending }) => {
	return (
		<div className="wrapInputValidation">
			<TextArea
				field={ProfileFields.OneLiner}
				onChange={onChange}
				initValue={profile.oneLiner}
				title={i18next.t("Edit Profile.One Liner")}
				rows={1}
			/>
			<Warnings
				warning={pending?.status === PendingStatus.Wait && i18next.t("Edit Profile.Pending")}
				error={pending?.status === PendingStatus.Rejected && i18next.t("Edit Profile.Rejected")}
			/>
		</div>
	);
};
