import { useEffect, useState } from "react";
import AOS from "aos";
import { useDispatch } from "react-redux";
import AppUrlListener from "./AppUrlListener";

import "aos/dist/aos.css";
import "./assets/scss/themes.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "animate.css";

import Routes from "./config/routes/routes";
import Popups from "components/Popups";
import PopupsListener from "components/PopupsListener";
import { initApp } from "redux/auth/actions";
import "./helpers/momentInit";
import ProfileBlockedListener from "components/ProfileBlockedListener";
import FeedbackButton from "components/FeedbackButton";
import Afilliate from "components/Afilliate";
import NotificationWrapper from "components/notification/NotificationWrapper";
import localStorage from "services/localStorage";
import connectingService from "services/connectingService";
import AppServices from "services/app";

const App = () => {
	const dispatch = useDispatch();
	const [isConnectToDb, setIsConnectToDb] = useState("");
	const loginFromManager = localStorage.get("loginFrommanager");
	useEffect(() => {
		AOS.init({
			//disable: "phone",
		});
		AOS.refresh();
	}, []);

	useEffect(() => {
		dispatch(initApp());
	}, []);

	/* 	useEffect(() => {
		let subscription;
		if (subscription) {
			subscription.unsubscribe();
		}
		subscription = connectingService.checkConnections().subscribe((data) => {
			if (data) {
				setIsConnectToDb(true);
			} else {
				if (isConnectToDb) {
					window.location.reload();
				}
			}
		});
	}, [isConnectToDb]); */

	useEffect(() => {
		//prevents the browser from scrolling when you press on go back btn
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
	}, []);

	return (
		<div className={loginFromManager ? `isManager` : ""}>
			<Routes>
				<Afilliate />
				<AppUrlListener />
				<Popups />
				<PopupsListener />
				<ProfileBlockedListener />
				<FeedbackButton />
				<NotificationWrapper />
			</Routes>
		</div>
	);
};

export default App;
