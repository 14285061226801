import firebase from "firebase/app";
import "firebase/functions";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";
const functions = firebase.functions();

class SearchService {
	async search(
		pitId,
		searchAfter,
		extraData,
		sortOption = ElasticSortOptions.SortByLastLoginCombinedWithBustsDESC,
		builderType = SearchQueryBuilderType.Search
	) {
		const data = {
			pitId,
			searchAfter,
			extraData,
			sortOption,
			builderType,
		};
		const result = await functions.httpsCallable("searchCall")(data);
		return result.data;
	}
}

export default new SearchService();
