import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import DropDown from "components/DropDown";
import RequiredPointContainer from "../RequiredPointContainer";

const HEIGHT_MIN = 130;
const HEIGHT_MAX = 210;
const HEIGHTS_ARRAY = Array.from({ length: HEIGHT_MAX - HEIGHT_MIN + 1 }, (_, i) => i + HEIGHT_MIN);

export default ({ onChange, profile }) => {
	const _onChange = (key, value) => onChange(key, Number(value));

	return (
		<RequiredPointContainer>
			<DropDown
				field={ProfileFields.Height}
				fieldOptions={HEIGHTS_ARRAY}
				onChange={_onChange}
				initSelected={profile.height}
				title={i18next.t("Edit Profile.Height")}
				itemsValueTranslate="X CM"
			/>
		</RequiredPointContainer>
	);
};
