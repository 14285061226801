import ReactDOM from "react-dom";
import "./helpers/firebaseConfig";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

import App from "./App";
import ThemeSelector from "./themes/themeSelector";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import currentLanguage from "./helpers/currentLanguage";
import "./i18n";
import { initFacebookSdk } from "helpers/initFacebookSdk";
import Nagishli from "helpers/Nagishli";
initFacebookSdk().then(startApp);

TagManager.initialize({ gtmId: "GTM-5RHPSCH" });
function startApp() {
	ReactDOM.render(
		<Provider store={store}>
			<Nagishli />
			<ThemeSelector lang={currentLanguage()}>
				<App />
			</ThemeSelector>
		</Provider>,
		document.getElementById("root")
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
