import QueueService from "services/queue";
import moment from "moment";

import MassletterEvents from "consts/massletterEvents";
import { getLoggedInUserUID } from "redux/auth/selectors";
import * as types from "./actionTypes";

export function sendMassLetter(massFiler) {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);

		await QueueService.sendEvent(uid, MassletterEvents.MassLetterSendMessage, massFiler);
		dispatch({ type: types.MASSLETTER_SENT_SUCCESSFULLY });
	};
}

export function cancelMassLetter() {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		await QueueService.sendEvent(uid, MassletterEvents.MassLetterCancelMessage);
		dispatch({ type: types.MASSLETTER_CANCELED_SUCCESSFULLY });
	};
}

export function resetMassLetter() {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		await QueueService.sendEvent(uid, MassletterEvents.ResetMassLetter);
		dispatch({ type: types.MASSLETTER_RESET_SUCCESSFULLY });
	};
}
