import { useDispatch, useSelector } from "react-redux";

import { getPicturesArray, getMainPicture } from "redux/profile/selectors";

import PendingStatus from "consts/pendingStatus";
import { deleteImage, saveEditedImage, setMainPicture, updateImage } from "redux/profile/actions";

export default function useProfileImages() {
	const dispatch = useDispatch();

	const images = useSelector(getPicturesArray);

	const mainImage = useSelector(getMainPicture);

	const mappedImages = images.map((image) => {
		return {
			isMain: image.key === mainImage,
			rejected: image.status === PendingStatus.Rejected,
			pending: image.status === PendingStatus.Wait,
			...image,
		};
	});

	const setMainImage = (key) => {
		dispatch(setMainPicture(key));
	};

	const deleteProfileImage = (key) => {
		dispatch(deleteImage(key));
	};

	const editProfileImage = (cropImageName, file) => {
		dispatch(saveEditedImage(cropImageName, file));
	};

	const saveNewImage = (profileName, file) => {
		dispatch(updateImage(profileName, file));
	};

	return {
		images: mappedImages,
		setMainImage,
		deleteProfileImage,
		editProfileImage,
		saveNewImage,
	};
}
