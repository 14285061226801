import firebase from "firebase/app";
import "firebase/functions";
const functions = firebase.functions();

class AuthPasswordService {
	async login(email, password) {
		try {
			const result = await functions.httpsCallable("loginCall")({
				email,
				password,
			});
			return result;
		} catch (err) {
			return { error: err };
		}
	}
}

export default new AuthPasswordService();
