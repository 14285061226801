import { useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import MessageBox from "components/search/MessageBox";
import { getPopups } from "redux/popups/selectors";
import LetPictures from "components/search/card/LetPictures";

export default () => {
	const { closePopup } = usePopups();
	const { currentUserProfile } = useSelector(getPopups);
	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog sendMessagePopup">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalBody className="sendMessageModal">
				<MessageBox user={currentUserProfile} onAfterSendMessage={closePopup} />
				<LetPictures user={currentUserProfile} />
			</ModalBody>
		</Modal>
	);
};
