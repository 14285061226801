import { createSelector } from "reselect";

import { getLoggedInUserUID } from "../auth/selectors";
import { getUserPublicProfile } from "../publicProfiles/selectors";
import {
	canReadMessages,
	getReadMessagesLimits,
	getUserSubscriptionType,
} from "../subscription/selectors";
import { orderBy } from "lodash";
import { getUserProfile } from "redux/profile/selectors";
import Subscription from "consts/subscription";
import MessagesCountLimit from "consts/messagesCountLimit";

const getChatMessages = (state) => state.chatMessages.data;

export const getMessages = (chatID, userID) =>
	createSelector(
		getChatMessages,
		getLoggedInUserUID,
		canReadMessages,
		getReadMessagesLimits,
		getUserPublicProfile(userID),

		(chatMessages, uid, canReadMessagesSubscription, readMessagesLimits, user) =>
			chatMessages[chatID]?.status !== "FAILED" &&
			chatMessages[chatID]?.messages.map((message) => {
				if (
					message.uid === uid ||
					canReadMessagesSubscription ||
					readMessagesLimits?.[user?.subscription] === true ||
					message.isTemplate ||
					message.system ||
					message.isRecommendation ||
					message.isPromotion
				) {
					return message;
				} else if (message.isMassletter) {
					return {
						...message,
						generalBlur: true,
					};
				} else {
					return {
						...message,
						privateBlur: true,
					};
				}
			})
	);

export const getLastMessageId = (chatID) =>
	createSelector(getChatMessages, (chatMessages) => {
		const lastIndex = chatMessages[chatID]?.messages.length - 1;
		return chatMessages[chatID]?.messages?.[lastIndex]?.id;
	});

export const getCountMessagesLimit = createSelector(getUserSubscriptionType, (subscriptionType) =>
	subscriptionType !== Subscription.PAID && subscriptionType !== Subscription.FREE
		? MessagesCountLimit.UnSubscribedMessagesLimit
		: MessagesCountLimit.SubscribedMessagesLimit
);

export const getCanSendMessage = createSelector(
	getUserProfile,
	getCountMessagesLimit,
	getChatMessages,
	(profile, countLimit, chatMessagesObj) => {
		const chatMessages = chatMessagesObj[Object.keys(chatMessagesObj)[0]]?.messages;
		let stopLoop = false;
		const messagesDesc = orderBy(chatMessages, "timeStamp", "desc");
		return (
			messagesDesc.filter((message, i) => {
				if (stopLoop) return false;
				message.uid !== profile.uid && (stopLoop = true);
				return message.uid === profile.uid && (i === 0 || message.uid === messagesDesc[i - 1].uid);
			}).length < countLimit
		);
	}
);

export const getIsUserSubscribed = createSelector(
	getUserSubscriptionType,
	(subscriptionType) =>
		subscriptionType === Subscription.PAID || subscriptionType === Subscription.FREE
);
