import { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";

import NavLinkItem from "components/NavLinkItem";

export default ({ icon, content, items, currentPath }) => {
  const isCollapsed = !items.find((item) => item.to === currentPath);
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const toggle = () => setCollapsed(!collapsed);

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle">
          <img src={icon} />
          {content}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => (
          <NavLinkItem key={index} currentPath={currentPath} {...item}>
            {item.content}
          </NavLinkItem>
        ))}
      </Collapse>
    </div>
  );
};
