import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.MASSLETTERS_FETCH_SUCCESSFULLY:
			return { ...action.payload, ...{ apiStatus: API_REQUESTS_STATUS.SUCCESSFULLY } };
		case authTypes.AUTH_LOG_OUT:
			return initialState;
		default:
			return state;
	}
}, initialState);
