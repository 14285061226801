import * as types from "./actionTypes";
import QueueService from "services/queue";
import ViewsEvents from "consts/viewEvents";
import { getLoggedInUserUID } from "redux/auth/selectors";
import localStorage from "../../services/localStorage";

export const viewViewedMeList = () => {
	return (dispatch, getState) => {
		const loginFromManager = localStorage.get("loginFrommanager");
		const state = getState();
		const userUid = getLoggedInUserUID(state);
		if (!loginFromManager) {
			QueueService.sendEvent(userUid, ViewsEvents.ViewViewedMeList);
		}
	};
};

export const fetchViewedMeUsers = () => {
	return { type: types.VIEWED_ME_USERS_FETCH };
};

export const fetchViewedMeUsersLoadMore = () => {
	return { type: types.VIEWED_ME_USERS_FETCH_LOAD_MORE };
};
