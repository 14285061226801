import * as types from "./actionTypes";
import QueueService from "services/queue";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { isLetPictures } from "./selectors";
import letPicturesService from "services/letPictures";

export const letPicturesToggle = (user, colleagueUid) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = getLoggedInUserUID(state);
    const hadLetPictures = isLetPictures(colleagueUid)(state) || false;
    if (!hadLetPictures) {
      await letPicturesToUser(dispatch, uid, colleagueUid, user);
    } else {
      await unLetPicturesToUser(dispatch, uid, colleagueUid);
    }
  };
};

export const letPicturesToUser = async (dispatch, uid, colleagueUid, user) => {
  QueueService.sendEvent(uid, "LetPictures", colleagueUid);
  try {
    await letPicturesService.letPictures(uid, colleagueUid);
    dispatch({ type: types.LET_PICTURES_ADD_SUCCESSFULLY, payload: user });
  } catch (e) {
    dispatch({
      type: types.LET_PICTURES_ADD_FAILED,
      payload: colleagueUid,
      error: e?.message,
    });
  }
};

export const unLetPicturesToUser = async (dispatch, uid, colleagueUid) => {
  QueueService.sendEvent(uid, "UnLetPictures", colleagueUid);
  try {
    await letPicturesService.unLetPictures(uid, colleagueUid);
    dispatch({ type: types.LET_PICTURES_REMOVE_SUCCESSFULLY, payload: colleagueUid });
  } catch (e) {
    dispatch({
      type: types.LET_PICTURES_REMOVE_FAILED,
      payload: colleagueUid,
      error: e?.message,
    });
  }
};
