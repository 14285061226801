import * as types from "./actionTypes";
import QueueService from "services/queue";
import LikeEvents from "consts/likeEvents";
import LikesService from "services/likes";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { isLike } from "./selectors";
import { isSkipped } from "redux/skips/selectors";
import { unSkip } from "redux/skips/actions";

export const likeToggle = (user, colleagueUid, forceLike = false) => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		const hadLike = isLike(colleagueUid)(state) || false;
		const hadSkip = isSkipped(colleagueUid)(state) || false;
		if (!hadLike) {
			await like(dispatch, uid, colleagueUid, user);
		} else if (!forceLike) {
			await unlike(dispatch, uid, colleagueUid);
		}
		if (hadSkip) {
			await unSkip(dispatch, uid, colleagueUid);
		}
	};
};

export const like = async (dispatch, uid, colleagueUid, user) => {
	dispatch({ type: types.LIKES_ADD, payload: colleagueUid });
	QueueService.sendEvent(uid, LikeEvents.Like, colleagueUid);
	try {
		await LikesService.like(uid, colleagueUid);
		dispatch({ type: types.LIKE_ADD_SUCCESSFULLY, payload: user });
	} catch (e) {
		dispatch({
			type: types.LIKES_ADD_FAILED,
			payload: colleagueUid,
			error: e?.message,
		});
	}
};

export const unlike = async (dispatch, uid, colleagueUid) => {
	dispatch({ type: types.LIKES_REMOVE, payload: colleagueUid });
	QueueService.sendEvent(uid, LikeEvents.Unlike, colleagueUid);
	try {
		await LikesService.unlike(uid, colleagueUid);
		dispatch({ type: types.LIKE_REMOVE_SUCCESSFULLY, payload: colleagueUid });
	} catch (e) {
		dispatch({
			type: types.LIKES_REMOVE_FAILED,
			payload: colleagueUid,
			error: e?.message,
		});
	}
};

export const fetchLikesUsers = () => {
	return { type: types.LIKES_USERS_FETCH };
};

export const fetchLikesUsersLoadMore = () => {
	return { type: types.LIKES_USERS_FETCH_LOAD_MORE };
};
export const addLikesUser = (payload) => {
	return { type: types.LIKES_ADDED, payload };
};
