import i18next from "i18next";

import MaritalStatus from "consts/maritalStatus";
import SearchMultiEnumButton from "./SearchMultiEnumButton";
import SearchFilterKeys from "consts/searchFilterKeys";

export default ({ onChange, profile, filter }) => {
  const spouseGender = 1 - profile.gender;

  return (
    <SearchMultiEnumButton
      className="showTinyTitle"
      onChange={onChange}
      options={Object.values(MaritalStatus)}
      subtitle={i18next.t("Edit Profile.MaritalStatus")}
      translatePrefix={`MaritalStatus_${spouseGender}`}
      SearchFilterKey={SearchFilterKeys.MaritalStatus}
      initSelected={filter}
    />
  );
};
