import { Observable } from "rxjs";

class LocationService {
  geoSuccess(position, subscriber) {
    const location = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    subscriber.next(location);
  }

  geoError(error, subscriber) {
    subscriber.error(error);
  }

  getCurrentLocation() {
    return new Observable((subscriber) => {
      navigator.geolocation.getCurrentPosition(
        (position) => this.geoSuccess(position, subscriber),
        (error) => this.geoError(error, subscriber),
        { enableHighAccuracy: false, timeout: 20000 }
      );
    });
  }
}

export default new LocationService();
