export const CHAT_MESSAGES_FETCH_SUCCESSFULLY = "chatMessages.CHAT_MESSAGES_FETCH_SUCCESSFULLY";
export const CHAT_MESSAGES_FETCH_FAILED = "chatMessages.CHAT_MESSAGES_FETCH_FAILED";
export const CHAT_MESSAGES_CLICK_NEW_USER = "chatMessages.CHAT_MESSAGES_CLICK_NEW_USER";
export const CHAT_MESSAGES_SEND_MESSAGE = "chatMessages.CHAT_MESSAGES_SEND_MESSAGE";
export const CHAT_MESSAGES_SEND_MESSAGE_SUCCESSFULLY = "chatMessages.CHAT_MESSAGES_SEND_MESSAGE_SUCCESSFULLY";
export const CHAT_MESSAGES_SEND_MESSAGE_FAILED = "chatMessages.CHAT_MESSAGES_SEND_MESSAGE_FAILED";
export const CHAT_MESSAGES_UPDATED = "chatMessages.CHAT_MESSAGES_UPDATED";
export const CHAT_MESSAGES_UPDATED_ERROR = "chatMessages.CHAT_MESSAGES_UPDATED_ERROR";
export const CHAT_MESSAGES_ADDED = "chatMessages.CHAT_MESSAGES_ADDED";
export const CHAT_MESSAGES_ADDED_ERROR = "chatMessages.CHAT_MESSAGES_ADDED_ERROR";
export const LEAVE_CHAT_MESSAGES_SCREEN = "chatMessages.LEAVE_CHAT_MESSAGES_SCREEN";
export const CHAT_MESSAGES_READ = "chatMessages.CHAT_MESSAGES_READ";

export const CHAT_MESSAGES_START_NEW_CHAT = "chatMessages.CHAT_MESSAGES_START_NEW_CHAT";
