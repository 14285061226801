import { useState } from "react";
import { useSelector } from "react-redux";
import {
	Carousel,
	CarouselCaption,
	CarouselControl,
	CarouselIndicators,
	CarouselItem,
	Modal,
	ModalBody,
} from "reactstrap";
import _ from "lodash";

import useGalleryModal from "hooks/useGalleryModal";
import usePopups from "hooks/usePopups";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { getUserProfile } from "redux/profile/selectors";
import GalleryTypes from "consts/galleryTypes";
import { getPopups } from "redux/popups/selectors";
import placeholderMan from "assets/images/emptyImages/placeholderMan.png";
import placeholderWoman from "assets/images/emptyImages/placeholderWoman.png";
import privatePicture from "assets/images/emptyImages/private.jpg";

export default () => {
	const activeIndex = useSelector((state) => state.galleryModal.activeIndex);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const galleryType = useSelector((state) => state.galleryModal.galleryType);
	const { currentUserProfile: activeUserProfile } = useSelector(getPopups);
	const defaultImage = activeUserProfile?.gender ? placeholderWoman : placeholderMan;

	const userProfile =
		galleryType === GalleryTypes.Public
			? active_userId
				? useSelector(getUserPublicProfile(active_userId))
				: activeUserProfile
			: useSelector(getUserProfile);

	const items = (_.isObject(userProfile.pictures) &&  !_.isEmpty(userProfile.pictures))
		? Object.keys(userProfile.pictures)?.map((picName) => ({
				src: userProfile.pictures[picName].url,
		  }))
		: userProfile.isPicturesPrivate
		? [{ src: privatePicture }]
		: [{ src: defaultImage }];

	const [animating, setAnimating] = useState(false);
	const { closePopup } = usePopups();
	const { setActiveIndex } = useGalleryModal();

	const next = () => {
		if (animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	const previous = () => {
		if (animating) return;
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	const withAnimation = () => {
		const carouselItems = document.querySelectorAll(".carousel-item");
		carouselItems.forEach((item) => (item.style.transition = "transform 300ms ease"));
	};

	const withoutAnimation = () => {
		const carouselItems = document.querySelectorAll(".carousel-item");
		carouselItems.forEach((item) => (item.style.transition = "transform 0ms ease"));
	};

	const nextAutoHandler = () => {
		next();
		withAnimation();
	};

	const preAutoHandler = () => {
		previous();
		withAnimation();
	};

	const nextClickHandler = () => {
		previous();
		withoutAnimation();
	};

	const preClickHandler = () => {
		next();
		withoutAnimation();
	};

	const goToIndex = (newIndex) => {
		if (animating) return;
		setActiveIndex(newIndex);
	};

	const slides = items.map((item) => {
		return (
			<CarouselItem
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={item.src}>
				<img src={item.src} alt="gallery image" />
				<CarouselCaption captionText={item.caption} captionHeader={item.caption} />
			</CarouselItem>
		);
	});

	return (
		<Modal tabIndex="-1" isOpen={true} toggle={closePopup} className="galleryModal" centered>
			<ModalBody>
				<button className="close closeGalleryModal ri-close-line" onClick={closePopup}></button>

				<Carousel
					activeIndex={activeIndex !== -1 ? activeIndex : 0}
					next={nextAutoHandler}
					previous={preAutoHandler}
					className="galleryCarousel"
					interval={5000}>
					{slides}
					{slides.length > 1 && (
						<>
							<CarouselIndicators
								items={items}
								activeIndex={activeIndex !== -1 ? activeIndex : 0}
								onClickHandler={goToIndex}
							/>
							<CarouselControl
								direction="next"
								directionText="Next"
								onClickHandler={nextClickHandler}
							/>
							<CarouselControl
								direction="prev"
								directionText="Previous"
								onClickHandler={preClickHandler}
							/>
						</>
					)}
				</Carousel>
			</ModalBody>
		</Modal>
	);
};
