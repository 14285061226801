import i18next from "i18next";
import { PopoverBody, Nav, NavLink, Popover } from "reactstrap";
import { useState } from "react";

import usePopups from "hooks/usePopups";
import GalleryTypes from "consts/galleryTypes";
import getImageBase64FromURl from "helpers/getImageBase64FromURl";
import { useEffect } from "react";
import CropImageTypes from "consts/cropImageTypes";
import ImageLoader from "components/ImageLoader";

export default ({
	url,
	className = "",
	header,
	name,
	index,
	setMainImage,
	onDeleteImage,
	isNewImageInProcess,
	...rest
}) => {
	const { showGalleryPopup, showCropImagePopup } = usePopups();
	const [isOpen, setIsOpen] = useState(false);
	const [isImgLoaded, setIsImgLoaded] = useState(false);
	const [imageBase64, setImageBase64] = useState("");
	const toggle = () => setIsOpen(!isOpen);

	useEffect(async () => setImageBase64(await getImageBase64FromURl(url)), []);

	const onLoad = () => setIsImgLoaded(true);

	const onEditImage = () => {
		showCropImagePopup({
			image: imageBase64,
			cropImageType: CropImageTypes.EditedImage,
			cropImageName: name,
		});
		toggle();
	};

	const onSetAsMainImage = () => {
		setMainImage(index);
		toggle();
	};

	const _onDeleteImage = () => {
		onDeleteImage(index);
		toggle();
	};

	return (
		<div className={`wrapOneImage ${className}`}>
			{header}
			<button className="roundBtn edit" id={`image_${name}`} />
			<Popover
				isOpen={isOpen}
				placement="top"
				target={`image_${name}`}
				trigger="legacy"
				toggle={toggle}>
				<PopoverBody>
					<div>
						<Nav vertical>
							<NavLink onClick={onEditImage} href="#" className="edit">
								{i18next.t("Edit Pictures.Edit image")}
							</NavLink>
							<NavLink onClick={onSetAsMainImage} href="#" className="pin">
								{i18next.t("Edit Pictures.Set as main image")}
							</NavLink>
							<NavLink onClick={_onDeleteImage} href="#" className="trash">
								{i18next.t("Edit Pictures.Delete image")}
							</NavLink>
						</Nav>
					</div>
				</PopoverBody>
			</Popover>
			<img
				src={url}
				onLoad={onLoad}
				onClick={() => showGalleryPopup(GalleryTypes.Private, index)}
			/>
			{(!isImgLoaded || isNewImageInProcess) && <ImageLoader />}
			{rest?.status === 4 && rest?.extraData?.hasFace === false && (
				<span
					className="noFace"
					style={{
						color: "red",
						position: "absolute",
						bottom: "-20px",
						textAlign: "center",
						minWidth: "max-content",
					}}>
					{i18next.t("Edit Pictures.noFace")}
				</span>
			)}
		</div>
	);
};
