import { mergeMap, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { EMPTY, of } from "rxjs";

import SystemService from "services/system";
import TagsService from "services/tags";
import * as types from "./actionTypes";
import * as initTypes from "../init/actionTypes";
import { loadAppSettingsSuccessfully, loadTags } from "./actions";

export const loadAppSettings = (action$) =>
	action$.pipe(
		ofType(initTypes.INIT_PLATFORM_SUCCESSFULLY),
		mergeMap(async () => {
			const settings = await SystemService.fetchAppSettings();
			return loadAppSettingsSuccessfully(settings);
		}),
		catchError((error) => {
			return of({
				type: types.APP_SETTINGS_FETCH_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const reFetchTags = (action$) =>
	action$.pipe(
		ofType(types.APP_SETTINGS_FETCH_SUCCESSFULLY),
		mergeMap(async ({ payload }) => {
			const isFetched = await TagsService.loadTags(payload?.tags?.key);
			return loadTags(isFetched);
		}),
		catchError(() => {
			return EMPTY;
		})
	);
