export default ({ pictureUrl, onClick }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-end wrapBigImage"
      onClick={onClick}
    >
      <img
        src={pictureUrl}
        className="avatar-lg img-thumbnail jsmartProfileImg"
        alt="main picture"
      />
    </div>
  );
};
