export const CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_FETCH_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_FETCH_FAILED";

export const CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_ADDED_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_ADDED_FAILED";

export const CHAT_RECEIVED_FILTERED_UPDATED_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_UPDATED_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_UPDATED_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_UPDATED_FAILED";

export const CHAT_RECEIVED_FILTERED_REMOVED_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_REMOVED_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_REMOVED_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_REMOVED_FAILED";

export const UPDATE_MESSAGES_FILTER_SUCCESFULLY =
	"chatReceivedFiltered.UPDATE_MESSAGES_FILTER_SUCCESFULLY";

export const UPDATE_MESSAGES_FILTER_SUBMITED =
	"chatReceivedFiltered.UPDATE_MESSAGES_FILTER_SUBMITED";

export const UPDATE_MESSAGES_FILTER_INIT = "chatReceivedFiltered.UPDATE_MESSAGES_FILTER_INIT";
export const UPDATE_MORE_NUMBER = "chatReceivedFiltered.UPDATE_MORE_NUMBER";
export const RESET_CHAT_RECEIVED_FILTERED_USERS =
	"chatReceivedFiltered.RESET_CHAT_RECEIVED_FILTERED_USERS";
export const RESET_CHAT_RECEIVED_FILTERED_USERS_SUCCESSFULLY =
	"chatReceivedFiltered.RESET_CHAT_RECEIVED_FILTERED_USERS_SUCCESSFULLY";
