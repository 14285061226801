import { mergeMap, map, takeUntil, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import { getLoggedInUserUID } from "../auth/selectors";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";

import LetPicturesMeService from "services/letPicturesMe";

export const fetchLetPicturesMe = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			try {
				const payload = await LetPicturesMeService.fetchLetPicturesMe(loggedInUserUID);
				return {
					type: types.LET_PICTURES_ME_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.LET_PICTURES_ME_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.LET_PICTURES_ME_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const fetchLetPicturesMeAdded = (action$, store) =>
	action$.pipe(
		ofType(types.LET_PICTURES_ME_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return LetPicturesMeService.listenLetPicturesMeAdded(loggedInUserUID).pipe(
				map((change) => {
					return {
						type: types.LET_PICTURES_ME_ADDED,
						payload: change,
					};
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.LET_PICTURES_ME_ADDED_FAILED,
				payload: error?.message,
			});
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		repeat()
	);

export const fetchLetPicturesMeRemoved = (action$, store) =>
	action$.pipe(
		ofType(types.LET_PICTURES_ME_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return LetPicturesMeService.listenLetPicturesMeRemoved(loggedInUserUID).pipe(
				map((change) => {
					return {
						type: types.LET_PICTURES_ME_REMOVED,
						payload: change,
					};
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.LET_PICTURES_ME_REMOVED_FAILED,
				payload: error?.message,
			});
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		repeat()
	);
