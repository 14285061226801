import i18next from "i18next";

import DetailsAreaContainer from "./DetailsAreaContainer";
import Terms from "./ProfileInputs/Terms";

export default () => {

  return  <DetailsAreaContainer title={i18next.t('Pages.Terms')}>
              <Terms/>
          </DetailsAreaContainer>
};
