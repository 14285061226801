import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default () => {
	const { closePopup } = usePopups();
	const { text } = useSelector(getPopups);

	useEffect(() => {
		setTimeout(() => {
			closePopup();
		}, 2500);
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>{text}</ModalHeader>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
