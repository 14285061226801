import { useSelector } from "react-redux";
import SendTemplateMessageButton from "components/search/card/SendTemplateMessageButton";
import MessageButton from "components/search/card/MessageButton";
import LikeButton from "components/search/card/LikeButton";
import { getChat } from "redux/chatReceived/selectors";

export default ({ user }) => {
	const chatID = useSelector(getChat(user.uid));

	return (
		<div className="buttonsActions">
			<div role="group" className="btn-group">
				<SendTemplateMessageButton user={user} isFromCardUserSidebar={true} />
				<MessageButton chatID={chatID} user={user} />
				<LikeButton user={user} />
			</div>
		</div>
	);
};
