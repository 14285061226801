import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {
	data: [],
	users: {},
	apiStatus: null,
	loadMoreStatus: null,
	pitId: null,
	searchAfter: null,
	hasMoreResults: true,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.LET_PICTURES_FETCH_SUCCESSFULLY:
			state.data = action.payload;
			break;
		case types.LET_PICTURES_ADDED:
			state.data = { ...state.data, ...action.payload };
			break;
		case types.LET_PICTURES_REMOVED:
			delete state.data[action.payload];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
