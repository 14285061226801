import React, { useState } from "react";
import Input from "../Input";
import Question from "./Question";

export default ({
  title,
  subTitle,
  onSubmit,
  hasError,
  errMsg,
  onBackButtonClick,
  showBackButton,
  initVal = "",
  ...rest
}) => {
  const [value, setValue] = useState();
  const [isValid, setIsValid] = useState();
  const handleOnChange = (val) => {
    setValue(val);
  };
  const handleOnSubmit = () => {
    onSubmit(value);
  };
  const handleOnValidityChange = (_isValid) => {
    setIsValid(_isValid);
  };

	const validateExpresion = (value) => value.trim() !== "";

  return (
    <Question
      isValid={isValid}
      title={title}
      subTitle={subTitle}
      onSubmit={handleOnSubmit}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    >
      <Input
        onValidityChange={handleOnValidityChange}
        onChange={handleOnChange}
        hasError={hasError}
        errMsg={errMsg}
        onEnterPressed={handleOnSubmit}
        initVal={initVal}
        validateExpresion={validateExpresion}
        {...rest}
      />
    </Question>
  );
};
