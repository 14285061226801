import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHAT_META_FETCH_SUCCESSFULLY:
		case types.CHAT_META_UPDATE_SUCESSFULLY:
		case types.CHAT_META_ADDED_SUCESSFULLY:
			return { ...state, ...action.payload };
		case types.CHAT_META_CHAT_REMOVED_SUCCESSFULLY:
			delete state[action.payload];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
