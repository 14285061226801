import firebase from "firebase/app";

class HttpsCallable {
	async call(type, data) {
		const functions = firebase.functions();
		const _data = { type, data };

		try {
			const result = await functions.httpsCallable("getCall")(_data);
			return result;
		} catch (e) {
			console.log(type, data);
			console.log(e);
			return { error: "error" };
		}
	}
}

export default new HttpsCallable();
