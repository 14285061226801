import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { unBlockUser } from "redux/blocks/actions";

export default () => {
  const { closePopup } = usePopups();
  const { unblockUserProfile } = useSelector(getPopups);
  const dispatch = useDispatch();

  const _unBlockUser = () => {
    dispatch(unBlockUser(unblockUserProfile));
    closePopup();
  };

  return (
    <Modal isOpen={true} toggle={closePopup} className="simpleDialog">
      <ModalHeader>
        <i className="ri-archive-line text-muted jsmartDrop jsmartReport bigIcon"></i>
        {i18next.t("BlockedUsers.UnBlock user")}
      </ModalHeader>
      <ModalBody>
        {i18next.t("BlockedUsers.Are you sure to unblock", {
          name: unblockUserProfile.name,
        })}
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          size="sm"
          color="primary"
          onClick={closePopup}
          className="btn-rounded littleBtn"
        >
          {i18next.t("no")}
        </Button>
        <Button
          outline
          size="sm"
          color="primary"
          onClick={_unBlockUser}
          className="btn-rounded littleBtn"
        >
          {i18next.t("BlockedUsers.Yes, Unblock")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
