import "assets/scss/blog/blogArticle.scss";
import BaseHelmet from "components/seo/BaseHelmet";
import blogService from "services/blogService";
import useNavigator from "hooks/useNavigator";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import i18next from "i18next";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import { getSearchUsers } from "redux/search/selectors";
import publicProfile from "services/publicProfile";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import backDescPopup from "assets/images/backDescPopup.png";
import articlesStatus from "consts/articlesStatus";
import { getUserProfile } from "redux/profile/selectors";
import BlogBox from "./BlogBox";

// import LandingFooter from "components/landing/LandingFooter";

export default function BlogArticle({}) {
	const userProfile = useSelector(getUserProfile) || {};
	const dispatch = useDispatch();
	const { navigateToPath } = useNavigator();
	const { urlName } = useParams();

	const history = useHistory();
	const searchUsersSelectors = useSelector(getSearchUsers);

	const [articleData, setArticleData] = useState({});
	const [users, setUsers] = useState([]);
	const [publicUser, setPublicUser] = useState({});

	const closeBlog = () => {
		if (articleData?.categorySlug)
			return navigateToPath(`/blog/category/${articleData?.categorySlug}`);
		return navigateToPath(`/blog`);
	};

	const openTalentPopup = (uid) => {
		const currentUrl = history.location.pathname + history.location.search;
		history.push(currentUrl);
	};

	const searchByTag = (e, tagName) => {
		e.stopPropagation();
		navigateToPath(`/search?freeText=${tagName}&tagsKeys=&category=`);
	};

	const shareArticle = async () => {
		if (!articleData?.slug) return;

		const affId = !!userProfile?.internalID ? `?affId=${userProfile?.internalID}` : "";
		const articleUrl = `${window.location.origin}/blog/${articleData?.slug}${affId}`;
		if (!!isMobile) {
			try {
				return await navigator.share({
					title: `מצאתי מאמר באתר "jsmart" שיכול לעניין אותך`,
					text: `${articleData?.title}`,
					url: articleUrl,
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			navigator.clipboard
				.writeText(articleUrl)
				.then(() => dispatch(openNotification(i18next.t("notificationActions.copyLink"))));
		}
	};

	useEffect(async () => {
		if (!!articleData?.linkedAuthor) {
			publicProfile.fetchProfile(articleData?.linkedAuthor).then((pUser) => {
				if (!pUser || !pUser?.uid) return;
				setPublicUser(pUser);
			});
		}
	}, [articleData]);

	useEffect(async () => {
		if (!!searchUsersSelectors && !!searchUsersSelectors?.length) setUsers(searchUsersSelectors);
	}, [searchUsersSelectors]);

	useEffect(async () => {
		if (!urlName) return navigateToPath("/blog");

		await blogService.getArticleByUrlName(urlName).then((data) => {
			if (!data) return navigateToPath("/blog");
			const art = Object.values(data || {})[0];
			if (
				!art ||
				(window.location.search !== "?draft=t" && art?.status !== articlesStatus.published)
			)
				return navigateToPath("/blog");
			blogService.updateArticleViews(art.key);
			setArticleData(art);
		});
	}, []);

	return (
		<>
			{!!articleData && (
				<>
					<BaseHelmet
						title={articleData?.title ? `jsmart - ${articleData?.title}` : "jsmart"}
						description={articleData?.description ? articleData?.description : "מאמר"}></BaseHelmet>
					<Box className="wrapArticlePage" style={{ paddingBottom: !!isMobile && "84px" }}>
						<Box className="bigImg">
							{!!isMobile && articleData?.key && (
								<Box className="wrapShareAndBack">
									<Box className="wrapBackBlog">
										<img src={backDescPopup} onClick={closeBlog} />
									</Box>
									<Button onClick={shareArticle}>שיתוף</Button>
								</Box>
							)}

							{!articleData?.panoramicImage ? (
								<Skeleton animation="pulse" className="skeleton imgSkeleton" />
							) : (
								<img src={articleData?.panoramicImage} alt="img" />
							)}
						</Box>

						<Box className="articlePage">
							<Box className="articleWrapper">
								{!isMobile && (
									<Box className="wrapBackBlog">
										<img style={{ cursor: "pointer" }} src={backDescPopup} onClick={closeBlog} />
									</Box>
								)}
								<Box className="wrapHeaderContent">
									{!articleData?.key ? (
										<>
											<Skeleton animation="wave" className="skeleton titleSkeleton" />
											<Skeleton animation="wave" className="skeleton authorSkeleton" />
										</>
									) : (
										<>
											<h2>{articleData?.title}</h2>
											<Box className="descText">{articleData?.description}</Box>
											<Box className="authorAndShare">
												<Box className="authorArticle">
													<span
														onClick={() => {
															if (!!publicUser?.uid) openTalentPopup(publicUser?.uid);
														}}
														className={`name ${
															!!publicUser?.uid && !!publicUser?.profileImage?.url ? "withImg" : ""
														}`}>
														<>
															{!!publicUser?.uid && !!publicUser?.profileImage?.url && (
																<Box className="wrapPublicUserImg">
																	<img src={publicUser?.profileImage?.url} />
																</Box>
															)}
														</>
														{articleData?.author}
													</span>

													<Box className="wrapViewNDate">
														<span>{moment(articleData?.publishData).format("DD.MM.YYYY")}</span>
														{/* <span>{`${articleData?.views ? articleData?.views : 0} צפיות`}</span> */}
													</Box>
												</Box>
												{!isMobile && <Button onClick={shareArticle}>שיתוף</Button>}
											</Box>
										</>
									)}
								</Box>

								<Box className="wrapContentAndMoreArt">
									<Box className="wrapContent">
										{!articleData?.key ? (
											<>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"100%"}
													height={"100px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"60%"}
													height={"100px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"100%"}
													height={"80px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"70%"}
													height={"200px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"80%"}
													height={"50px"}
												/>
											</>
										) : (
											<>
												{/* {!isMobile && <Box className="descText">{articleData?.description}</Box>} */}

												{!!articleData?.content && (
													<div
														className="contentText"
														dangerouslySetInnerHTML={{ __html: articleData?.content }}></div>
												)}
												{!!articleData?.tags && !!articleData?.tags?.length && (
													<Box className="wrapFooterTags">
														{articleData.tags.map((tag, index) => (
															<button
																onClick={(e) => searchByTag(e, tag.name)}
																className="tag"
																key={`tag${index}`}>
																{tag?.name}
															</button>
														))}
													</Box>
												)}
											</>
										)}
									</Box>
									{!isMobile && (
										<Box className="moreArticle">
											<BlogBox
												withoutHeader={true}
												countBlogs={5}
												sortBy={articleData?.categoryKey || ""}
												blogKey={articleData?.key}
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
						{/* <LandingFooter /> */}
					</Box>
				</>
			)}
		</>
	);
}
