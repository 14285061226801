import { Button } from "reactstrap";
import { useSelector } from "react-redux";

import usePopups from "hooks/usePopups";
import { getFetchSubscriptionPlansApiStatus } from "redux/subscription/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";

export default ({ className = "", text }) => {
	const { showSubscriptionPopup } = usePopups();
	const fetchSubscriptionPlansApiStatus = useSelector(getFetchSubscriptionPlansApiStatus);

	const handleButtonClick = () => {
		showSubscriptionPopup();
	};

	return (
		<>
			{fetchSubscriptionPlansApiStatus === ApiRequestStatus.SUCCESSFULLY && (
				<Button className={className} onClick={handleButtonClick} outline color="secondary">
					{text}
				</Button>
			)}
		</>
	);
};
