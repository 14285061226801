import heGlobals from "./he/globals.json";
import heEnums from "./he/enums.json";
import he from "./he/he.json";

import enGlobals from "./en/globals.json";
import enEnums from "./en/enums.json";
import en from "./en/en.json";

export default {
  he: { ...heGlobals, ...heEnums, ...he },
  en: { ...enGlobals, ...enEnums, ...en },
};
