import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class LetPicturesMe {
  BASE_REF = "let_pictures_me";

  async fetchLetPicturesMe(uid) {
    return (
      (
        await firebase
          .database()
          .ref(this.BASE_REF)
          .child(uid)
          .orderByChild("timeStamp")
          .startAt(null)
          .once("value")
      ).val() || {}
    );
  }

  listenLetPicturesMeAdded(uid) {
    return new Observable((subscriber) => {
      const ref = firebase
        .database()
        .ref(this.BASE_REF)
        .child(uid)
        .orderByChild("timeStamp")
        .startAt(Date.now());

      const callbackFn = ref.on(
        "child_added",
        (snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_added", callbackFn);
    });
  }

  listenLetPicturesMeRemoved(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_removed",
        (snapshot) => subscriber.next(snapshot.key),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_removed", callbackFn);
    });
  }

  listenChildChanged(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_changed",
        (snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_changed", callbackFn);
    });
  }
}

export default new LetPicturesMe();
