export const LET_PICTURES_FETCH_SUCCESSFULLY = "letPictures.LET_PICTURES_FETCH_SUCCESSFULLY";
export const LET_PICTURES_FETCH_FAILED = "letPictures.LET_PICTURES_FETCH_FAILED";

export const LET_PICTURES_REMOVED = "letPictures.LET_PICTURES_REMOVED";
export const LET_PICTURES_REMOVED_FAILED = "letPictures.LET_PICTURES_REMOVED_FAILED";
export const LET_PICTURES_ADDED = "letPictures.LET_PICTURES_ADDED";
export const LET_PICTURES_ADDED_FAILED = "letPictures.LET_PICTURES_ADDED_FAILED";
export const LET_PICTURES_ADD_SUCCESSFULLY = "letPictures.LET_PICTURES_ADD_SUCCESSFULLY";
export const LET_PICTURES_ADD_FAILED = "letPictures.LET_PICTURES_ADD_FAILED";
export const LET_PICTURES_REMOVE_SUCCESSFULLY = "letPictures.LET_PICTURES_REMOVE_SUCCESSFULLY";
export const LET_PICTURES_REMOVE_FAILED = "letPictures.LET_PICTURES_REMOVE_FAILED";
