import React from "react";
import ButtonGroup from "../ButtonGroup";
import Question from "./Question";

export default ({
  options,
  title,
  subTitle,
  onSubmit,
  onBackButtonClick,
  showBackButton,
  translationPrefix,
}) => {
  return (
    <Question
      title={title}
      subTitle={subTitle}
      showSubmitButton={false}
      onBackButtonClick={onBackButtonClick}
      showBackButton={showBackButton}
    >
      <ButtonGroup
        onClick={onSubmit}
        options={options}
        translatePrefix={translationPrefix}
      />
    </Question>
  );
};
