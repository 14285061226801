import i18next from "i18next";

import SearchMultiEnumButton from "./SearchMultiEnumButton";
import Children from "consts/children";
import SearchFilterKeys from "consts/searchFilterKeys";

export default ({ onChange, filter }) => {
  return (
    <SearchMultiEnumButton
      className="hideTinyTitle"
      onChange={onChange}
      options={Object.values(Children)}
      subtitle={i18next.t("Edit Profile.Children")}
      translatePrefix="Children"
      SearchFilterKey={SearchFilterKeys.Children}
      initSelected={filter}
    />
  );
};
