import React from "react";
import { Route } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import StopRoute from "./StopRoute";

export default ({ layout: Layout, component: Component, auth, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(matchProps) => (
				<StopRoute>
					<AuthRoute checkAuth={auth}>
						<Layout>
							<Component {...matchProps} />
						</Layout>
					</AuthRoute>
				</StopRoute>
			)}
		/>
	);
};
