import { getLoggedInUserUID } from "redux/auth/selectors";
import ChatMetaService from "services/chatMeta";
import * as types from "./actionTypes";

export const updatePromotionsRead = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		// await ChatMetaService.updateRead(uid, "promotions");
		dispatch({ type: types.CHAT_META_PROMOTION_READ_UPDATED });
	};
};

export const clearUnviewedNewMessages = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		await ChatMetaService.clearUnviewedNewMessages(uid);
		dispatch({ type: types.CHAT_META_CLEAR_UNVIEWED_NEW_MESSAGES });
	};
};
