export default {
	Search: "Search",
	Swipe: "Swipe",
	Likes: "Likes",
	LikedMe: "LikedMe",
	Views: "Views",
	ViewedMe: "ViewedMe",
	Blocks: "Blocks",
	Matches: "Matches",
};
