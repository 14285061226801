import * as types from "./actionTypes";

export const showPopup = (popupType, infoObj) => {
	return {
		type: types.SHOW_POPUP,
		payload: { popupType, ...infoObj },
	};
};

export const closePopupUnderPopup = () => ({
	type: types.SHOW_POPUP,
	payload: { popupUnderPopupType: false },
});

export const closeSecondPopupUnderPopup = () => ({
	type: types.SHOW_POPUP,
	payload: { secondPopupUnderPopupType: false },
});
