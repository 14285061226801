import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";
import googleIAPState from "consts/googleIAPState";

const initialState = {
	data: undefined,
	openActionSheetLimitSource: undefined,
	plans: [],
	selectedPlan: null,
	fetchCardcomApiStatus: ApiRequestStatus.INIT,
	googleApiStatus: ApiRequestStatus.INIT,
	cardComUrl: "",
	fetchBitApiStatus: ApiRequestStatus.INIT,
	bitUrl: "",
	purchaseStatus: ApiRequestStatus.INIT,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.SUBSCRIPTIONS_FETCH_SUCCESSFULLY:
			state.data = action.payload;
			break;
		case types.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY:
			state.plans = action.payload;
			state.fetchSubscriptionPlansApiStatus = ApiRequestStatus.SUCCESSFULLY;
			break;
		case types.SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM:
			state.fetchCardcomApiStatus = ApiRequestStatus.PENDING;
			state.selectedPlan = action.plan;
			break;
		case types.SUBSCRIPTIONS_PURCHASE_WITH_GOOGLE:
			state.googleApiStatus = ApiRequestStatus.PENDING;
			state.selectedPlan = action.plan;
			break;
		case types.SUBSCRIPTIONS_GOOGLE_STATUS_CHANGED:
			state.googleApiStatus =
				action.payload.status === googleIAPState.verified
					? ApiRequestStatus.SUCCESSFULLY
					: action.payload.status === googleIAPState.canceled
					? ApiRequestStatus.FAILED
					: ApiRequestStatus.PENDING;
			break;
		case types.SUBSCRIPTIONS_GOOGLE_CANCELLED:
			state.googleApiStatus = ApiRequestStatus.INIT;
			break;
		case types.SUBSCRIPTIONS_GOOGLE_APPROVED_FAILED:
		case types.SUBSCRIPTIONS_GOOGLE_ORDERED_FAILED:
			state.googleApiStatus = ApiRequestStatus.FAILED;
			break;
		case types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY:
			state.fetchCardcomApiStatus = ApiRequestStatus.SUCCESSFULLY;
			state.cardComUrl = action.response.url;
			break;
		case types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED:
			state.fetchCardcomApiStatus = ApiRequestStatus.FAILED;
			break;
		case types.SUBSCRIPTIONS_CARDCOM_PURCHASE_SUCCESSFULLY:
			state.purchaseStatus = ApiRequestStatus.SUCCESSFULLY;
			break;
		case types.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED:
			state.purchaseStatus = ApiRequestStatus.FAILED;
			break;
		case types.SUBSCRIPTIONS_FETCH_SALE_SUCCESSFULLY:
			state.sale = action.payload;
			break;
		case types.SUBSCRIPTION_POPUP_INIT_SUCCESSFULLY:
			state.googleApiStatus = ApiRequestStatus.INIT;
			state.fetchCardcomApiStatus = ApiRequestStatus.INIT;
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
