import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class ViewedMeService {
	BASE_REF = "viewed_me";

	async fetchViewedMe(uid) {
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("unView")
					.equalTo(true)
					.once("value")
			).val() || {}
		);
	}

	listenChildAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("unView")
				.equalTo(true);

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenViewedMeAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenChildChanged(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("unView")
				.equalTo(true);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenViewedMeRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("unView")
				.equalTo(true);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}
}

export default new ViewedMeService();
