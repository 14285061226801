export default {
  KnittedKipah: 1,
  BlackKipah: 2,
  BlackKnittedKipah: 3,
  SuedeKipah: 5,
  WhiteKipah: 6,
  Hat: 7,
  NoHeadCovering: 8,
  Other: 9,
};
