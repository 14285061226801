import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {
	users: {},
	apiStatus: null,
	loadMoreStatus: null,
	pitId: null,
	searchAfter: null,
	hasMoreResults: true,
	unViewedViewedMe: {},
};

export default produce((state, action) => {
	switch (action.type) {
		case types.VIEWED_ME_USERS_FETCH:
			return {
				...state,
				apiStatus: API_REQUESTS_STATUS.PENDING,
				pitId: null,
				searchAfter: null,
				hasMoreResults: true,
			};
		case types.VIEWED_ME_USERS_FETCH_LOAD_MORE:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.PENDING,
			};
		case types.VIEWED_ME_USERS_FETCH_SUCCESSFULLY:
			return {
				...state,
				users: { ...action.payload.users },
				apiStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
			};
		case types.VIEWED_ME_USERS_FETCH_LOAD_MORE_SUCCESSFULLY:
			return {
				...state,
				users: { ...action.payload.users, ...state.users },
				loadMoreStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
			};
		case types.VIEWED_ME_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY:
			state.users[action.user.uid] = action.user;
			break;
		case types.VIEWED_ME_UNVIEWED_FETCH_SUCCESSFULLY:
			state.unViewedViewedMe = action.payload;
			break;
		case types.VIEWED_ME_UNVIEWED_CHANGE_SUCCESSFULLY:
		case types.VIEWED_ME_UNVIEWED_ADDED_SUCCESSFULLY:
			state.unViewedViewedMe = { ...state.unViewedViewedMe, ...action.payload };
			break;
		case types.VIEWED_ME_UNVIEWED_REMOVE_SUCCESSFULLY:
			delete state.unViewedViewedMe[action.payload];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
