export default {
	wisdom: 1,
	happines: 2,
	agility: 3,
	perfectionism: 4,
	cordiality: 5,
	innocence: 6,
	grace: 7,
	charismatic: 8,
	raliability: 9,
	smiling: 10,
	diligence: 11,
	"self discipline": 12,
	"stub bornness": 13,
	wit: 14,
	sincerity: 15,
	optism: 16,
	diggy: 17,
	courage: 18,
	givingWay: 19,
	generosity: 20,
	selectivy: 21,
	warmth: 22,
	kidness: 23,
	determination: 24,
};
