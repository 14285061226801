import i18next from "i18next";

import replaceStartWith from "helpers/replaceStartWith";

export default ({ profile }) => {
  const phone = replaceStartWith(profile.phone, 4, 0);

  return (
    <h6>
      {i18next.t("Account Settings.Phone number")} {phone}
    </h6>
  );
};
