import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';

import { getUserProfile } from "redux/profile/selectors";

const useIsEqualToRemoteData = data => {
    const loggedInUserProfile = useSelector(getUserProfile);
    const [ isEqualToRemoteData, setIsEqualToRemoteData ] = useState();

    useEffect(()=>{
        let _isEqualToRemoteData = true;
        Object.entries(data).map(([key, value]) => {
          if(Array.isArray(value)) {
            !isEqual([...loggedInUserProfile[key]||[]].sort(), [...value].sort()) && (_isEqualToRemoteData = false);
          } else {
            !isEqual(loggedInUserProfile[key], value) && (_isEqualToRemoteData = false);
          }
        });
        setIsEqualToRemoteData(_isEqualToRemoteData);
    },[data]);

    return isEqualToRemoteData;
  };
  
export default useIsEqualToRemoteData;