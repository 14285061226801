import produce from 'immer';

import * as types from './actionTypes';

const initialState = {};

export default produce((state , action) => {
  switch (action.type) {
    case types.UPDATE_QRCODE_HEADER_TEXT:
      state.headerText = action.payload;
      break;
  }
} , initialState);