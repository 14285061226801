import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
class SkipsService {
	BASE_REF = "skipped_users";

	async fetchSkipped(uid) {
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("timeStamp")
					.startAt(null)
					.once("value")
			).val() || {}
		);
	}

	skip(uid, colleagueUid) {
		return firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set({
			timeStamp: firebase.database.ServerValue.TIMESTAMP,
		});
	}

	unSkip(uid, colleagueUid) {
		return firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set(null);
	}

	listenSkippedAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("timeStamp")
				.startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenSkippedRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}
}

export default new SkipsService();
