import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { JaaSMeeting } from "@jitsi/react-sdk";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);

	console.log(data);
	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className="simpleDialog"
			style={{ minWidth: "calc(100vw - 50px)" }}>
			<ModalBody>
				<Box sx={{ margin: "10px 0", fontWeight: "600" }}>
					{`${data?.name ? data.name : ""} כאן ${
						!!data?.gender ? "את יכולה" : "אתה יכול"
					} לבדוק את המיקרופון והמצלמה`}
				</Box>

				{data?.roomName && data?.token && (
					<JaaSMeeting
						appId={"vpaas-magic-cookie-666af2da7dc64003ac0ba5e77b50b790"}
						roomName={data.roomName}
						configOverwrite={{
							disableDeepLinking: true,
							//toolbarButtons:["chat"]
							breakoutRooms: { hideAddRoomButton: true, hideJoinRoomButton: true },
						}}
						jwt={data.token}
						//spinner={CustomSpinner}
						getIFrameRef={(iframeRef) => {
							iframeRef.style.height = "700px";
						}}
						onApiReady={(externalApi) => console.log("test", externalApi)}
					/>
				)}
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("סגירה")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
