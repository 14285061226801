import { catchError, map, mergeMap, repeat, switchMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { EMPTY, of } from "rxjs";

import ChatMetaService from "services/chatMeta";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import * as chatTypes from "../chat/actionTypes";
import * as chatMessages from "../chatMessages/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import localStorage from "services/localStorage";

export const fetchChatMeta = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(async () => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			try {
				const payload = await ChatMetaService.fetchChatMeta(loggedInUserUID);
				return {
					type: types.CHAT_META_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.CHAT_META_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_META_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const updateReadMessages = (action$, store) =>
	action$.pipe(
		ofType(chatTypes.ACTIVE_USER_ID),
		mergeMap(({ payload }) => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			const loginFromManager = localStorage.get("loginFrommanager");
			if (!loginFromManager) {
				payload.chatID && ChatMetaService.updateRead(payload.userId);
			}
			return EMPTY;
		})
	);

export const fetchChatMetaUpdate = (action$, store) =>
	action$.pipe(
		ofType(types.CHAT_META_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return ChatMetaService.listenChatMeta(loggedInUserUID).pipe(
				map((change) => {
					return {
						type: types.CHAT_META_UPDATE_SUCESSFULLY,
						payload: change,
					};
				})
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_META_UPDATE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatMetaAdded = (action$, store) =>
	action$.pipe(
		ofType(types.CHAT_META_FETCH_SUCCESSFULLY),
		switchMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return ChatMetaService.listenChatMetaAdded(loggedInUserUID).pipe(
				map((change) => {
					return {
						type: types.CHAT_META_ADDED_SUCESSFULLY,
						payload: change,
					};
				})
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_META_ADDED_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatMetaRemoved = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return ChatMetaService.listenChatMetaRemoved(loggedInUserUID).pipe(
				map((change) => {
					return {
						type: types.CHAT_META_CHAT_REMOVED_SUCCESSFULLY,
						payload: change,
					};
				})
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_META_CHAT_REMOVED_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const disableTypingOnDisconect = (action$) =>
	action$.pipe(
		ofType(chatMessages.CHAT_MESSAGES_CLICK_NEW_USER),
		switchMap(async ({ collegueUid, chatID }) => {
			try {
				await ChatMetaService.clearTypingOnDisconect(collegueUid, chatID);
				return {
					type: types.CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_SUCCESSFULLY,
				};
			} catch (error) {
				return {
					type: types.CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_FAILED,
				payload: error?.message,
			});
		})
	);
