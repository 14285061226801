import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import CardsView from "components/CardsView";
import { getHasMoreResults, getIsLoadingMore, getViewsStatus } from "redux/views/selectors";
import { fetchViewsUsersLoadMore } from "redux/views/actions";
import { fetchViewsUsers } from "redux/views/actions";
import { getViewsUsers } from "redux/views/selectors";
import API_REQUEST_STATUS from "consts/apiRequestStatus";
import Loader from "components/Loader";

export default ({ scrollToTop }) => {
	const dispatch = useDispatch();
	const isLoadingMore = useSelector(getIsLoadingMore);
	const hasMoreResults = useSelector(getHasMoreResults);
	const users = useSelector(getViewsUsers);
	const status = useSelector(getViewsStatus);

	const fetchData = () => {
		if (!isLoadingMore && hasMoreResults) {
			dispatch(fetchViewsUsersLoadMore());
		}
	};

	useEffect(() => {
		dispatch(fetchViewsUsers());
		scrollToTop();
	}, []);

	return (
		<InfiniteScroll dataLength={users ? users.length : 0} next={fetchData} hasMore={true}>
			{status === API_REQUEST_STATUS.SUCCESSFULLY ? (
			<CardsView
			users={users}
			addViewedMeTime //ViewedMe timestamp of collage user is equal to Views timestamp
		/>			) : (
				<Loader className="loaderBeforeResults" />
			)}
		</InfiniteScroll>
	);
};
