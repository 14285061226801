import moment from "moment";

export const isSameDay = (currentMessage, diffMessage) => {
	if (!diffMessage || !diffMessage.timeStamp) {
		return false;
	}

	const currentCreatedAt = moment(currentMessage.timeStamp);
	const diffCreatedAt = moment(diffMessage.timeStamp);

	if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
		return false;
	}

	return currentCreatedAt.isSame(diffCreatedAt, "day");
};

export const getAgeByDob = (dob) => {
	return moment().diff(dob * 1000, "years");
};

export const getDaysArray = (month, year) => {
	const count = new Date(year, month, 0).getDate();
	return Array.from(new Array(count), (_, index) => String(1 + index).padStart(2, "0"));
};

export const getMonthsArray = () =>
	Array.from(new Array(12), (_, index) => String(1 + index).padStart(2, "0"));

export const getYearsArray = (minYear, maxYear) =>
	Array.from(new Array(maxYear - minYear + 1), (_, index) => index + minYear);

export const getSeparatedDate = (_date) => {
	const date = new Date(_date);
	return [
		String(date.getDate()).padStart(2, "0"),
		String(date.getMonth() + 1).padStart(2, "0"),
		date.getFullYear(),
	];
};
