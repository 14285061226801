import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { Observable } from "rxjs";

class UserChatReceivedFilteredService {
	BASE_REF = "chat_received_filterd";

	async fetchChatReceivedFiltered(countChats = 30) {
		const uid = firebase.auth().currentUser.uid;
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("timeStamp")
					.limitToLast(countChats)
					.once("value")
			).val() || {}
		);
	}

	listenChatReceivedFilteredUpdates(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);
			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenChatReceivedFilteredAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("timeStamp")
				.startAt(Date.now());
			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenChatReceivedFilteredRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}

	virtualDeleteChat(colleagueUid) {
		const uid = firebase.auth().currentUser?.uid;
		const ref = firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid);
		ref.once("value", (snapshot) => {
			if (snapshot.exists()) {
				ref.child("deleted").set(true);
			}
		});
	}

	cancelDeleteChat(colleagueUid) {
		const uid = firebase.auth().currentUser?.uid;
		const ref = firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid);
		ref.once("value", (snapshot) => {
			if (snapshot.exists()) {
				ref.child("deleted").set(false);
			}
		});
	}
}

export default new UserChatReceivedFilteredService();
