import { combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

import * as globalTypes from "./actionTypes";
import {
	loginWithPhone,
	resendCode,
	verifyPhone,
	listenToAuthChange,
	loginWithFacebook,
	loginWithGoogle,
	initHotjar,
	logAuthErrors,
	loginWithApple,
	loginWithToken,
} from "./auth/effects";
import {
	fetchChatReceived,
	fetchChatReceivedAdded,
	fetchChatReceivedRemoved,
	fetchChatReceivedUpdate,
	resetChatReceived,
} from "./chatReceived/effects";
import {
	fetchChatReceivedFiltered,
	fetchChatReceivedFilteredAdded,
	fetchChatReceivedFilteredUpdated,
	fetchChatReceivedFilteredRemoved,
	resetChatReceivedFiltered,
} from "./chatReceivedFiltered/effects";
import {
	fetchChatSent,
	fetchChatSentAdded,
	fetchChatSentUpdate,
	fetchChatSentRemoved,
	resetChatSent,
} from "./chatSent/effects";
import {
	fetchLoggedInUserProfile,
	fetchPublicUsersProfile,
	fetchUsersProfiles,
	listenToProfileAdded,
	listenToProfileUpdate,
} from "./publicProfiles/effects";
import {
	disableTypingOnDisconect,
	fetchChatMeta,
	fetchChatMetaAdded,
	fetchChatMetaRemoved,
	fetchChatMetaUpdate,
	updateReadMessages,
} from "./chatMeta/effects";
import {
	fetchBlockedMe,
	fetchBlockedMeAdded,
	unBlockUser,
	fetchBlocks,
	fetchBlocksAdded,
} from "./blocks/effects";
import {
	fetchOnlineUsers,
	fetchOnlineUsersAdded,
	fetchOnlineUsersRemoved,
} from "./presence/effects";
import {
	fetchChatMessages,
	listenToNewMessages,
	listenUpdateChatMessages,
} from "./chatMessages/effects";
import {
	fetchCardCom,
	fetchSubscription,
	fetchSubscriptionPlans,
	listenerCardcomPurchase,
	initSubscriptionModal,
	orderGoogleIAP,
	listenGoogleIAPStatusChanged,
	listenGoogleIAPStoreError,
	fetchSale,
} from "./subscription/effects";
import { saveForTest } from "./tests/effects";
import { loadAppSettings, reFetchTags } from "./system/effects";
import {
	fetchLikes,
	fetchLikesAdded,
	fetchLikesRemoved,
	fetchLikesUsers,
	// fetchUserProfileForLikesAdded,
	syncLikesUsersNLikesData,
} from "./likes/effects";
import { fetchSkipAdded, fetchSkipRemoved, fetchSkips } from "./skips/effects";
import {
	addImage,
	deleteImage,
	fetchProfileEffect,
	saveImage,
	setFirstImageAsMain,
	updateLocation,
	updateProfileEffect,
	updateProfileRegisterWithFacebookEffect,
	updateUserPhone,
	updateUserProviders,
} from "./profile/effects";
import { fetchUserGeneral } from "./general/effects";
import {
	fetchPromotions,
	fetchPromotionUpdate,
	updatePromotionStatusOnLikeOrDislike,
	fetchPromotionAdded,
} from "./promotions/effects";
import {
	fetchLikedMe,
	fetchLikedMeAdded,
	fetchLikedMeRemoved,
	fetchLikedMeUsers,
	fetchUserProfileForLikedMeAdded,
	listenToLikedMeUpdate,
} from "./likedMe/effects";
import { fetchSearch, fetchSearchLoadMore } from "./search/effects";
import { fetchMassLetter } from "./massLetter/effects";
import {
	fetchViewedMeUsers,
	fetchUserProfileForViewedMeAdded,
	listenToViewedMeUnViewedUpdate,
	listenToViewedMeUnViewedRemove,
	fetchViewedMeUnViewed,
} from "./viewedMe/effects";
import { fetchUserProfileForViewsAdded, fetchViewsUsers } from "./views/effects";
import {
	fetchLetPictures,
	fetchLetPicturesAdded,
	fetchLetPicturesRemoved,
} from "./letPictures/effects";
import {
	fetchLetPicturesMe,
	fetchLetPicturesMeAdded,
	fetchLetPicturesMeRemoved,
} from "./letPicturesMe/effects";
import { fetchMatches, fetchMatchesLoadMore } from "./matches/effects";
import { initAppPlatform, listenToFcmToken, fetchFcmToken } from "./init/effects";
import { setContacts } from "./contacts/effects";

export default (action$, store$, dependencies) =>
	combineEpics(
		loginWithPhone,
		loginWithToken,
		resendCode,
		verifyPhone,
		listenToAuthChange,
		initHotjar,
		logAuthErrors,
		loginWithApple,
		fetchChatReceived,
		resetChatReceived,
		fetchPublicUsersProfile,
		fetchUsersProfiles,
		fetchChatMeta,
		fetchBlocks,
		fetchBlockedMe,
		fetchBlocksAdded,
		fetchOnlineUsers,
		fetchChatMessages,
		listenUpdateChatMessages,
		fetchSubscription,
		loadAppSettings,
		listenToNewMessages,
		fetchLikes,
		fetchSkips,
		fetchProfileEffect,
		updateProfileEffect,
		fetchUserGeneral,
		fetchChatMetaUpdate,
		fetchChatMetaAdded,
		fetchChatMetaRemoved,
		updateReadMessages,
		disableTypingOnDisconect,
		fetchPromotions,
		unBlockUser,
		fetchBlockedMeAdded,
		fetchLoggedInUserProfile,
		fetchChatReceivedUpdate,
		fetchChatReceivedAdded,
		fetchChatReceivedRemoved,
		fetchChatReceivedFiltered,
		fetchChatReceivedFilteredAdded,
		fetchChatReceivedFilteredUpdated,
		fetchChatReceivedFilteredRemoved,
		resetChatReceivedFiltered,
		fetchChatSent,
		resetChatSent,
		fetchChatSentAdded,
		fetchChatSentUpdate,
		fetchChatSentRemoved,
		fetchLikesAdded,
		fetchLikesRemoved,
		fetchOnlineUsersAdded,
		fetchOnlineUsersRemoved,
		fetchSkipAdded,
		fetchSkipRemoved,
		listenToProfileAdded,
		listenToProfileUpdate,
		reFetchTags,
		deleteImage,
		addImage,
		saveImage,
		fetchLikedMe,
		fetchLikedMeAdded,
		fetchLikedMeRemoved,
		updatePromotionStatusOnLikeOrDislike,
		fetchSearch,
		fetchSearchLoadMore,
		fetchSubscriptionPlans,
		fetchCardCom,
		listenerCardcomPurchase,
		initSubscriptionModal,
		orderGoogleIAP,
		listenGoogleIAPStatusChanged,
		listenGoogleIAPStoreError,
		fetchSale,
		updateLocation,
		loginWithFacebook,
		updateProfileRegisterWithFacebookEffect,
		updateUserProviders,
		loginWithGoogle,
		setFirstImageAsMain,
		updateUserPhone,
		fetchLikesUsers,
		syncLikesUsersNLikesData,
		fetchLikedMeUsers,
		fetchUserProfileForLikedMeAdded,
		listenToLikedMeUpdate,
		fetchPromotionUpdate,
		fetchPromotionAdded,
		fetchMassLetter,
		fetchViewedMeUsers,
		fetchViewedMeUnViewed,
		fetchViewsUsers,
		fetchUserProfileForViewedMeAdded,
		fetchUserProfileForViewsAdded,
		listenToViewedMeUnViewedUpdate,
		listenToViewedMeUnViewedRemove,
		// fetchUserProfileForLikesAdded,
		fetchLetPictures,
		fetchLetPicturesAdded,
		fetchLetPicturesRemoved,
		fetchLetPicturesMe,
		fetchLetPicturesMeAdded,
		fetchLetPicturesMeRemoved,
		fetchMatches,
		fetchMatchesLoadMore,
		initAppPlatform,
		listenToFcmToken,
		fetchFcmToken,
		setContacts
	)(action$, store$, dependencies).pipe(
		catchError((err) => {
			console.log(err);
			return of({
				type: globalTypes.GLOBAL_ERROR,
			});
		})
	);
