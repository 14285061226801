import React, { useState } from "react";
import _ from "lodash";
import Question from "./Question";
import DropDown from "components/login/Dropdown";

export default ({
	options,
	title,
	subTitle,
	onSubmit,
	onBackButtonClick,
	showBackButton,
	defaultTitle,
	initValue,
	className,
	leaveScrollMode,
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();

	const handleOnChange = (val) => {
		setValue(val);
	};

	const handleOnSubmit = () => {
		onSubmit(value);
	};

	const handleOnValidityChange = (_isValid) => {
		setIsValid(_isValid);
	};
	return (
		<Question
			isValid={isValid}
			title={title}
			subTitle={subTitle}
			onSubmit={handleOnSubmit}
			showBackButton={showBackButton}
			onBackButtonClick={onBackButtonClick}>
			<DropDown
				onValidityChange={handleOnValidityChange}
				onChange={handleOnChange}
				options={options}
				defaultTitle={defaultTitle}
				initValue={initValue}
				className={className}
				leaveScrollMode={leaveScrollMode}
			/>
		</Question>
	);
};
