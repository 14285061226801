import React from "react";
import Header from "../views/Header";
import Footer from "../views/Footer";

export default ({ children }) => {
	return (
		<>
			<div className="layout-wrapper d-lg-flex">
				<Header />
				{children}
				<Footer />
			</div>
		</>
	);
};
