import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	lang: undefined,
	userGeneral: undefined,
	userGeneralApiStatus: undefined,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.USER_GENERAL_FETCH_SUCCESSFULLY:
			state.userGeneral = action.payload;
			state.userGeneralApiStatus = ApiRequestStatus.SUCCESSFULLY;
			break;
		case types.USER_GENERAL_FETCH_FAILED:
			state.userGeneralApiStatus = ApiRequestStatus.FAILED;
			break;
		case types.USER_GENERAL_UPDATE_COMMUNICATION_SETTINGS:
			state.userGeneral.public.settings.communication[action.key] = action.newVal;
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
