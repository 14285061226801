import { getCountryCallingCode, isValidNumber as isValid } from "libphonenumber-js";
import { isValidPhoneNumber } from "react-phone-number-input";

const COUNTRY = "IL";

export const getPhoneWithPrefix = (phone) => {
	return `+${getCountryCallingCode(COUNTRY)} ${phone}`;
};

export const isValidNumber = (number) => {
	return isValid(number, COUNTRY);
};

export const isValidSelectNumber = (number) => {
	return isValidPhoneNumber(number);
};
