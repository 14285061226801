import moment from "moment";
import { useState, useEffect } from "react";

import Dropdown from "./Dropdown";
import { getDaysArray, getMonthsArray, getSeparatedDate, getYearsArray } from "helpers/date";

export default ({ onChange, initDate, minDate, maxDate, onValidityChange }) => {
	const [day, month, year] = getSeparatedDate(initDate);
	const [daysOfCurrentMonth, setDaysOfCurrentMonth] = useState(getDaysArray(month, year));
	const [isValid, setIsValid] = useState();
	const [selectedDay, setSelectedDay] = useState(day);
	const [selectedMonth, setSelectedMonth] = useState(month);
	const [selectedYear, setSelectedYear] = useState(year);

	useEffect(() => {
		handleOnChange(
			moment(new Date(selectedYear, selectedMonth - 1, selectedDay)).format("YYYY-MM-DD")
		);
	}, [selectedDay, selectedMonth, selectedYear]);

	useEffect(() => {
		setDaysOfCurrentMonth(getDaysArray(selectedMonth, selectedYear));
	}, [selectedMonth, selectedYear]);

	const handleOnChange = (val) => {
		setIsValid(minDate >= val);
		onChange(val);
	};

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	return (
		<div className="dropdown-datePicker">
			<Dropdown
				onChange={setSelectedDay}
				options={daysOfCurrentMonth}
				initValue={day}
				className="day"
				leaveScrollMode={true}
			/>
			<Dropdown
				onChange={setSelectedMonth}
				options={getMonthsArray()}
				initValue={month}
				className="month"
				leaveScrollMode={true}
			/>
			<Dropdown
				onChange={setSelectedYear}
				options={getYearsArray(
					moment(maxDate, "YYYY-MM-DD").year(),
					moment(minDate, "YYYY-MM-DD").year()
				)}
				initValue={year}
				className="year"
				leaveScrollMode={true}
			/>
		</div>
	);
};
