import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import Sidebar from "views/Sidebar";
import Content from "views/Content";
import ChatReceived from "components/chatSideBar";
import ChatContent from "components/chatContent";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import BottomStrip from "components/BottomStrip";
import isMobile from "helpers/isMobile";
import { useEffect } from "react";
import usePopups from "hooks/usePopups";
import { cancelActiveUser } from "redux/chat/actions";
import { getAllChatUsers } from "redux/chatReceived/selectors";
import chatTabs from "consts/chatTabs";

export default () => {
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const active_userId = useSelector((state) => state.chat.active_userId);
	const isFilteredChat = useSelector((state) => state.chat.isFilteredChat);
	const allChatUsers = useSelector(getAllChatUsers);
	const isActiveUserExistsInAllChat = allChatUsers.find((user) => user.uid === active_userId);
	const { _closePopupUnderPopup } = usePopups();
	const dispatch = useDispatch();

	useEffect(() => {
		if (isActiveUserExistsInAllChat) return;

		if (!isFilteredChat) {
			!isMobile() && _closePopupUnderPopup();
			dispatch(cancelActiveUser());
		}
	}, [isActiveUserExistsInAllChat]);

	return isUserLoggedIn ? (
		<>
			<Sidebar>
				<ChatReceived tab={chatTabs.ReceivedFiltered} />
				{isMobile() && <BottomStrip />}
			</Sidebar>
			<Content>{active_userId && <ChatContent />}</Content>
		</>
	) : (
		<Loader />
	);
};
