import i18next from "i18next";

export default ({ message, name }) => {
	return (
		<div className="promotionMessage">
			<p className="specificMessage">
				<b>{message}</b>
			</p>
			<p className="generalMessage">
				<b>{i18next.t("Chat Message.Manualy Promoted Message", { name })}</b>
			</p>
		</div>
	);
};
