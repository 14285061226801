import i18next from "i18next";
import { useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";

export default () => {
	const profile = useSelector(getUserProfile);

	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				{i18next.t("Restriction Messages.Subscriber.Header", {
					name: profile.name,
				})}
			</ModalHeader>
			<ModalBody>{i18next.t("Restriction Messages.Subscriber.Body")}</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
