import { mergeMap, map, takeUntil, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import MassletterService from "services/massletter";
import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import { getLoggedInUserUID } from "redux/auth/selectors";

export const fetchMassLetter = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return MassletterService.fetchMassLetter(loggedInUserUID).pipe(
				map((payload) => ({
					type: types.MASSLETTERS_FETCH_SUCCESSFULLY,
					payload,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.MASSLETTERS_FETCH_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
