import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getProfileGender } from "redux/profile/selectors";
import { useSelector } from "react-redux";

export default () => {
  const { closePopup } = usePopups();
  const gender = useSelector(getProfileGender);

  return (
    <Modal isOpen={true} toggle={closePopup} className='simpleDialog'>
      <ModalHeader>{i18next.t("Subscription.Purchase Successfully Message.Header")}</ModalHeader>
      <ModalBody>{i18next.t(`Subscription.Purchase Successfully Message.Message_${gender}`)}</ModalBody>
      <ModalFooter>
        <Button outline size='sm' color='primary' onClick={closePopup} className='btn-rounded littleBtn'>
          {i18next.t("Ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
