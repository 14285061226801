import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import Switch from "components/Switch";
import { geIsPicturesPrivate } from "redux/profile/selectors";
import profileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";

export default () => {
	const didMount = useRef(false);
	const dispatch = useDispatch();
	const isPicturesPrivate = useSelector(geIsPicturesPrivate);
	const [isPrivate, setIsPrivate] = useState(isPicturesPrivate);

	const handleChange = () => {
		setIsPrivate(!isPrivate);
	};

	useEffect(() => {
		if (didMount.current) {
			dispatch(updateProfile(profileFields.IsPicturesPrivate, isPrivate));
		} else {
			didMount.current = true;
		}
	}, [isPrivate]);

	return (
		<>
			<Switch
				title={
					isPicturesPrivate
						? i18next.t("Edit Pictures.Is private pictures")
						: i18next.t("Edit Pictures.Is Public Pictures")
				}
				value={isPrivate}
				onChange={handleChange}
			/>
			{isPicturesPrivate && <h6>{i18next.t("Edit Pictures.Only users allowed")}</h6>}
		</>
	);
};
