import firebase from "firebase/app";
import "firebase/database";
class TestService {
	BASE_REF = "tests";

	async test(uid, data) {
		try {
			return await firebase.database().ref(this.BASE_REF).child(uid).set({
				data: data,
			});
		} catch (error) {
			alert("error " + error);
		}
	}
}

export default new TestService();
