import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";

import { skipToggle } from "redux/skips/actions";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { cancelActiveUser } from "redux/chat/actions";

export default () => {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector(getPopups);
	const currentUser = useSelector(getUserProfile);
	const { closePopup, _closePopupUnderPopup } = usePopups();
	const history = useHistory();
	const currentPath = history.location.pathname;
	const isChat = () => currentPath.indexOf("messages") !== -1;

	const unmatchUserHandler = () => {
		dispatch(skipToggle(currentUserProfile.uid, true, true));
		closePopup();
		_closePopupUnderPopup();
		isChat() && dispatch(cancelActiveUser());
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>
				<i className="ri-archive-line float-end text-muted jsmartDrop jsmartDislike"></i>
				{i18next.t("Unmatch")}
			</ModalHeader>
			<ModalBody>
				<span>
					{i18next.t(`Unmatch Message ${currentUser.gender}`, {
						name: currentUserProfile.name,
					})}
				</span>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={unmatchUserHandler}
					className="btn-rounded littleBtn"
				>
					{i18next.t("yes")}
				</Button>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn"
				>
					{i18next.t("no")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
