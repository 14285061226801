import { useState } from "react";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";

import usePopups from "hooks/usePopups";
import DropDown from "components/DropDown";
import { freeze } from "redux/general/actions";
import FreezeReasons from "consts/freezeReasons";

export default () => {
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const { showFreezeAccountSuccessfullyPopup, closePopup } = usePopups();
  const history = useHistory();

  const onFreeze = () => {
    dispatch(freeze(i18next.t(`Freeze.Reasons.${reason}`)));
    showFreezeAccountSuccessfullyPopup();
    setTimeout(() => {
      history.push("/logout");
    }, 2000);
  };

  return (
    <Modal isOpen={true} toggle={closePopup} className="simpleDialog">
      <div className="user-chat-nav ">
        <Button
          color="none"
          type="button"
          onClick={closePopup}
          className="nav-btn"
          id="user-profile-hide"
        >
          <i className="ri-close-line"></i>
        </Button>
      </div>
      <ModalHeader className="freezeAccount">
        {i18next.t("Freeze.Modal Title")}
      </ModalHeader>
      <ModalBody>
        <DropDown
          fieldOptions={Object.values(FreezeReasons)}
          onChange={setReason}
          title={i18next.t("Freeze.Modal Sub Title")}
          translatePrefix="Freeze.Reasons"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onFreeze}
          disabled={reason === ""}
          outline
          size="sm"
          color="primary"
          className="btn-rounded littleBtn"
        >
          {i18next.t("Account Settings.Freeze account")}
        </Button>
        <Button
          onClick={closePopup}
          outline
          size="sm"
          color="primary"
          className="btn-rounded littleBtn mainBtn"
        >
          {i18next.t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
