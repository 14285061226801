export default {
  Age: "Age",
  Height: "Height",
  MaritalStatus: "MaritalStatus",
  Children: "Children",
  Stance: "Stance",
  Range: "Range",
  PrayerTime: "PrayerTime",
  Origin: "Origin",
  Education: "Education",
  Smoking: "Smoking",
};
