import { catchError, filter, map, mergeMap, repeat, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { isNil } from "lodash";
import { of } from "rxjs";

import * as types from "./actionTypes";
import * as chatReceiveTypes from "../chatReceived/actionTypes";
import * as chatReceiveFiltered from "../chatReceivedFiltered/actionTypes";
import * as chatSentTypes from "../chatSent/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import * as promotionsTypes from "../promotions/actionTypes";
import * as AuthTypes from "../auth/actionTypes";
import * as likedMeTypes from "../likedMe/actionTypes";
import { fetchPublicProfile, reFetchPublicProfile } from "./actions";
import { getUserPublicProfile } from "./selectors";
import PublicProfileService from "services/publicProfile";
import ApiRequestStatus from "consts/apiRequestStatus";
import { getLoggedInUserUID } from "../auth/selectors";
import publicProfileUpdater from "services/publicProfileUpdater";

export const fetchUsersProfiles = (action$, store) =>
	action$.pipe(
		ofType(
			chatReceiveTypes.RESET_CHAT_RECEIVED_USERS_SUCCESSFULLY,
			chatReceiveTypes.CHAT_RECEIVED_FETCH_SUCCESSFULLY,
			chatReceiveTypes.CHAT_RECEIVED_ADDED,
			chatReceiveFiltered.RESET_CHAT_RECEIVED_FILTERED_USERS_SUCCESSFULLY,
			chatReceiveFiltered.CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY,
			chatReceiveFiltered.CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY,
			chatSentTypes.RESET_CHAT_SENT_USERS_SUCCESSFULLY,
			chatSentTypes.CHAT_SENT_FETCH_SUCCESSFULLY,
			chatSentTypes.CHAT_SENT_ADDED_SUCCESSFULLY,
			chatSentTypes.CHAT_SENT_ADD_TEMPORARY_CHAT_SENT,
			likedMeTypes.LIKED_ME_ADDED,
			blocksTypes.BLOCKS_FETCH_SUCCESSFULLY,
			blocksTypes.BLOCKS_ADDED,
			blocksTypes.BLOCKS_BLOCKED_ME_FETCH_SUCCESSFULLY,
			blocksTypes.BLOCKS_BLOCKED_ME_BY_USER_ADDED,
			promotionsTypes.PROMOTIONS_FETCH_SUCCESSFULLY
		),
		mergeMap(({ payload }) => {
			let uids = [];

			if (!!payload?.users) {
				uids = Object.keys(payload.users);
			} else {
				uids = Object.keys(payload);
			}

			const publicProfileActions = uids.map((uid) => fetchPublicProfile(uid));
			return publicProfileActions;
		})
	);

export const fetchLoggedInUserProfile = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return of(fetchPublicProfile(loggedInUserUID));
		})
	);

export const fetchPublicUsersProfile = (action$, store) =>
	action$.pipe(
		ofType(types.PUBLIC_PROFILE_FETCH_PROFILE, types.PUBLIC_PROFILE_REFETCH_PROFILE),
		filter(({ type, payload }) => {
			const state = store.value;
			const profile = getUserPublicProfile(payload)(state);
			return (
				isNil(profile) ||
				profile.status === ApiRequestStatus.PENDING ||
				type === types.PUBLIC_PROFILE_REFETCH_PROFILE
			);
		}),
		mergeMap(async ({ payload }) => {
			try {
				const user = await PublicProfileService.fetchProfile(payload);
				return {
					type: types.PUBLIC_PROFILE_FETCH_PROFILE_SUCCESSFULLY,
					user: user || {},
					userUID: payload,
				};
			} catch (error) {
				return {
					type: types.PUBLIC_PROFILE_FETCH_PROFILE_FAILED,
					payload,
					error: error?.message,
					userUID: payload,
				};
			}
		})
	);

export const listenToProfileAdded = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			return publicProfileUpdater.listenChildAdded().pipe(
				filter((key) => {
					const state = store.value;
					const profile = getUserPublicProfile(key)(state);
					return !isNil(profile);
				}),
				map((key) => {
					return reFetchPublicProfile(key);
				})
			);
		}),
		takeUntil(action$.pipe(ofType(AuthTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.PUBLIC_PROFILE_LISTEN_PROFILE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenToProfileUpdate = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			return publicProfileUpdater.listenChildChanged().pipe(
				filter((key) => {
					const state = store.value;
					const profile = getUserPublicProfile(key)(state);
					return !isNil(profile);
				}),
				map((key) => {
					return reFetchPublicProfile(key);
				})
			);
		}),
		takeUntil(action$.pipe(ofType(AuthTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.PUBLIC_PROFILE_LISTEN_PROFILE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
