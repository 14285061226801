import firebase from "firebase/app";
import "firebase/database";

class ViewsService {
	BASE_REF = "views";

	view(uid, colleagueUid) {
		firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set({
			timeStamp: firebase.database.ServerValue.TIMESTAMP,
		});
	}
}

export default new ViewsService();
