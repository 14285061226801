import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isUserLoggedIn, getIsAuthChecked } from "redux/auth/selectors";
import useNavigator from "hooks/useNavigator";
import { getUserProfile } from "redux/profile/selectors";

export default ({ children, checkAuth }) => {
	const navigator = useNavigator();
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const profile = useSelector(getUserProfile);

	useEffect(() => {
		if (checkAuth && isAuthChecked && profile) {
			if (!isLoggedIn || (profile?.uid && !profile.hasCompleteRegistration)) {
				navigator.navigateToHomePage();
			}
		}
	}, [profile, isLoggedIn, isAuthChecked]);

	return children;
};
