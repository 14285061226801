import RedirectToChatButton from "./RedirectToChatButton";
import SendMessageButton from "./SendMessageButton";

export default ({ user, chatID }) => {
	return (
		<div className="helloAction">
			{chatID ? (
				<RedirectToChatButton user={user} chatID={chatID} />
			) : (
				<SendMessageButton user={user} />
			)}
		</div>
	);
};
