import React, { useState, useEffect } from "react";

import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from "reactstrap";

export default ({
	options,
	onChange,
	onValidityChange = () => {},
	defaultTitle = "",
	initValue,
	className = "",
	leaveScrollMode = false,
}) => {
	const [value, setValue] = useState(initValue);
	const [isValid, setIsValid] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleOnClick = (_value) => {
		setValue(_value);
		onChange(_value);
		setIsValid(!!_value);
	};

	useEffect(() => {
		initValue && handleOnClick(value);
	}, []);

	useEffect(() => {
		onValidityChange(!!isValid);
	}, [isValid]);

	useEffect(() => {
		if (!leaveScrollMode || !className) return;
		const dropDown = document.querySelector("." + className);
		const active = dropDown.querySelector(".active");
		active && (dropDown.scrollTop = active.offsetTop);
	});

	return (
		<div className="wraptxt">
			<Dropdown className="dropDown" isOpen={isOpen} toggle={() => setIsOpen((v) => !v)}>
				<DropdownToggle tag="a" className="nav-link">
					{value || defaultTitle}
				</DropdownToggle>
				<DropdownMenu className={className}>
					{options.map((item) => (
						<DropdownItem
							className={item === value ? "active" : ""}
							key={item}
							active={item === value}
							onClick={() => handleOnClick(item)}
							value={item}>
							{item}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};
