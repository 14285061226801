export const HEIGHT_MIN = 130;
export const HEIGHT_MAX = 210;

export default {
  name: {
    presence: { allowEmpty: false, message: "required" },
    length: {
      minimum: 2,
      tooShort: "tooShort",
    },
  },
  height: {
    numericality: {
      greaterThan: HEIGHT_MIN,
      lessThanOrEqualTo: HEIGHT_MAX,
      notValid: "Height not valid",
      notInteger: "Height not integer",
      notGreaterThan: "Height not greater than",
      notLessThanOrEqualTo: "Height not less than or equal to",
    },
  },
  email: {
    email: {
      message: "invalid email",
    },
  },
};
