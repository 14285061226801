import React from 'react';
import moment from 'moment';
import i18next from 'i18next';

import { isSameDay } from 'helpers/date';
import { dateFormat } from 'consts/date';

export default ({ currentMessage , previousMessage }) => {
    
const addDateBeforeMessage = currentMessage && !isSameDay(currentMessage , previousMessage) ?  true : false;
const isToday = moment(currentMessage.timeStamp).isSame(new Date(), "day");
const isYesterday = moment(currentMessage.timeStamp).isSame(moment().add(-1, 'days'), "day");

    return (
        <>
            {addDateBeforeMessage && 
                    <li >
                        <div className="chat-day-title">
                            <span className="title">{
                                isToday ? i18next.t('Today') : isYesterday ? i18next.t('Yesterday') :
                                moment(currentMessage.timeStamp).format(dateFormat)
                            }</span>
                        </div>
                    </li>}
        </>
    );
};