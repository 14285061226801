import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

const initialState = {
	users: {},
	apiStatus: null,
	loadMoreStatus: null,
	pitId: null,
	searchAfter: null,
	hasMoreResults: true,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.VIEWS_USERS_FETCH:
			return {
				...state,
				apiStatus: API_REQUESTS_STATUS.PENDING,
				pitId: null,
				searchAfter: null,
				hasMoreResults: true,
			};
		case types.VIEWS_USERS_FETCH_LOAD_MORE:
			return {
				...state,
				loadMoreStatus: API_REQUESTS_STATUS.PENDING,
			};
		case types.VIEWS_USERS_FETCH_SUCCESSFULLY:
			return {
				...state,
				users: { ...action.payload.users },
				apiStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
			};
		// case blocksTypes.BLOCKS_UNBLOCK_USER_SUCCESSFULLY:
		// 	state?.data && state.data[action.user.uid] && (state.users[action.user.uid] = action.user);
		// 	break;
		case types.VIEWS_USERS_FETCH_LOAD_MORE_SUCCESSFULLY:
			return {
				...state,
				users: { ...action.payload.users, ...state.users },
				loadMoreStatus: API_REQUESTS_STATUS.SUCCESSFULLY,
				pitId: action.payload.pitId,
				searchAfter: action.payload.searchAfter,
				hasMoreResults: action.payload.hasMoreResults,
			};
		case types.VIEWS_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY:
			state.users[action.user.uid] = action.user;
			break;
		case types.VIEWS_FETCH_SUCCESSFULLY:
			state.data = action.payload;
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
