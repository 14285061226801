import React, { useState } from "react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter, Accordion } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import { Reasons } from "consts/reasonsReport";
import { reportUser } from "redux/blocks/actions";
import SectionAccordion from "./sectionAccordion";
import SectionAccordionSub from "./sectionAccordionSub";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";

export default () => {
	const [activeSection, setActiveSection] = useState();
	const [activeSubSection, setActiveSubSection] = useState();
	const [message, setMessage] = useState("");
	const { currentUserProfile, currentUserPromotedProfile } = useSelector(getPopups);
	const user = currentUserPromotedProfile || currentUserProfile;
	const currentUser = useSelector(getUserProfile);
	const { showThanksReportUserPopup, closePopup, _closePopupUnderPopup } = usePopups();
	const dispatch = useDispatch();

	const reportUserHandler = () => {
		dispatch(reportUser(user.uid, i18next.t(Reasons[activeSection]), message, currentUser.email));
		_closePopupUnderPopup();
		showThanksReportUserPopup();
	};

	const onChangeMessage = (e) => setMessage(e.target.value);

	const toggleActiveSection = (clickedSection) => {
		if (clickedSection !== activeSection) {
			setActiveSection(clickedSection);
			setActiveSubSection(null);
			setMessage("");
		} else {
			setActiveSection(null);
		}
	};

	return (
		<Modal isOpen toggle={closePopup} className="bigSlideDialog">
			<Button
				color="none"
				type="button"
				onClick={closePopup}
				className="nav-btn closeButton"
				id="user-profile-hide">
				<i className="ri-close-line"></i>
			</Button>
			<ModalHeader>
				<div className="topHeader">
					<i className="ri-archive-line float-start text-muted jsmartDrop jsmartReport"> </i>
					{i18next.t(`Report ${currentUser?.gender}`)}
				</div>
				<div className="titleBig">
					<h5>
						<i className="ri-archive-line text-muted jsmartDrop jsmartReport bigIcon"> </i>
						{i18next.t(`Report ${currentUser?.gender}`)} {user.name}
					</h5>
					<span>
						{i18next.t("Hi")} {currentUser.name},
					</span>
					<span>{i18next.t("Report Sub Title")},</span>
					<span>
						{i18next.t("Report Sub Sub Title")}
						{user.name}
					</span>
				</div>
			</ModalHeader>
			<ModalBody>
				<Accordion open={activeSection} toggle={toggleActiveSection}>
					<SectionAccordionSub
						sectionIndex={0}
						message={message}
						setMessage={setMessage}
						activeSubSection={activeSubSection}
						setActiveSubSection={setActiveSubSection}
					/>
					<SectionAccordion sectionIndex={1} value={message} onChange={onChangeMessage} />
					<SectionAccordion sectionIndex={2} value={message} onChange={onChangeMessage} />
					<SectionAccordion sectionIndex={3} value={message} onChange={onChangeMessage} />
					<SectionAccordion sectionIndex={4} value={message} onChange={onChangeMessage} />
					<SectionAccordion sectionIndex={5} value={message} onChange={onChangeMessage} />
				</Accordion>
			</ModalBody>
			<ModalFooter>
				<Button
					disabled={message === ""}
					outline
					size="sm"
					color="primary"
					onClick={reportUserHandler}
					className="btn-rounded reportBtn mainBtn">
					{i18next.t(`Report Now ${currentUser.gender}`)}
				</Button>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded reportBtn">
					{i18next.t(`Cancel ${currentUser.gender}`)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
