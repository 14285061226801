import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Sidebar from "views/Sidebar";
import Content from "views/Content";
import SearchFilter from "components/search/SearchFilter";
import TopBar from "components/search/TopBar";
import SearchContent from "components/search/SearchContent";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import BottomStrip from "components/BottomStrip";
import Dimmer from "components/Dimmer";
import isMobile from "helpers/isMobile";
import searchTypes from "consts/typesSearch";
import { useScrollDirection } from "../hooks/useScrollDirection";
import LocalStorageService from "services/localStorage";
import FloatingGoUpButton from "./FloatingGoUpButton";

export default () => {
	const [showSearchFilterSlide, setShowSearchFilterSlide] = useState(false);
	const [searchLocation, setSearchLocation] = useState();
	const [showFloatingGoUpButton, setShowFloatingGoUpButton] = useState(false);
	const [pageYOffset, setPageYOffset] = useState(window.pageYOffset);

	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const searchPageRef = useRef();

	const scrollToTop = () => {
		searchPageRef.current.scrollIntoView();
	};

	//NAVBAR
	const navRef = useRef(null);
	const scrollDirection = useScrollDirection(window);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setPageYOffset(window.pageYOffset);
		});
	}, []);

	useEffect(() => {
		if (!!navRef?.current?.classList) {
			if (scrollDirection == "down") {
				navRef.current.classList.remove("downSlide");
				navRef.current.classList.add("upSlide");
				setShowFloatingGoUpButton(true);
			} else if (pageYOffset < 50) {
				navRef.current?.classList.remove("upSlide");
				navRef.current.classList.add("downSlide");
				setShowFloatingGoUpButton(false);
			}
		}
	}, [scrollDirection, pageYOffset]);

	useEffect(() => {
		if (!!navRef?.current?.classList) {
			if (scrollDirection == "up") {
				navRef.current.classList.remove("upSlide");
				navRef.current.classList.add("downSlide");
			}
		}
	}, [scrollDirection]);

	const isFilterTimeExpired = () => {
		const lastFilterTime = Number(LocalStorageService.get("lastFilterTime"));
		const oneMonthAgo = new Date().setMonth(new Date().getMonth() - 1);
		const lastFilterTimeIsMoreThanMonth =
			lastFilterTime && moment(lastFilterTime).isBefore(moment(oneMonthAgo));
		return !lastFilterTime || lastFilterTimeIsMoreThanMonth;
	};

	useEffect(() => {
		// const isFilterExpired = isFilterTimeExpired();
		// if (isFilterExpired) {
		// 	setShowSearchFilterSlide(true);
		// 	LocalStorageService.set("lastFilterTime", Date.now());
		// }
	}, []);

	return true ? (
		<>
			{isUserLoggedIn && (
				<Sidebar
					className={`sideBarSearchFilter ${showSearchFilterSlide ? "showSearchFilterSlide" : ""}`}>
					<SearchFilter
						scrollToTop={scrollToTop}
						searchLocation={searchLocation}
						setSearchLocation={setSearchLocation}
					/>
				</Sidebar>
			)}
			<div className="toBlur"></div>
			<TopBar
				scrollToTop={scrollToTop}
				navRef={navRef}
				className="downSlide"
				typeSearch={searchTypes.Search}
			/>

			<div className="searchPage" ref={searchPageRef}>
				<Content>
					<SearchContent searchLocation={searchLocation} />
					{isMobile() && <BottomStrip />}
				</Content>
			</div>
			{showFloatingGoUpButton && <FloatingGoUpButton onClick={scrollToTop} />}
			<Dimmer />
		</>
	) : (
		<Loader />
	);
};
