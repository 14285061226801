import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import CardsView from "components/CardsView";
import { getHasMoreResults, getIsLoadingMore, getLikesStatus } from "redux/likes/selectors";
import { fetchLikesUsersLoadMore } from "redux/likes/actions";
import { fetchLikesUsers } from "redux/likes/actions";
import { getLikesUsers } from "redux/likes/selectors";
import API_REQUEST_STATUS from "consts/apiRequestStatus";
import Loader from "components/Loader";

export default ({ scrollToTop }) => {
	const dispatch = useDispatch();
	const isLoadingMore = useSelector(getIsLoadingMore);
	const hasMoreResults = useSelector(getHasMoreResults);
	const users = useSelector(getLikesUsers);
	const status = useSelector(getLikesStatus);

	const fetchData = () => {
		if (!isLoadingMore && hasMoreResults) {
			dispatch(fetchLikesUsersLoadMore());
		}
	};

	useEffect(() => {
		dispatch(fetchLikesUsers());
		scrollToTop();
	}, []);

	return (
		<InfiniteScroll dataLength={users.length} next={fetchData} hasMore={true}>
			{status === API_REQUEST_STATUS.SUCCESSFULLY ? (
				<CardsView
					users={users}
					addLikesTime //likedMe timestamp of collage user is equal to likes timestamp
				/>
			) : (
				<Loader className="loaderBeforeResults" />
			)}
		</InfiniteScroll>
	);
};
