import { createSelector } from "reselect";
import { isNil } from "lodash";

import MatchTypes from "consts/matchTypes";
import { getPublicProfiles } from "../publicProfiles/selectors";
import { getBlocksUIDs } from "../blocks/selectors";
import PromotionTypes from "consts/promotionTypes";

const getPromotions = (state) => state.promotions;
const getChatReceived = (state) => state.chatReceived;

export const getPromotionsList = createSelector(
	getPromotions,
	getPublicProfiles,
	getBlocksUIDs,
	getChatReceived,
	(promotions, publicProfiles, blockedUsers, chatReceived) => {
		return Object.keys(promotions || {})
			.filter(
				(uid) =>
					!promotions[uid].deleted && publicProfiles[uid]?.approved && isNil(blockedUsers[uid])
			)
			.map((uid) => ({
				uid,
				...promotions[uid],
				promotionStatus: promotions[uid].status,
				matchType: MatchTypes.Promotion,
				...publicProfiles[uid],
			}));
	}
);

export const getManualyPromotedUsers = createSelector(getPromotionsList, (promotions) => {
	const result = promotions.filter(
		(promotion) => promotion.type === PromotionTypes.ManuallyByBackoffice
	);
	return result;
});

export const getIsManualyPromotedUser = (userUid) =>
	createSelector(
		getManualyPromotedUsers,
		(manualyPromotedUsers) => !!manualyPromotedUsers.find((x) => x.uid === userUid)
	);

export const getIsPromotedUser = (userUid) =>
	createSelector(getPromotions, (promotions) => !!promotions[userUid]);
