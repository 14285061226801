import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class LikedMeService {
	BASE_REF = "liked_me";

	async fetchLikedMe(uid) {
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("timeStamp")
					.startAt(null)
					.once("value")
			).val() || {}
		);
	}

	listenLikedMeAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("timeStamp")
				.startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}
	listenLikedMeAddedFromNow(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenLikedMeRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}

	listenChildChanged(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	virtualDeleteLike(colleagueUid) {
		const uid = firebase.auth().currentUser?.uid;
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child(colleagueUid)
			.child("deleted")
			.set(true);
	}
}

export default new LikedMeService();
