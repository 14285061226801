export const CHAT_META_FETCH_SUCCESSFULLY = "chatMeta.CHAT_META_FETCH_SUCCESSFULLY";
export const CHAT_META_FETCH_FAILED = "chatMeta.CHAT_META_FETCH_FAILED";
export const CHAT_META_UPDATE_SUCESSFULLY = "chatMeta.CHAT_META_UPDATE_SUCESSFULLY";
export const CHAT_META_UPDATE_FAILED = "chatMeta.CHAT_META_UPDATE_FAILED";
export const CHAT_META_ADDED_SUCESSFULLY = "chatMeta.CHAT_META_ADDED_SUCESSFULLY";
export const CHAT_META_ADDED_FAILED = "chatMeta.CHAT_META_ADDED_FAILED";
export const CHAT_META_CHAT_REMOVED_SUCCESSFULLY = "chatMeta.CHAT_META_CHAT_REMOVED_SUCCESSFULLY";
export const CHAT_META_CHAT_REMOVED_FAILED = "chatMeta.CHAT_META_CHAT_REMOVED_FAILED";
export const CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_SUCCESSFULLY =
	"chatMeta.CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_SUCCESSFULLY";
export const CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_FAILED =
	"chatMeta.CHAT_META_CHAT_TYPING_LISTEN_ONDISCONECT_FAILED";
export const CHAT_META_PROMOTION_READ_UPDATED = "chatMeta.CHAT_META_PROMOTION_READ_UPDATED";
export const CHAT_META_CLEAR_UNVIEWED_NEW_MESSAGES =
	"chatMeta.CHAT_META_CLEAR_UNVIEWED_NEW_MESSAGES";
