import useNavigator from "hooks/useNavigator";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import i18next from "i18next";
import { useState } from "react";
import { getUserProfile } from "redux/profile/selectors";
import TextArea from "components/TextArea";
import { Label } from "reactstrap";
import usePopups from "hooks/usePopups";
import Button from "components/Button";
import contactsService from "services/contactsService";
import { CircularProgress } from "@mui/material";

export default function ReplyContectUs() {
	const { showContactFormSentSuccessfullyPopup } = usePopups();
	const { name } = useSelector(getUserProfile);
	const { key } = useParams();
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const navigator = useNavigator();

	const onSend = (e) => {
		e.preventDefault();
		if (!message || !key) return;
		setError(false);
		setLoading(true);
		contactsService.replayContactUs(key, message).then((data) => {
			if (!!data?.data?.success) {
				showContactFormSentSuccessfullyPopup();
				setMessage("");
				closePage();
			} else if (data.data?.msg?.includes("key not exist")) {
				setError(true);
			}
			setLoading(false);
		});
	};

	const closePage = () => {
		navigator.navigateToHomePage();
	};
	return (
		<Box className="contactPage Feedback">
			<Link to="#" onClick={closePage} style={{ margin: "0 0 10px" }}>
				<i className="ri-arrow-left-s-line"></i>
				<h5 className="mainTitle" style={{ margin: "0" }}>
					{i18next.t("Pages.ReplyContact")}
				</h5>
			</Link>

			{!!name && (
				<h5 className="mainTitle" style={{ margin: 0 }}>
					{i18next.t("Contact.hey")} {name},
				</h5>
			)}
			<h5 className="mainTitle">{i18next.t("Contact.replyDesc")}</h5>
			<Box>
				<TextArea onChange={setMessage} key={key} rows={4} />
			</Box>
			{error && (
				<Box>
					<span style={{ color: "red" }}>אי אפשר לכתוב תגובה</span>
				</Box>
			)}
			<Box className="wrapMainBtn">
				{!!loading ? (
					<CircularProgress size={40} className="text-secondary" />
				) : (
					<Button
						type="submit"
						disabled={message.trim() === ""}
						className="mainAccountBtn"
						onClick={onSend}
						text={i18next.t("Contact.Send")}
					/>
				)}
			</Box>
		</Box>
	);
}
