import { useEffect, useState } from "react";
import _ from "lodash";
import StepWizard from "react-step-wizard";

import LoginSteps, { LoginStepsMap } from "consts/loginSteps";
import SocialStep from "./SocialStep";
import NameStep from "./NameStep";
import GenderStep from "./GenderStep";
import HeightStep from "./HeightStep";
import AgeStep from "./AgeStep";
import ChildrenStep from "./ChildrenStep";
import ChildrenCountStep from "./ChildrenCountStep";
import KeepShabbatStep from "./KeepShabbatStep";
import KosherFoodStep from "./KosherFoodStep";
import MartialStatusStep from "./MartialStatusStep";
import StanceStep from "./StanceStep";
import AboutMeStep from "./AboutMeStep";
import UploadImageStep from "./UploadImageStep";
import LivingCityStep from "./LivingCityStep";
import EmailStep from "./EmailStep";

export default ({ profile, stepNames, onStepsFinish, className = "" }) => {
	const [_stepNames, _setStepNames] = useState([]);
	const [initStep, setInitStep] = useState([]);

	useEffect(() => {
		if (_.isEmpty(_stepNames)) {
			_setStepNames(stepNames);
			const stepMap = LoginStepsMap[stepNames[0]];
			setInitStep(stepMap);
		}
	}, [stepNames]);

	return (
		_stepNames &&
		_stepNames.length > 0 && (
			<StepWizard
				isLazyMount={true}
				initialStep={initStep}
				steps={_stepNames}
				onFinalStepFinish={onStepsFinish}
				className={className}>
				<SocialStep
					stepName={LoginSteps.Social}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<NameStep
					stepName={LoginSteps.Name}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<GenderStep
					stepName={LoginSteps.Gender}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<AgeStep
					stepName={LoginSteps.Age}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<MartialStatusStep
					stepName={LoginSteps.MartialStatus}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<ChildrenStep
					stepName={LoginSteps.Children}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<ChildrenCountStep
					stepName={LoginSteps.ChildrenCount}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<StanceStep
					stepName={LoginSteps.Stance}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<KeepShabbatStep
					stepName={LoginSteps.KeepShabbat}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<KosherFoodStep
					stepName={LoginSteps.KosherFood}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<HeightStep
					stepName={LoginSteps.Height}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<AboutMeStep
					stepName={LoginSteps.AboutMe}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<LivingCityStep
					stepName={LoginSteps.LivingCity}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<UploadImageStep
					stepName={LoginSteps.UploadImage}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
				<EmailStep
					stepName={LoginSteps.Email}
					profile={profile}
					steps={_stepNames}
					onFinalStepFinish={onStepsFinish}
				/>
			</StepWizard>
		)
	);
};
