import React, { useState } from "react";
import i18next from "i18next";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";

import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import usePopups from "hooks/usePopups";

export default () => {
	const [isOpen, setIsOpen] = useState(false);
	const currentUser = useSelector(getUserProfile);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};

	const { showBlockUserPopup, showReportUserPopup } = usePopups();

	const toggle = () => setIsOpen(!isOpen);

	return (
		<Dropdown isOpen={isOpen} toggle={toggle} className="dropMenuTop">
			<DropdownToggle id="user-blocks" className="btn nav-btn " color="none" type="button">
				<i className="ri-more-fill jsmartIcon jsmartMenu"></i>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu-end">
				<DropdownItem onClick={() => showBlockUserPopup(activeUserProfile)}>
					<i className="ri-delete-bin-line float-end text-muted jsmartDrop jsmartBlock"></i>
					{i18next.t(`Block ${currentUser?.gender}`)}
				</DropdownItem>
				<DropdownItem onClick={() => showReportUserPopup(activeUserProfile)}>
					<i className="ri-volume-mute-line float-end text-muted jsmartDrop jsmartReport"></i>
					{i18next.t(`Report ${currentUser?.gender}`)}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};
