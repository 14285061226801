import { useEffect, useState } from "react";
import i18next from "i18next";
import { Button } from "reactstrap";
import _ from "lodash";
import Subtitle from "components/Subtitle";
import SelectButtonGroup from "./SelectButtonGroup";

export default ({
	onChange,
	options,
	className = "",
	subtitle = "",
	translatePrefix,
	SearchFilterKey,
	initSelected = [],
}) => {
	const [items, setItems] = useState();
	const handleSelected = (key) => {
		const _items = { ...items, [key]: !items[key] };
		const itemsAfterChange = Object.keys(_items).filter((item) => _items[item]);
		onChange(SearchFilterKey, itemsAfterChange);
	};
	useEffect(() => {
		if (options) {
			const _items = options.reduce((res, cur) => {
				res[cur] = false;
				return res;
			}, {});
			if (initSelected) {
				initSelected.forEach((key) => {
					_items[key] = true;
				});
			}
			setItems((lastItems) => {
				if (JSON.stringify(lastItems) === JSON.stringify(_items)) {
					return lastItems;
				}
				return _items;
			});
		}
	}, [initSelected, options]);

	return (
		<div className="section">
			<Subtitle className="colorLabel">{subtitle}</Subtitle>
			<span className={`tinyTitle ${className}`}>ניתן לבחור מס' אפשרויות</span>
			<SelectButtonGroup items={items} onClick={handleSelected} translatePrefix={translatePrefix} />
		</div>
	);
};
