import i18next from "i18next";
import { Link } from "react-router-dom";

import usePopups from "hooks/usePopups";

export default () => {
	const { showDeleteAccountPopup } = usePopups();

	return (
		<Link to="#" onClick={showDeleteAccountPopup} className="deleteAccount">
			{i18next.t("Account Settings.Delete account")}
		</Link>
	);
};
