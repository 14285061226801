import { createSelector } from "reselect";
import { orderBy } from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";
import _ from "lodash";

export const getLikedMe = (state) => state.likedMe;

export const getLikedMeData = createSelector(getLikedMe, (likedMe) => likedMe.data || {});

export const getLikedMeSortByDate = createSelector(
	getLikedMeData,
	getBlocks,
	(likedMeData, blocks) => {
		const likedMeArray = Object.entries(likedMeData).map(([key, value]) => ({
			...value,
			uid: key,
		}));
		const likedMeFilteredDeleted = likedMeArray
			.filter(({ deleted }) => deleted !== true)
			.filter((user) => !blocks[user.uid]);
		return orderBy(likedMeFilteredDeleted, "timeStamp", "desc");
	}
);

export const getUnViewLikedMeUsers = createSelector(getLikedMeSortByDate, (likedMe) => {
	return likedMe.filter(({ unView }) => unView);
});

export const getUidsUnViewedLikedMe = createSelector(getUnViewLikedMeUsers, (likedMeUnViewed) => {
	const result = likedMeUnViewed.filter((item) => item.deleted !== true && !item.unSync);
	return result.map((item) => item.uid);
});

export const getPitId = createSelector(getLikedMe, (likedMe) => likedMe.pitId);

export const getSearchAfter = createSelector(getLikedMe, (likes) => likes.searchAfter);

export const getLikedMeUsers = createSelector(
	getLikedMe,
	getBlocks,
	getUidsUnViewedLikedMe,
	(likedMe, blocks, newLikedMe = []) => {
		const likedMeList = Object.keys(likedMe.users)
			.map((uid) => likedMe.users[uid])
			.filter((user) => !user.deleted && !blocks[user.uid]);

		const likedMeSorted = _.sortBy(likedMeList, (item) => {
			if (newLikedMe.includes(item.uid)) {
				return -1;
			} else {
				return item.sort[0];
			}
		});
		return likedMeSorted;
	}
);

export const getIsLoadingMore = createSelector(
	getLikedMe,
	(likedMe) => likedMe.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getLikedMe, (likedMe) => likedMe.hasMoreResults);

export const getLikedMeStatus = createSelector(getLikedMe, (likedMe) => likedMe.apiStatus);
