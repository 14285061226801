import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";
import MaritalStatus from "components/search/SearchFiltersInputs/MaritalStatus";
import Stance from "components/search/SearchFiltersInputs/Stance";

import { getUserProfile } from "redux/profile/selectors";
import AgeRangeInputs from "components/AgeRangeInputs";
import {
	updateMessagesFilter,
	updateMessageFilterInit,
	updateMessageFilterSubmit,
} from "redux/chatReceivedFiltered/actions";
import { getMessagesFilter } from "redux/general/selectors";
import { getStatus } from "redux/chatReceivedFiltered/selectors";
import MessagesFilterKeys from "consts/messagesFilterKeys";
import apiRequestStatus from "consts/apiRequestStatus";

export default () => {
	const filter = useSelector(getMessagesFilter);
	const profile = useSelector(getUserProfile);
	const status = useSelector(getStatus);
	const [state, setState] = useState(filter);
	const dispatch = useDispatch();
	const { closePopup } = usePopups();

	const handleChange = (key, value, key2, value2) => {
		const _state = { ...state };

		if (!!key && !!value) {
			_state[key] = value;
		}

		if (!!key2 && !!value2) {
			_state[key2] = value2;
		}

		setState(_state);
	};

	const handleSend = () => {
		dispatch(updateMessagesFilter(state));
	};

	const handleCancel = () => {
		closePopup();
	};

	const handleSubmit = () => {
		dispatch(updateMessageFilterSubmit());
		closePopup();
	};

	useEffect(() => {
		dispatch(updateMessageFilterInit());
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog recievedFilter">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				<h4>{i18next.t(`Popups.ChatRecievedFilter.Title`)}</h4>
			</ModalHeader>
			{status === apiRequestStatus.INIT && (
				<>
					<ModalBody>
						<h5>
							ברצוני <h5 className="boldTitle"> לקבל</h5> פניות לדואר הנכנס מ:{" "}
						</h5>
						<AgeRangeInputs
							className="rangeFormailFilter"
							onChange={(key, val) =>
								handleChange(MessagesFilterKeys.AgeFrom, val.from, MessagesFilterKeys.AgeTo, val.to)
							}
							profile={profile}
							filter={{
								from: state[MessagesFilterKeys.AgeFrom],
								to: state[MessagesFilterKeys.AgeTo],
							}}
						/>
						<MaritalStatus
							onChange={(key, val) => handleChange(MessagesFilterKeys.MaritalStatus, val)}
							profile={profile}
							filter={state[MessagesFilterKeys.MaritalStatus]}
						/>
						<Stance
							onChange={(key, val) => handleChange(MessagesFilterKeys.Stance, val)}
							profile={profile}
							filter={state[MessagesFilterKeys.Stance]}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							outline
							size="sm"
							color="secondary"
							onClick={handleCancel}
							className="btn-rounded littleBtn">
							{i18next.t("Cancel")}
						</Button>
						<Button
							size="md"
							color="secondary"
							onClick={handleSend}
							className="btn-rounded littleBtn saveBtn">
							{i18next.t(`Save_${profile.gender}`)}
						</Button>
						<span className="alert">
							{i18next.t("Chat Received.Note Message_" + profile.gender)}
						</span>
					</ModalFooter>
				</>
			)}
			{status === apiRequestStatus.PENDING && (
				<>
					<ModalBody>
						<h5>{i18next.t("Chat Received.Saved Successfully")}</h5>
					</ModalBody>
					<ModalFooter>
						<Button
							size="md"
							color="secondary"
							onClick={handleSubmit}
							className="btn-rounded littleBtn saveBtn">
							{i18next.t(`Continue`)}
						</Button>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};
